import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import { EditDocumentFolderForm } from '../containers';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';

type EditFolderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  folder: NormalizedFolderTreeItem;
};

const EditFolderModal: FC<EditFolderModalProps> = ({ isOpen, onClose, folder, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="write" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: 'lg', textAlign: 'center', mb: 3 }}>
            Edit Folder
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>Changing the name or location of a folder.</Text>
          <EditDocumentFolderForm folder={folder} onClose={onClose} />
        </CardBody>
      </Card>
    </Modal>
  );
};

export default EditFolderModal;
