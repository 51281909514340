import { LenderLocationsSummary } from '~/generated/graphql';
import formatList from '../formatList';

export default function getLenderLocationsSummaryString(locations: LenderLocationsSummary): string {
  let summary = '';

  const hasHQ = locations.mainOffice;
  const states = Object.keys(locations.otherOffices ?? {});

  const totalStates = states.length;
  const totalCities = states.reduce((sum, state) => sum + Object.keys(locations.otherOffices![state]).length, 0);
  const totalOffices = states.reduce(
    (sum, state) => sum + (Object as LenderLocationsSummary['otherOffices'])!.values(locations.otherOffices![state]).reduce((a: number, b: number) => a + b, 0),
    0,
  );

  if (hasHQ) {
    summary += `Headquarters in ${locations.mainOffice!.city}, ${locations.mainOffice!.state}${totalOffices >= 1 ? ' and ' : ''}`;
  }

  if (totalOffices === 1) {
    summary += `1${hasHQ ? ' additional' : ''} office in ${Object.keys(locations.otherOffices![states[0]])[0]}, ${states[0]}`;
  } else if (totalCities === 1 && totalStates === 1) {
    summary += `${totalOffices}${hasHQ ? ' additional' : ''} offices in ${Object.keys(locations.otherOffices![states[0]])[0]}, ${states[0]}`;
  } else if (totalCities > 1 && totalStates === 1) {
    summary += `${totalOffices}${hasHQ ? ' additional' : ''} offices in ${states[0]}`;
  } else if (totalStates > 1 && totalStates <= 5) {
    summary += `${totalOffices}${hasHQ ? ' additional' : ''} offices in ${formatList(states)}`;
  } else if (totalStates > 5) {
    summary += `${totalOffices}${hasHQ ? ' additional' : ''} offices in ${totalStates} states`;
  }

  return summary;
}
