import React, { FC, useEffect, useRef, useState } from 'react';
import { Avatar, Modal, ModalDialog, Spinner } from '~/components/ui';
import { Block, Col, FlexCol, Row } from '~/components/layout';
import { Button, InputGroup, InputAddon, Input } from '~/components/form';
import { Card, CardBody, CardHeader } from '~/components/card';
import { getBorrowerLogoUrl } from '~/helpers/getBorrowerLogoUrl';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { Heading, Text } from '~/components/type';
import { Icon } from '~/components/vendor';
import { GlobalSearchResultFragment, useGetSearchQuery, useGlobalElasticSearchLazyQuery, useSaveUserSearchMutation } from '~/generated/graphql';
import { LenderTypeLabels } from '~/data/lenderTypeOptions';
import { search } from '~/components/vendor/Icon/icons';
import { StyledCard } from '~/components/card/Card';
import DealsAvatar from '~/containers/Deals/DealsAvatar';
import Link from 'next/link';
import Router from 'next/router';
import styled, { css } from 'styled-components';
import useAsyncDebounceCallback from '~/hooks/ useAsyncDebounceCallback';

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    &&& {
      box-shadow: inset 0 0 0 100vmax ${theme.colors.black20};

      ${StyledCard} {
        height: 360px;
      }

      @media (max-width: ${theme.breakpoints.desktop - 1}px) {
        ${ModalDialog} {
          align-items: flex-start;
          margin: 0 auto ${theme.spacers[9]}px;
        }

        ${StyledCard} {
          border-radius: 0 0 ${theme.cardBorderRadius}px ${theme.cardBorderRadius}px;
        }
      }
    }
  `}
`;

type SearchModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const SearchModal: FC<SearchModalProps> = ({ isOpen, onClose, onOpen, ...props }) => {
  const itemsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [results, setResults] = useState<GlobalSearchResultFragment[]>([]);
  const [focusedItem, setFocusedItem] = useState(-1);

  const { data: getSearchData } = useGetSearchQuery({
    fetchPolicy: 'cache-first',
  });
  const savedSearches = getSearchData?.getSearch ?? [];

  const [saveSearch] = useSaveUserSearchMutation({ refetchQueries: ['getSearch'] });
  const [globalSearch, { loading }] = useGlobalElasticSearchLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const globalSearchDebounced = useAsyncDebounceCallback(
    async (query: string) => {
      if (!query) {
        return;
      }
      try {
        const { data } = await globalSearch({ variables: { query } });
        if (inputRef.current?.value) {
          setResults(data?.globalElasticSearch ?? []);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    300,
    [],
  );

  useEffect(() => {
    if (!inputValue) {
      setResults(getSearchData?.getSearch ?? []);
    }
  }, [inputValue, getSearchData?.getSearch]);

  const handleKeyUp = (e: KeyboardEvent) => {
    const items = inputValue?.length === 0 ? savedSearches : results;

    if (e.keyCode === 38 && isOpen) {
      if (items.length > 0 && focusedItem > 0) {
        setFocusedItem(focusedItem - 1);
      }
    }

    if (e.keyCode === 40 && isOpen) {
      if (items.length > 0 && focusedItem + 1 < items.length) {
        setFocusedItem(focusedItem + 1);
      }
    }

    if (e.keyCode === 13 && focusedItem !== -1 && isOpen) {
      (itemsRef.current!.querySelectorAll('a')[focusedItem]?.children?.[0] as HTMLDivElement).click();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  useEffect(() => {
    if (focusedItem !== -1) {
      itemsRef.current!.querySelectorAll('a')[focusedItem]?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [focusedItem]);

  useEffect(() => setFocusedItem(-1), [results, isOpen]);

  Router.events.on('routeChangeComplete', () => {
    onClose();
    setInputValue('');
  });

  return (
    <StyledModal size="lg" timeout={{ enter: 500, exit: 0 }} isOpen={isOpen} onClose={onClose} {...props}>
      <Card as={FlexCol} size="sm" isModalContent>
        <CardHeader>
          <InputGroup isFlush>
            <InputAddon>{inputValue && loading ? <Spinner variant="primary" size="xs" isAbsolute={false} /> : <Icon icon={search} />}</InputAddon>
            <Input
              type="search"
              placeholder="Type to search for anything"
              onChange={(e) => {
                const query = e.target.value;
                setInputValue(query);
                globalSearchDebounced(query);
              }}
              ref={inputRef}
              value={inputValue}
              autoFocus
            />
            <Button size="sm" variant="white" onClick={onClose} desktop={{ display: 'none' }}>
              Close
            </Button>
          </InputGroup>
        </CardHeader>

        <CardBody utils={{ height: '100%', py: 6, overflowY: 'auto' }} ref={itemsRef}>
          {savedSearches.length > 0 && results === savedSearches && (
            <Heading utils={{ mb: 6, fontSize: '2xs', textTransform: 'uppercase', color: 'gray600' }}>Recent visited</Heading>
          )}
          {results.map((result, index) => {
            if (result.__typename === 'Company') {
              return (
                <Link href={`/lender/${result.id}/overview`} key={result.id}>
                  <Block
                    utils={{ mx: -5, py: 4, px: 5, borderRadius: 'sm', bgColor: index === focusedItem ? 'gray200' : undefined }}
                    hover={{ bgColor: 'gray200' }}
                    onClick={() => saveSearch({ variables: { searchId: result.id } })}
                  >
                    <Row gutter={3} utils={{ alignItems: 'center' }}>
                      <Col span="auto">
                        <Avatar size="sm" src={getLogoUrl(result)} alt={result.name} utils={{ borderRadius: 'sm' }} imageProps={{ utils: { border: 1 } }} />
                      </Col>
                      <Col>
                        <Text utils={{ fontSize: 'sm', color: 'black', textTruncate: true }}>{result.name}</Text>
                      </Col>
                      <Col span="auto">
                        <Text utils={{ fontSize: 'sm', whiteSpace: 'nowrap', color: 'gray700' }}>{LenderTypeLabels[result.lender_type]}</Text>
                      </Col>
                    </Row>
                  </Block>
                </Link>
              );
            }

            if (result.__typename === 'DealSearchResult') {
              return (
                <Link href={`/deal/${result._id}/home`} key={result._id} shallow>
                  <Block
                    utils={{ mx: -5, py: 4, px: 5, borderRadius: 'sm', bgColor: index === focusedItem ? 'gray200' : undefined }}
                    hover={{ bgColor: 'gray200' }}
                    onClick={() => saveSearch({ variables: { searchId: result._id } })}
                  >
                    <Row gutter={3} utils={{ alignItems: 'center' }}>
                      <Col span="auto">
                        <DealsAvatar
                          size="sm"
                          coverageImageFileId={result.project?.coverageImageFileId}
                          coverageImageCrop={result.project?.coverageImageCrop}
                          lat={result.project?.coordinates?.lat}
                          lng={result.project?.coordinates?.lng}
                          name={result.name}
                          alt={result.name}
                          utils={{ borderRadius: 'sm' }}
                        />
                      </Col>
                      <Col>
                        <Text utils={{ fontSize: 'sm', color: 'black', textTruncate: true }}>{result.name}</Text>
                      </Col>
                      <Col span="auto">
                        <Text utils={{ fontSize: 'sm', whiteSpace: 'nowrap', color: 'gray700' }}>Deal</Text>
                      </Col>
                    </Row>
                  </Block>
                </Link>
              );
            }

            if (result.__typename === 'Borrower') {
              return (
                <Link href={`/borrower/${result._id}/overview`} key={result._id}>
                  <Block
                    utils={{ mx: -5, py: 4, px: 5, borderRadius: 'sm', bgColor: index === focusedItem ? 'gray200' : undefined }}
                    hover={{ bgColor: 'gray200' }}
                    onClick={() => saveSearch({ variables: { searchId: result._id } })}
                  >
                    <Row gutter={3} utils={{ alignItems: 'center' }}>
                      <Col span="auto">
                        <Avatar
                          size="sm"
                          src={getBorrowerLogoUrl(result)}
                          alt={result.name}
                          utils={{ borderRadius: 'sm' }}
                          imageProps={{ utils: { border: 1 } }}
                        />
                      </Col>
                      <Col>
                        <Text utils={{ fontSize: 'sm', color: 'black', textTruncate: true }}>{result.name}</Text>
                      </Col>
                      <Col span="auto">
                        <Text utils={{ fontSize: 'sm', whiteSpace: 'nowrap', color: 'gray700' }}>Borrower</Text>
                      </Col>
                    </Row>
                  </Block>
                </Link>
              );
            }

            return null;
          })}
          {!loading && results.length === 0 && inputValue.length !== 0 && (
            <FlexCol utils={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Text utils={{ fontSize: 'lg' }}>No results for this search.</Text>
              <Text utils={{ fontSize: 'sm', color: 'gray700' }}>If you think this is wrong, DM Dave on Slack.</Text>
            </FlexCol>
          )}
          {results.length === 0 && savedSearches.length === 0 && inputValue.length === 0 && (
            <FlexCol utils={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
              <Text utils={{ fontSize: 'lg' }}>Welcome to Search</Text>
              <Text utils={{ fontSize: 'sm', color: 'gray700' }}>Search for any deal, lender, or property.</Text>
            </FlexCol>
          )}
        </CardBody>
      </Card>
    </StyledModal>
  );
};

export default SearchModal;
