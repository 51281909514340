import { Card } from '~/components/card';
import { Toggle } from '~/components/ui';
import styled, { css } from 'styled-components';

export const ActivityNoteCard = styled(Card).attrs(() => ({ size: 'xs' }))`
  ${({ theme }) => css`
    width: fit-content;

    @media (min-width: ${theme.breakpoints.desktop}px) {
      &:not(:hover) ${Toggle}[aria-expanded='false'] {
        visibility: hidden;
      }
    }
  `}
`;
