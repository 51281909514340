import React, { useState, useEffect } from 'react';
import { ActivityItemLink } from '../../components/activity/ActivityItemLink';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import { ActivityDocumentRequestFragment } from '~/generated/graphql';
import { DocumentsModal, EditDocumentRequestModal, DocumentsDeleteModal } from '~/modals';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { Text } from '~/components/type';
import { useRouter } from 'next/router';
import useDocumentFoldersOps from '~/containers/Document/useDocumentFoldersOps';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

interface Props {
  documentRequest: ActivityDocumentRequestFragment;
  search?: string;
  skipQueryUpdate?: boolean;
}

const DocumentRequestName: React.FC<Props> = ({ documentRequest, search, skipQueryUpdate }) => {
  const showGlobalAlert = useShowGlobalAlert();
  const router = useRouter();
  const dealId = router.query?.dealId as string;

  const [isDocumentModalVisible, setDocumentModalVisibility] = useState(false);
  const [activeDocumentRequest, setDocumentRequest] = useState<NormalizedFolderTreeItem | null>(null);
  const [editRequestModal, setEditRequestModal] = useState<{ isOpen: boolean; request?: NormalizedFolderTreeItem }>({
    isOpen: false,
  });

  const [deleteFolderModal, setDeleteFolderModal] = useState<{ isOpen: boolean; item?: NormalizedFolderTreeItem }>({
    isOpen: false,
  });

  useEffect(() => {
    if (isDocumentModalVisible && documentRequest.isActive === false) {
      setDocumentModalVisibility(false);
      setDocumentRequest(null);
      showGlobalAlert('The document request you were viewing has been deleted', 'danger');
    }
  }, [documentRequest]);

  const { resetDocRequestStatus } = useDocumentFoldersOps({ dealId });

  const handleResetDocStatus = async (docRequest: any, callback?: () => void) => {
    await resetDocRequestStatus(docRequest._id);
    callback?.();
  };

  return (
    <>
      {documentRequest.isActive ? (
        <ActivityItemLink
          role="button"
          onClick={() => {
            setDocumentModalVisibility(true);
            setDocumentRequest({
              id: documentRequest._id,
              title: documentRequest.name,
              isRequest: true,
              ...documentRequest,
              data: documentRequest,
            });
            if (!skipQueryUpdate) {
              router.replace({ pathname: router.pathname, query: { dealId, doc: documentRequest._id } }, undefined, { shallow: true });
            }
          }}
        >
          {addHighlightWords(documentRequest.name, search)}
        </ActivityItemLink>
      ) : (
        <Text as="span" utils={{ color: 'gray700', textDecoration: 'line-through' }}>
          {addHighlightWords(documentRequest.name, search)}
        </Text>
      )}
      <DocumentsModal
        editedDocRequestId={activeDocumentRequest?.id || null}
        setDeleteFolderModal={setDeleteFolderModal}
        handleResetDocStatus={handleResetDocStatus}
        openEditReqModal={() => {
          setEditRequestModal({
            isOpen: true,
            request: activeDocumentRequest!,
          });
        }}
        onClose={() => {
          setDocumentModalVisibility(false);
        }}
        onExited={() => {
          router.replace({ pathname: router.pathname, query: { dealId } }, undefined, { shallow: true });
        }}
        isOpen={isDocumentModalVisible}
      />
      <DocumentsDeleteModal
        item={deleteFolderModal.item!}
        isOpen={deleteFolderModal.isOpen}
        onClose={(arg) => {
          setDeleteFolderModal((oldState) => ({
            ...oldState,
            isOpen: false,
          }));

          if (arg?.isOnConfirm) {
            setDocumentModalVisibility(false);
          }
        }}
      />
      <EditDocumentRequestModal
        isBorrower={false}
        isOpen={editRequestModal.isOpen}
        request={editRequestModal.request!}
        onClose={() => {
          setEditRequestModal((oldState) => ({ ...oldState, isOpen: false }));
        }}
      />
    </>
  );
};

export default DocumentRequestName;
