import React, { useRef, useState } from 'react';
import { Toggle } from '~/components/ui';
import { DealSearchResultFragment } from '~/generated/graphql';
import { DealStatus } from '~/components/deal';
import { DropdownControlled, DropdownMenu, DropdownItem } from '~/components/vendor';
import { formatDate, formatMoney } from '~/helpers';
import { Heading, StretchedLink, Text, TextFiller } from '~/components/type';
import { Row, Col } from '~/components/layout';
import { settings, copy, dollarSign, trash2 } from '~/components/vendor/Icon/icons';
import { TableRow, TableCell, TableIcon } from '~/components/table';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { useRouter } from 'next/router';
import DealsAvatar from './DealsAvatar';
import formatName from '~/helpers/formatName';
import Link from 'next/link';
import useUpdateDealStatus from '~/hooks/useUpdateDealStatus';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

interface DealsListItemProps {
  item?: DealSearchResultFragment;
  isBorrower?: boolean;
  isAdmin?: boolean;
  isCreatorColumnVisible?: boolean;
  style: React.CSSProperties;
  setOpenDealSettingsModal: (args: DealSearchResultFragment) => void;
  setCloseDealModalOpen: (args: any) => void;
  handleDeleteDeal: (args: any) => void;
  setOpenDuplicateDealModal: (args: any) => void;
}

const DealsListItem: React.FC<DealsListItemProps> = ({
  item,
  isAdmin,
  isCreatorColumnVisible,
  style,
  setOpenDealSettingsModal,
  setCloseDealModalOpen,
  handleDeleteDeal,
  setOpenDuplicateDealModal,
}) => {
  const router = useRouter();

  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number } | null>(null);
  const anchorProps = useClick(menuState.state, toggleMenu);

  const updateDealStatus = useUpdateDealStatus(item?.settings.closingFeeBreakdown?.lender);

  function handleClose() {
    toggleMenu(false);
    setAnchorPoint(null);
  }

  function handleContextMenu(e: React.MouseEvent) {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  }

  return (
    <TableRow
      style={style}
      onMouseEnter={() => {
        if (item) {
          router.prefetch(isAdmin ? `/deal/${item._id}/home` : `/deal/${item._id}/documents`);
        }
      }}
      onContextMenu={(e) => handleContextMenu(e)}
    >
      <TableCell>
        <Row gutter={0} utils={{ alignItems: 'center', maxWidth: '100%' }}>
          <Col span="auto" utils={{ mr: 6 }}>
            {item ? (
              <DealsAvatar
                lat={item?.project?.coordinates?.lat}
                lng={item?.project?.coordinates?.lng}
                coverageImageFileId={item?.project?.coverageImageFileId}
                coverageImageCrop={item?.project?.coverageImageCrop}
                name={item.name}
                size="lg"
                utils={{ borderRadius: 'sm' }}
              />
            ) : (
              <TextFiller utils={{ height: '40px', width: '40px' }} />
            )}
          </Col>
          <Col>
            {item ? (
              <>
                <Link
                  href={{
                    pathname: isAdmin ? '/deal/[dealId]/home' : '/deal/[dealId]/documents',
                    query: { dealId: item._id },
                  }}
                  passHref
                >
                  <StretchedLink>
                    <Heading as="h4" utils={{ fontSize: 'base', color: 'black' }}>
                      {item.name}
                    </Heading>
                    <Text utils={{ color: 'gray800' }}>
                      {item?.project?.coordinates?.cityState?.length ? item.project.coordinates.cityState.replace(/^N\/A, /, '') : 'Unknown address'}
                    </Text>
                  </StretchedLink>
                </Link>
              </>
            ) : (
              <>
                <TextFiller utils={{ display: 'block', mb: 2, height: '12px', width: '120px' }} />
                <TextFiller utils={{ width: '100px' }} />
              </>
            )}
          </Col>
        </Row>
      </TableCell>

      {isAdmin && (
        <TableCell span="12%" mobile={{ display: 'none' }}>
          {item ? (
            <Text utils={{ color: 'gray800' }}>{item?.settings?.closingFee ? formatMoney(item.settings.closingFee, { maximumFractionDigits: 0 }) : '--'}</Text>
          ) : (
            <TextFiller utils={{ width: '100px' }} />
          )}
        </TableCell>
      )}

      {isCreatorColumnVisible && (
        <TableCell span="15%" mobile={{ display: 'none' }}>
          <Row utils={{ alignItems: 'center', ml: 0 }}>
            {item ? (
              <>
                <UserAvatar size="xs" role="button" user={item.createdBy} utils={{ mr: 4 }} />
                <Text utils={{ color: 'gray800' }}>{formatName(item.createdBy?.fullName, { initialLastName: true })}</Text>
              </>
            ) : (
              <>
                <TextFiller utils={{ height: '26px', width: '26px', mr: 4, borderRadius: 'rounded' }} />
                <TextFiller utils={{ width: '60px' }} />
              </>
            )}
          </Row>
        </TableCell>
      )}

      <TableCell span="15%" mobile={{ display: 'none' }}>
        {item ? (
          <Text utils={{ color: 'gray800' }}>{item.lastUpdatedDoc ? formatDate(item.lastUpdatedDoc) : 'N/A'}</Text>
        ) : (
          <TextFiller utils={{ width: '70px' }} />
        )}
      </TableCell>

      {isAdmin && (
        <TableCell span="20%" mobile={{ display: 'none' }}>
          {item ? (
            <DealStatus status={item?.status} onChange={(status) => updateDealStatus(item._id, status, item.status)} disabled={!isAdmin} />
          ) : (
            <TextFiller utils={{ width: '120px' }} />
          )}
        </TableCell>
      )}

      <TableIcon>
        {isAdmin && (
          <>
            <Toggle ref={ref} {...anchorProps} />
            {item && (
              <DropdownControlled
                anchorRef={anchorPoint ? undefined : ref}
                anchorPoint={anchorPoint ? anchorPoint : undefined}
                onClose={handleClose}
                {...menuState}
              >
                <DropdownMenu>
                  <DropdownItem onClick={() => setOpenDealSettingsModal(item)} icon={settings}>
                    Settings
                  </DropdownItem>
                  <DropdownItem onClick={() => setCloseDealModalOpen(item._id)} icon={dollarSign}>
                    Closing Fees
                  </DropdownItem>
                  <DropdownItem onClick={() => setOpenDuplicateDealModal(item)} icon={copy}>
                    Duplicate
                  </DropdownItem>
                  <DropdownItem onClick={() => handleDeleteDeal(item)} icon={trash2}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </DropdownControlled>
            )}
          </>
        )}
      </TableIcon>
    </TableRow>
  );
};

export default DealsListItem;
