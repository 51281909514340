import React, { FC, useCallback, useEffect, useState } from 'react';
import { ActivityList } from '~/components/activity/ActivityList';
import {
  BaseLenderActivityFragment,
  CreateNoteInput,
  ActivitiesFragment,
  NoteFragment,
  UpdateNoteInput,
  useCreateNoteMutation,
  useDeleteNoteMutation,
  useGetLenderActivitiesQuery,
  useGetLocalUserQuery,
  useUpdateNoteMutation,
} from '~/generated/graphql';
import { Block, FlexRow } from '~/components/layout';
import { ConfirmModal } from '~/modals';
import { Heading } from '~/components/type';
import { NetworkStatus } from '@apollo/client';
import { RadioGroup, RadioButton } from '~/components/form';
import AddNote from '../Note/AddNote';
import LenderActivitiesItem from './LenderActivitiesItem';
import Note from '../Note/Note';
import useInfiniteScroll from '~/hooks/useInfiniteScroll';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import EditorContextProvider from '~/components/vendor/Editor/EditorContextProvider';

const LIMIT_PER_PAGE = 20;

type LenderActivitiesProps = {
  lender: { id: string; name: string; about?: string | null | undefined };
  dealId?: string;
  isOverview?: boolean;
  setNoteModifyingInProgress?: (isNoteModifyingInProgress: boolean) => void;
  notesOnly?: boolean;
  setCount?: (count: number) => void;
  placeholder?: string;
  disabledCrossPosting?: boolean;
};

const LenderActivities: FC<LenderActivitiesProps> = ({
  lender,
  dealId,
  isOverview,
  setNoteModifyingInProgress,
  notesOnly: defaultNotesOnly,
  setCount,
  placeholder,
  disabledCrossPosting = false,
}) => {
  const showGlobalAlert = useShowGlobalAlert();
  const [notesOnly, setNotesOnly] = useState(defaultNotesOnly ?? false);
  const [deleteNoteId, setDeleteNoteId] = useState('');

  const { data, previousData, fetchMore, networkStatus, refetch, updateQuery } = useGetLenderActivitiesQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      lenderId: lender.id,
      dealId,
      skip: 0,
      limit: LIMIT_PER_PAGE,
      notesOnly,
    },
  });
  const activitiesQuery = data?.getLenderActivities ?? previousData?.getLenderActivities;

  useEffect(() => {
    if (activitiesQuery) {
      setCount?.(activitiesQuery.notesCount);
    }
  }, [activitiesQuery]);

  useEffect(() => {
    if (activitiesQuery && activitiesQuery.notesOnly !== notesOnly) {
      refetch({
        lenderId: lender.id,
        dealId,
        skip: 0,
        limit: LIMIT_PER_PAGE,
        notesOnly,
      });
    }
  }, [notesOnly]);

  const handleLoadMore = async () => {
    await fetchMore({
      variables: {
        lenderId: lender.id,
        dealId,
        skip: data?.getLenderActivities.groupedActivities.length,
        limit: LIMIT_PER_PAGE,
        notesOnly,
      },
    });
  };

  const infiniteRef = useInfiniteScroll<HTMLUListElement>({
    loading: networkStatus === NetworkStatus.fetchMore,
    hasNextPage: activitiesQuery?.count! > activitiesQuery?.groupedActivities.length!,
    onLoadMore: handleLoadMore,
    scrollContainer: 'window',
  });

  const { data: currentUserData } = useGetLocalUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = currentUserData?.currentUser;

  const [createNote] = useCreateNoteMutation();
  const [deleteNote] = useDeleteNoteMutation();
  const [updateNote] = useUpdateNoteMutation();

  const onCreate = async (input: CreateNoteInput) => {
    await createNote({
      variables: { input },
      onCompleted: (res) => {
        updateQuery((data) => ({
          getLenderActivities: {
            ...data.getLenderActivities,
            groupedActivities: [{ ...res.createNote, noteBorrower: null, noteLender: null }, ...data.getLenderActivities.groupedActivities],
            count: data.getLenderActivities.count + 1,
            notesCount: data.getLenderActivities.notesCount + 1,
          },
        }));
      },
      refetchQueries: input.showOnExplore ? ['getLenderActivities'] : [],
    });
  };

  const onDelete = async () => {
    setDeleteNoteId('');
    await deleteNote({
      variables: { id: deleteNoteId },
      onCompleted: () => {
        updateQuery((data) => ({
          getLenderActivities: {
            ...data.getLenderActivities,
            groupedActivities: data.getLenderActivities.groupedActivities.filter((note) => (note as NoteFragment)._id !== deleteNoteId),
          },
        }));
      },
    });
  };

  const onUpdate = async (input: UpdateNoteInput) => {
    await updateNote({
      variables: { input },
      onCompleted: (res) => {
        updateQuery((data) => ({
          getLenderActivities: {
            ...data.getLenderActivities,
            groupedActivities: data.getLenderActivities.groupedActivities.map((note) =>
              (note as NoteFragment)._id === res.updateNote?._id ? res.updateNote : note,
            ),
          },
        }));
      },
    });
  };

  const onUploadError = useCallback(
    (err: string) => {
      showGlobalAlert(err, 'danger');
    },
    [showGlobalAlert],
  );

  if (!activitiesQuery) {
    return null;
  }

  return (
    <EditorContextProvider onUploadError={onUploadError}>
      <FlexRow utils={{ alignItems: 'center', mb: 6 }}>
        <Heading as="h3" utils={{ fontSize: isOverview ? '2xl' : 'xl' }}>
          Activity
        </Heading>
        <Block utils={{ ml: 'auto' }}>
          <RadioGroup size="sm">
            <RadioButton isActive={!notesOnly} onClick={() => setNotesOnly(false)}>
              All
            </RadioButton>
            <RadioButton isActive={notesOnly} onClick={() => setNotesOnly(true)}>
              Notes
            </RadioButton>
          </RadioGroup>
        </Block>
      </FlexRow>
      <ActivityList ref={infiniteRef}>
        <AddNote lender={lender} dealId={dealId} setNoteModifyingInProgress={setNoteModifyingInProgress} onCreate={onCreate} placeholder={placeholder} />
        {activitiesQuery.groupedActivities.map((groupedActivity) => {
          if (groupedActivity.__typename === 'Note') {
            return (
              <Note
                key={groupedActivity._id}
                lender={lender}
                dealId={dealId}
                note={groupedActivity}
                currentUserId={currentUser?._id!}
                onDelete={setDeleteNoteId}
                onUpdate={onUpdate}
                setNoteModifyingInProgress={setNoteModifyingInProgress}
                disabledCrossPosting={disabledCrossPosting}
              />
            );
          }
          return (
            <LenderActivitiesItem
              lenderId={lender.id}
              activity={groupedActivity as ActivitiesFragment}
              key={(groupedActivity as BaseLenderActivityFragment)._id}
            />
          );
        })}
      </ActivityList>
      <ConfirmModal
        isOpen={Boolean(deleteNoteId)}
        onClose={() => setDeleteNoteId('')}
        onConfirm={onDelete}
        onCancel={() => setDeleteNoteId('')}
        text={'Note will be deleted'}
      />
    </EditorContextProvider>
  );
};

export default LenderActivities;
