import { Modal, ModalDialog } from '~/components/ui';
import PreviewModalRight from './PreviewModalRight';
import styled, { css } from 'styled-components';

const PreviewModal = styled(Modal)`
  ${() => css`
    ${ModalDialog} {
      display: flex;
      height: 100%;
      max-width: 100%;
      margin-top: 0;
      margin-bottom: 0;
      transform: none;
      overflow-x: hidden;
      overflow-y: hidden;
    }

    &[style*='opacity: 1'] ${PreviewModalRight} {
      transform: translateX(0);
    }
  `}
`;

export default PreviewModal;
