import { useReportLoanInaccuracyMutation } from '~/generated/graphql';
import { Card, CardBody } from '../components/card';
import { Button, Feedback, FormGroup, Input, Label } from '../components/form';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import Textarea from 'react-textarea-autosize';
import { Block } from '~/components/layout';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { ChangeEvent, ElementType, useEffect, useState } from 'react';

type ReportLenderLoanInaccuracyModalType = {
  isOpen: boolean;
  onClose: () => void;
  lenderId: string;
  loanId: string;
};

export default function ReportLenderLoanInaccuracyModal({ isOpen, onClose, lenderId, loanId, ...props }: ReportLenderLoanInaccuracyModalType) {
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setExplanation('');
      setExplanationError(false);
    }
  }, [isOpen]);

  const [reportLoanInaccuracy, { loading }] = useReportLoanInaccuracyMutation();
  const showGlobalAlert = useShowGlobalAlert();

  const handleReport = () => {
    if (!explanation) {
      setExplanationError(true);
      return;
    }
    reportLoanInaccuracy({
      variables: {
        lenderId,
        loanId,
        explanation,
      },
      onCompleted: () => {
        showGlobalAlert('Inaccuracy was successfully reported!');
        onClose();
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon={'exclamation'} isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>Report an Inaccuracy</Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>
            Explain what is inaccurate and <u>provide evidence via links</u> whenever possible.
          </Text>
          <Block>
            <FormGroup isFloating isValid={!explanationError}>
              <Label htmlFor="ReportLenderLoanInaccuracyExplanation">Explanation of Inaccuracy</Label>
              <Input
                as={Textarea as ElementType}
                id="ReportLenderLoanInaccuracyExplanation"
                minRows={4}
                placeholder="Why is this loan inaccurate?"
                value={explanation}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setExplanation(e.target.value);
                  setExplanationError(false);
                }}
              />
              {explanationError && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <Button
              onClick={handleReport}
              isLoading={loading}
              disabled={loading}
              isBlock
            >
              Report
            </Button>
          </Block>
        </CardBody>
      </Card>
    </Modal>
  );
}
