import React from 'react';
import { Img } from 'react-image';
import { PreviewModalContent } from '~/components/preview';
import { Spinner } from '~/components/ui';

type Props = {
  url: string;
};

const PreviewImage: React.FC<Props> = ({ url }) => {
  return (
    <PreviewModalContent>
      <Img src={url} loader={<Spinner variant="white" />} style={{ maxWidth: '100%' }} />
    </PreviewModalContent>
  );
};

export default PreviewImage;
