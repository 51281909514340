import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

type PreviewModalContentProps = {
  isFill?: boolean;
};

const PreviewModalContent = styled(Base)<PreviewModalContentProps>`
  ${({ isFill }) => css`
    pointer-events: auto;
    max-width: calc(100% - 0.1px); // IE bug fix

    ${isFill &&
    `
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}
  `}
`;

export default PreviewModalContent;
