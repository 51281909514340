import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { RadioGroup, RadioButton } from '~/components/form';
import { Heading, Text } from '~/components/type';
import { useGetSingleDealQuery } from '~/generated/graphql';
import useDocumentFoldersOps from './useDocumentFoldersOps';
import CreateDocumentRequestForm from './CreateDocumentRequestForm';
import CreateFolderForm from './CreateFolderForm';

interface Props {
  closeModal?: () => void;
}

const DocumentAddForm = ({ closeModal }: Props) => {
  const router = useRouter();
  const dealId = router.query.dealId as string;

  const [activeTab, setActiveTab] = useState<'FolderTab' | 'RequestTab'>('RequestTab');

  const { data } = useGetSingleDealQuery({
    variables: { id: dealId },
    fetchPolicy: 'cache-only',
  });
  const deal = data?.getSingleDeal;

  const {
    createFolder: { creatingNewDocumentFolder },
    createRequest: { creatingNewRequest },
  } = useDocumentFoldersOps({ dealId });

  const isLender = deal?.userDealRole === 'LENDER';
  const isBorrower = deal?.userDealRole === 'BORROWER';
  const isAdmin = deal?.userDealRole === 'ADMIN';

  if (!deal) {
    return null;
  }

  return (
    <>
      <Heading as="h3" utils={{ fontSize: 'lg', textAlign: 'center', mb: 3 }}>
        {isLender ? 'Request Missing Document' : 'Add Document or Folder'}
      </Heading>
      <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>
        {isBorrower && 'Want to add a document or folder not already in the deal? Add it here!'}
        {isLender && "Require a document that's not in the list? Simply add it and sponsors will be notified."}
        {isAdmin && 'Add documents and folders to organize and complete the deal.'}
      </Text>
      {!isLender && (
        <RadioGroup utils={{ mb: 6 }} isBlock>
          <RadioButton
            isActive={activeTab === 'RequestTab'}
            onClick={() => {
              setActiveTab('RequestTab');
            }}
            disabled={creatingNewDocumentFolder || creatingNewRequest}
          >
            Document
          </RadioButton>
          <RadioButton
            isActive={activeTab === 'FolderTab'}
            onClick={() => {
              setActiveTab('FolderTab');
            }}
            disabled={creatingNewDocumentFolder || creatingNewRequest}
          >
            Folder
          </RadioButton>
        </RadioGroup>
      )}
      {activeTab === 'RequestTab' && <CreateDocumentRequestForm deal={deal} onAfterSubmit={closeModal} />}
      {activeTab === 'FolderTab' && <CreateFolderForm deal={deal} onAfterSubmit={closeModal} />}
    </>
  );
};

export default DocumentAddForm;
