import { SortDirection, useGetLenderLoanTransactionsQuery } from '~/generated/graphql';
import { AssetTypeLabels } from '~/data/assetTypeOptions';
import { BaseProps } from '~/components/layout/Base';
import { Block, Col, FlexRow, Row, Sticky } from '~/components/layout';
import { Card, CardBody, CardHeader } from '~/components/card';
import { Close, Divider, Modal } from '~/components/ui';
import { Heading, StretchedLink, Text, TextFiller } from '~/components/type';
import { Icon } from '~/components/vendor';
import { Input, InputAddon, InputGroup } from '~/components/form';
import { List, ListItem } from '~/components/list';
import { search } from '~/components/vendor/Icon/icons';
import { Table, TableCell, TableCollapse, TableCollapseBody, TableRow } from '~/components/table';
import { useState } from 'react';
import capitalizeString from '~/helpers/capitalizeString';
import DealsAvatar from '~/containers/Deals/DealsAvatar';
import TableInfinite from '~/components/table/TableInfinite';

const LIMIT_PER_PAGE = 50;

type LenderLoanPropertiesModalProps = BaseProps & {
  loanId?: string | null;
  onClose: () => void;
};

export default function LenderLoanPropertiesModal({ loanId, onClose, ...props }: LenderLoanPropertiesModalProps) {
  const [searchString, setSearchString] = useState('');
  const [isLoading, setLoading] = useState(false);

  const { data, previousData, fetchMore } = useGetLenderLoanTransactionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      loanId: loanId!,
      limit: LIMIT_PER_PAGE,
      skip: 0,
      search: searchString,
      sort: {
        field: 'mostRecent',
        direction: SortDirection.Asc,
      },
    },
    skip: !loanId,
  });
  const lenderLoanTransactionsData = data ?? previousData;

  function handleLoadMoreItems(index: number) {
    if (
      isLoading ||
      (lenderLoanTransactionsData?.getLenderLoanTransactions.count ?? 0) <=
        (lenderLoanTransactionsData?.getLenderLoanTransactions.lenderLoanTransactions.length ?? 0)
    ) {
      return;
    }
    setLoading(true);
    fetchMore({ variables: { skip: index, limit: LIMIT_PER_PAGE } }).then(() => {
      setLoading(false);
    });
  }

  return (
    <Modal layout="horizontal" isOpen={Boolean(loanId)} onClose={onClose} {...props}>
      <Card id="lenderLoanPropertiesModalScrollable" isModalContent>
        <div>
          <Sticky rootId="lenderLoanPropertiesModalScrollable" style={{ top: 0 }}>
            {(isStuck) => (
              <CardHeader utils={{ px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                <Row utils={{ alignItems: 'center', mb: 5 }}>
                  <Col as={FlexRow} utils={{ alignItems: 'baseline' }}>
                    <Heading as="h2" utils={{ fontSize: 'xl' }}>
                      Properties
                    </Heading>
                    <Text as="span" utils={{ fontSize: 'sm', color: 'gray600', ml: 2 }}>
                      {data?.getLenderLoanTransactions?.count}
                    </Text>
                  </Col>
                  <Col span="auto">
                    <Close onClick={onClose} utils={{ ml: 'auto' }} />
                  </Col>
                </Row>
                <Row gutter={2}>
                  <Col>
                    <InputGroup size="sm" utils={{ borderRadius: 'rounded' }}>
                      <InputAddon>
                        <Icon icon={search} />
                      </InputAddon>
                      <Input
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchString(e.target.value);
                        }}
                        value={searchString}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </CardHeader>
            )}
          </Sticky>
          <CardBody utils={{ px: 7, pt: 0, pb: 8 }} desktop={{ px: 10 }}>
            <Table isBorderFlush>
              <TableInfinite
                items={lenderLoanTransactionsData?.getLenderLoanTransactions.lenderLoanTransactions ?? []}
                totalItems={lenderLoanTransactionsData?.getLenderLoanTransactions.count}
                renderItem={(property) => {
                  const propertyDetails = {
                    landUse: {
                      name: 'Land Use',
                      value: property?.reonomyAssetType,
                      isHidden: property?.reonomyAssetType === null,
                    },
                    location: {
                      name: 'Location',
                      value: `${capitalizeString(property?.propertyAddress?.city || 'Unknown City')}, ${property?.propertyAddress?.state}`,
                      isHidden: property?.propertyAddress?.city === null || property?.propertyAddress?.state === null,
                    },
                    totalLotSizeAcres: {
                      name: 'Lot Size',
                      value: property?.lotSizeAcres ? `${Math.round(property.lotSizeAcres * 100) / 100} acres` : '',
                      isHidden: property?.lotSizeAcres === null,
                    },
                    zoningCode: {
                      name: 'Zoning',
                      value: property?.zoningCode,
                      isHidden: property?.zoningCode === null,
                    },
                    totalBuildingsSqFt: {
                      name: 'Building Area',
                      value: property?.buildingsSqFt ? `${new Intl.NumberFormat('en-US').format(property.buildingsSqFt)} sqft` : '',
                      isHidden: property?.buildingsSqFt === null,
                    },
                    yearBuilt: {
                      name: 'Year Built',
                      value: property?.yearBuilt,
                      isHidden: property?.yearBuilt === null,
                    },
                    yearRenovated: {
                      name: 'Year Renovated',
                      value: property?.yearRenovated,
                      isHidden: property?.yearRenovated === null,
                    },
                    floorCount: {
                      name: 'Stories',
                      value: property?.floorCount,
                      isHidden: property?.floorCount === null,
                    },
                    totalUnitCount: {
                      name: 'Units',
                      value: property?.totalUnitCount,
                      isHidden: property?.totalUnitCount === null,
                    },
                  };

                  return (
                    <TableCollapse {...props}>
                      {(isOpen, setOpen) => (
                        <>
                          <TableRow isActive={isOpen}>
                            <TableCell span="auto" utils={{ mr: -10 }}>
                              {property ? (
                                <DealsAvatar
                                  lat={property.propertyAddress?.latitude}
                                  lng={property.propertyAddress?.longitude}
                                  name={capitalizeString(property.propertyAddress?.street || 'Unknown Address')}
                                  utils={{ borderRadius: 'base', mr: 6 }}
                                />
                              ) : (
                                <TextFiller utils={{ height: '40px', width: '40px', mr: 6 }} />
                              )}
                            </TableCell>
                            <TableCell>
                              {property ? (
                                <Block utils={{ minWidth: 0 }}>
                                  <StretchedLink onClick={() => setOpen(!isOpen)}>
                                    <Heading as="h3" utils={{ fontSize: 'base', fontWeight: 'bold', color: 'black', textTruncate: true }}>
                                      {capitalizeString(property.propertyAddress?.street || 'Unknown Address')}
                                    </Heading>
                                  </StretchedLink>
                                  <Text utils={{ fontSize: 'sm', color: 'gray700', textTruncate: true }}>
                                    {capitalizeString(property.propertyAddress?.city || 'Unknown City')}, {property.propertyAddress?.state} ·{' '}
                                    {AssetTypeLabels[property.assetType]}
                                  </Text>
                                </Block>
                              ) : (
                                <Block>
                                  <TextFiller utils={{ display: 'block', mb: 2, height: '12px', width: '120px' }} />
                                  <TextFiller utils={{ width: '100px' }} />
                                </Block>
                              )}
                            </TableCell>
                            {property && (
                              <TableCollapseBody isOpen={isOpen}>
                                <TableCell utils={{ position: 'relative' }}>
                                  <List gutter={3} utils={{ fontSize: 'sm', mb: 0 }}>
                                    {Object.values(propertyDetails)
                                      .filter((item) => !item.isHidden)
                                      .map((item, index) => (
                                        <ListItem key={index}>
                                          <Row gutter={3} utils={{ alignItems: 'center' }}>
                                            <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                              {item.name}
                                            </Col>
                                            <Col>
                                              <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                                            </Col>
                                            <Col span="auto">{item.value}</Col>
                                          </Row>
                                        </ListItem>
                                      ))}
                                  </List>
                                </TableCell>
                              </TableCollapseBody>
                            )}
                          </TableRow>
                        </>
                      )}
                    </TableCollapse>
                  );
                }}
                onLoadedItemsScrollEnd={handleLoadMoreItems}
              />
            </Table>
          </CardBody>
        </div>
      </Card>
    </Modal>
  );
}
