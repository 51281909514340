import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';
import { pluralize } from '~/helpers/pluralize';

type CircleProps = {
  lat: number;
  lng: number;
  zoom: number;
  radius: number;
};

const Circle = styled(Base)<CircleProps>`
  ${({ zoom, radius, theme }) => css`
    --market-circle-size: ${Math.pow(2, zoom - 13) * (200 * radius)}px;
    --market-circle-bg: ${theme.markertCircleBg};
    --market-circle-border-width: ${theme.markertCircleBorderWidth}px;
    --market-circle-border-color: ${theme.markertCircleBorderColor};
    --market-circle-label-padding-y: ${theme.marketCircleLabelPaddingY}px;
    --market-circle-label-padding-x: ${theme.marketCircleLabelPaddingX}px;
    --market-circle-label-bg: ${theme.marketCircleLabelBg};
    --market-circle-label-border-radius: ${theme.marketCircleLabelBorderRadius}px;
    --market-circle-label-font-size: ${theme.markertCircleLabelFontSize}px;
    --market-circle-label-font-weight: ${theme.markertCircleLabelFontWeight};
    --market-circle-label-color: ${theme.markertCircleLabelColor};
    width: var(--market-circle-size);
    height: var(--market-circle-size);
    background-color: var(--market-circle-bg);
    border: var(--market-circle-border-width) solid var(--market-circle-border-color);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: 350ms ease-out;
    pointer-events: none;

    &::after {
      content: '${`${radius} ${pluralize(radius, 'Mile', 'Miles')}`}';
      position: absolute;
      top: 100%;
      left: 50%;
      padding: ${theme.spacers[1]}px ${theme.spacers[3]}px;
      border-radius: var(--market-circle-label-border-radius);
      background-color: var(--market-circle-label-bg);
      font-size: var(--market-circle-label-font-size);
      font-weight: var(--market-circle-label-font-weight);
      white-space: nowrap;
      color: var(--market-circle-label-color);
      transform: translate(-50%, -50%);
    }
  `}
`;

export default Circle;
