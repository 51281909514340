import React from 'react';

import { ContextMenuSourceProps } from '~/containers/DocumentRequestTree';
import { getBaseApiUrl } from '../../../helpers';
import { useStateWithLocalStorage } from '../../../hooks';
import FolderDropdownAdmin from './FolderDropdownAdmin';
import FolderDropdownBorrower from './FolderDropdownBorrower';
import RequestDropdownAdmin from './RequestDropdownAdmin';
import RequestDropdownBorrower from './RequestDropdownBorrower';
import RequestDropdownLender from './RequestDropdownLender';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { DocRequestFragment } from '~/generated/graphql';
import FolderDropdownLender from './FolderDropdownLender';

interface Props {
  deal: { isTemplate: boolean; userDealRole?: string | null };
  currentUserId: string;
  item: NormalizedFolderTreeItem;
  contextMenuSource: ContextMenuSourceProps;
  setEditFolderModal: (arg: { isOpen: boolean; folder: NormalizedFolderTreeItem }) => void;
  setEditRequestModal: (arg: { isOpen: boolean; request: NormalizedFolderTreeItem }) => void;
  setDeleteFolderModal: (arg: { isOpen: boolean; item: NormalizedFolderTreeItem }) => void;
  onDocRequestRowClick: ({ requestId }: { requestId: string }) => void;
  onDropdownMenuClose: () => void;
  downloadFolder: (folderId: string) => Promise<void>;
}

const DocumentTreeItemDropdown = ({
  deal,
  currentUserId,
  item,
  contextMenuSource,
  setEditFolderModal,
  setEditRequestModal,
  setDeleteFolderModal,
  onDocRequestRowClick,
  onDropdownMenuClose,
  downloadFolder,
}: Props) => {
  const [token] = useStateWithLocalStorage('sessionToken');

  const isUserCreatedItem = item.data!.createdBy === currentUserId;

  const downloadFile = () => {
    if (item.isRequest) {
      window.location.href = `${getBaseApiUrl()}/api/download?attachment=true&docId=${
        (item.data as DocRequestFragment).lastDocumentId
      }&token=${encodeURIComponent(token as string)}&saveActivity=true`;
    }
  };

  if (!deal) {
    return null;
  }

  if (item.isFolder) {
    switch (deal.userDealRole) {
      case 'ADMIN':
        return (
          <FolderDropdownAdmin
            item={item}
            contextMenuSource={contextMenuSource}
            isUserCreatedItem={isUserCreatedItem}
            setEditFolderModal={setEditFolderModal}
            setDeleteFolderModal={setDeleteFolderModal}
            onDropdownMenuClose={onDropdownMenuClose}
            downloadFolder={downloadFolder}
          />
        );
      case 'BORROWER':
        return (
          <FolderDropdownBorrower
            item={item}
            contextMenuSource={contextMenuSource}
            isUserCreatedItem={isUserCreatedItem}
            setEditFolderModal={setEditFolderModal}
            setDeleteFolderModal={setDeleteFolderModal}
            onDropdownMenuClose={onDropdownMenuClose}
            downloadFolder={downloadFolder}
          />
        );
      case 'LENDER':
        return (
          <FolderDropdownLender
            item={item}
            contextMenuSource={contextMenuSource}
            isUserCreatedItem={isUserCreatedItem}
            onDropdownMenuClose={onDropdownMenuClose}
            downloadFolder={downloadFolder}
          />
        );
    }
  }

  if (item.isRequest) {
    switch (deal.userDealRole) {
      case 'ADMIN':
        return (
          <RequestDropdownAdmin
            downloadFile={downloadFile}
            isTemplateDeal={deal.isTemplate}
            isUserCreatedItem={isUserCreatedItem}
            item={item}
            contextMenuSource={contextMenuSource}
            onDocRequestRowClick={onDocRequestRowClick}
            onDropdownMenuClose={onDropdownMenuClose}
            setDeleteFolderModal={setDeleteFolderModal}
            setEditRequestModal={setEditRequestModal}
          />
        );
      case 'BORROWER':
        return (
          <RequestDropdownBorrower
            downloadFile={downloadFile}
            isUserCreatedItem={isUserCreatedItem}
            item={item}
            contextMenuSource={contextMenuSource}
            onDocRequestRowClick={onDocRequestRowClick}
            onDropdownMenuClose={onDropdownMenuClose}
            setDeleteFolderModal={setDeleteFolderModal}
            setEditRequestModal={setEditRequestModal}
          />
        );
      case 'LENDER':
        return (
          <RequestDropdownLender
            isUserCreatedItem={isUserCreatedItem}
            item={item}
            contextMenuSource={contextMenuSource}
            setDeleteFolderModal={setDeleteFolderModal}
            setEditRequestModal={setEditRequestModal}
            onDropdownMenuClose={onDropdownMenuClose}
          />
        );
    }
  }

  //   Find some another solution. We just stub width when there is no dropdown
  return <div style={{ width: 17.59 }} />;
};

export default DocumentTreeItemDropdown;
