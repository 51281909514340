import React, { FC, useEffect } from 'react';
import { Card, CardBody } from '../components/card';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import { Controller, useForm } from 'react-hook-form';
import { Feedback, Form, FormGroup, Label, Input, Button } from '~/components/form';
import { useDuplicateDealMutation } from '~/generated/graphql';
import { useRouter } from 'next/router';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type FormData = {
  name: string;
};

type DuplicateDealModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deal?: { _id: string; name: string } | null;
};

const DuplicateDealModal: FC<DuplicateDealModalProps> = ({ isOpen, onClose, deal }) => {
  const router = useRouter();
  const showGlobalAlert = useShowGlobalAlert();

  const form = useForm<FormData>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (isOpen && deal) {
      form.setValue('name', `${deal.name} (Copy)`);
    }
  }, [isOpen, deal]);

  const [duplicateDeal, { loading }] = useDuplicateDealMutation();

  const onSubmit = async ({ name }: FormData) => {
    const { data } = await duplicateDeal({
      variables: { id: deal!._id, name },
    });

    router.push('/deal/[dealId]/home', `/deal/${data?.duplicateDeal?._id}/home`);

    showGlobalAlert('Deal successfully duplicated');

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card icon="refresh" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>Duplicate Deal</Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>
            The duplicated deal will include Summary, Financing, and Documents, but Members, Lenders, Tasks, and Activity will not be duplicated.
          </Text>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormGroup isValid={!form.formState.errors.name} data-testid="dealName" isFloating>
              <Label>Deal name</Label>
              <Controller
                name="name"
                render={({ field }) => <Input autoFocus type="text" placeholder="Deal name" maxLength={25} {...field} />}
                control={form.control}
                rules={{ required: true }}
                defaultValue=""
              />
              {form.formState.errors.name?.type === 'required' && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <Button variant="primary" type="submit" isLoading={loading} isBlock>
              Duplicate Deal
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DuplicateDealModal;
