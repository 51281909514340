import React, { FC, ReactNode } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type CopyToClipboardProps = {
  text: string;
  message: string;
  children: ReactNode;
};

const CopyToClipboard: FC<CopyToClipboardProps> = ({ text, message, children }) => {
  const showGlobalAlert = useShowGlobalAlert();

  return (
    <ReactCopyToClipboard
      text={text}
      onCopy={() => showGlobalAlert(message)}
    >
      {children}
    </ReactCopyToClipboard>
  );
};

export default CopyToClipboard;
