import { Col, FlexRow, Row } from '~/components/layout';
import { DropdownControlled, DropdownMenu, Icon, Select, Tooltip } from '~/components/vendor';
import { Form, FormGroup, RadioButton, RadioGroup } from '~/components/form';
import { Heading, Text } from '~/components/type';
import { sortAsc, sortDesc, switchVertical01 } from '~/components/vendor/Icon/icons';
import { SortDirection, SortInput } from '~/generated/graphql';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { useEffect, useRef, useState } from 'react';
import TableHeaderIcon from './TableHeaderIcon';

type TableSortDropdownProps = {
  fields: Record<SortInput['field'], { columnName: SortInput['field']; direction: SortDirection; isAdmin?: boolean }>;
  sort: SortInput | null;
  defaultSort?: SortInput | null;
  onSort: (sort: SortInput | null) => void;
  isAdmin?: boolean | null;
  isStuck?: boolean;
};

export default function TableSortDropdown({ fields, sort, defaultSort = null, isAdmin, onSort }: TableSortDropdownProps) {
  const [sortField, setSortField] = useState<SortInput['field']>(sort?.field || defaultSort?.field || '');
  const [sortDirection, setSortDirection] = useState<SortDirection>(sort?.direction || defaultSort?.direction || SortDirection.Asc);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const shouldDelayTooltipShow = useRef(true);
  const tooltipEnterTimeoutRef = useRef<number | null>(null);
  const tooltipLeaveTimeoutRef = useRef<number | null>(null);

  const anchorRef = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  const defaultOption = { value: 'default', label: 'Default' };
  const fieldsFiltered = Object.keys(fields).filter((field) => (fields[field]?.isAdmin ? fields[field].isAdmin === isAdmin : true));
  const fieldsFormatted = fieldsFiltered.map((field) => ({ value: field, label: fields[field].columnName }));
  const fieldOptions = defaultSort ? fieldsFormatted : [defaultOption, ...fieldsFormatted];
  const fieldValue = sortField ? { value: sortField, label: fields[sortField]?.columnName } : defaultOption;

  function handleSortReset() {
    setSortField(defaultSort?.field || '');
    setSortDirection(defaultSort?.direction || SortDirection.Asc);
    onSort(defaultSort);
  }

  function handleSortFieldChange(field: SortInput['field']) {
    if (field === sort?.field) {
      return;
    } else if (field === 'default') {
      onSort(defaultSort);
      setSortDirection(SortDirection.Asc);
    } else {
      setSortField(field);
      setSortDirection(fields[field].direction);
      onSort({ field, direction: fields[field].direction });
    }
  }

  function handleSortDirectionChange(direction: SortDirection) {
    if (direction === sort?.direction) {
      return;
    }
    setSortDirection(direction);
    onSort({ field: sortField, direction });
  }

  function handleTooltipVisibility(isOpen: boolean) {
    if (isOpen) {
      clearTimeout(tooltipLeaveTimeoutRef.current!);
      tooltipEnterTimeoutRef.current = window.setTimeout(
        () => {
          setIsTooltipOpen(true);
          shouldDelayTooltipShow.current = false;
        },
        shouldDelayTooltipShow.current ? 1000 : 0,
      );
    } else {
      tooltipLeaveTimeoutRef.current = window.setTimeout(() => (shouldDelayTooltipShow.current = true), 3000);
      clearTimeout(tooltipEnterTimeoutRef.current!);
      setIsTooltipOpen(false);
    }
  }

  useEffect(() => {
    if (sort?.field !== sortField) {
      setSortField(sort?.field || '');
    }
    if (sort?.direction !== sortDirection) {
      setSortDirection(sort?.direction || SortDirection.Asc);
    }
  }, [sort]);

  return (
    <>
      <DropdownControlled align="center" gap={8} anchorRef={anchorRef} onClose={() => toggleMenu(false)} {...menuState}>
        <DropdownMenu>
          <Form desktop={{ width: '25rem', py: 6, px: 8 }}>
            <FlexRow utils={{ alignItems: 'baseline', justifyContent: 'space-between', mb: 6 }}>
              <Heading utils={{ fontSize: 'lg' }}>Sort</Heading>
              <Text utils={{ fontSize: 'sm', color: 'gray700' }} hover={{ color: 'primary' }} onClick={() => handleSortReset()} role="button">
                Reset to default
              </Text>
            </FlexRow>
            <Row as={FormGroup} gutter={3} utils={{ justifyContent: 'space-between', mb: 0 }}>
              <Col>
                <Select
                  size="sm"
                  value={fieldValue}
                  options={fieldOptions}
                  placeholder="Select a field to sort by"
                  onChange={(e) => handleSortFieldChange(e?.value as string)}
                />
              </Col>
              {sortField !== '' && (
                <Col span="auto">
                  <RadioGroup size="sm">
                    <RadioButton
                      type="button"
                      data-tooltip-id="tableSortDropdownTooltip"
                      data-tooltip-content="Ascending"
                      onClick={() => handleSortDirectionChange(SortDirection.Asc)}
                      onMouseEnter={() => handleTooltipVisibility(true)}
                      onMouseLeave={() => handleTooltipVisibility(false)}
                      isActive={sortDirection === 'ASC'}
                    >
                      <span>
                        <Icon icon={sortAsc} size={16} />
                      </span>
                    </RadioButton>
                    <RadioButton
                      type="button"
                      data-tooltip-id="tableSortDropdownTooltip"
                      data-tooltip-content="Descending"
                      onClick={() => handleSortDirectionChange(SortDirection.Desc)}
                      onMouseEnter={() => handleTooltipVisibility(true)}
                      onMouseLeave={() => handleTooltipVisibility(false)}
                      isActive={sortDirection === 'DESC'}
                    >
                      <span>
                        <Icon icon={sortDesc} size={16} />
                      </span>
                    </RadioButton>
                  </RadioGroup>
                  <Tooltip id="tableSortDropdownTooltip" isOpen={isTooltipOpen} />
                </Col>
              )}
            </Row>
          </Form>
        </DropdownMenu>
      </DropdownControlled>
      <TableHeaderIcon>
        <Icon ref={anchorRef} icon={switchVertical01} size={16} utils={{ color: 'gray600' }} role="button" {...anchorProps} />
      </TableHeaderIcon>
    </>
  );
}
