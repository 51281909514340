import React, { useRef } from 'react';
import { ContextMenuSourceProps } from '~/containers/DocumentRequestTree';
import { DropdownControlled, DropdownMenu, DropdownItem } from '~/components/vendor';
import { Toggle } from '~/components/ui';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { DocRequestFragment } from '~/generated/graphql';
import { edit, trash2 } from '~/components/vendor/Icon/icons';

interface Props {
  isUserCreatedItem: boolean;
  item: NormalizedFolderTreeItem;
  contextMenuSource: ContextMenuSourceProps;
  setDeleteFolderModal: (arg: { isOpen: boolean; item: NormalizedFolderTreeItem }) => void;
  setEditRequestModal: (arg: { isOpen: boolean; request: NormalizedFolderTreeItem }) => void;
  onDropdownMenuClose: () => void;
}

const RequestDropdownLender = ({ isUserCreatedItem, item, contextMenuSource, setEditRequestModal, setDeleteFolderModal, onDropdownMenuClose }: Props) => {
  const isApproved = (item.data as DocRequestFragment).status === 'approved';

  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  function handleClose() {
    toggleMenu(false);
    onDropdownMenuClose();
  }

  if (contextMenuSource && contextMenuSource.id === item.id) {
    toggleMenu(true);
  }

  if (!isApproved && isUserCreatedItem) {
    return (
      <>
        <Toggle ref={ref} {...anchorProps} />
        <DropdownControlled
          anchorRef={contextMenuSource ? undefined : ref}
          anchorPoint={contextMenuSource ? contextMenuSource.anchorPoint : undefined}
          onClose={handleClose}
          {...menuState}
        >
          <DropdownMenu>
            <DropdownItem
              key="edit-request"
              onClick={() => {
                setEditRequestModal({
                  isOpen: true,
                  request: item,
                });
              }}
              icon={edit}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              key="delete-request"
              onClick={() => {
                setDeleteFolderModal({
                  isOpen: true,
                  item,
                });
              }}
              icon={trash2}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </DropdownControlled>
      </>
    );
  }

  //   Find some another solution. We just stub width when there is no dropdown
  return <div style={{ width: 17.59 }} />;
};

export default RequestDropdownLender;
