import { Theme as theme } from '~/theme';
import { UserOnlineStatus } from '~/generated/graphql';

export const UserOnlineStatusMapped: Record<typeof UserOnlineStatus[keyof typeof UserOnlineStatus], string> = {
  away: 'Away',
  online: 'Online',
  offline: 'Offline',
};

export const UserOnlineStatusVariants: Record<typeof UserOnlineStatus[keyof typeof UserOnlineStatus], keyof typeof theme.colors> = {
  away: 'warning',
  online: 'success',
  offline: 'gray500',
};
