import { useCallback, useMemo } from 'react';
import { SortInput, UserMetadataFragment, useCurrentUserQuery, useUpdateUserMetadataMutation } from '~/generated/graphql';

const useUserSorting = (key: keyof Omit<UserMetadataFragment, '__typename'>, defaultSort?: SortInput) => {
  const { data, updateQuery } = useCurrentUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = data?.currentUser;

  const [updateUserMetadata] = useUpdateUserMetadataMutation();

  const sort = useMemo<SortInput | null>(
    () =>
      currentUser?.metadata?.[key]?.[0]
        ? {
            field: currentUser?.metadata?.[key]?.[0].field!,
            direction: currentUser?.metadata?.[key]?.[0].direction!,
          }
        : null,
    [key, currentUser],
  );

  const setSort = useCallback(
    (newSort: SortInput | null) => {
      updateUserMetadata({
        variables: {
          input: {
            [key]: newSort ? [newSort] : null,
          },
        },
      });

      updateQuery((prevData) => ({
        ...prevData,
        currentUser: {
          ...prevData.currentUser!,
          metadata: {
            ...prevData.currentUser!.metadata,
            [key]: newSort ? [newSort] : null,
          },
        },
      }));
    },
    [key],
  );

  return [sort ?? defaultSort ?? null, setSort] as const;
};

export default useUserSorting;
