import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Heading } from '../components/type';
import { Close, Modal } from '../components/ui';
import { RenameDealForm } from '../containers';

type RenameDealModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deal: {
    _id: string;
    name: string;
  };
  isTemplate?: boolean;
};

const RenameDealModal: FC<RenameDealModalProps> = ({ isOpen, isTemplate, onClose, deal, ...props }) => (
  <Modal isOpen={isOpen} onClose={onClose} {...props}>
    <Card icon="box" isModalContent>
      <CardBody>
        <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
        <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 6 }}>Rename {isTemplate ? 'Template' : 'Deal'}</Heading>
        <RenameDealForm deal={deal} onClick={onClose} isTemplate={isTemplate} />
      </CardBody>
    </Card>
  </Modal>
);

export default RenameDealModal;
