import React from 'react';
import { Close, Modal, ModalContent } from '../components/ui';
import { Col, Container, FlexCol, Row } from '~/components/layout';
import { Header, TopnavLink } from '~/components/nav';
import { MarketMap } from '../components/market';
import { pluralize } from '~/helpers/pluralize';
import { Props as MapProps } from 'google-map-react';
import { radiuses } from '~/components/market/MarketMap';
import { Text } from '~/components/type';

type MarketMapModalProps = MapProps & {
  title: string;
  radius: number;
  isOpen: boolean;
  onClose: () => void;
  onRadiusChange: (radius: number) => void;
};

export default function MarketMapModal({ defaultCenter, title, radius, isOpen, onClose, onRadiusChange, ...props }: MarketMapModalProps) {
  return (
    <Modal onClose={onClose} isOpen={isOpen} layout="fullscreen">
      <ModalContent as={FlexCol}>
        <Header as="header" utils={{ mb: 0, borderBottom: 0 }}>
          <Container utils={{ width: '100%' }} isFluid>
            <Row gutter={0} utils={{ flexWrap: 'nowrap' }}>
              <Col span="25%" desktop={{ span: 'auto' }}>
                <Text utils={{ my: 1, fontWeight: 'bold', textTruncate: true }}>{title}</Text>
              </Col>
              <Col span="auto" utils={{ mx: 'auto' }}>
                {radiuses.map((rad) => (
                  <TopnavLink key={rad} onClick={() => onRadiusChange(rad)} isActive={radius === rad} role="button">
                    {rad} {pluralize(rad, 'Mile', 'Miles')}
                  </TopnavLink>
                ))}
              </Col>
              <Col span="auto">
                <Close onClick={onClose} />
              </Col>
            </Row>
          </Container>
        </Header>
        <MarketMap
          defaultCenter={defaultCenter}
          defaultZoom={14}
          title={title}
          radius={radius}
          showCircle
          options={{ clickableIcons: false, fullscreenControl: false, gestureHandling: 'greedy' }}
          onRadiusChange={onRadiusChange}
          {...props}
        />
      </ModalContent>
    </Modal>
  );
}
