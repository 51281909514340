import React from 'react';
import { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import { Block } from '~/components/layout';
import { Fragment } from 'react';
import { Theme as theme } from '~/theme';

type FunnelType = {
  <C extends React.ElementType = 'svg'>(props: FunnelProps<C> & { ref?: React.Ref<HTMLOrSVGElement> }): React.ReactNode;
  displayName?: string | undefined;
};

type FunnelInnerProps = {
  data?: number[];
  size?: number;
  variant?: keyof typeof theme.colors;
};

type FunnelProps<C extends React.ElementType = 'svg'> = BaseHOCPropsWithoutRef<C, FunnelInnerProps>;

const Funnel: FunnelType = React.forwardRef(
  <C extends React.ElementType = 'svg'>({ as, size = 24, variant = 'primary', data = [], ...props }: FunnelProps<C>, ref: React.Ref<HTMLOrSVGElement>) => {
    const viewboxSize = 14;
    const colGap = 2;
    const colWidth = 3;

    const coords = data?.map((value, index) => ({
      x: index * (colWidth + colGap) + colWidth / 2,
      y1: viewboxSize - colWidth / 2,
      y2: Math.min(viewboxSize - Math.round((viewboxSize / 100) * value) + colWidth / 2, viewboxSize - colWidth / 2),
    }));

    return (
      <Block
        ref={ref}
        as={'svg' as React.ElementType}
        width={size}
        height={size}
        viewBox={`0 0 ${viewboxSize} ${viewboxSize}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        {data.map((value, index) => {
          const item = coords[index];
          return (
            <Fragment key={index}>
              <line x1={item.x} x2={item.x} y1={item.y1} y2={colWidth / 2} stroke={theme.colors.gray400} strokeWidth={colWidth} strokeLinecap="round" />
              <line x1={item.x} x2={item.x} y1={item.y1} y2={item.y2} stroke={theme.colors[variant]} strokeWidth={colWidth} strokeLinecap="round" />
            </Fragment>
          );
        })}
      </Block>
    );
  },
);

Funnel.displayName = 'Funnel';
export default Funnel;
