import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { Avatar } from '~/components/ui';
import { Icon, Select } from '~/components/vendor';
import { plus } from '~/components/vendor/Icon/icons';
import { BorrowersSimpleSearchQuery, useBorrowersSimpleSearchLazyQuery } from '~/generated/graphql';
import { getBorrowerLogoUrl } from '~/helpers/getBorrowerLogoUrl';
import { BorrowerModal } from '~/modals';

const dividerOption = { type: 'divider', isDivider: true };

export type BorrowersDropdownOption = {
  value?: BorrowersSimpleSearchQuery['borrowersSimpleSearch'][number];
  label?: string;
  type?: string;
  isDivider?: boolean;
};

type BorrowersDropdownProps = {
  borrower?: BorrowersSimpleSearchQuery['borrowersSimpleSearch'][number] | null;
  selectProps: any;
  autoFocus?: boolean;
};

const BorrowersDropdown: FC<BorrowersDropdownProps> = ({ borrower, selectProps, autoFocus = false }) => {
  const { ref, onChange, value, ...props } = selectProps as ControllerRenderProps<{ borrower?: BorrowersDropdownOption }, 'borrower'>;

  const [createBorrowerOption, setCreateBorrowerOption] = useState({ value: { _id: '', name: '' }, label: '', type: 'createBorrower' });
  const borrowerInputValueRef = useRef<string | null>(null);
  const [borrowers, setBorrowers] = useState<BorrowersDropdownOption[]>([]);
  const [openBorrowerModal, setOpenBorrowerModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState<BorrowersDropdownOption | null>(null);

  const [borrowersSimpleSearch] = useBorrowersSimpleSearchLazyQuery();

  const loadBorrowerOptions = async (query: string): Promise<BorrowersDropdownOption[]> => {
    const { data } = await borrowersSimpleSearch({ variables: { query } });

    const newOptions = (data?.borrowersSimpleSearch ?? []).map((res) => ({ value: res, label: res.name }));
    const newCreateBorrowerOption = {
      ...createBorrowerOption,
      value: { _id: '', name: borrowerInputValueRef.current! },
      label: borrowerInputValueRef.current!,
    };
    const createBorrowerOptions = newOptions?.length > 0 ? [dividerOption, newCreateBorrowerOption] : [newCreateBorrowerOption];
    setBorrowers(newOptions);

    return [...newOptions, ...createBorrowerOptions];
  };

  const handleChange = (option: BorrowersDropdownOption | null) => {
    onChange(option);
    setSelectedOption(option);
  };

  useEffect(() => {
    if (borrower) {
      const option = { value: borrower, label: borrower.name };
      setBorrowers([option]);
      handleChange(option);
    } else {
      loadBorrowerOptions('');
      handleChange(null);
    }
  }, [borrower]);

  return (
    <>
      <Select
        autoFocus={autoFocus}
        instanceId="borrower"
        placeholder="Select a borrower"
        loadOptions={loadBorrowerOptions}
        defaultOptions={[...borrowers, dividerOption, createBorrowerOption]}
        onOptionRender={({ value, type }) => {
          if (value?._id) {
            return (
              <Fragment key={value._id}>
                <Avatar
                  size="sm"
                  alt={value.name}
                  src={getBorrowerLogoUrl(value)}
                  imageProps={{ utils: { border: 1 } }}
                  utils={{ borderRadius: 'sm', mr: 5 }}
                />
                {value.name}
              </Fragment>
            );
          }
          if (type === 'createBorrower') {
            return (
              <>
                <Avatar
                  size="sm"
                  alt={<Icon icon={plus} size="1em" utils={{ display: 'inline-flex' }} />}
                  utils={{ borderRadius: 'sm', fontSize: 'lg', color: 'primary', mr: 5 }}
                  titleProps={{ utils: { bgColor: 'primary10' } }}
                />
                Create new borrower
              </>
            );
          }
        }}
        onChange={(option) => {
          if (option?.type === 'createBorrower') {
            setOpenBorrowerModal(true);
          } else {
            handleChange(option);
          }
        }}
        onInputChange={(value, { action }) => {
          if (action === 'input-change') {
            if (value === '') {
              loadBorrowerOptions('');
            }
            borrowerInputValueRef.current = value;
            setCreateBorrowerOption((borrower) => ({ ...borrower, value: { _id: '', name: value }, label: value }));
          }
        }}
        async
        value={selectedOption}
        isClearable
        {...props}
      />
      <BorrowerModal
        isOpen={openBorrowerModal}
        onClose={() => {
          setOpenBorrowerModal(false);
        }}
        borrower={createBorrowerOption.value}
        setBorrower={(borrower) => {
          const option = {
            value: borrower,
            label: borrower.name,
          };
          setBorrowers([option]);
          handleChange(option);
        }}
      />
    </>
  );
};

export default BorrowersDropdown;
