import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat, NumberFormatValues, NumericFormat } from 'react-number-format';
import { assetTypeOptions, capitalTypeOptions, dealTypeOptions, dealTypeOptionsMap } from '~/data';
import { DealCreateInput, DealType, GetTemplatesQuery, useCreateDealMutation, useGetTemplatesQuery } from '~/generated/graphql';
import isValidDate from '~/helpers/isValidDate';
import { Button, Feedback, Form, Input, FormGroup, Label } from '../components/form';
import { FlexRow, Sticky } from '../components/layout';
import { Heading, Text } from '../components/type';
import { Badge } from '../components/ui';
import { Icon } from '../components/vendor';
import { Select, SelectAddress } from '../components/vendor';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { users } from '~/components/vendor/Icon/icons';
import BorrowersDropdown, { BorrowersDropdownOption } from './BorrowersDropdown';
import { CardBody, CardHeader } from '~/components/card';
import { Table, TableCell, TableRow } from '~/components/table';
import { isNotNil } from '~/helpers';

const DATE_PATTERN = /^(\d{2})\/(\d{2})\/(\d{4})$/;

type DealCreateForm = Omit<DealCreateInput, 'template' | 'borrowerId'> & {
  template?: { value: string; label: string };
  notValidAddress?: undefined;
  borrower?: BorrowersDropdownOption;
  amountFinanced?: string;
};

type NewDealFormProps = {
  onClose: () => void;
  template?: GetTemplatesQuery['getTemplates'][0];
  preflight?: boolean;
};

const NewDealForm: FC<NewDealFormProps> = ({ onClose, template, preflight }) => {
  const router = useRouter();
  const showGlobalAlert = useShowGlobalAlert();
  const { data } = useGetTemplatesQuery({ fetchPolicy: 'cache-first' });

  const { filter, ...formQueryValues } = router.query;
  const queryValues: Record<string, any> = { ...(formQueryValues ?? {}), coordinates: JSON.parse((router?.query?.coordinates as string) ?? '{}') };

  const newDealForm = useForm<DealCreateForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      address: queryValues?.address || '',
      template: template && { value: template._id, label: template.name },
    },
  });

  useEffect(() => {
    newDealForm.register('coordinates');
  }, [newDealForm]);

  useEffect(() => {
    if (router.pathname === '/preflight' && router?.query?.coordinates) {
      newDealForm.setValue('address', queryValues.address);
      newDealForm.setValue('coordinates', queryValues.coordinates);
      newDealForm.setValue(
        'assetType',
        assetTypeOptions.find((option) => option.value === queryValues?.assetType),
      );
      newDealForm.setValue('capitalType', capitalTypeOptions.find((option) => option.value === queryValues?.financingType)!);
      newDealForm.setValue('loanType', dealTypeOptionsMap[queryValues?.loanType as DealType]);
    }
  }, [queryValues]);

  const {
    formState: { errors: formErrors },
  } = newDealForm;

  const [createDeal, { loading }] = useCreateDealMutation();

  const onCreateDeal = async (fields: DealCreateForm) => {
    if (fields?.address?.length! > 0 && !fields.coordinates) {
      newDealForm.setError('notValidAddress', {});
      return;
    }
    const { template, borrower, amountFinanced, ...formValues } = fields;

    const additionalDealInfo: Partial<DealCreateForm> = {};
    const isTherePreflightValues = Object.keys(queryValues).length === 8;
    if (isTherePreflightValues) {
      const allSelectOptions = [...assetTypeOptions, ...capitalTypeOptions];

      Object.keys(queryValues).forEach((queryKey) => {
        if (['assetType', 'loanType', 'capitalType'].includes(queryKey)) {
          if (queryKey === 'loanType') {
            additionalDealInfo[queryKey] = dealTypeOptionsMap[queryValues[queryKey] as DealType];
          } else {
            (additionalDealInfo as any)[queryKey] = allSelectOptions.find((option) => option.value === queryValues[queryKey]);
          }
        } else {
          (additionalDealInfo as any)[queryKey] = isNaN(parseFloat(queryValues[queryKey])) ? queryValues[queryKey] : parseFloat(queryValues[queryKey]);
        }
      });
    }
    const { data } = await createDeal({
      variables: {
        ...additionalDealInfo,
        ...formValues,
        template: template?.value,
        borrowerId: borrower?.value?._id,
        amountFinanced: amountFinanced ? parseFloat(amountFinanced) : null,
      },
    });

    router.push('/deal/[dealId]/home', `/deal/${data?.createDeal?._id}/home`);

    showGlobalAlert('Deal successfully created!');

    onClose?.();
  };

  return (
    <Form onSubmit={newDealForm.handleSubmit(onCreateDeal)}>
      <Sticky rootId="dealModalScrollable" style={{ top: 0 }}>
        {(isStuck) => (
          <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
            <Button size="sm" variant="white" utils={{ my: -1 }} onClick={onClose} type="button">
              Cancel
            </Button>
            <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{preflight ? 'Save as a Deal' : 'New Deal'}</Heading>
            <Button size="sm" utils={{ my: -1 }} type="submit" isLoading={loading} disabled={loading}>
              Create
            </Button>
          </CardHeader>
        )}
      </Sticky>
      <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
        <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
          Deal Basics
        </Heading>

        <Table size="sm" utils={{ mb: 9 }}>
          <TableRow as={FormGroup} isValid={!formErrors.name} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Name</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="name"
                render={({ field }) => <Input _size="sm" autoFocus type="text" placeholder="Deal name" maxLength={25} {...field} />}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue=""
              />
              {formErrors.name?.type === 'required' && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.address} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Address</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="address"
                render={({ field: { onChange, value } }) => {
                  return (
                    <SelectAddress
                      inputProps={{
                        _size: 'sm',
                        id: 'address',
                        placeholder: 'Enter a location',
                        utils: { boxShadow: 2 },
                      }}
                      value={value!}
                      onChange={(value) => {
                        newDealForm.setValue('coordinates', null);
                        onChange(value);
                      }}
                      onError={(error) => {
                        if (error === 'ZERO_RESULTS') {
                          newDealForm.setError('notValidAddress', {});
                        }
                      }}
                      onSelect={({ __typename, ...coordinates }) => {
                        newDealForm.setValue('coordinates', coordinates);
                        newDealForm.clearErrors('notValidAddress');
                        newDealForm.clearErrors('address');
                      }}
                    />
                  );
                }}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue={newDealForm.getValues()?.address}
              />
              {formErrors.notValidAddress && <Feedback>Address can&apos;t be found.</Feedback>}
              {formErrors.address && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.template} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Template</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="template"
                render={({ field: { onChange, value } }) => (
                  <Select
                    size="sm"
                    id="dealTemplate"
                    placeholder="Select a template"
                    options={[
                      { value: '', label: 'No Template' },
                      ...(data?.getTemplates ? data.getTemplates.map((template) => ({ value: template._id, label: template.name })) : []),
                    ]}
                    value={value}
                    onChange={onChange}
                    onOptionRender={(templateOption) => {
                      const template = data?.getTemplates?.find((template) => template._id === templateOption.value);
                      if (!template) {
                        return templateOption.label;
                      }
                      return (
                        <FlexRow utils={{ width: '100%', justifyContent: 'space-between' }}>
                          <Text utils={{ mr: 5, textTruncate: true }}>{template?.name}</Text>
                          {template?.isTemplateShared && (
                            <Badge utils={{ bgColor: 'gray200', color: 'gray800' }} style={{ float: 'right' }}>
                              <Icon icon={users} utils={{ mr: 2 }} />
                              <Text as="span">Shared</Text>
                            </Badge>
                          )}
                        </FlexRow>
                      );
                    }}
                  />
                )}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue={undefined}
              />
              {formErrors.template && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.assetType} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Asset Type</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="assetType"
                render={({ field: { onChange, value } }) => (
                  <Select size="sm" id="assetType" placeholder="Pick asset types" options={assetTypeOptions} value={value} onChange={onChange} />
                )}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue={null}
              />
              {formErrors.assetType && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.loanType} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Deal Type</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="loanType"
                render={({ field: { onChange, value } }) => (
                  <Select size="sm" id="loanType" placeholder="Select deal type" options={dealTypeOptions} value={value} onChange={onChange} />
                )}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue={null}
              />
              {formErrors.loanType && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.capitalType} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Capital Type</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="capitalType"
                render={({ field: { onChange, value } }) => (
                  <Select size="sm" id="capitalType" placeholder="Select capital type" options={capitalTypeOptions} value={value} onChange={onChange} />
                )}
                control={newDealForm.control}
                rules={{ required: true }}
                defaultValue={undefined}
              />
              {formErrors.capitalType && <Feedback>This field is required.</Feedback>}
            </TableCell>
          </TableRow>
        </Table>

        <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
          Additional Info
        </Heading>

        <Table size="sm" utils={{ mb: 9 }}>
          <TableRow as={FormGroup} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Borrower</Label>
            </TableCell>
            <TableCell>
              <Controller name="borrower" render={({ field }) => <BorrowersDropdown selectProps={{ ...field, size: 'sm' }} />} control={newDealForm.control} />
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} isValid={!formErrors.estimatedCloseDate} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Est. Close Date</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="estimatedCloseDate"
                render={({ field: { value, onChange, ...props } }) => (
                  <Input
                    _size="sm"
                    as={PatternFormat}
                    format="##/##/####"
                    placeholder="MM/DD/YYYY"
                    onValueChange={(e: NumberFormatValues) => {
                      if (e.formattedValue && DATE_PATTERN.test(e.formattedValue) && isValidDate(e.formattedValue)) {
                        onChange(new Date(new Date(e.formattedValue).setHours(0, 0, 0, 0)));
                      } else {
                        onChange(e.formattedValue);
                      }
                    }}
                    {...props}
                  />
                )}
                control={newDealForm.control}
                rules={{
                  required: false,
                  pattern: {
                    value: DATE_PATTERN,
                    message: 'Invalid date format. Use MM/DD/YYYY',
                  },
                  validate: (value) => {
                    if (value && !isValidDate(value)) {
                      return 'Invalid date';
                    }
                  },
                }}
              />
              {formErrors.estimatedCloseDate && <Feedback>{formErrors.estimatedCloseDate.message}</Feedback>}
            </TableCell>
          </TableRow>

          <TableRow as={FormGroup} utils={{ mb: 0 }}>
            <TableCell span="30%">
              <Label utils={{ fontSize: 'sm', mb: 0 }}>Est. Financing</Label>
            </TableCell>
            <TableCell>
              <Controller
                name="amountFinanced"
                render={({ field: { onChange, ...props } }) => (
                  <NumericFormat
                    _size="sm"
                    customInput={Input}
                    placeholder="$0"
                    utils={{ boxShadow: 2 }}
                    prefix="$"
                    onValueChange={(e) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                    thousandSeparator
                    {...props}
                  />
                )}
                control={newDealForm.control}
              />
            </TableCell>
          </TableRow>
        </Table>

        <Button variant="primary" type="submit" isLoading={loading} isBlock>
          Create Deal
        </Button>
      </CardBody>
    </Form>
  );
};

export default NewDealForm;
