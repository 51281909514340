import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Close, Modal } from '../components/ui';
import DocumentAddForm from '../containers/Document/DocumentAddForm';

type AddDocumentModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const AddDocumentModal: FC<AddDocumentModalProps> = ({ isOpen, onClose, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="write" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <DocumentAddForm closeModal={onClose} />
        </CardBody>
      </Card>
    </Modal>
  );
};

export default AddDocumentModal;
