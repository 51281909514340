import { useCallback } from 'react';
import {
  LightCompanyFragment,
  DealFieldsFragment,
  DealFieldsFragmentDoc,
  DealSearchResultFragment,
  DealSearchResultFragmentDoc,
  DealStatus,
  DealUserRole,
  useUpdateDealStatusMutation,
} from '~/generated/graphql';
import { closeDealEventModalVar, dealStatusLenderModalVar, dealStatusReasonModalVar } from '~/lib/apolloClient';
import useRemoveDealFromList from './useRemoveDealFromList';
import getDealTabByStatus from '~/helpers/getDealTabByStatus';
import useShowGlobalAlert from './useGlobalAlert';
import { DealLenderStatusLabels } from '~/data/dealLenderStatusOptions';
import { useUpdateDealMembers } from './useUpdateDealLenderStatus';

const useUpdateDealStatus = (lender?: { id: string } | null) => {
  const showGlobalAlert = useShowGlobalAlert();

  const [updateDealStatusMutation] = useUpdateDealStatusMutation({ refetchQueries: ['getDealMembers'] });
  const { setIsOpen } = closeDealEventModalVar();
  const removeDealFromList = useRemoveDealFromList();

  const updateDealMembers = useUpdateDealMembers();

  const updateDealStatus = useCallback(
    async (id: string, status: DealStatus, oldStatus?: DealStatus, reason?: string, lenderId = lender?.id, quoteId?: string | null) => {
      if (status === DealStatus.Closed) {
        setIsOpen({
          _id: id,
          isClosing: true,
          lenderId: lender?.id,
        });
        return;
      }

      if ([DealStatus.TermsSigned, DealStatus.LoanApproved, DealStatus.LoanDocsSent].includes(status) && !lenderId) {
        dealStatusLenderModalVar({
          dealId: id,
          onSubmit: async (lenderId, quoteId, contactsAction, otherContactsAction) => {
            await updateDealMembers(
              id,
              updateDealStatus(id, status, oldStatus, undefined, lenderId, quoteId),
              (user) =>
                user.role === DealUserRole.Lender && user.lenderContact?.lenderId !== lenderId && (otherContactsAction === true || Boolean(user.lenderContact)),
              contactsAction,
            );
          },
        });
        return;
      }

      if ([DealStatus.Paused, DealStatus.Dead].includes(status) && !reason) {
        dealStatusReasonModalVar({
          status: status as DealStatus.Dead,
          onSubmit: async (dealStatusReason: string) => {
            await updateDealStatus(id, status, oldStatus, dealStatusReason);
          },
        });
        return;
      }

      await updateDealStatusMutation({
        variables: {
          id,
          status,
          reason,
          lenderId,
          quoteId,
        },
        update: (cache, { data }) => {
          let lenderStatusUpdate: LightCompanyFragment | null | undefined = null;

          const searchResultFragment = cache.readFragment<DealSearchResultFragment>({
            id: `DealSearchResult:${id}`,
            fragmentName: 'DealSearchResult',
            fragment: DealSearchResultFragmentDoc,
          });
          if (searchResultFragment) {
            cache.writeFragment<DealSearchResultFragment>({
              id: `DealSearchResult:${id}`,
              fragmentName: 'DealSearchResult',
              fragment: DealSearchResultFragmentDoc,
              data: {
                ...searchResultFragment,
                status: data?.updateDealStatus.status as DealStatus,
                settings: {
                  ...searchResultFragment.settings,
                  closingFeeBreakdown: {
                    ...searchResultFragment.settings.closingFeeBreakdown,
                    lender: data?.updateDealStatus.settings.closingFeeBreakdown?.lender,
                  },
                },
              },
            });
            if (searchResultFragment.settings.closingFeeBreakdown?.lender) {
              lenderStatusUpdate = searchResultFragment.settings.closingFeeBreakdown.lender;
            }
          }
          const dealFieldsFragment = cache.readFragment<DealFieldsFragment>({
            id: `Deal:${id}`,
            fragmentName: 'DealFields',
            fragment: DealFieldsFragmentDoc,
          });
          if (dealFieldsFragment?.settings.closingFeeBreakdown?.lender) {
            lenderStatusUpdate = dealFieldsFragment.settings.closingFeeBreakdown.lender;
          }
          if (lenderStatusUpdate && oldStatus === DealStatus.Closed) {
            showGlobalAlert(`Auto updated ${lenderStatusUpdate.name}'s status to ${DealLenderStatusLabels.QUOTED}`, 'black');
          }
        },
      });

      if (oldStatus) {
        const tab = getDealTabByStatus(status);
        const oldTab = getDealTabByStatus(oldStatus);
        if (tab !== oldTab) {
          removeDealFromList(id, oldTab);
        }
      }
    },
    [updateDealStatusMutation, setIsOpen, removeDealFromList, lender],
  );

  return updateDealStatus;
};

export default useUpdateDealStatus;
