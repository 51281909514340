import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { GetSearchDocument, GetSearchQuery, BorrowersElasticSearchDocument, BorrowersElasticSearchQuery, useDeleteBorrowerMutation } from '~/generated/graphql';
import { searchBorrowersVars } from '~/lib/apolloClient';
import { Close, Modal } from '~/components/ui';
import { Card, CardBody } from '~/components/card';
import { Heading, Text } from '~/components/type';
import { Button, FormGroup, Input, Label } from '~/components/form';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type DeleteBorrowerModalProps = {
  isOpen: boolean;
  borrowerId?: string;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteBorrowerModal: React.FC<DeleteBorrowerModalProps> = ({ isOpen, onClose, onDelete, borrowerId }) => {
  const [reason, setReason] = useState('');

  useEffect(() => {
    setReason('');
  }, [isOpen]);

  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [deleteBorrower, { loading: deletingBorrower }] = useDeleteBorrowerMutation();

  const handleFormSubmit = async () => {
    await deleteBorrower({
      variables: {
        borrowerId: borrowerId!,
        reason,
      },
    });

    const borrowersQuery = client.readQuery<BorrowersElasticSearchQuery>({
      query: BorrowersElasticSearchDocument,
      variables: searchBorrowersVars(),
    });
    if (borrowersQuery) {
      const updatedBorrowers = borrowersQuery?.borrowersElasticSearch.borrowers.filter((b) => b._id !== borrowerId);
      client.writeQuery<BorrowersElasticSearchQuery>({
        query: BorrowersElasticSearchDocument,
        variables: searchBorrowersVars(),
        data: {
          borrowersElasticSearch: {
            ...borrowersQuery?.borrowersElasticSearch,
            borrowers: updatedBorrowers!,
            count: borrowersQuery?.borrowersElasticSearch.count! - 1,
          },
        },
      });
    }

    const getSearchQuery = client.readQuery<GetSearchQuery>({
      query: GetSearchDocument,
    });

    if (getSearchQuery) {
      client.writeQuery<GetSearchQuery>({
        query: GetSearchDocument,

        data: {
          ...getSearchQuery.getSearch,
          getSearch: getSearchQuery.getSearch.filter((s) => (s as { _id: string })._id !== borrowerId),
        },
      });
    }

    showGlobalAlert('Borrower has been deleted');

    onDelete();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card icon="check" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>
            Are you sure?
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>This will remove this borrower and contacts.</Text>
          <FormGroup isFloating>
            <Label htmlFor="reason">Reason for deleting (Optional)</Label>
            <Input
              as={Textarea as React.ElementType}
              id="reason"
              placeholder="Provide a reason for deleting..."
              minRows={5}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
            />
          </FormGroup>
          <Button isBlock isLoading={deletingBorrower} onClick={handleFormSubmit}>
            Delete Borrower
          </Button>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DeleteBorrowerModal;
