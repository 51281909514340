import React from 'react';
import styled, { css } from 'styled-components';
import CalculateForm from '~/containers/Preflight/CalculateForm';

import { Card, CardBody } from '../components/card';
import { Heading, Text } from '../components/type';
import { Close, Modal, ModalDialog } from '../components/ui';

import { OnCalculateFormSubmit } from '~/containers/Preflight/CalculateForm';

const StyledModal = styled(Modal)`
  ${({ theme }) => css`
    & > ${ModalDialog} {
      @media (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 480px;
      }
    }
  `}
`;

type PreflightModalProps = {
  recalculate?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: OnCalculateFormSubmit;
};

const PreflightModal = ({ recalculate = false, isOpen, onClose, onSubmit, ...props }: PreflightModalProps) => {
  return (
    <StyledModal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="clipboard" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>Preflight Your Deal</Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>Match your deal with the perfect lender program.</Text>
          <CalculateForm isRecalculate={recalculate} onSubmit={onSubmit} isModal />
        </CardBody>
      </Card>
    </StyledModal>
  );
};

export default PreflightModal;
