import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { Input, InputAddon, InputGroup } from '~/components/form';
import { Block, Col, FlexRow, Row, Sticky } from '~/components/layout';
import { Table, TableCell, TableRow } from '~/components/table';
import { Heading, Text, TextFiller } from '~/components/type';
import LenderDeal from '~/containers/Matching/LenderDeal';
import { CompanyFragment, LenderDealsQuery, SortDirection, useLenderDealsQuery } from '~/generated/graphql';
import { Icon, Select } from '~/components/vendor';
import { CardBody, CardHeader } from '~/components/card';
import { Close } from '~/components/ui';
import { search } from '~/components/vendor/Icon/icons';
import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { NotFound } from '~/components/not-found';

export type LenderDealsSort = 'mostSimilar' | 'closest' | 'mostRecent' | 'status' | 'largestLoanValue';

type LenderDealsSortOption = { value: LenderDealsSort; label: string };

export const commonLenderDealsSortOptions: LenderDealsSortOption[] = [
  { value: 'mostRecent', label: 'Most Recent' },
  { value: 'status', label: 'Status' },
  { value: 'largestLoanValue', label: 'Largest loans' },
];

type ListItemProps = {
  lenderDeal?: LenderDealsQuery['lenderDeals']['lenderDeals'][number];
  lender: CompanyFragment;
  isModal?: boolean;
  refCallback?: (node: HTMLDivElement) => void;
};

const ListItem: FC<ListItemProps> = ({ lenderDeal, lender, isModal, refCallback }) => {
  if (lenderDeal) {
    return <LenderDeal key={lenderDeal.deal._id} lenderDeal={lenderDeal} lender={lender} isOverview={isModal} />;
  }
  return (
    <TableRow ref={refCallback}>
      <TableCell utils={{ flexWrap: 'nowrap' }}>
        <TextFiller utils={{ height: '40px', width: '40px', mr: 6 }} />
        <Block>
          <Block>
            <TextFiller utils={{ height: '14px', width: '150px' }} />
          </Block>
          <Block>
            <TextFiller utils={{ height: '12px', width: '120px' }} />
          </Block>
        </Block>
      </TableCell>
      <TableCell span="auto" style={{ alignSelf: 'stretch' }}>
        <TextFiller utils={{ height: '12px', width: '50px' }} />
      </TableCell>
    </TableRow>
  );
};

const LIMIT_PER_PAGE = 30;

type LenderDealsListProps = {
  lender: CompanyFragment;
  dealId?: string;
  isModal?: boolean;
  onClose?: () => void;
  isHidden?: boolean;
  sort?: LenderDealsSort;
  setSort?: (sort: LenderDealsSort) => void;
};

const LenderDealsList: FC<LenderDealsListProps> = ({ lender, dealId, isModal, onClose, isHidden, sort: externalSort, setSort: externalSetSort }) => {
  const [searchString, setSearchString] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [internalSort, internalSetSort] = useState<LenderDealsSort>('mostRecent');
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [isLoading, setLoading] = useState(false);

  const sort = externalSort ?? internalSort;
  const setSort = externalSetSort ?? internalSetSort;

  const lenderDealsSortOptions = useMemo(() => {
    const options: LenderDealsSortOption[] = [...commonLenderDealsSortOptions];
    if (dealId) {
      options.unshift({ value: 'mostSimilar', label: 'Most Similar' }, { value: 'closest', label: 'Closest' });
    }
    return options;
  }, [dealId]);

  const { data, previousData, fetchMore } = useLenderDealsQuery({
    variables: {
      lenderId: lender.id,
      dealId,
      limit: LIMIT_PER_PAGE,
      skip: 0,
      search: searchString,
      sort: {
        field: sort,
        direction: SortDirection.Asc,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const lenderDealsData = data ?? previousData;

  const entry = useIntersectionObserver(node, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (!isVisible || isLoading || (lenderDealsData?.lenderDeals.count ?? 0) <= (lenderDealsData?.lenderDeals?.lenderDeals.length ?? 0)) {
      return;
    }
    setLoading(true);
    fetchMore({
      variables: {
        skip: lenderDealsData?.lenderDeals.lenderDeals.length,
        limit: LIMIT_PER_PAGE,
      },
    }).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [isVisible, isLoading, lenderDealsData, fetchMore]);

  const { run: changeSearchString } = useDebounceFn(
    (value: string) => {
      setSearchString(value);
    },
    { wait: 300 },
  );

  if (isHidden) {
    return null;
  }

  const table =
    lenderDealsData?.lenderDeals.count === 0 ? (
      <NotFound
        size="sm"
        title="No matches"
        description={
          <>
            Update or{' '}
            <Text
              as="span"
              utils={{ color: 'primary' }}
              onClick={() => {
                setSearchString('');
                searchInputRef.current!.value = '';
              }}
              role="button"
            >
              clear search
            </Text>
          </>
        }
        utils={{ border: 0, my: 'auto', pt: 0 }}
      />
    ) : (
      <Table size="lg" align="center" gutterY={0} isBorderFlush={isModal}>
        {Array.from({ length: lenderDealsData?.lenderDeals.count ?? 0 }).map((_, inx) => (
          <ListItem
            key={inx}
            lenderDeal={lenderDealsData?.lenderDeals.lenderDeals[inx]}
            lender={lender}
            isModal={isModal}
            refCallback={inx === lenderDealsData?.lenderDeals.lenderDeals.length ? setNode : undefined}
          />
        ))}
      </Table>
    );

  return (
    <>
      {isModal ? (
        <Sticky rootId="lenderDealsModalScrollable" style={{ top: 0 }}>
          {(isStuck) => (
            <CardHeader utils={{ px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
              <Row utils={{ alignItems: 'center', mb: 5 }}>
                <Col as={FlexRow} utils={{ alignItems: 'baseline' }}>
                  <Heading as="h2" utils={{ fontSize: 'xl' }}>
                    Deals
                  </Heading>
                  <Text as="span" utils={{ fontSize: 'sm', color: 'gray600', ml: 2 }}>
                    {lenderDealsData?.lenderDeals.count}
                  </Text>
                </Col>
                <Col span="auto">
                  <Close onClick={onClose} utils={{ ml: 'auto' }} />
                </Col>
              </Row>
              <Row gutter={2}>
                <Col>
                  <InputGroup size="sm" utils={{ borderRadius: 'rounded' }}>
                    <InputAddon>
                      <Icon icon={search} />
                    </InputAddon>
                    <Input
                      placeholder="Search"
                      onChange={(e) => {
                        changeSearchString(e.target.value);
                      }}
                      ref={searchInputRef}
                    />
                  </InputGroup>
                </Col>
                <Col span="180px">
                  <Select
                    size="sm"
                    options={lenderDealsSortOptions}
                    value={lenderDealsSortOptions.find(({ value }) => value === sort)}
                    onChange={(option) => setSort(option!.value)}
                    menuWidth="auto"
                    menuAlignment="right"
                    isSearchable={false}
                  />
                </Col>
              </Row>
            </CardHeader>
          )}
        </Sticky>
      ) : (
        <Row utils={{ alignItems: 'center', mb: 6 }}>
          <Col>
            <Heading as="h3" utils={{ fontSize: 'xl', mr: 'auto' }}>
              Deals
            </Heading>
          </Col>
          <Col span="180px">
            <Select
              size="sm"
              options={lenderDealsSortOptions}
              value={lenderDealsSortOptions.find(({ value }) => value === sort)}
              onChange={(option) => setSort(option!.value)}
              menuWidth="auto"
              menuAlignment="right"
              isSearchable={false}
            />
          </Col>
        </Row>
      )}
      {isModal && lenderDealsData?.lenderDeals.count !== 0 ? (
        <CardBody utils={{ px: 7, py: 0 }} desktop={{ px: 10 }}>
          {table}
        </CardBody>
      ) : (
        table
      )}
    </>
  );
};

export default LenderDealsList;
