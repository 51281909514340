import { DocumentRequestStatus, DocRequestFragment } from '~/generated/graphql';
import formatName from '~/helpers/formatName';
import { ShowGlobalAlertFn } from '~/hooks/useGlobalAlert';

export const showAutoAssignmentAlert = ({
  showGlobalAlert,
  newDocReq,
  prevDocReq,
  currentUserId,
}: {
  showGlobalAlert: ShowGlobalAlertFn;
  newDocReq: DocRequestFragment;
  prevDocReq: DocRequestFragment;
  currentUserId: string;
}) => {
  const prevDocumentIsOlder = newDocReq.lastDocumentId !== prevDocReq?.lastDocumentId &&
    new Date(newDocReq.lastDocCreatedAt!).getTime() < new Date(prevDocReq?.lastDocCreatedAt!).getTime();
  const newDocumentHasNoFile = Boolean(newDocReq.lastDocumentId) && !prevDocReq?.lastDocumentId;
  const wasDocumentDeleted = prevDocumentIsOlder || newDocumentHasNoFile;

  const isNewAssigneeCurrUser = newDocReq.task?.user?._id === currentUserId;

  const getAlertText = () => {
    if (wasDocumentDeleted) {
      if (newDocReq.status === DocumentRequestStatus.Nofile || newDocReq.status === DocumentRequestStatus.Approved) {
        if (isNewAssigneeCurrUser) {
          return 'Auto assigned to you';
        } else {
          return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })}`;
        }
      }
      if (newDocReq.status === DocumentRequestStatus.Rejected) {
        if (isNewAssigneeCurrUser) {
          return 'Auto assigned to you to upload revision';
        } else {
          return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })} to upload revision`;
        }
      }
      return;
    }

    if (
      [DocumentRequestStatus.Nofile, DocumentRequestStatus.Rejected, DocumentRequestStatus.Approved].includes(
        prevDocReq.status as DocumentRequestStatus
      ) &&
      newDocReq.status === DocumentRequestStatus.Review
    ) {
      if (isNewAssigneeCurrUser) {
        return 'Auto assigned to you for review';
      } else {
        return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })} for review`;
      }
    }
    if (prevDocReq.status === DocumentRequestStatus.Review && newDocReq.status === DocumentRequestStatus.Rejected) {
      // “Auto assigned to First L. to upload revision”
      if (isNewAssigneeCurrUser) {
        return 'Auto assigned to you to upload revision';
      } else {
        return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })} to upload revision`;
      }
    }
    if (prevDocReq.status === DocumentRequestStatus.Review && newDocReq.status === DocumentRequestStatus.Approved) {
      if (isNewAssigneeCurrUser) {
        return 'Auto assigned to you';
      } else {
        return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })}`;
      }
    }

    if (isNewAssigneeCurrUser) {
      return 'Auto assigned to you';
    } else {
      return `Auto assigned to ${formatName(newDocReq.task?.user?.fullName!, { initialLastName: true })}`;
    }
  };

  if (!prevDocReq.task || newDocReq.task?.createdBy || newDocReq.task?.docReqStatus === DocumentRequestStatus.Approved) {
    return;
  }

  showGlobalAlert(getAlertText()!, 'black');
};
