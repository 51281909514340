import { useEffect } from 'react';
import { FieldElement, FieldErrors } from 'react-hook-form';

const getVisibleElement = (input: HTMLElement) => {
  while (['none', 'contents'].includes(window.getComputedStyle(input).display)) {
    input = input.parentElement!;
  }
  return input;
};

const findError = (errors: FieldErrors, depth = 0, elements: HTMLElement[] = []) => {
  if (depth > 4) {
    return [];
  }

  Object.values(errors ?? {}).forEach((error) => {
    const name = (error?.ref as FieldElement)?.name;
    if (name) {
      const input =
        (document.querySelector(`[id="react-select-${name}-input"]`) as HTMLElement) ??
        (document.querySelector(`[name="${name}"]`) as HTMLElement) ??
        (document.querySelector(`[id="${name}"]`) as HTMLElement);
      if (input) {
        elements.push(getVisibleElement(input));
      }
    }
    findError(error as FieldErrors, depth + 1, elements);
  });

  return elements;
};

export function useFormScrollOnError(errors: FieldErrors, submitCount?: number) {
  useEffect(() => {
    if (
      errors &&
      Object.values(errors).length &&
      (document.activeElement as HTMLInputElement)?.type !== 'text' &&
      !(document.activeElement as HTMLElement)?.isContentEditable
    ) {
      const elements = findError(errors);
      if (elements.length > 0) {
        const element = elements.reduce((acc, current) => {
          if (acc.getBoundingClientRect().top > current.getBoundingClientRect().top) {
            return current;
          }
          return acc;
        });
        if (element instanceof HTMLInputElement) {
          element.focus();
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    }
  }, [errors, Object.values(errors).length, submitCount]);
}
