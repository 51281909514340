import React, { FC, useEffect, useRef, useState } from 'react';
import { Block } from '~/components/layout';
import { Text } from '~/components/type';
import { QuoteFragment } from '~/generated/graphql';
import { getBaseApiUrl } from '~/helpers';

type QuoteThumbnailProps = {
  quote: QuoteFragment;
  size?: '48px' | '32px';
};

const QuoteThumbnail: FC<QuoteThumbnailProps> = ({ quote, size = '48px' }) => {
  const [isImgLoaded, setImgLoaded] = useState<boolean | null>(null);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const avatarUrl = `${getBaseApiUrl()}/api/images/${quote.attachment._id}?width=96&height=96&fit=inside`;

  useEffect(() => {
    setTimeout(() => {
      if (imgRef.current?.complete) {
        return;
      }
      setImgLoaded((isLoaded) => Boolean(isLoaded));
    }, 100);
  }, []);

  return (
    <Block
      style={{
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      utils={{ mr: 6 }}
    >
      {isImgLoaded === false && <Text style={{ fontSize: size }}>📄</Text>}
      <Block
        as="img"
        src={avatarUrl}
        style={{
          maxWidth: size,
          maxHeight: size,
          boxShadow: '0px 1px 3px 0px #00000024',
          display: isImgLoaded === false ? 'none' : undefined,
        }}
        onLoad={() => {
          setImgLoaded(true);
        }}
        ref={imgRef}
      />
    </Block>
  );
};

export default QuoteThumbnail;
