import React, { useRef } from 'react';
import { CollapseProps } from './types';
import { UnmountClosed } from 'react-collapse';
import styled, { css } from 'styled-components';

type StyledTreeCollapseProps = {
  isSmooth: boolean;
};

const StyledTreeCollapse = styled.div<StyledTreeCollapseProps>`
  ${({ isSmooth }) => css`
    .ReactCollapse--collapse {
      transition: height ${isSmooth ? 300 : 0}ms ease-out;
    }
  `}
`;

const TreeCollapse = ({ isSmooth, isExpanded, onExpand, onCollapse, children }: CollapseProps) => {
  const isWorking = useRef(true);

  function handleRest({ isOpened }: { isOpened: boolean }) {
    if (!isWorking.current) {
      isOpened ? onExpand!() : onCollapse!();
      isWorking.current = true;
    }
  }

  function handleWork() {
    isWorking.current = false;
  }

  return (
    <StyledTreeCollapse isSmooth={isSmooth}>
      <UnmountClosed isOpened={isExpanded} onRest={handleRest} onWork={handleWork}>
        {children}
      </UnmountClosed>
    </StyledTreeCollapse>
  );
};

export default TreeCollapse;
