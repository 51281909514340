import { StatusProps } from '~/components/ui/Status';
import { BorrowerStatus, BorrowersTab } from '~/generated/graphql';

export type BorrowerStatusOption = {
  value: BorrowerStatus;
  label: string;
};

export const BorrowerStatusMapped: Record<BorrowerStatus, string> = {
  [BorrowerStatus.Lead]: 'Lead',
  [BorrowerStatus.Qualified]: 'Qualified',
  [BorrowerStatus.WorkingDeals]: 'Working Deals',
  [BorrowerStatus.Paused]: 'Paused',
  [BorrowerStatus.BadFit]: 'Bad Fit',
};

export const BorrowerStatusIcons: Record<BorrowerStatus, StatusProps['icon']> = {
  [BorrowerStatus.Lead]: null,
  [BorrowerStatus.Qualified]: null,
  [BorrowerStatus.WorkingDeals]: 'check',
  [BorrowerStatus.Paused]: 'ellipsis',
  [BorrowerStatus.BadFit]: 'x',
};

export const BorrowerStatusVariants: Record<BorrowerStatus, StatusProps['variant']> = {
  [BorrowerStatus.Lead]: 'circle-dashed',
  [BorrowerStatus.Qualified]: 'primary',
  [BorrowerStatus.WorkingDeals]: 'success',
  [BorrowerStatus.Paused]: 'warning',
  [BorrowerStatus.BadFit]: 'danger',
};

export const BorrowerStatusProgress: Record<BorrowerStatus, StatusProps['progress']> = {
  [BorrowerStatus.Lead]: null,
  [BorrowerStatus.Qualified]: 50,
  [BorrowerStatus.WorkingDeals]: null,
  [BorrowerStatus.Paused]: null,
  [BorrowerStatus.BadFit]: null,
};

export const borrowerStatusOptions: BorrowerStatusOption[] = Object.entries(BorrowerStatusMapped).map(([value, label]) => ({
  value: value as BorrowerStatus,
  label,
}));

export const BorrowersTabStatus: Record<BorrowersTab, BorrowerStatus[]> = {
  [BorrowersTab.Active]: [BorrowerStatus.Lead, BorrowerStatus.Qualified, BorrowerStatus.WorkingDeals],
  [BorrowersTab.Inactive]: [BorrowerStatus.Paused, BorrowerStatus.BadFit],
};

export const borrowersTabStatusOptions = Object.entries(BorrowersTabStatus).reduce((acc, [tab, statuses]) => {
  acc[tab as BorrowersTab] = borrowerStatusOptions.filter(({ value }) => statuses.includes(value));
  return acc;
}, {} as Record<BorrowersTab, BorrowerStatusOption[]>);

export default borrowerStatusOptions;
