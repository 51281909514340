import React, { FC } from 'react';
import { FlexRow } from '~/components/layout';
import { Table } from '~/components/table';
import { Heading } from '~/components/type';
import { CompanyFragment, LightCompanyFragment, QuoteFragment } from '~/generated/graphql';
import MatchModalQuote from './MatchModalQuote';
import { Button } from '~/components/form';
import { quoteEditModalVar } from '~/lib/apolloClient';

type MatchModalQuotesProps = {
  lender: CompanyFragment | LightCompanyFragment;
  quotes?: QuoteFragment[];
};

const MatchModalQuotes: FC<MatchModalQuotesProps> = ({ lender, quotes }) => {
  if (!quotes?.length) {
    return null;
  }

  return (
    <>
      <FlexRow utils={{ alignItems: 'center', mb: 6 }}>
        <Heading as="h3" utils={{ fontSize: 'xl' }}>
          Quotes
        </Heading>
        <Button size="sm" variant="white" utils={{ ml: 'auto' }} onClick={() => quoteEditModalVar({ isOpen: true, lenderId: lender.id })}>
          Add
        </Button>
      </FlexRow>

      <Table size="lg" align="center" gutterY={0} utils={{ mb: 9 }}>
        {quotes.map((quote) => (
          <MatchModalQuote key={quote._id} lender={lender} quotes={quotes} quote={quote} />
        ))}
      </Table>
    </>
  );
};

export default MatchModalQuotes;
