import React, { useState, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useRouter } from 'next/router';
import {
  DocRequestFoldersFragment,
  GetDealsToImportDocumentFromQuery,
  useGetDealsToImportDocumentFromQuery,
  useGetDocumentRequestFoldersQuery,
  useImportDocsIntoDealMutation,
} from '~/generated/graphql';
import { Checkbox, Feedback, FormGroup, Form, Label, Button } from '~/components/form';
import { Icon } from '~/components/vendor';
import { ListFolder } from '~/components/list';
import { Card, CardBody } from '~/components/card';
import { Spinner } from '~/components/ui';
import { Close, Modal } from '~/components/ui';
import { Heading, Text } from '~/components/type';
import { Select } from '~/components/vendor';

import { FlexRow } from '~/components/layout';
import { getFolderOptions } from '~/helpers/getFolderOptions';
import getNormalizedFolderTree from '~/helpers/getNormalizedFolderTree';
import { pluralize } from '~/helpers/pluralize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { plus } from '~/components/vendor/Icon/icons';

type SelectValue = {
  value: string;
  label: string;
  deal?: GetDealsToImportDocumentFromQuery['getDealsToImportFrom'][number];
  folder?: any;
  request?: any;
};

type RemoveFundForm = {
  importFrom: { value: string; label: string; deal: GetDealsToImportDocumentFromQuery['getDealsToImportFrom'][number] };
  documentsAndFolders: SelectValue[];
  importTo: SelectValue;
};

const ImportDealDocumentsModal: React.FC<{ isOpen: boolean; onClose: () => void; defaultFolderId?: string }> = ({
  isOpen,
  onClose,
  defaultFolderId,
  ...props
}) => {
  const router = useRouter();
  const showGlobalAlert = useShowGlobalAlert();
  const dealId = router.query?.dealId as string;

  const { data: docReqFoldersData } = useGetDocumentRequestFoldersQuery({
    fetchPolicy: 'cache-first',
    variables: { dealId },
  });
  const docReqFolders = docReqFoldersData?.getDocumentRequestFolders;

  const [importDocsIntoDeal, { loading: importingDocs }] = useImportDocsIntoDealMutation();

  let importOptions: ReturnType<typeof getFolderOptions> = [];

  if (docReqFolders) {
    const tree = getNormalizedFolderTree({ ...docReqFolders });
    importOptions = getFolderOptions({ data: tree, ignoreRoot: true });
  }

  const [activeStep, setActiveStep] = useState<'form' | 'confirm'>('form');
  const [formData, setFormData] = useState<RemoveFundForm | null>(null);
  const form = useForm<RemoveFundForm>({
    reValidateMode: 'onChange',
  });

  const formValues = useWatch({
    control: form.control,
  });

  const {
    formState: { errors: formErrors },
  } = form;

  const { data: deals, loading: dealsLoading } = useGetDealsToImportDocumentFromQuery({ skip: !isOpen });

  useEffect(() => {
    if (isOpen) {
      setActiveStep('form');
      form.reset();
    }
  }, [isOpen]);

  function handleFormSubmit(data: RemoveFundForm) {
    setActiveStep('confirm');
    // We need to save form data somewhere because it will be destroyed when form DOM in unmounted
    setFormData(data);
  }

  async function onImportDocsConfirm() {
    await importDocsIntoDeal({
      variables: {
        dealId,
        folderIds: formData?.documentsAndFolders.filter((doc) => Boolean(doc?.folder)).map((doc) => doc.value),
        requestIds: formData?.documentsAndFolders.filter((doc) => Boolean(doc?.request)).map((doc) => doc.value),
        targetFolderId: formData!.importTo.value,
      },
    });
    showGlobalAlert('Import success');
    onClose();
  }

  const dealsToImportFrom = deals?.getDealsToImportFrom.filter(
    (deal) => deal._id !== dealId && deal?.documentRequestFolders?.folders?.length > 0 && deal?.documentRequestFolders?.requests?.length > 0,
  );

  let foldersAndDocsToImport: ReturnType<typeof getFolderOptions> = [];

  const activeDealToImportFrom = formValues?.importFrom?.deal;
  if (activeDealToImportFrom) {
    const treeToImportFrom = getNormalizedFolderTree(activeDealToImportFrom.documentRequestFolders as DocRequestFoldersFragment);
    foldersAndDocsToImport = getFolderOptions({ data: { ...treeToImportFrom }, ignoreRoot: true, skipRequests: false }).map((row) => ({
      ...row,
      subTitle: true,
    }));
  }

  function findSubfolders(folderId: string) {
    // We are looking for all subfolders that we need to delete
    const subFolders = activeDealToImportFrom?.documentRequestFolders?.folders!.filter((folder) => String(folder.parentFolderId) === String(folderId));

    if (subFolders!.length === 0) {
      return [String(folderId)];
    }

    const moreSubfolders: string[] = subFolders!
      .map((subFolder) => {
        return findSubfolders(subFolder._id!);
      })
      .flat();

    return [String(folderId), ...moreSubfolders];
  }

  function getSelectedFoldersAndDocsText(selectedOptions: readonly SelectValue[] | undefined): React.ReactNode {
    const folders = (selectedOptions?.filter((value) => Boolean(value.folder)) ?? []).length;
    const requests = (selectedOptions?.filter((value) => Boolean(value.request)) ?? []).length;
    let finalText = '';
    if (folders > 0) {
      finalText = `${folders} ${pluralize(folders, 'folder')}`;
    }
    if (requests > 0) {
      finalText = `${requests} ${pluralize(requests, 'document')}`;
    }
    if (folders > 0 && requests > 0) {
      finalText = `${requests} ${pluralize(requests, 'document')} and ${folders} ${pluralize(folders, 'folder')}`;
    }
    return finalText;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="box" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: 'lg', textAlign: 'center', mb: 3 }}>
            {activeStep === 'form' ? 'Import Documents & Folders' : 'Confirm your Import?'}
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>
            {activeStep === 'form'
              ? 'Clone documents from another deal to make it faster to build out your complete file.'
              : 'Make sure you understand the finer points.'}
          </Text>
          {dealsLoading && <Spinner variant="primary" utils={{ mt: 10 }} style={{ position: 'relative' }} />}
          {activeStep === 'confirm' && (
            <>
              {[
                'Imported documents & folders are copies. Any edits made in the source or destination deal will not effect the other.',
                'Existing users on the deal will not be sent any notifications about imported documents',
                'Folders will not retain limited visibility settings',
              ].map((text, index) => (
                <FlexRow key={index} utils={{ alignItems: 'start' }}>
                  <Checkbox inputProps={{ checked: true }} utils={{ mt: 1 }} />
                  <Text utils={{ fontSize: 'sm', color: 'black', mb: 6 }}>{text}</Text>
                </FlexRow>
              ))}

              <Button
                type="submit"
                variant="primary"
                isBlock
                isLoading={importingDocs}
                disabled={importingDocs}
                utils={{ mt: 6 }}
                onClick={onImportDocsConfirm}
              >
                Import {getSelectedFoldersAndDocsText(formData?.documentsAndFolders)}
              </Button>
            </>
          )}
          {!dealsLoading && activeStep === 'form' && (
            <Form onSubmit={form.handleSubmit(handleFormSubmit)}>
              <FormGroup isFloating isValid={!formErrors.importFrom}>
                <Label>Import from</Label>
                <Controller
                  name="importFrom"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="importFrom"
                      placeholder="Select a deal"
                      options={dealsToImportFrom?.map((deal) => ({ value: deal._id, label: deal.name, deal }))}
                      value={value}
                      onChange={(newValue) => {
                        form.setValue('documentsAndFolders', []);
                        onChange(newValue);
                      }}
                    />
                  )}
                  control={form.control}
                  rules={{ required: true }}
                />
                {formErrors.importFrom && <Feedback>Field is required.</Feedback>}
              </FormGroup>

              <FormGroup isFloating isValid={!formErrors.documentsAndFolders}>
                <Label>Documents/Folders to import</Label>
                <Controller
                  name="documentsAndFolders"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      id="documentsAndFolders"
                      value={value}
                      placeholder="Select documents"
                      options={foldersAndDocsToImport.map((option) => ({ ...option, replaceOptionRender: true }))}
                      noOptionsMessage={() => (activeDealToImportFrom ? 'No folders and documents' : 'You must first select an “Import from”')}
                      onChange={(selectedValue) => {
                        const currentSelectedOptions = (value ?? []) as SelectValue[];
                        // Is select operation on unselect
                        const isSelectOp = selectedValue.length > currentSelectedOptions.length;
                        // We look for an option that was clicked from 2 array
                        const diffValue = (
                          isSelectOp
                            ? selectedValue.filter((item) => !currentSelectedOptions.map((r) => r.value).includes(item.value))
                            : currentSelectedOptions.filter((item) => !selectedValue.map((r) => r.value).includes(item.value))
                        )?.[0];

                        // If folder was selected - we should select all subfolders
                        if (diffValue && diffValue?.folder && isSelectOp) {
                          const allSubfolders = findSubfolders(diffValue.value);
                          const allSubDocumentRequests = activeDealToImportFrom?.documentRequestFolders?.requests
                            ?.filter((request) => allSubfolders.includes(request.parentFolderId!))
                            .map((request) => request._id);
                          const subOptionsToAttach = foldersAndDocsToImport.filter((o) => [...allSubDocumentRequests!, ...allSubfolders].includes(o.value));
                          const allNewOptionIds = [...new Set([...selectedValue, ...subOptionsToAttach].map((o) => o.value))];
                          const newSelectValue = foldersAndDocsToImport.filter((o) => allNewOptionIds.includes(o.value));
                          return onChange(newSelectValue);
                        }
                        if (selectedValue.length === 0) {
                          return onChange(null);
                        }
                        onChange(selectedValue);
                      }}
                      onOptionRender={(data, { innerProps, innerRef, isSelected }) => (
                        <div ref={innerRef} className="react-select__option" {...innerProps}>
                          {data.folder ? (
                            <FlexRow utils={{ alignItems: 'center' }} style={{ paddingLeft: data.folder.depth! * 12 }}>
                              <Checkbox inputProps={{ checked: isSelected, style: { pointerEvents: 'none' } }} />
                              {data.folder.title === 'At The Top Level' ? (
                                <Icon icon={plus} utils={{ color: 'primary', mr: 4 }} />
                              ) : (
                                <ListFolder size="xs" variant={data.folder.adminsOnlyAccess ? 'black' : 'primary'} isExpandable={false} utils={{ mr: 4 }} />
                              )}
                              <Text utils={{ fontWeight: data.folder.depth! > 0 ? 'base' : 'bold', textTruncate: true }}>{data.folder.title}</Text>
                            </FlexRow>
                          ) : (
                            <FlexRow utils={{ alignItems: 'center' }} style={{ paddingLeft: data.request.depth * 12 }}>
                              <Checkbox inputProps={{ checked: isSelected, style: { pointerEvents: 'none' } }} />
                              <Text utils={{ fontWeight: data.request.depth > 0 ? 'base' : 'bold', textTruncate: true }}>{data.request.title}</Text>
                            </FlexRow>
                          )}
                        </div>
                      )}
                      onValueContainerRender={getSelectedFoldersAndDocsText}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      showDropdownIcon
                      isMulti
                      isSearchable={false}
                    />
                  )}
                  control={form.control}
                  rules={{ required: true }}
                />
                {formErrors.documentsAndFolders && <Feedback>Field is required.</Feedback>}
              </FormGroup>

              <FormGroup isFloating isValid={!formErrors.importTo}>
                <Label>Import to</Label>
                <Controller
                  name="importTo"
                  render={({ field: { onChange, value } }) => (
                    <Select id="importTo" placeholder="Select a folder" options={importOptions} value={value} onChange={onChange} />
                  )}
                  control={form.control}
                  rules={{ required: true }}
                  defaultValue={importOptions.find((option) => option.value === defaultFolderId)}
                />
                {formErrors.importTo && <Feedback>Field is required.</Feedback>}
              </FormGroup>
              <Button type="submit" variant="primary" isBlock isLoading={false}>
                Continue
              </Button>
            </Form>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default ImportDealDocumentsModal;
