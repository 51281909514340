import React, { Fragment } from 'react';
import { AssetType, GetLenderLoansQuery } from '~/generated/graphql';
import { AssetTypeLabels } from '~/data/assetTypeOptions';
import { BaseProps } from '~/components/layout/Base';
import { Block, Col, Row } from '~/components/layout';
import { chevronRight, flag } from '~/components/vendor/Icon/icons';
import { DealTypeLabels } from '~/data';
import { Divider } from '~/components/ui';
import { formatDate, formatMoney, formatNumber } from '~/helpers';
import { CappedList, Heading, StretchedLink, Text } from '~/components/type';
import { Icon } from '~/components/vendor';
import { List, ListItem } from '~/components/list';
import { TableCell, TableCollapse, TableCollapseBody, TableRow } from '~/components/table';
import capitalizeString from '~/helpers/capitalizeString';
import DealsAvatar from '../Deals/DealsAvatar';
import { getBadgeVariant } from '~/modals/MatchModal/MatchModalMatch';

type LenderLoansItemProps = BaseProps & {
  loan: GetLenderLoansQuery['getLenderLoans']['lenderLoans'][number];
  showScore?: boolean;
  setInaccuracyLoanId: (loanId: string) => void;
  setLoanPropertiesModalLoanId: (loanId: string) => void;
};

export default function LenderLoansItem({ loan, showScore, setInaccuracyLoanId, setLoanPropertiesModalLoanId, ...props }: LenderLoansItemProps) {
  const isMultiProperty = loan.propertyCount && loan.propertyCount > 1;

  const loanDetails = {
    totalLoanValue: {
      name: 'Total Loan Value',
      value: `$${formatNumber(loan.totalLoanValue ?? 0)}`,
      isHidden: loan.totalLoanValue === null,
    },
    issuedOn: {
      name: 'Issued On',
      value: loan.executedDate && new Date(loan.executedDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
      isHidden: loan.executedDate === null,
    },
    term: {
      name: 'Term',
      value: `${loan.termMonths} Months`,
      isHidden: loan.termMonths === null,
    },
    documentType: {
      name: 'Document Type',
      value: loan.documentType,
      isHidden: loan.documentType === null,
    },
    mortgageType: {
      name: 'Mortgage Type',
      value: loan.mortgageType ? capitalizeString(loan.mortgageType.toLowerCase()) : '',
      isHidden: loan.mortgageType === null,
    },
    interestRate: {
      name: 'Interest Rate',
      value: `${loan.interestRateAmount}% (${loan.interestRateType})`,
      isHidden: loan.interestRateAmount === null || loan.interestRateType === null,
    },
  };

  const propertyDetails = {
    landUse: {
      name: isMultiProperty ? 'Land Uses' : 'Land Use',
      value:
        loan.propertySummary?.reonomyAssetTypes && loan.propertySummary.reonomyAssetTypes.length > 0 ? (
          <CappedList list={loan.propertySummary.reonomyAssetTypes as string[]} maxLength={2} />
        ) : (
          ''
        ),
      isHidden: loan.propertySummary?.reonomyAssetTypes === null,
    },
    location: {
      name: isMultiProperty ? 'Location(s)' : 'Location',
      value: `${capitalizeString(loan.propertySummary?.propertyAddress?.city || 'Unknown City')}, ${loan.propertySummary?.propertyAddress?.state}`,
      isHidden: loan.propertySummary?.propertyAddress?.city === null || loan.propertySummary?.propertyAddress?.state === null,
    },
    totalLotSizeAcres: {
      name: isMultiProperty ? 'Total Lot Size' : 'Lot Size',
      value: loan.propertySummary?.totalLotSizeAcres ? `${Math.round(loan.propertySummary.totalLotSizeAcres * 100) / 100} acres` : '',
      isHidden: loan.propertySummary?.totalLotSizeAcres === null,
    },
    zoningCode: {
      name: 'Zoning',
      value: loan.propertySummary?.zoningCode,
      isHidden: loan.propertySummary?.zoningCode === null,
    },
    totalBuildingsSqFt: {
      name: isMultiProperty ? 'Total Building Area' : 'Building Area',
      value: loan.propertySummary?.totalBuildingsSqFt ? `${new Intl.NumberFormat('en-US').format(loan.propertySummary.totalBuildingsSqFt)} sqft` : '',
      isHidden: loan.propertySummary?.totalBuildingsSqFt === null,
    },
    yearBuilt: {
      name: 'Year Built',
      value: loan.propertySummary?.yearBuilt,
      isHidden: loan.propertySummary?.yearBuilt === null,
    },
    yearRenovated: {
      name: 'Year Renovated',
      value: loan.propertySummary?.yearRenovated,
      isHidden: loan.propertySummary?.yearRenovated === null,
    },
    floorCount: {
      name: 'Stories',
      value: loan.propertySummary?.floorCount,
      isHidden: loan.propertySummary?.floorCount === null,
    },
    totalUnitCount: {
      name: isMultiProperty ? 'Total Units' : 'Units',
      value: loan.propertySummary?.totalUnitCount,
      isHidden: loan.propertySummary?.totalUnitCount === null,
    },
  };

  const assetType =
    loan.inferredAssetType && AssetTypeLabels[loan.inferredAssetType as AssetType]
      ? AssetTypeLabels[loan.inferredAssetType as AssetType]
      : loan.expectedDevelopment
        ? AssetTypeLabels[loan.expectedDevelopment.assetType]
        : loan.propertySummary?.assetTypes?.[0]
          ? AssetTypeLabels[loan.propertySummary.assetTypes[0] as AssetType]
          : '';

  return (
    <>
      <TableCollapse {...props}>
        {(isOpen, setOpen) => (
          <>
            <TableRow isActive={isOpen}>
              <TableCell utils={{ flexWrap: 'nowrap' }}>
                <DealsAvatar
                  lat={loan.propertySummary?.propertyAddress?.latitude}
                  lng={loan.propertySummary?.propertyAddress?.longitude}
                  name={capitalizeString(loan.propertySummary?.propertyAddress?.street || loan.propertySummary?.propertyAddress?.address || 'Unknown Address')}
                  utils={{ borderRadius: 'base', mr: 6 }}
                  {...(true && loan.matchScore?.score
                    ? {
                        badge: `${Math.round(loan.matchScore.score)}%`,
                        badgeProps: { utils: { bgColor: getBadgeVariant(loan.matchScore.score) } },
                      }
                    : {})}
                />
                <Block utils={{ minWidth: 0 }}>
                  <StretchedLink onClick={() => setOpen(!isOpen)}>
                    <Heading as="h3" utils={{ fontSize: 'base', fontWeight: 'bold', color: 'black', textTruncate: true }}>
                      {isMultiProperty ? (
                        <>
                          Multi-Property{' '}
                          <Text as="span" utils={{ fontSize: 'sm', fontWeight: 'base', color: 'gray700' }}>
                            ({loan.propertyCount} properties)
                          </Text>
                        </>
                      ) : (
                        capitalizeString(loan.propertySummary?.propertyAddress?.street || 'Unknown Address')
                      )}
                    </Heading>
                  </StretchedLink>
                  <Text utils={{ fontSize: 'sm', color: 'gray700', textTruncate: true }}>
                    {capitalizeString(loan.propertySummary?.propertyAddress?.city || 'Unknown City')}, {loan.propertySummary?.propertyAddress?.state} ·{' '}
                    {assetType} {DealTypeLabels[loan.dealType]}
                  </Text>
                </Block>
              </TableCell>
              <TableCell span="auto">
                <Block utils={{ textAlign: 'right' }}>
                  {loan.totalLoanValue && (
                    <Heading as="h3" utils={{ fontSize: 'base', fontWeight: 'bold', color: 'black' }}>
                      {formatMoney(loan.totalLoanValue)}
                    </Heading>
                  )}
                  {loan.executedDate && (
                    <Text utils={{ fontSize: 'sm', color: 'gray700' }}>
                      {new Date(loan.executedDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}
                    </Text>
                  )}
                </Block>
              </TableCell>
              <TableCollapseBody isOpen={isOpen}>
                {isOpen && (
                  <TableCell utils={{ position: 'relative' }}>
                    <Text as="h6" utils={{ color: 'gray700', textTransform: 'uppercase', fontSize: '2xs', fontWeight: 'bold', mb: 4 }}>
                      What was this loan for?
                    </Text>
                    <Text utils={{ fontSize: 'sm' }}>
                      {loan.loanHeadline && <Text as="strong">{loan.loanHeadline}</Text>} {loan.loanDescription}{' '}
                      {loan.sourceUrls && loan.sourceUrls.length > 0 && (
                        <Text as="span" utils={{ color: 'gray700' }}>
                          Sources:{' '}
                          {loan.sourceUrls?.map(
                            (item, index) =>
                              item?.url && (
                                <Fragment key={index}>
                                  <Text
                                    as="a"
                                    key={index}
                                    href={item.url}
                                    target="_blank"
                                    rel="nofollow noopener"
                                    utils={{ color: 'gray700' }}
                                    hover={{ color: 'primary' }}
                                  >
                                    {item.label}
                                  </Text>
                                  {loan.sourceUrls && index !== loan.sourceUrls.length - 1 && ', '}
                                </Fragment>
                              ),
                          )}
                        </Text>
                      )}
                    </Text>
                    <Text as="h6" utils={{ color: 'gray700', textTransform: 'uppercase', fontSize: '2xs', fontWeight: 'bold', mb: 4, mt: 8 }}>
                      Loan Details
                    </Text>
                    <List gutter={3} utils={{ fontSize: 'sm', mb: 0 }}>
                      {Object.values(loanDetails)
                        .filter((item) => !item.isHidden)
                        .map((item, index) => (
                          <ListItem key={index}>
                            <Row gutter={3} utils={{ alignItems: 'center' }}>
                              <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                {item.name}
                              </Col>
                              <Col>
                                <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                              </Col>
                              <Col span="auto">{item.value}</Col>
                            </Row>
                          </ListItem>
                        ))}
                    </List>
                    <Text as="h6" utils={{ color: 'gray700', textTransform: 'uppercase', fontSize: '2xs', fontWeight: 'bold', mt: 8, mb: 4 }}>
                      Property & Building (at time of loan issuance)
                    </Text>
                    <List gutter={3} utils={{ fontSize: 'sm', mb: 0 }}>
                      {Object.values(propertyDetails)
                        .filter((item) => !item.isHidden)
                        .map((item, index) => (
                          <ListItem key={index}>
                            <Row gutter={3} utils={{ alignItems: 'center' }}>
                              <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                {item.name}
                              </Col>
                              <Col>
                                <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                              </Col>
                              <Col span="auto">{item.value}</Col>
                            </Row>
                          </ListItem>
                        ))}
                    </List>
                    {isMultiProperty && (
                      <Text utils={{ fontSize: 'sm', color: 'primary', mt: 8 }} onClick={() => setLoanPropertiesModalLoanId(loan.loanId)} role="button">
                        View details of {loan.propertyCount} properties <Icon icon={chevronRight} utils={{ ml: 3 }} />
                      </Text>
                    )}
                    <Divider utils={{ mt: 8, mb: 6 }} />
                    <Row>
                      <Col>
                        <Text utils={{ fontSize: 'sm', color: 'gray700' }}>Updated {formatDate(loan.sourceDataLastUpdatedDate)}</Text>
                      </Col>
                      <Col span="auto">
                        <Text
                          utils={{ fontSize: 'sm', color: 'gray700' }}
                          hover={{ color: 'primary' }}
                          onClick={() => setInaccuracyLoanId(loan.loanId)}
                          role="button"
                        >
                          <Icon icon={flag} /> Report Inaccuracy
                        </Text>
                      </Col>
                    </Row>
                  </TableCell>
                )}
              </TableCollapseBody>
            </TableRow>
          </>
        )}
      </TableCollapse>
    </>
  );
}
