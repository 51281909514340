import React, { FC, FormEvent, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import { useRouter } from 'next/router';

import { Button, Feedback, Form, Input, FormGroup } from '../components/form';
import { Select } from '../components/vendor';
import { getFolderOptions } from '../helpers';
import getNormalizedFolderTree, { NormalizedDocRequest, NormalizedFolderTreeItem } from '../helpers/getNormalizedFolderTree';
import useDocumentFoldersOps from './Document/useDocumentFoldersOps';
import { DealUserRole, DocFolderFragment, DocRequestFragment, useGetDealMembersQuery } from '~/generated/graphql';

type EditDocumentRequestFormProps = {
  request: NormalizedFolderTreeItem;
  onClose: (data?: { isOnConfirm?: boolean }) => void;
  isBorrower?: boolean;
};

const EditDocumentRequestForm: FC<EditDocumentRequestFormProps> = ({ request, onClose, isBorrower }) => {
  const dealId = useRouter().query.dealId as string;

  const {
    updateDocumentRequest: { updateDocumentRequest, updatingDocumentRequest },
    getCacheDocRequestFolders,
  } = useDocumentFoldersOps({ dealId });
  const data = getNormalizedFolderTree(getCacheDocRequestFolders()!);

  const { data: dealMembersData } = useGetDealMembersQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
  });
  const dealAdminsIds = dealMembersData?.getDealMembers.usersAndInvitations
    .filter((data) => data.role === DealUserRole.Admin)
    .map((data) => data.user?._id ?? data._id);

  const [documentName, setDocumentName] = useState(request.title);
  const folderOptions = getFolderOptions({ data, ignoreRoot: true });
  const [folder, setFolder] = useState<{ value: string; label: string } | null>(
    request
      ? {
          value: (request.data as DocRequestFragment).parentFolderId!,
          label: folderOptions.find((o) => o.value === (request.data as DocRequestFragment).parentFolderId)!.label,
        }
      : null,
  );
  const [instructions, setInstructions] = useState((request.data as DocRequestFragment).instructions);
  const [errors, setErrors] = useState({
    submitted: false,
    name: false,
    folder: false,
    limitedAccess: false,
  });

  const handleEditSingleDocReq = async (e: FormEvent) => {
    e.preventDefault();

    setErrors({ ...errors, submitted: true });
    if (errors.name || errors.folder || errors.limitedAccess) {
      return;
    }

    const variables = {
      id: request.id,
      name: documentName,
      parentFolderId: folder!.value,
      instructions,
    };

    const newOrderValue = data.items[folder!.value].children!.length + 1;

    await updateDocumentRequest(variables, newOrderValue);

    // FIXME: For some reason Apollo doesn't want to update the cache without it. OMG.
    // Investigate this thing. Probably it's because of how modal is written.
    setTimeout(() => {
      onClose();
    }, 10);
  };

  return (
    <Form utils={{ mb: 6 }} onSubmit={handleEditSingleDocReq}>
      <FormGroup isValid={!errors.submitted || !errors.name}>
        <Input
          type="text"
          value={documentName}
          placeholder="Document Name"
          name="editDocumentName"
          onChange={(e) => {
            setDocumentName(e.target.value);
            setErrors({ ...errors, name: !e.target.value });
          }}
        />
        {errors.submitted && errors.name && <Feedback>A Document name is required</Feedback>}
      </FormGroup>
      <FormGroup isValid={!errors.submitted || (!errors.folder && !errors.limitedAccess)}>
        <Select
          placeholder="Select a section?"
          name="editDocumentFolder"
          value={folder}
          options={folderOptions}
          onChange={(selectedOption) => {
            setFolder(selectedOption);
            const targetFolderId = selectedOption!.value;
            function getDestinationLimitAccess(folderId: string) {
              const parentFolder = data.items[folderId]?.data;

              if ((parentFolder as DocFolderFragment)?.adminsOnlyAccess) {
                return true;
              }

              if ((parentFolder as DocFolderFragment)?.userIdsWithAccess?.length! > 0) {
                return (parentFolder as DocFolderFragment).userIdsWithAccess!;
              }

              if (data.items[folderId]?.title === 'root' || !parentFolder?.parentFolderId) {
                return [];
              }

              return getDestinationLimitAccess(parentFolder.parentFolderId);
            }

            let assignedUserId = (request?.data as NormalizedDocRequest)?.task?.user?._id ?? (request?.data as NormalizedDocRequest)?.task?.invitation?._id;
            if (dealAdminsIds?.some((_id) => assignedUserId?.includes(_id))) {
              assignedUserId = undefined;
            }
            const destinationLimitAccess = getDestinationLimitAccess(targetFolderId);
            const assignedTaskUserHasNoAccess =
              assignedUserId && (destinationLimitAccess === true || (destinationLimitAccess.length > 0 && !destinationLimitAccess.includes(assignedUserId)));

            if (assignedTaskUserHasNoAccess) {
              setErrors({ ...errors, limitedAccess: true });
              return;
            }

            setErrors({ ...errors, limitedAccess: false, folder: !selectedOption });
          }}
        />
        {errors.submitted && errors.folder && <Feedback>A Folder is required</Feedback>}
        {errors.submitted && errors.limitedAccess && <Feedback>Cannot move this document to a folder the assigned user doesn't have access to</Feedback>}
      </FormGroup>
      {!isBorrower && (
        <FormGroup>
          <Input
            as={Textarea as React.ElementType}
            name="editDocumentInstructions"
            minRows={4}
            value={instructions}
            placeholder="Instructions (optional)"
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setInstructions(e.target.value)}
          />
        </FormGroup>
      )}
      <Button variant="primary" type="submit" isLoading={updatingDocumentRequest} isBlock>
        Save changes
      </Button>
    </Form>
  );
};

export default EditDocumentRequestForm;
