import React, { useRef } from 'react';
import { AssignDocReqDropdownMenu } from '~/containers/Tasks/AssignDocReqDropdownMenu';
import { ContextMenuSourceProps } from '~/containers/DocumentRequestTree';
import { DropdownControlled, DropdownMenu, DropdownSubmenu, DropdownItem, Icon } from '~/components/vendor';
import { Toggle } from '../../../components/ui';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { useRouter } from 'next/router';
import useDocumentFoldersOps from '../useDocumentFoldersOps';
import { NormalizedDocRequest, NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { DocRequestFragment } from '~/generated/graphql';
import { download, upload, edit, userCircle01, refreshCcw, trash2 } from '~/components/vendor/Icon/icons';

interface Props {
  downloadFile: () => void;
  isTemplateDeal: boolean;
  isUserCreatedItem: boolean;
  item: NormalizedFolderTreeItem;
  contextMenuSource: ContextMenuSourceProps;
  onDocRequestRowClick: ({ requestId }: { requestId: string }) => void;
  onDropdownMenuClose: () => void;
  setDeleteFolderModal: (arg: { isOpen: boolean; item: NormalizedFolderTreeItem }) => void;
  setEditRequestModal: (arg: { isOpen: boolean; request: NormalizedFolderTreeItem }) => void;
}

const RequestDropdownAdmin = ({
  item,
  contextMenuSource,
  isTemplateDeal,
  setEditRequestModal,
  setDeleteFolderModal,
  downloadFile,
  onDocRequestRowClick,
  onDropdownMenuClose,
}: Props) => {
  const router = useRouter();
  const dealId = router.query.dealId as string;
  const { resetDocRequestStatus } = useDocumentFoldersOps({ dealId });

  const isNoFile = (item.data as DocRequestFragment).status === 'nofile';
  const isApproved = (item.data as DocRequestFragment).status === 'approved';
  const isRejected = (item.data as DocRequestFragment).status === 'rejected';

  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  function handleClose() {
    toggleMenu(false);
    onDropdownMenuClose();
  }

  if (contextMenuSource && contextMenuSource.id === item.id) {
    toggleMenu(true);
  }

  return (
    <>
      <Toggle ref={ref} {...anchorProps} />
      <DropdownControlled
        anchorRef={contextMenuSource ? undefined : ref}
        anchorPoint={contextMenuSource ? contextMenuSource.anchorPoint : undefined}
        onClose={handleClose}
        {...menuState}
      >
        <DropdownMenu>
          {!isTemplateDeal && (
            <DropdownSubmenu
              label={
                <>
                  <Icon icon={userCircle01} size={16} utils={{ color: 'gray700' }} />
                  Assign to
                </>
              }
            >
              <AssignDocReqDropdownMenu task={(item?.data as NormalizedDocRequest)?.task ?? { docReq: item.id }} />
            </DropdownSubmenu>
          )}
          {!isNoFile && (
            <DropdownItem key="download-file" onClick={downloadFile} icon={download}>
              Download
            </DropdownItem>
          )}
          {isNoFile && !isTemplateDeal && (
            <DropdownItem
              key="upload"
              onClick={() => {
                onDocRequestRowClick({ requestId: item.id });
              }}
              icon={upload}
            >
              Upload
            </DropdownItem>
          )}
          <DropdownItem
            key="edit-request"
            onClick={() => {
              setEditRequestModal({
                isOpen: true,
                request: item,
              });
            }}
            icon={edit}
          >
            Edit
          </DropdownItem>
          {isApproved && !isTemplateDeal && (
            <DropdownItem key="undo-approval" onClick={() => resetDocRequestStatus(item.id)} icon={refreshCcw}>
              Undo Approval
            </DropdownItem>
          )}
          {isRejected && !isTemplateDeal && (
            <DropdownItem key="undo-rejection" onClick={() => resetDocRequestStatus(item.id)} icon={refreshCcw}>
              Undo Rejection
            </DropdownItem>
          )}
          <DropdownItem
            key="delete-request"
            onClick={() => {
              setDeleteFolderModal({
                isOpen: true,
                item,
              });
            }}
            icon={trash2}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </DropdownControlled>
    </>
  );
};

export default RequestDropdownAdmin;
