import { useApolloClient } from '@apollo/client';
import { v4 as uuid } from 'uuid';
import {
  CreateDocumentFolderMutationVariables,
  CreateDocumentRequestMutationVariables,
  DocFolderFragment,
  DocFolderFragmentDoc,
  DocRequestFoldersFragment,
  DocRequestFoldersFragmentDoc,
  DocRequestFragment,
  DocRequestFragmentDoc,
  DocumentRequestStatus,
  UpdateDocumentFolderMutationVariables,
  UpdateDocumentRequestMutationVariables,
  useCreateDocumentFolderMutation,
  useCreateDocumentRequestMutation,
  useDeleteDocumentFolderMutation,
  useDeleteDocumentRequestMutation,
  useGetLocalUserQuery,
  useResetDocStatusMutation,
  useUpdateDocumentFolderMutation,
  useUpdateDocumentRequestMutation,
} from '../../generated/graphql';
import { TargetItemProps, TreeData } from '~/components/tree/types';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type UpdatedTreeItem = {
  id: string;
  parentFolderId?: string | null;
  order: number;
};

// TODO: split to smaller hooks
const useDocumentFoldersOps = ({ dealId, withServerSync = true }: { dealId: string; withServerSync?: boolean }) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();

  const queryData = useGetLocalUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = queryData.data?.currentUser;

  const [createDocumentFolder, { loading: creatingNewDocumentFolder }] = useCreateDocumentFolderMutation();
  const [createDocumentRequest, { loading: creatingNewRequest }] = useCreateDocumentRequestMutation();
  const [deleteRequest, { loading: deletingDocumentRequest }] = useDeleteDocumentRequestMutation();
  const [deleteFolder, { loading: deletingDocumentFolder }] = useDeleteDocumentFolderMutation();
  const [updateRequest, { loading: updatingDocumentRequest }] = useUpdateDocumentRequestMutation();
  const [updateFolder, { loading: updatingDocumentFolder }] = useUpdateDocumentFolderMutation();
  const [resetDocStatus] = useResetDocStatusMutation();

  /**
   * E.g. update all folders/requests after dragging
   */
  async function updateTree(
    updatedTree: TreeData<NormalizedFolderTreeItem>,
    { dragTarget, destination }: { dragTarget: NormalizedFolderTreeItem; destination: TargetItemProps },
  ) {
    console.log(destination);
    if (typeof destination.index !== 'undefined' && isNaN(destination.index)) {
      return;
    }
    const docRequestFolders = getCacheDocRequestFolders();

    const updatedFolders: UpdatedTreeItem[] = [];
    const updatedRequests: UpdatedTreeItem[] = [];

    // Get all requests + folders from the tree that were updated
    // We go though all items, because dragging can update almost all items order field
    // It's better to be sure that everything are updated and saved correctly
    Object.values(updatedTree.items)
      // We aren't interested in "root" item. It's a "dummy" item that we don't save on the server
      // It's just a holder of all "root" folders
      .filter((treeItem) => treeItem.id !== 'root')
      .forEach((treeItem) => {
        // We are looking for a new parent folder of the item
        const parentItem = Object.values(updatedTree.items).find((item) => item.children?.includes(treeItem.id));

        if (!parentItem) {
          return;
        }

        // Next we need to find new order value
        if (!parentItem.children) {
          parentItem.children = [];
        }
        const order = parentItem.children.findIndex((item) => item === treeItem.id)!;

        // So, right now we have everything what we need: updated parentFolderId and order field
        if (treeItem.isFolder) {
          const parentFolderId = parentItem?.id === 'root' ? null : parentItem?.id;
          updatedFolders.push({
            id: treeItem.id,
            parentFolderId,
            order,
          });
        }

        if (treeItem.isRequest) {
          updatedRequests.push({
            id: treeItem.id,
            parentFolderId: parentItem?.id,
            order,
          });
        }
      });

    const newCacheFolders = docRequestFolders?.folders.map((cacheFolder) => {
      const updatedFolder = updatedFolders.find(({ id }) => id === cacheFolder?._id);
      if (updatedFolder) {
        return {
          ...cacheFolder,
          order: updatedFolder.order,
          parentFolderId: updatedFolder.parentFolderId,
        };
      }
      return { ...cacheFolder };
    });
    const newCacheRequests = docRequestFolders?.requests.map((cacheRequest) => {
      const updatedRequest = updatedRequests.find(({ id }) => id === cacheRequest?._id);
      if (updatedRequest) {
        return {
          ...cacheRequest,
          order: updatedRequest.order,
          parentFolderId: updatedRequest.parentFolderId,
        };
      }
      return { ...cacheRequest };
    });

    // Updating cache for the immediate result
    client.writeFragment({
      id: `DocumentRequestFolders:${dealId}`,
      fragmentName: 'DocRequestFolders',
      fragment: DocRequestFoldersFragmentDoc,
      data: {
        _id: dealId,
        requests: newCacheRequests,
        folders: newCacheFolders,
      },
    });

    if (withServerSync) {
      // Check if something was changed
      if (dragTarget?.isRequest) {
        const request = client.readFragment<DocRequestFragment>({
          id: `DocumentRequest:${dragTarget.id}`,
          fragmentName: 'DocRequest',
          fragment: DocRequestFragmentDoc,
        });

        await updateRequest({
          variables: {
            id: request!._id,
            name: request!.name,
            parentFolderId: destination.parentId!,
            order: destination.index,
          },
        });
      }

      if (dragTarget?.isFolder && dragTarget.id) {
        const folder = client.readFragment<DocFolderFragment>({
          id: `DocumentRequestFolder:${dragTarget.id}`,
          fragmentName: 'DocFolder',
          fragment: DocFolderFragmentDoc,
        });
        await updateFolder({
          variables: {
            id: folder!._id,
            name: folder!.name,
            parentFolderId: destination.parentId === 'root' ? null : destination.parentId,
            order: destination.index,
          },
        });
      }
    }
  }

  async function createFolder(folder: CreateDocumentFolderMutationVariables) {
    let newFolder: DocFolderFragment;

    if (withServerSync) {
      const res = await createDocumentFolder({
        variables: folder,
      });
      newFolder = res.data?.createDocumentRequestFolder!;
    } else {
      newFolder = {
        name: folder.name,
        order: folder.order,
        createdBy: currentUser!._id,
        parentFolderId: folder.parentFolderId || null,
        __typename: 'DocumentRequestFolder',
        _id: uuid(),
        usersWithAccess: [],
        userIdsWithAccess: [],
        isActive: true,
      };
    }

    addNewFolderToCache(newFolder);
    return newFolder;
  }

  const addNewFolderToCache = (newFolder: DocFolderFragment) => {
    const docRequestFolders = getCacheDocRequestFolders();

    const folders = JSON.parse(JSON.stringify(docRequestFolders?.folders ?? [])) as DocRequestFoldersFragment['folders'];
    const siblingFolders = folders?.filter((folder) => folder.parentFolderId === newFolder?.parentFolderId);
    const subsequentFolders = siblingFolders?.filter((folder) => folder.order >= newFolder?.order);

    subsequentFolders.forEach((folder) => {
      folder.order++;
    });

    const requests = JSON.parse(JSON.stringify(docRequestFolders?.requests ?? [])) as DocRequestFoldersFragment['requests'];
    const siblingRequests = requests?.filter((request) => request.parentFolderId === newFolder?.parentFolderId);
    const subsequentRequests = siblingRequests?.filter((request) => request.order >= newFolder?.order);

    subsequentRequests.forEach((request) => {
      request.order++;
    });

    if (!docRequestFolders?.folders.find((folder) => folder._id === newFolder._id)) {
      client.writeFragment({
        id: `DocumentRequestFolders:${dealId}`,
        fragmentName: 'DocRequestFolders',
        fragment: DocRequestFoldersFragmentDoc,
        data: { ...docRequestFolders, folders: [...(folders || []), newFolder], requests: [...(requests || [])] },
      });
    }
  };

  async function createRequest(request: CreateDocumentRequestMutationVariables, requestOrder: number) {
    let newRequest: DocRequestFragment;

    if (withServerSync) {
      const res = await createDocumentRequest({
        variables: { ...request, order: requestOrder },
      });
      newRequest = res.data?.addSingleDocument!;
    } else {
      newRequest = {
        __typename: 'DocumentRequest',
        _id: uuid(),
        createdBy: currentUser!._id,
        creatorName: currentUser!.fullName,
        dealId: dealId,
        instructions: request.instructions,
        lastDocCreatedAt: null,
        lastDocCreator: null,
        lastDocument: null,
        lastDocumentId: null,
        name: request.name,
        order: requestOrder,
        parentFolderId: request.parentFolderId,
        isActive: true,
        status: DocumentRequestStatus.Nofile,
      };
    }

    addNewRequestToCache(newRequest);
    return newRequest;
  }

  const addNewRequestToCache = (newRequest: DocRequestFragment) => {
    const docRequestFolders = getCacheDocRequestFolders();

    const folders = JSON.parse(JSON.stringify(docRequestFolders?.folders ?? [])) as DocRequestFoldersFragment['folders'];
    const siblingFolders = folders?.filter((folder) => folder.parentFolderId === newRequest?.parentFolderId);
    const subsequentFolders = siblingFolders?.filter((folder) => folder.order >= newRequest?.order);

    subsequentFolders.forEach((folder) => {
      folder.order++;
    });

    const requests = JSON.parse(JSON.stringify(docRequestFolders?.requests ?? [])) as DocRequestFoldersFragment['requests'];
    const siblingRequests = requests?.filter((request) => request.parentFolderId === newRequest?.parentFolderId);
    const subsequentRequests = siblingRequests?.filter((request) => request.order >= newRequest?.order);

    subsequentRequests.forEach((request) => {
      request.order++;
    });

    if (!docRequestFolders?.requests.find((request) => request._id === newRequest._id)) {
      client.writeFragment({
        id: `DocumentRequestFolders:${dealId}`,
        fragmentName: 'DocRequestFolders',
        fragment: DocRequestFoldersFragmentDoc,
        data: { ...docRequestFolders, folders: [...(folders || [])], requests: [...(requests || []), newRequest] },
      });
    }
  };

  async function updateDocumentFolder(folder: UpdateDocumentFolderMutationVariables, newOrderValue: number, newUsersWithAccess?: string[]) {
    if (withServerSync) {
      await updateFolder({ variables: folder, refetchQueries: ['getDealActivities'] });
    }

    const cachedFolder = client.readFragment<DocFolderFragment>({
      id: `DocumentRequestFolder:${folder.id}`,
      fragmentName: 'DocFolder',
      fragment: DocFolderFragmentDoc,
    });

    let order = cachedFolder!.order;

    if (cachedFolder!.parentFolderId !== folder.parentFolderId) {
      order = newOrderValue;
    }

    client.writeFragment<DocFolderFragment>({
      id: `DocumentRequestFolder:${folder.id}`,
      fragmentName: 'DocFolder',
      fragment: DocFolderFragmentDoc,
      data: {
        ...cachedFolder!,
        name: folder.name,
        parentFolderId: folder.parentFolderId,
        usersWithAccess: (newUsersWithAccess ?? folder.usersWithAccess) as string[],
        order,
      },
    });

    showGlobalAlert('Folder updated');
  }

  async function updateDocumentRequest(request: UpdateDocumentRequestMutationVariables, newOrderValue: number) {
    if (withServerSync) {
      await updateRequest({ variables: request, refetchQueries: ['getDealActivities'] });
    }

    const cachedRequest = client.readFragment<DocRequestFragment>({
      id: `DocumentRequest:${request.id}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
    });

    let order = cachedRequest!.order;

    if (cachedRequest!.parentFolderId !== request.parentFolderId) {
      order = newOrderValue;
    }

    client.writeFragment({
      id: `DocumentRequest:${request.id}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
      data: {
        ...cachedRequest,
        name: request.name,
        parentFolderId: request.parentFolderId,
        instructions: request.instructions,
        order,
      },
    });

    showGlobalAlert('Document updated');
  }

  async function setDocumentAutoUpdates(
    request: UpdateDocumentRequestMutationVariables,
    autoUpdateSourceCache: { _id: string; name: string } | { _id: string } | null,
    alertText: string,
    onClose?: () => void,
  ) {
    if (withServerSync) {
      await updateRequest({ variables: request });
    }

    const cachedRequest = client.readFragment<DocRequestFragment>({
      id: `DocumentRequest:${request.id}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
    });

    onClose?.();

    client.writeFragment({
      id: `DocumentRequest:${request.id}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
      data: {
        ...cachedRequest,
        name: request.name,
        parentFolderId: request.parentFolderId,
        instructions: request.instructions,
        autoUpdateSource: autoUpdateSourceCache,
        autoUpdateSourceType: request.autoUpdateSourceType,
      },
    });

    showGlobalAlert(alertText);
  }

  function recalculateTreeLevelOrder(updatedTreeItem: DocFolderFragment | DocRequestFragment) {
    const docRequestFolders = getCacheDocRequestFolders();

    const allItems = [...(docRequestFolders?.folders ?? []), ...(docRequestFolders?.requests ?? [])];

    const treeLevelItems = allItems
      .filter((item) => item.parentFolderId === updatedTreeItem.parentFolderId && item._id !== updatedTreeItem._id)
      .sort((a, b) => a.order - b.order);

    treeLevelItems.splice(updatedTreeItem.order, 0, updatedTreeItem);

    treeLevelItems.forEach((item, index) => {
      if (item.__typename === 'DocumentRequest') {
        client.writeFragment({
          id: `DocumentRequest:${item._id}`,
          fragmentName: 'DocRequest',
          fragment: DocRequestFragmentDoc,
          data: {
            ...item,
            order: index,
          },
        });
      }
      if (item.__typename === 'DocumentRequestFolder') {
        client.writeFragment({
          id: `DocumentRequestFolder:${item._id}`,
          fragmentName: 'DocFolder',
          fragment: DocFolderFragmentDoc,
          data: {
            ...item,
            order: index,
          },
        });
      }
    });
  }

  async function deleteTreeItem(item: { id: string; isFolder: boolean; isRequest: boolean }) {
    const docRequestFolders = getCacheDocRequestFolders();

    let newCacheFolders = docRequestFolders?.folders.filter((folder) => folder?._id !== item.id && folder?.parentFolderId !== item.id);
    let newCacheRequests = docRequestFolders?.requests.filter((request) => request?._id !== item.id && request?.parentFolderId !== item.id);

    if (item.isFolder && withServerSync) {
      await deleteFolder({
        variables: {
          id: item.id,
        },
        refetchQueries: ['getDealActivities', 'getSingleDeal'],
      });
      showGlobalAlert('Folder deleted');
    }

    const findFoldersToDelete = (folderId: string) => {
      // We are looking for all subfolders that we need to delete
      const subFolders = docRequestFolders?.folders.filter((folder) => String(folder.parentFolderId) === String(folderId));

      if (!subFolders?.length) {
        return [String(folderId)];
      }

      const foldersToDelete: string[] = subFolders
        .map((subFolder) => {
          return findFoldersToDelete(subFolder._id);
        })
        .flat();

      return [String(folderId), ...foldersToDelete];
    };

    const foldersToDelete = findFoldersToDelete(item.id);

    // We have to make sure any subsequent item's order is deducted
    const folders = JSON.parse(JSON.stringify(docRequestFolders?.folders ?? [])) as DocRequestFoldersFragment['folders'];
    const requests = JSON.parse(JSON.stringify(docRequestFolders?.requests ?? [])) as DocRequestFoldersFragment['requests'];

    const items = [...folders, ...requests];
    const itemToDelete = items?.find((currentItem) => currentItem?._id === item.id);

    const siblingFolders = folders?.filter((folder) => folder.parentFolderId === itemToDelete?.parentFolderId);
    const subsequentFolders = siblingFolders?.filter((folder) => folder.order > itemToDelete?.order!);

    subsequentFolders?.forEach((folder) => {
      folder.order--;
    });

    const siblingRequests = requests?.filter((request) => request.parentFolderId === itemToDelete?.parentFolderId);
    const subsequentRequests = siblingRequests?.filter((request) => request.order > itemToDelete?.order!);

    subsequentRequests?.forEach((request) => {
      request.order--;
    });

    newCacheFolders = folders.filter((folder) => !foldersToDelete.includes(folder?._id) && !foldersToDelete.includes(folder?.parentFolderId!));
    newCacheRequests = requests?.filter((request) => request?._id !== item.id && !foldersToDelete.includes(request?.parentFolderId));

    if (item.isRequest && withServerSync) {
      await deleteRequest({
        variables: {
          id: item.id,
        },
        refetchQueries: ['getDealActivities'],
      });
      showGlobalAlert('Document request deleted');
    }

    // Updating cache for the immediate result
    client.writeFragment({
      id: `DocumentRequestFolders:${dealId}`,
      fragmentName: 'DocRequestFolders',
      fragment: DocRequestFoldersFragmentDoc,
      data: {
        _id: dealId,
        requests: newCacheRequests,
        folders: newCacheFolders,
      },
    });
  }

  async function resetDocRequestStatus(requestId: string) {
    await resetDocStatus({ variables: { id: requestId }, refetchQueries: ['getDealActivities'] });

    const cachedRequest = client.readFragment<DocRequestFragment>({
      id: `DocumentRequest:${requestId}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
    });

    client.writeFragment({
      id: `DocumentRequest:${requestId}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
      data: {
        ...cachedRequest,
        status: 'review',
      },
    });
  }

  function getCacheDocRequestFolders(): DocRequestFoldersFragment | null {
    return client.readFragment<DocRequestFoldersFragment>({
      id: `DocumentRequestFolders:${dealId}`,
      fragmentName: 'DocRequestFolders',
      fragment: DocRequestFoldersFragmentDoc,
    });
  }

  function refreshCacheFolders() {
    const docRequestFolders = getCacheDocRequestFolders();

    // Updating cache for the immediate result
    client.writeFragment({
      id: `DocumentRequestFolders:${dealId}`,
      fragmentName: 'DocRequestFolders',
      fragment: DocRequestFoldersFragmentDoc,
      data: {
        _id: dealId,
        requests: docRequestFolders!.requests,
        folders: docRequestFolders!.folders,
      },
    });
  }

  // TODO: Make all names consistent
  return {
    addNewFolderToCache,
    addNewRequestToCache,
    createFolder: { createFolder, creatingNewDocumentFolder },
    createRequest: { createRequest, creatingNewRequest },
    deleteTreeItem: { deleteTreeItem, deletingTreeItem: deletingDocumentRequest || deletingDocumentFolder },
    getCacheDocRequestFolders,
    recalculateTreeLevelOrder,
    refreshCacheFolders,
    resetDocRequestStatus,
    setDocumentAutoUpdates: { settingDocumentAutoUpdates: updatingDocumentRequest, setDocumentAutoUpdates },
    updateDocumentFolder: { updateDocumentFolder, updatingDocumentFolder },
    updateDocumentRequest: { updateDocumentRequest, updatingDocumentRequest },
    updateTree,
  };
};

export default useDocumentFoldersOps;
