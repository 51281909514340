import React, { FC } from 'react';

import { Card } from '../components/card';
import { Modal } from '../components/ui';
import { NewDealForm } from '../containers';
import { GetTemplatesQuery } from '~/generated/graphql';

type NewDealModalProps = {
  isOpen: boolean;
  onClose: () => void;
  preflight?: boolean;
  template?: GetTemplatesQuery['getTemplates'][0];
};

const NewDealModal: FC<NewDealModalProps> = ({ preflight, isOpen, onClose, template, ...props }) => (
  <Modal layout="horizontal" isOpen={isOpen} onClose={onClose} {...props}>
    <Card id="dealModalScrollable" isModalContent>
      <NewDealForm template={template} onClose={onClose} preflight={preflight} />
    </Card>
  </Modal>
);

export default NewDealModal;
