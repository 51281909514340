export const depositRequiredOptions: { value: boolean; label: string }[] = [
  {
    value: false,
    label: 'Not Required',
  },
  {
    value: true,
    label: 'Required',
  },
];
