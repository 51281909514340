import React, { Fragment, useEffect, useRef, useState } from 'react';
import { PatternFormat, NumberFormatValues } from 'react-number-format';
import { Card, CardBody } from '~/components/card';
import { Button, Input, FormGroup, Label } from '~/components/form';
import { FlexRow } from '~/components/layout';
import { Heading, Text } from '~/components/type';
import { Avatar, Close, Modal, Spinner } from '~/components/ui';
import { Select } from '~/components/vendor';
import {
  LenderDissolutionEventType,
  GetLenderDocument,
  LendersElasticSearchDocument,
  useCreateLenderDissolutionEventMutation,
  useUpdateLenderDissolutionEventMutation,
  useDeleteLenderDissolutionEventMutation,
  GetLenderQuery,
  GetSearchDocument,
  LendersElasticSearchQuery,
  GetSearchQuery,
  useLendersSimpleSearchLazyQuery,
  LendersSimpleSearchQuery,
} from '~/generated/graphql';
import isNotNil from '~/helpers/isNotNil';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { searchLendersVars } from '~/lib/apolloClient';

import { useApolloClient } from '@apollo/client';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import useAsyncDebounceCallback from '~/hooks/ useAsyncDebounceCallback';

const DISSOLUTION_EVENT_OPTIONS = [
  { value: LenderDissolutionEventType.Acquired, label: 'Acquired' },
  { value: LenderDissolutionEventType.Bankrupt, label: 'Bankrupt' },
];

const DissolutionModal: React.FC<{
  lender: GetLenderQuery['getLender'];
  isOpen: boolean;
  onClose: () => void;
  onLenderModalClose: () => void;
}> = ({ isOpen, onClose, onLenderModalClose, lender, ...props }) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();

  const [autocompleteData, setAutocompleteData] = useState<{ value: LendersSimpleSearchQuery['lendersSimpleSearch'][number]; label: string }[]>([]);
  const [dissolutionType, setDissolutionType] = useState<null | LenderDissolutionEventType>(null);
  const [dissolutionDate, setDissolutionDate] = useState<null | string>(null);
  const dateRef = useRef(null);
  const [dissolutionAcquiredBy, setDissolutionAcquiredBy] = useState<null | { id: string; name: string }>(null);
  const [createLenderDissolutionEvent, { loading: creating }] = useCreateLenderDissolutionEventMutation();
  const [updateLenderDissolutionEvent, { loading: updating }] = useUpdateLenderDissolutionEventMutation();
  const [deleteLenderDissolutionEvent, { loading: deleting }] = useDeleteLenderDissolutionEventMutation();

  const [lendersSimpleSearch] = useLendersSimpleSearchLazyQuery();

  useEffect(() => {
    async function updateAcquiredOptions(query: string) {
      const { data } = await lendersSimpleSearch({ variables: { query } });

      const newOptions = (data?.lendersSimpleSearch ?? []).map((res) => ({ value: res, label: res.name }));
      setAutocompleteData(newOptions);
    }

    if (lender?.dissolution_events?.length! > 0) {
      const activeEvent = lender?.dissolution_events?.[0]!;
      setDissolutionType(activeEvent.type);
      setDissolutionDate(activeEvent.date!);

      if (activeEvent.acquired_by_lender) {
        updateAcquiredOptions(activeEvent.acquired_by_lender?.name);
        setDissolutionAcquiredBy(activeEvent.acquired_by_lender);
      }
    }
  }, [isOpen]);

  async function onFormSubmit() {
    if (!dissolutionType) {
      return;
    }

    const input = {
      id: lender?.dissolution_events?.[0]?.id!,
      lender_id: lender!.id,
      acquired_by: dissolutionAcquiredBy?.id,
      date: dissolutionDate,
      type: dissolutionType,
    };

    let newDissolutionEvent;
    if (lender?.dissolution_events?.[0]?.id) {
      const { data } = await updateLenderDissolutionEvent({
        variables: {
          dissolutionEvent: input,
        },
      });
      newDissolutionEvent = data!.updateLenderDissolutionEvent;
    } else {
      const { data } = await createLenderDissolutionEvent({
        variables: {
          dissolutionEvent: input,
        },
      });
      newDissolutionEvent = data!.createLenderDissolutionEvent;
    }

    if (newDissolutionEvent) {
      const queryData = client.readQuery<GetLenderQuery>({
        query: GetLenderDocument,
        variables: { id: lender!.id },
      });

      if (queryData?.getLender) {
        client.writeQuery<GetLenderQuery>({
          query: GetLenderDocument,
          data: {
            getLender: {
              ...queryData?.getLender,
              dissolution_events: [newDissolutionEvent],
            },
          },
          variables: { id: lender!.id },
        });
      }

      const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
        query: LendersElasticSearchDocument,
        variables: searchLendersVars(),
      });

      if (lendersQuery) {
        const updatedLenders = lendersQuery?.lendersElasticSearch.lenders.filter((l) => l.id !== lender!.id);
        client.writeQuery<LendersElasticSearchQuery>({
          query: LendersElasticSearchDocument,
          variables: searchLendersVars(),
          data: {
            lendersElasticSearch: {
              ...lendersQuery?.lendersElasticSearch,
              lenders: updatedLenders,
              count: updatedLenders?.length,
            },
          },
        });
      }

      const getSearchQuery = client.readQuery<GetSearchQuery>({
        query: GetSearchDocument,
      });

      if (getSearchQuery) {
        client.writeQuery<GetSearchQuery>({
          query: GetSearchDocument,
          data: {
            ...getSearchQuery.getSearch,
            getSearch: getSearchQuery.getSearch.filter((s) => (s as { id: string }).id !== lender!.id),
          },
        });
      }

      showGlobalAlert(lender?.dissolution_events?.length! > 0 ? 'Dissolution Event has been updated' : 'Dissolution Event has been added');

      onClose();
      onLenderModalClose();
    }
  }

  async function onDelete() {
    await deleteLenderDissolutionEvent({
      variables: {
        eventId: lender?.dissolution_events?.[0]?.id!,
      },
      update: (cache) => {
        const queryData = cache.readQuery<GetLenderQuery>({
          query: GetLenderDocument,
          variables: { id: lender!.id },
        });

        if (queryData?.getLender) {
          cache.writeQuery<GetLenderQuery>({
            query: GetLenderDocument,
            data: {
              getLender: {
                ...queryData?.getLender,
                dissolution_events: [],
              },
            },
            variables: { id: lender!.id },
          });
        }
      },
    });

    showGlobalAlert('Dissolution Event removed');

    setAutocompleteData([]);
    setDissolutionType(null);
    setDissolutionDate(null);
    setDissolutionAcquiredBy(null);

    onClose();
    onLenderModalClose();
  }

  const loadLenderOptions = useAsyncDebounceCallback(
    async (query: string) => {
      const { data } = await lendersSimpleSearch({ variables: { query } });

      const newOptions = (data?.lendersSimpleSearch ?? []).map((res) => ({ value: res, label: res.name }));
      setAutocompleteData(newOptions);
      return newOptions;
    },
    300,
    [],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="check" isModalContent>
        <CardBody as="form">
          <Close onClick={onClose} desktop={{ display: 'none' }} isAbsolute />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>
            {lender?.dissolution_events?.length! > 0 ? 'Edit' : 'Add'} Dissolution Event
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>
            Dissolution events should be reported when a company is acquired or goes bankrupt.
          </Text>
          <FormGroup isFloating>
            <Label>Dissolution Event</Label>
            <Select
              placeholder="Select event"
              options={DISSOLUTION_EVENT_OPTIONS}
              onChange={(e) => {
                if (dissolutionType !== e!.value) {
                  setDissolutionType(e!.value);

                  const activeEvent = lender?.dissolution_events?.[0];

                  if (activeEvent && activeEvent?.type === e!.value) {
                    setDissolutionDate(activeEvent.date!);

                    if (activeEvent.acquired_by_lender) {
                      setDissolutionAcquiredBy(activeEvent.acquired_by_lender);
                    }
                  } else {
                    setDissolutionAcquiredBy(null);
                    setDissolutionDate(null);
                  }
                }
              }}
              value={DISSOLUTION_EVENT_OPTIONS.find((option) => option.value === dissolutionType)}
            />
          </FormGroup>

          {dissolutionType && (
            <FormGroup isFloating>
              <Label>{dissolutionType === 'acquired' ? 'Acquisition' : 'Bankruptcy'} Date (Optional)</Label>
              <Input
                key={dissolutionType}
                as={PatternFormat}
                format="##/##"
                placeholder="MM/YY"
                onValueChange={(e: NumberFormatValues) => setDissolutionDate(isNotNil(e.value) ? e.value : '')}
                getInputRef={dateRef}
                value={dissolutionDate}
              />
            </FormGroup>
          )}
          {dissolutionType === 'acquired' && (
            <FormGroup isFloating>
              <Label>Acquired By (Optional)</Label>
              <Select
                placeholder="Search for Lender"
                async
                loadOptions={loadLenderOptions}
                onOptionRender={({ value: lender }) => {
                  return (
                    <Fragment key={lender.id}>
                      <Avatar
                        src={getLogoUrl(lender)}
                        fallbackSrc={lender?.zoominfo_logo}
                        alt={lender.name}
                        imageProps={{ utils: { border: 1 } }}
                        size="sm"
                        utils={{ borderRadius: 'sm', mr: 5 }}
                      />{' '}
                      {lender.name}
                    </Fragment>
                  );
                }}
                onChange={(option) => setDissolutionAcquiredBy(option!.value)}
                defaultInputValue={dissolutionAcquiredBy?.name}
                defaultOptions={autocompleteData}
              />
            </FormGroup>
          )}
          <FlexRow>
            <Button
              type="button"
              variant="primary"
              isBlock
              onClick={dissolutionType ? onFormSubmit : onClose}
              isLoading={creating || updating}
              disabled={creating || updating}
            >
              Save
            </Button>
          </FlexRow>
          {lender?.dissolution_events?.length! > 0 && (
            <Text
              role="button"
              utils={{ position: 'relative', display: 'flex', mt: 7, fontSize: 'sm', alignItems: 'center', justifyContent: 'center', color: 'gray700' }}
              onClick={onDelete}
            >
              {deleting && (
                <>
                  <Spinner size="xs" utils={{ mr: 4 }} style={{ position: 'relative', transform: 'none', left: 0, top: 0 }} />
                </>
              )}
              {deleting ? 'Removing' : 'Remove'} Dissolution Event
            </Text>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DissolutionModal;
