import { SortDirection } from '~/generated/graphql';
import { DealFilterFields, LenderFilterFields, QuoteFilterFields } from './filters';

export const DealsSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  owner: { columnName: DealFilterFields.owner, direction: SortDirection.Asc },
  loanAmount: { columnName: DealFilterFields.loanAmount, direction: SortDirection.Desc, isAdmin: true },
  fee: { columnName: DealFilterFields.totalFee, direction: SortDirection.Desc, isAdmin: true },
  status: { columnName: DealFilterFields.status, direction: SortDirection.Desc, isAdmin: true },
  updatedAt: { columnName: DealFilterFields.updatedAt, direction: SortDirection.Asc },
  createdAt: { columnName: DealFilterFields.createdAt, direction: SortDirection.Asc },
};

export const TemplatesSortFields = {
  dealName: { columnName: 'Name', direction: SortDirection.Asc },
  creator: { columnName: 'Owner', direction: SortDirection.Asc },
  created: { columnName: 'Created', direction: SortDirection.Asc },
  lastUpdate: { columnName: 'Last Edited', direction: SortDirection.Asc },
};

export const LenderSortFields = {
  name: { columnName: 'Lender Name', direction: SortDirection.Asc },
  latestActivity: { columnName: 'Latest Activity', direction: SortDirection.Asc },
  status: { columnName: 'Status', direction: SortDirection.Asc },
  invited: { columnName: 'Invited', direction: SortDirection.Desc },
};

export const MembersAdminSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  company: { columnName: 'Company', direction: SortDirection.Asc },
  status: { columnName: 'Status', direction: SortDirection.Asc },
  role: { columnName: 'Role', direction: SortDirection.Asc },
};

export const MembersSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  status: { columnName: 'Status', direction: SortDirection.Asc },
  role: { columnName: 'Role', direction: SortDirection.Asc },
};

export const LendersSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  responsiveness: { columnName: LenderFilterFields.responsiveness, direction: SortDirection.Desc },
  interestFrequency: { columnName: LenderFilterFields.interestFrequency, direction: SortDirection.Desc },
  updatedAt: { columnName: LenderFilterFields.updatedAt, direction: SortDirection.Asc },
  createdAt: { columnName: LenderFilterFields.createdAt, direction: SortDirection.Asc },
};

export const QuotesSortFields = {
  type: { columnName: QuoteFilterFields.types, direction: SortDirection.Asc },
  lender: { columnName: 'Lender', direction: SortDirection.Asc },
  loanValue: { columnName: QuoteFilterFields.loanValue, direction: SortDirection.Desc },
  ltv: { columnName: QuoteFilterFields.ltv, direction: SortDirection.Desc },
  ltc: { columnName: QuoteFilterFields.ltc, direction: SortDirection.Desc },
  dscr: { columnName: QuoteFilterFields.dscr, direction: SortDirection.Desc },
  debtYield: { columnName: QuoteFilterFields.debtYield, direction: SortDirection.Desc },
  updatedAt: { columnName: QuoteFilterFields.updatedAt, direction: SortDirection.Asc },
  createdAt: { columnName: QuoteFilterFields.createdAt, direction: SortDirection.Asc },
};

export const BorrowersSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  latestActivity: { columnName: 'Latest Activity', direction: SortDirection.Asc },
  amountFinanced: { columnName: 'Total Deals', direction: SortDirection.Asc },
  status: { columnName: 'Status', direction: SortDirection.Asc },
};

export const FindLendersSortFields = {
  name: { columnName: 'Name', direction: SortDirection.Asc },
  score: { columnName: 'Match Score', direction: SortDirection.Asc },
  responsiveness: { columnName: LenderFilterFields.responsiveness, direction: SortDirection.Desc },
  interestFrequency: { columnName: LenderFilterFields.interestFrequency, direction: SortDirection.Desc },
  dealSimilarityScore: { columnName: 'Deal Similarity Score', direction: SortDirection.Asc },
  loanSimilarityScore: { columnName: 'Loan Similarity Score', direction: SortDirection.Asc },
  distanceToDeal: { columnName: 'Distance to Deal', direction: SortDirection.Asc },
  updatedAt: { columnName: LenderFilterFields.updatedAt, direction: SortDirection.Asc },
  createdAt: { columnName: LenderFilterFields.createdAt, direction: SortDirection.Asc },
};
