import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { GetSearchDocument, GetSearchQuery, LendersElasticSearchDocument, LendersElasticSearchQuery, useDeleteLenderMutation } from '~/generated/graphql';
import { searchLendersVars } from '~/lib/apolloClient';
import { Close, Modal } from '~/components/ui';
import { Card, CardBody } from '~/components/card';
import { Heading, Text } from '~/components/type';
import { Button, FormGroup, Input, Label } from '~/components/form';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type DeleteLenderModalProps = {
  isOpen: boolean;
  lenderId?: string;
  onClose: () => void;
  onDelete: () => void;
};

const DeleteLenderModal: React.FC<DeleteLenderModalProps> = ({ isOpen, onClose, onDelete, lenderId }) => {
  const [reason, setReason] = useState('');

  useEffect(() => {
    setReason('');
  }, [isOpen]);

  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [deleteLender, { loading: deletingLender }] = useDeleteLenderMutation();

  const handleFormSubmit = async () => {
    await deleteLender({
      variables: {
        lenderId: lenderId!,
        reason,
      },
    });

    const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
      query: LendersElasticSearchDocument,
      variables: searchLendersVars(),
    });
    if (lendersQuery) {
      const updatedLenders = lendersQuery?.lendersElasticSearch.lenders.filter((l) => l.id !== lenderId);
      client.writeQuery<LendersElasticSearchQuery>({
        query: LendersElasticSearchDocument,
        variables: searchLendersVars(),
        data: {
          lendersElasticSearch: {
            ...lendersQuery?.lendersElasticSearch,
            lenders: updatedLenders!,
            count: lendersQuery?.lendersElasticSearch.count! - 1,
          },
        },
      });
    }

    const getSearchQuery = client.readQuery<GetSearchQuery>({
      query: GetSearchDocument,
    });

    if (getSearchQuery) {
      client.writeQuery<GetSearchQuery>({
        query: GetSearchDocument,
        data: {
          ...getSearchQuery.getSearch,
          getSearch: getSearchQuery.getSearch.filter((s) => (s as { id: string }).id !== lenderId),
        },
      });
    }

    showGlobalAlert('Lender has been deleted');

    onDelete();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card icon="check" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>
            Are you sure?
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>
            This will remove this lender, contacts, and programs from matching and all lender lists.
          </Text>
          <FormGroup isFloating>
            <Label htmlFor="reason">Reason for deleting (Optional)</Label>
            <Input
              as={Textarea as React.ElementType}
              id="reason"
              placeholder="Provide a reason for deleting..."
              minRows={5}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReason(e.target.value)}
            />
          </FormGroup>
          <Button isBlock isLoading={deletingLender} onClick={handleFormSubmit}>
            Delete Lender
          </Button>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DeleteLenderModal;
