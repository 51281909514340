import React from 'react';
import Base, { BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import PreviewModalDialog from './PreviewModalDialog';
import styled, { css } from 'styled-components';

const StyledModalLeft = styled(Base)`
  ${({ theme }) => css`
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    pointer-events: auto;

    @media (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `}
`;

type PreviewModalLeftInnerProps = {
  onClose: () => void;
};

type PreviewModalLeftProps = BaseHOCPropsWithoutRef<'div', PreviewModalLeftInnerProps>;

const PreviewModalLeft = ({ onClose, ...props }: PreviewModalLeftProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.target === e.currentTarget && onClose();
  };

  return (
    <StyledModalLeft onClick={handleClick} {...props}>
      <PreviewModalDialog>{props.children}</PreviewModalDialog>
    </StyledModalLeft>
  );
};

export default PreviewModalLeft;
