import React, { FC, useEffect, useState } from 'react';
import { Badge, Close, Modal, ModalContent, Spinner, Status } from '~/components/ui';
import { Block, Col, Container, Main, Row, Sticky } from '~/components/layout';
import { Button, Input, InputAddon, InputGroup } from '~/components/form';
import { formatDate } from '~/helpers';
import {
  CompanyFragment,
  CompanyFragmentDoc,
  GetLenderQuery,
  SearchZoominfoContactsQuery,
  SortDirection,
  useAddLenderZoominfoContactMutation,
  useSearchZoominfoContactsLazyQuery,
} from '~/generated/graphql';
import { Heading, Text } from '~/components/type';
import { Icon } from '~/components/vendor';
import { NotFound } from '~/components/not-found';
import { search } from '~/components/vendor/Icon/icons';
import { Table, TableCell, TableHeader, TableRow, TableWindow } from '~/components/table';
import { Topnav } from '~/components/nav';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

const filterContacts = (contacts?: SearchZoominfoContactsQuery['searchZoominfoContacts'], search?: string, sort?: [string, SortDirection] | null) => {
  if (!contacts) {
    return [];
  }

  if (search) {
    search = search.toLocaleLowerCase();
    contacts = contacts.filter(
      ({ firstName, lastName, jobTitle }) =>
        firstName?.toLowerCase().includes(search!) || lastName?.toLowerCase().includes(search!) || jobTitle?.toLowerCase().includes(search!),
    );
  }

  const [field, direction] = sort ?? ['name', SortDirection.Asc];
  const multiplier = direction === SortDirection.Asc ? 1 : -1;
  contacts = [...contacts].sort((a, b) => {
    if (field === 'contactAccuracyScore') {
      return (a.contactAccuracyScore - b.contactAccuracyScore) * multiplier;
    }
    if (field === 'lastUpdatedDate') {
      const aDateInvalid = isNaN(new Date(a.lastUpdatedDate).getTime());
      const bDateInvalid = isNaN(new Date(b.lastUpdatedDate).getTime());
      if (aDateInvalid) {
        return 1 * multiplier;
      }
      if (bDateInvalid) {
        return -1 * multiplier;
      }
      return (new Date(b.lastUpdatedDate).getTime() - new Date(a.lastUpdatedDate).getTime()) * multiplier;
    }
    return (a.firstName ?? a.lastName)!.localeCompare((b.firstName ?? b.lastName)!) * multiplier;
  });

  return contacts;
};

const getBadgeVariant = (score: number) => {
  return score >= 85 ? 'success' : score >= 75 ? 'warning' : 'danger';
};

type LenderFindContactsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  lender: NonNullable<GetLenderQuery['getLender']>;
  refetchQueries?: string[];
};

const LenderFindContactsModal: FC<LenderFindContactsModalProps> = ({ isOpen, onClose, lender, refetchQueries }) => {
  const [personId, setPersonId] = useState('');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState<[string, SortDirection] | null>(null);
  const [filteredContacts, setFilteredContacts] = useState<SearchZoominfoContactsQuery['searchZoominfoContacts']>([]);

  const [searchZoominfoContacts, { data, loading: searchLoading, updateQuery }] = useSearchZoominfoContactsLazyQuery({
    variables: { lenderId: lender.id },
    fetchPolicy: 'network-only',
  });
  const [addLenderZoominfoContact] = useAddLenderZoominfoContactMutation();
  const showGlobalAlert = useShowGlobalAlert();

  useEffect(() => {
    if (isOpen) {
      searchZoominfoContacts();
    } else {
      setFilteredContacts([]);
      setSearchString('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setFilteredContacts(filterContacts(data?.searchZoominfoContacts, searchString, sort));
    }
  }, [isOpen, searchString, sort, data]);

  const addLenderContact = async (personId: string) => {
    setPersonId(personId);

    await addLenderZoominfoContact({
      variables: {
        lenderId: lender.id,
        personId,
      },
      update: (cache, { data: addLenderZoominfoContactData }) => {
        updateQuery((searchZoominfoContactsData) => ({
          ...searchZoominfoContactsData,
          searchZoominfoContacts: searchZoominfoContactsData.searchZoominfoContacts.filter(({ id }) => id !== personId),
        }));

        const lenderData = cache.readFragment<CompanyFragment>({
          id: `Company:${lender.id}`,
          fragmentName: 'Company',
          fragment: CompanyFragmentDoc,
          variables: { skipPrograms: !lender.programs },
        });
        if (lenderData) {
          cache.writeFragment<CompanyFragment>({
            id: `Company:${lender.id}`,
            fragmentName: 'Company',
            fragment: CompanyFragmentDoc,
            data: {
              ...lenderData,
              contacts: [...lenderData.contacts, addLenderZoominfoContactData!.addLenderZoominfoContact],
            },
            variables: { skipPrograms: !lender.programs },
          });
        }
      },
      refetchQueries: refetchQueries ? [...refetchQueries, 'getLenderActivities'] : ['getLenderActivities'],
    });

    showGlobalAlert('Contact has been added', 'success');

    setPersonId('');
  };

  const setSortColumn = (field: string) => () => {
    if (sort?.[0] === field) {
      if (sort[1] === SortDirection.Asc) {
        setSort([field, SortDirection.Desc]);
      } else {
        setSort(null);
      }
    } else {
      setSort([field, SortDirection.Asc]);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} layout="fullscreen">
      <ModalContent id="lenderFindContactsModal" utils={{ overflowY: 'auto' }}>
        <div style={{ minHeight: '100%' }}>
          <Sticky rootId="lenderFindContactsModal" utils={{ zindex: 'header' }} style={{ top: 0 }}>
            {(isStuck) => (
              <Topnav isStuck={isStuck}>
                <Text utils={{ fontWeight: 'bold', mr: 'auto' }}>Find Contacts</Text>
                <Close onClick={onClose} />
              </Topnav>
            )}
          </Sticky>
          <Main>
            {searchLoading ? (
              <Spinner size="sm" variant="primary">
                <Text utils={{ color: 'black', fontSize: 'base' }}>Finding contacts...</Text>
              </Spinner>
            ) : (
              <Container utils={{ width: '100%' }}>
                <Row gutter={4} utils={{ alignItems: 'center', mb: 8 }}>
                  <Col>
                    <Heading utils={{ fontSize: '5xl' }}>Find Contacts</Heading>
                    <Text utils={{ color: 'gray700' }}>Discover contacts for {lender.name}.</Text>
                  </Col>
                  <Col span="100%" desktop={{ span: 'auto' }}>
                    <InputGroup size="sm" utils={{ minWidth: '200px', mt: 5, borderRadius: 'rounded' }} desktop={{ mt: 0 }}>
                      <InputAddon>
                        <Icon icon={search} utils={{ minWidth: '16px' }} />
                      </InputAddon>
                      <Input
                        desktop={{ width: '250px' }}
                        type="search"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchString(e.target.value);
                        }}
                        value={searchString}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {!data?.searchZoominfoContacts.length ? (
                      <NotFound image="desktop" title="No contacts found." description={`No additional contacts for ${lender.name}.`} />
                    ) : searchString && !filteredContacts.length ? (
                      <NotFound
                        image="gallery"
                        title="No matching contacts."
                        description={
                          <>
                            Modify or{' '}
                            <Text as="span" utils={{ color: 'primary' }} onClick={() => setSearchString('')} role="button">
                              clear your search
                            </Text>
                            .
                          </>
                        }
                      />
                    ) : (
                      <Table size="lg" align="center" gutterY={0}>
                        <Sticky rootId="lenderFindContactsModal" style={{ top: 60 }}>
                          {(isStuck) => (
                            <TableRow mobile={{ display: 'none' }} isStuck={isStuck}>
                              <TableHeader
                                role="button"
                                onClick={setSortColumn('name')}
                                isSortable
                                isAsc={sort?.[0] === 'name' && sort?.[1] === SortDirection.Asc}
                                isDesc={sort?.[0] === 'name' && sort?.[1] === SortDirection.Desc}
                              >
                                Name
                              </TableHeader>
                              <TableHeader
                                span="15%"
                                mobile={{ display: 'none' }}
                                role="button"
                                onClick={setSortColumn('contactAccuracyScore')}
                                isSortable
                                isAsc={sort?.[0] === 'contactAccuracyScore' && sort?.[1] === SortDirection.Asc}
                                isDesc={sort?.[0] === 'contactAccuracyScore' && sort?.[1] === SortDirection.Desc}
                              >
                                Accuracy
                              </TableHeader>
                              <TableHeader span="10%" mobile={{ display: 'none' }}>
                                Mobile
                              </TableHeader>
                              <TableHeader span="10%" mobile={{ display: 'none' }}>
                                Direct
                              </TableHeader>
                              <TableHeader
                                span="10%"
                                mobile={{ display: 'none' }}
                                role="button"
                                onClick={setSortColumn('lastUpdatedDate')}
                                isSortable
                                isAsc={sort?.[0] === 'lastUpdatedDate' && sort?.[1] === SortDirection.Asc}
                                isDesc={sort?.[0] === 'lastUpdatedDate' && sort?.[1] === SortDirection.Desc}
                              >
                                Updated
                              </TableHeader>
                              <TableHeader span="auto" desktop={{ span: '5%' }} />
                            </TableRow>
                          )}
                        </Sticky>

                        <TableWindow
                          items={filteredContacts}
                          totalItems={filteredContacts.length}
                          itemHeight={90}
                          rootId="lenderFindContactsModal"
                          renderItem={(contact, style) => (
                            <TableRow key={contact!.id} style={style}>
                              <TableCell>
                                <Block style={{ minWidth: 0 }}>
                                  <Heading as="h4" utils={{ fontSize: 'base', color: 'black', textTruncate: true }}>
                                    {contact!.firstName} {contact!.lastName}
                                  </Heading>
                                  {contact!.jobTitle && (
                                    <Text utils={{ color: 'gray700', textTruncate: true }}>
                                      {contact!.jobTitle}
                                      {' at '}
                                      {lender.name}
                                    </Text>
                                  )}
                                </Block>
                              </TableCell>
                              <TableCell span="15%" mobile={{ display: 'none' }}>
                                <Badge utils={{ bgColor: getBadgeVariant(contact!.contactAccuracyScore) }}>{contact!.contactAccuracyScore}%</Badge>
                              </TableCell>
                              <TableCell span="10%" mobile={{ display: 'none' }}>
                                <Status icon={contact!.hasMobilePhone ? 'check' : 'minus'} variant={contact!.hasMobilePhone ? 'success' : 'gray500'} />
                              </TableCell>
                              <TableCell span="10%" mobile={{ display: 'none' }}>
                                <Status icon={contact!.hasDirectPhone ? 'check' : 'minus'} variant={contact!.hasDirectPhone ? 'success' : 'gray500'} />
                              </TableCell>
                              <TableCell span="10%" mobile={{ display: 'none' }}>
                                <Text utils={{ color: 'gray700' }}>{contact!.lastUpdatedDate ? formatDate(contact!.lastUpdatedDate) : '--'}</Text>
                              </TableCell>
                              <TableCell span="auto" desktop={{ span: '5%' }} utils={{ justifyContent: 'flex-end' }}>
                                <Button
                                  size="sm"
                                  variant="white"
                                  onClick={() => addLenderContact(contact!.id)}
                                  isLoading={personId === contact!.id}
                                  disabled={Boolean(personId)}
                                >
                                  Add
                                </Button>
                              </TableCell>
                            </TableRow>
                          )}
                        />
                      </Table>
                    )}
                  </Col>
                </Row>
              </Container>
            )}
          </Main>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default LenderFindContactsModal;
