import React, { FC, useEffect } from 'react';
import { Block, Columns, Sticky } from '../components/layout';
import { Button } from '../components/form';
import { Icon } from '../components/vendor';
import { getBaseApiUrl } from '../helpers/getBaseUrl';
import { Modal, ModalContent } from '../components/ui';
import { Text } from '../components/type';
import { Topnav } from '../components/nav';
import { DealFieldsFragment, DealPreviewFieldsFragment } from '~/generated/graphql';
import { x } from '~/components/vendor/Icon/icons';
import dynamic from 'next/dynamic';

const ImageZoom = dynamic(() => import('~/components/ui/ImageZoom'), { ssr: false });

type GalleryModalProps = {
  deal: DealFieldsFragment | DealPreviewFieldsFragment;
  imageId: number;
  onClose: () => void;
  isOpen: boolean;
};

const GalleryModal: FC<GalleryModalProps> = ({ deal, imageId, onClose, isOpen, ...props }) => {
  useEffect(() => {
    if (imageId) {
      const currentImage = document.getElementById(`galleryImage${imageId}`);
      currentImage && currentImage.scrollIntoView({ behavior: 'smooth' });
    }
  }, [imageId]);

  const images = [deal.project!.coverageImageFileId, ...(deal.project?.additionalImages || [])].map((id, index) => {
    return (
      <Block utils={{ mb: 4 }} key={index}>
        <ImageZoom key={id} id={`galleryImage${index}`} src={`${getBaseApiUrl()}/api/images/${id}?width=1500`} style={{ width: '100%', height: 'auto' }} />
      </Block>
    );
  });

  return (
    <Modal onClose={onClose} isOpen={isOpen} layout="fullscreen" {...props}>
      <ModalContent style={{ height: 'auto', minHeight: '100%' }}>
        <Sticky style={{ top: 0 }}>
          {() => (
            <Topnav>
              <Text utils={{ fontWeight: 'bold', mr: 'auto' }}>
                <Block as="span" mobile={{ display: 'none' }}>
                  {deal.name}{' '}
                </Block>
                Photos
              </Text>
              <Icon icon={x} utils={{ fontSize: 'xl', color: 'gray700' }} hover={{ color: 'black' }} desktop={{ display: 'none' }} onClick={onClose} />
              <Button variant="white" size="sm" mobile={{ display: 'none' }} onClick={onClose}>
                Close
              </Button>
            </Topnav>
          )}
        </Sticky>
        <Columns columns={1} columnGap={4} desktop={{ columns: 2 }}>
          {images}
        </Columns>
      </ModalContent>
    </Modal>
  );
};

export default GalleryModal;
