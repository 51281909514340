import React, { FC, useEffect, useState, useRef } from 'react';
import { Avatar, Close, Modal, Scrollable } from '~/components/ui';
import { FlexCol, FlexRow, Row, Col, Sticky } from '~/components/layout';
import { Button, ButtonGroup } from '~/components/form';
import {
  CapitalType,
  CompanyFragment,
  CoordinatesInput,
  DealFieldsFragment,
  DealLenderStatus,
  DealProjectAssetTypeInput,
  DealProjectLoanTypeInput,
  DealStatus,
  GetDealMembersQuery,
  GetSingleDealQuery,
  LenderProgramFragment,
  LightCompanyFragment,
  QuoteFragment,
  SortDirection,
  useAddDealLenderMutation,
  useCurrentUserQuery,
  useGetLenderLocationsSummaryQuery,
  useGetLenderQuery,
  useHideDealLenderMutation,
  useLenderDealsQuery,
  useRemoveDealLenderMutation,
} from '~/generated/graphql';
import { Card, CardBody, CardHeader } from '~/components/card';
import { Dropdown, DropdownMenu, DropdownItem } from '~/components/vendor';
import { externalLink, minusCircle, eyeOff, plus, filePlus01, arrowLeft, arrowRight } from '~/components/vendor/Icon/icons';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { Heading, Text, TextCollapsible, TextFiller } from '~/components/type';
import { Icon } from '~/components/vendor';
import { LenderTypeLabels } from '~/data/lenderTypeOptions';
import { Toggle } from '~/components/ui';
import { TopnavLink } from '~/components/nav';
import { useReactiveVar } from '@apollo/client';
import ConfirmModal from '~/modals/ConfirmModal';
import LenderActivities from '~/containers/Lender/LenderActivities';
import LenderStatus from '~/containers/Members/LenderStatus';
import Link from 'next/link';
import MatchModalContacts from './MatchModalContacts';
import MatchModalMatch from './MatchModalMatch';
import Responsiveness from '~/containers/Responsiveness';
import MatchModalQuotes from './MatchModalQuotes';
import { escHandlersStack, quoteEditModalVar, removeLenderFromFindListVar } from '~/lib/apolloClient';
import { ModalProps } from '~/components/ui/Modal/Modal';
import MatchModalSimilarDeals from './MatchModalSimilarDeals';
import getLenderLocationsSummaryString from '~/helpers/lender/getLenderLocationsSummaryString';
import LenderLocationsModal from '../LenderLocationsModal';
import DealLenderReason from '~/containers/Matching/DealLenderReason';
import useUpdateDealLenderStatus from '~/hooks/useUpdateDealLenderStatus';
import LenderLoansList from '~/containers/Lender/LenderLoansList';
import LenderLoans from '~/containers/Lender/LenderLoans';
import LenderDealsList from '~/containers/Lender/LenderDealsList';
import MatchModalSimilarLoans from './MatchModalSimilarLoans';
import LenderQuotesList from '~/containers/Lender/LenderQuotesList';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

export type MatchModalTabs = 'thisDeal' | 'match' | 'contacts' | 'quotes' | 'deals' | 'transactions' | 'about';

export type MatchModalLender = {
  lender?: LightCompanyFragment;
  lenderId?: string;
  program?: LenderProgramFragment;
  programId?: string;
  tab?: MatchModalTabs;
  blur?: boolean;
} | null;

export type MatchModalDeal = {
  _id?: string;
  project?: {
    coordinates?: CoordinatesInput | null;
    loanType?: DealProjectLoanTypeInput | null;
    assetType?: DealProjectAssetTypeInput | null;
  } | null;
  financials?:
    | (Partial<Record<CapitalType, number | null>> & {
        loanRequestAmount?: number | null;
        completedValue?: number | null;
        totalCost?: number | null;
      })
    | null;
  name?: string;
  status?: DealStatus;
  settings?: DealFieldsFragment['settings'];
  quotes?: QuoteFragment[];
} | null;

type MatchModalProps = ModalProps & {
  deal: MatchModalDeal | GetSingleDealQuery['getSingleDeal'];
  dealMembers?: GetDealMembersQuery['getDealMembers'];
  enableBlur?: boolean;
  handleInviteClick?: (contact: { email: string }) => void;
  isTestMatch?: boolean;
  showAddDealLender?: boolean;
  showHideDealLender?: boolean;
  showRemoveDealLender?: boolean;
  matchLender?: MatchModalLender;
  onPrevClick?: (() => void) | null;
  onNextClick?: (() => void) | null;
};

const MatchModal: FC<MatchModalProps> = ({
  deal,
  dealMembers,
  enableBlur = false,
  handleInviteClick,
  isOpen,
  isTestMatch = true,
  showAddDealLender,
  onClose,
  onExited,
  showHideDealLender,
  showRemoveDealLender,
  matchLender,
  onPrevClick,
  onNextClick,
  ...props
}) => {
  const showGlobalAlert = useShowGlobalAlert();

  const modalContentRef = useRef<HTMLDivElement>(null);
  const modalContentScrollTop = useRef<Record<MatchModalTabs, number> | Record<string, never>>({});

  const [addLoading, setAddLoading] = useState(false);
  const [hideLoading, setHideLoading] = useState(false);

  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);
  const [isNoteModifyingInProgress, setNoteModifyingInProgress] = useState(false);
  const [activeTab, setActiveTab] = useState<MatchModalTabs | null>(null);
  // const [aboutCount, setAboutCount] = useState<number | undefined>(undefined);
  const [lenderTransactionsCount, setLenderTransactionsCount] = useState(0);
  const [quotesCount, setQuotesCount] = useState(0);

  const invitedLender = dealMembers?.lenders.find(({ id }) => id === matchLender?.lenderId);
  const showMenu = !isTestMatch && showRemoveDealLender && invitedLender;
  const showButtons = !isTestMatch && !invitedLender && (showAddDealLender || showHideDealLender);

  const [openLenderLocationsModal, setOpenLenderLocationsModal] = useState(false);

  const { data } = useGetLenderQuery({
    variables: { id: matchLender?.lenderId!, skipPrograms: Boolean(matchLender?.program) },
    fetchPolicy: 'cache-first',
    skip: !matchLender?.lenderId,
  });
  const lender = matchLender?.lender ?? data?.getLender;
  const program =
    matchLender?.program ??
    (matchLender?.programId
      ? (lender as CompanyFragment)?.programs?.find(({ id }) => id === matchLender?.programId)
      : (lender as CompanyFragment)?.programs?.reduce(
          (acc, program) => {
            if (program.matchScore?.score && (!acc?.matchScore?.score || acc?.matchScore.score < program.matchScore.score)) {
              return program;
            }
            return acc;
          },
          (lender as CompanyFragment)?.programs?.[0],
        ));

  const { data: currentUserData } = useCurrentUserQuery({ fetchPolicy: 'cache-only' });
  const { data: lenderDealsData } = useLenderDealsQuery({
    variables: {
      lenderId: matchLender?.lenderId!,
      dealId: deal?._id,
      similarOnly: true,
      sort: {
        field: 'mostSimilar',
        direction: SortDirection.Asc,
      },
    },
    skip: !currentUserData?.currentUser || !matchLender?.lenderId,
  });
  const { data: lenderLocationsSummaryData } = useGetLenderLocationsSummaryQuery({
    variables: { lenderId: matchLender?.lenderId! },
    skip: !matchLender?.lenderId,
  });

  const lenderDeals = lenderDealsData?.lenderDeals;
  const lenderLocations = lenderLocationsSummaryData?.getLenderLocationsSummary;

  const quotes = deal?.quotes?.filter((quote) => quote.lenderId === matchLender?.lenderId);

  function onModalClose() {
    if (isNoteModifyingInProgress) {
      setConfirmCloseModelOpened(true);
    } else {
      modalContentScrollTop.current = {};
      setActiveTab(null);
      onClose?.();
    }
  }

  const [addDealLender] = useAddDealLenderMutation({ refetchQueries: ['getLenderActivities', 'getDealMembers'] });
  const [removeDealLender] = useRemoveDealLenderMutation({ refetchQueries: ['getLenderActivities', 'getDealMembers'] });
  const [hideDealLender] = useHideDealLenderMutation();
  const removeLenderFromList = useReactiveVar(removeLenderFromFindListVar);

  function handleAdd() {
    setAddLoading(true);
    setTimeout(async () => {
      await addDealLender({ variables: { dealId: deal!._id!, lenderId: matchLender?.lenderId! } });
      removeLenderFromList?.(matchLender?.lenderId!);
      showGlobalAlert(`${lender!.name} added to Lenders`);
      if (onNextClick) {
        onNextClick();
        setAddLoading(false);
      } else {
        onModalClose();
      }
    }, 200);
  }

  function handleHide() {
    setHideLoading(true);
    setTimeout(async () => {
      await hideDealLender({ variables: { dealId: deal!._id!, lenderId: matchLender?.lenderId! } });
      removeLenderFromList?.(matchLender?.lenderId!);
      showGlobalAlert(`${lender!.name} hidden`);
      if (onNextClick) {
        onNextClick();
        setHideLoading(false);
      } else {
        onModalClose();
      }
    }, 200);
  }

  async function handleRemove() {
    await removeDealLender({ variables: { dealId: deal!._id!, lenderId: matchLender?.lenderId! } });
    showGlobalAlert(`${lender!.name} removed from Lenders`);
  }

  function handleActiveTabChange(tab: MatchModalTabs) {
    if (modalContentRef.current && activeTab) {
      modalContentScrollTop.current[activeTab] = modalContentRef.current.scrollTop;
    }
    setActiveTab(tab);
  }

  function scrollToTop() {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTop = 0;
    }
  }

  useEffect(() => {
    if (modalContentRef.current && activeTab) {
      modalContentRef.current.scrollTo({ top: modalContentScrollTop.current[activeTab] ?? 0 });
    }
  }, [activeTab]);

  useEffect(() => {
    setAddLoading(false);
    setHideLoading(false);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setActiveTab(matchLender?.tab ?? (invitedLender?.id ? 'thisDeal' : 'match'));
    }
  }, [matchLender?.tab, invitedLender?.id, isOpen]);

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        if (document.activeElement?.getAttribute('contenteditable')) {
          return;
        }

        if (escHandlersStack()[escHandlersStack().length - 1].id !== 'matchModal') {
          return;
        }

        if (event.key === 'ArrowLeft') {
          onPrevClick?.();
        } else {
          onNextClick?.();
        }
      }
    }
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [isOpen, onNextClick, onPrevClick]);

  const onLenderStatusChange = useUpdateDealLenderStatus(deal?._id);

  return (
    <>
      <Modal id="matchModal" layout="horizontal" isOpen={isOpen} onClose={onModalClose} onExited={onExited} {...props}>
        <Card as={FlexCol} ref={modalContentRef} id="matchModalCard" isModalContent>
          <div>
            <Sticky rootId="matchModalCard" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader utils={{ px: 7, pt: 8, pb: 7 }} desktop={{ px: 10 }} isStuck={isStuck}>
                  <Row gutter={2} utils={{ alignItems: 'center', mb: 6 }}>
                    <Col as={FlexRow} utils={{ alignItems: 'center' }}>
                      {lender ? (
                        <Avatar
                          size="xl"
                          src={getLogoUrl({ logo: lender.logo, website: lender.urls[0] })}
                          fallbackSrc={lender.zoominfo_logo}
                          alt={lender.name}
                          utils={{ mr: 5, borderRadius: 'base', blur: (matchLender?.blur && '4px') || undefined }}
                          mobile={{ size: 'lg' }}
                          imageProps={{ utils: { border: 1 } }}
                        />
                      ) : (
                        <TextFiller utils={{ height: '60px', width: '60px', mr: 5, borderRadius: 'base' }} />
                      )}
                      <FlexCol utils={{ minWidth: 0, blur: (matchLender?.blur && '4px') || undefined }}>
                        {lender ? (
                          <>
                            <FlexRow utils={{ alignItems: 'baseline', minWidth: 0 }}>
                              <Heading as="h2" utils={{ fontSize: 'xl', textTruncate: true }}>
                                {lender.name}
                              </Heading>
                              <Responsiveness data={lender.responsiveness} />
                            </FlexRow>
                            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>
                              {LenderTypeLabels[lender.lender_type!]}
                              {!enableBlur && (
                                <>
                                  {' · '}
                                  <Link href={`/lender/${lender.id}/overview`} target="_blank" passHref>
                                    View profile
                                    <Icon icon={externalLink} size=".9em" utils={{ ml: 1 }} />
                                  </Link>
                                </>
                              )}
                            </Text>
                          </>
                        ) : (
                          <>
                            <TextFiller utils={{ display: 'block', mb: 5, height: '12px', width: '120px' }} />
                            <TextFiller utils={{ width: '100px' }} />
                          </>
                        )}
                      </FlexCol>
                    </Col>
                    {invitedLender && (
                      <Col span="auto" mobile={{ span: '100%', order: 1 }}>
                        <LenderStatus
                          dealId={deal!._id!}
                          lenderId={invitedLender.id}
                          status={invitedLender.status}
                          onChange={(status) => onLenderStatusChange({ ...invitedLender, lenderId: invitedLender.id, status })}
                          isMatchModal
                          closingLender={deal?.status === DealStatus.Closed ? deal?.settings?.closingFeeBreakdown?.lender : null}
                          isContactInvited={invitedLender.isContactInvited}
                        />
                      </Col>
                    )}
                    {showButtons && (
                      <>
                        <Col span="100%" mobile={{ order: 1, mt: 6, display: 'flex' }} desktop={{ span: 'auto' }}>
                          <Button size="sm" variant="white" mobile={{ flexGrow: 1 }} onClick={handleAdd} isLoading={addLoading} disabled={addLoading}>
                            <Icon icon={plus} utils={{ mr: 2 }} />
                            Add
                          </Button>
                          {showHideDealLender && (
                            <Button size="sm" variant="white" mobile={{ flexGrow: 1 }} onClick={handleHide} isLoading={hideLoading} disabled={hideLoading}>
                              <Icon icon={eyeOff} utils={{ mr: 2 }} />
                              Hide
                            </Button>
                          )}
                        </Col>
                      </>
                    )}
                    {showMenu && (
                      <Col span="auto">
                        <Dropdown menuButton={<Toggle />}>
                          <DropdownMenu>
                            {lender?.status === DealLenderStatus.NoAnswer && (
                              <DropdownItem
                                icon={minusCircle}
                                onClick={() => {
                                  handleRemove();
                                  if (onNextClick) {
                                    onNextClick();
                                  } else {
                                    onClose?.();
                                  }
                                }}
                              >
                                Remove from Lenders
                              </DropdownItem>
                            )}
                            <DropdownItem icon={filePlus01} onClick={() => quoteEditModalVar({ isOpen: true, lenderId: matchLender?.lenderId })}>
                              Add a quote
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </Col>
                    )}
                    <Col span="auto" desktop={{ display: 'none' }}>
                      <Close onClick={onModalClose} />
                    </Col>
                  </Row>
                  <Scrollable>
                    {invitedLender && (
                      <TopnavLink isActive={activeTab === 'thisDeal'} onClick={() => handleActiveTabChange('thisDeal')} role="button">
                        This Deal
                      </TopnavLink>
                    )}
                    <TopnavLink
                      // count={program?.matchScore?.score ? `${Math.round(program?.matchScore?.score * 10) / 10}%` : undefined}
                      isActive={activeTab === 'match'}
                      onClick={() => handleActiveTabChange('match')}
                      role="button"
                    >
                      Match
                    </TopnavLink>
                    <TopnavLink
                      // count={contacts?.length || undefined}
                      isActive={activeTab === 'contacts'}
                      onClick={() => handleActiveTabChange('contacts')}
                      role="button"
                    >
                      Contacts
                    </TopnavLink>
                    {lender && !enableBlur && (
                      <>
                        {quotesCount > 0 && (
                          <TopnavLink
                            //  count={quotesCount}
                            isActive={activeTab === 'quotes'}
                            onClick={() => handleActiveTabChange('quotes')}
                            role="button"
                          >
                            Quotes
                          </TopnavLink>
                        )}
                        {lenderDeals?.count! > 0 && (
                          <TopnavLink
                            // count={lenderDeals?.count}
                            isActive={activeTab === 'deals'}
                            onClick={() => handleActiveTabChange('deals')}
                            role="button"
                          >
                            Deals
                          </TopnavLink>
                        )}
                        {lenderTransactionsCount > 0 && (
                          <TopnavLink
                            // count={lenderTransactionsCount}
                            isActive={activeTab === 'transactions'}
                            onClick={() => handleActiveTabChange('transactions')}
                            role="button"
                          >
                            Loans
                          </TopnavLink>
                        )}
                        <TopnavLink
                          //  count={aboutCount}
                          isActive={activeTab === 'about'}
                          onClick={() => handleActiveTabChange('about')}
                          role="button"
                        >
                          About
                        </TopnavLink>
                      </>
                    )}
                  </Scrollable>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, pt: 6, pb: 8 }} desktop={{ px: 10 }}>
              {invitedLender && (
                <div style={{ display: activeTab !== 'thisDeal' ? 'none' : 'unset' }}>
                  {invitedLender && <DealLenderReason {...invitedLender} />}
                  <MatchModalQuotes lender={invitedLender} quotes={quotes} />
                  <LenderActivities
                    lender={invitedLender}
                    dealId={deal?._id}
                    setNoteModifyingInProgress={setNoteModifyingInProgress}
                    placeholder="Add a note"
                    disabledCrossPosting={true}
                  />
                </div>
              )}
              <div style={{ display: activeTab !== 'match' ? 'none' : 'unset' }}>
                {lender && <MatchModalMatch program={program} deal={deal} enableBlur={enableBlur} />}
                {deal?._id && program && (
                  <>
                    <MatchModalSimilarDeals
                      deal={deal as DealFieldsFragment}
                      lenderDeals={lenderDeals?.lenderDeals}
                      lender={lender as CompanyFragment}
                      isHidden={activeTab !== 'match'}
                    />
                    <MatchModalSimilarLoans lenderId={matchLender?.lenderId!} dealId={deal._id} isHidden={activeTab !== 'match'} />
                  </>
                )}
              </div>
              <div style={{ display: activeTab !== 'contacts' ? 'none' : 'unset' }}>
                {(lender as CompanyFragment)?.contacts && (
                  <MatchModalContacts
                    lender={lender as CompanyFragment}
                    dealMembers={dealMembers}
                    enableBlur={enableBlur}
                    handleInviteClick={handleInviteClick}
                    isTestMatch={isTestMatch}
                  />
                )}
              </div>
              {lender && !enableBlur && (
                <>
                  <div style={{ display: activeTab !== 'quotes' ? 'none' : 'unset' }}>
                    <LenderQuotesList key={lender.id} lender={lender as CompanyFragment} isHidden={activeTab !== 'quotes'} setCount={setQuotesCount} />
                  </div>
                  <div style={{ display: activeTab !== 'deals' ? 'none' : 'unset' }}>
                    <LenderDealsList lender={lender as CompanyFragment} dealId={deal?._id} isHidden={activeTab !== 'deals'} />
                  </div>
                  <div style={{ display: activeTab !== 'transactions' ? 'none' : 'unset' }}>
                    <LenderLoans key={`LenderLoans-${lender.id}`} lenderId={lender.id} utils={{ mb: 8 }} isHidden={activeTab !== 'transactions'} />
                    <LenderLoansList
                      key={`LenderLoansList-${lender.id}`}
                      lenderId={lender.id}
                      dealId={deal?._id}
                      setCount={setLenderTransactionsCount}
                      isHidden={activeTab !== 'transactions'}
                    />
                  </div>
                  <div style={{ display: activeTab !== 'about' ? 'none' : 'unset' }}>
                    {lender.about && (
                      <>
                        <Heading as="h3" utils={{ fontSize: 'xl', mb: 4 }}>
                          About
                        </Heading>
                        <Text utils={{ fontSize: 'sm', mb: 8 }}>
                          <TextCollapsible maxWords={30}>{lender.about}</TextCollapsible>
                        </Text>
                      </>
                    )}
                    {lenderLocations && (lenderLocations.mainOffice || Object.keys(lenderLocations.otherOffices ?? {}).length > 0) && (
                      <>
                        <Heading utils={{ fontSize: 'sm' }}>Locations</Heading>
                        <Text utils={{ fontSize: 'sm', color: 'gray800', mb: 8 }}>
                          {getLenderLocationsSummaryString(lenderLocations)}.{' '}
                          <Text as="span" utils={{ color: 'primary' }} onClick={() => setOpenLenderLocationsModal(true)} role="button">
                            View details
                          </Text>
                          .
                        </Text>
                      </>
                    )}
                    <LenderActivities
                      lender={lender}
                      setNoteModifyingInProgress={setNoteModifyingInProgress}
                      notesOnly
                      // setCount={setAboutCount}
                      placeholder="Add a note"
                    />
                  </div>
                </>
              )}
            </CardBody>
          </div>
          {(onPrevClick || onNextClick) && (
            <ButtonGroup utils={{ position: 'sticky', zindex: 'sticky', bottom: 8, alignSelf: 'center', mt: 'auto' }} isRounded>
              <Button
                variant="white"
                utils={{ color: !onPrevClick ? 'gray600' : undefined }}
                hover={{ borderColor: 'gray300' }}
                onClick={() => {
                  scrollToTop();
                  onPrevClick?.();
                }}
                disabled={!onPrevClick}
              >
                <Icon icon={arrowLeft} size={22} />
              </Button>
              <Button
                variant="white"
                utils={{ color: !onNextClick ? 'gray600' : undefined }}
                hover={{ borderColor: 'gray300' }}
                onClick={() => {
                  scrollToTop();
                  onNextClick?.();
                }}
                disabled={!onNextClick}
              >
                <Icon icon={arrowRight} size={22} />
              </Button>
            </ButtonGroup>
          )}
        </Card>
      </Modal>

      {/* Modals */}
      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose?.();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have an unsaved Note"
        text="Are you sure you want to abandon your unsaved note?"
      />
      {lenderLocations && (
        <LenderLocationsModal lenderId={lender?.id as string} isOpen={openLenderLocationsModal} onClose={() => setOpenLenderLocationsModal(false)} />
      )}
    </>
  );
};

export default MatchModal;
