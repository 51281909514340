import React, { FC, useMemo, useState } from 'react';
import { Block, Col, Row } from '~/components/layout';
import { Chart, Select } from '~/components/vendor';
import { ChartOptions } from 'chart.js';
import { formatNumber } from '~/helpers';
import { Heading, Text } from '~/components/type';
import {
  LenderLoansChartGroupBy,
  LenderLoansChartTimeWindow,
  SortDirection,
  useGetLenderLoansQuery,
  useGetLenderSimilarLoansChartDataQuery,
} from '~/generated/graphql';
import { lenderLoansChartGroupByLabels } from '~/data/lenderLoansChartGroupByOptions';
import { NotFound } from '~/components/not-found';
import { Spinner } from '~/components/ui';
import { StyledCircle } from '~/containers/Reports/ReportsCharts';
import { Table } from '~/components/table';
import lenderLoansChartTimeWindowOptions from '~/data/lenderLoansChartTimeWindowOptions';
import LenderLoansItem from '~/containers/Lender/LenderLoansItem';
import ReportLenderLoanInaccuracyModal from '../ReportLenderLoanInaccuracyModal.tsx';
import theme from '~/theme/Theme';
import LenderLoanPropertiesModal from '../LenderLoanPropertiesModal';

type MatchModalSimilarLoansProps = {
  dealId: string;
  lenderId: string;
  isHidden?: boolean;
};

const MatchModalSimilarLoans: FC<MatchModalSimilarLoansProps> = ({ dealId, lenderId, isHidden }) => {
  const [timeWindow, setTimeWindow] = useState(LenderLoansChartTimeWindow.PastYear);
  const [inaccuracyLoanId, setInaccuracyLoanId] = useState<string | null>(null);
  const [loanPropertiesModalLoanId, setLoanPropertiesModalLoanId] = useState<string | null>(null);

  const {
    data: chartData,
    previousData: chartPreviousData,
    loading: loadingLenderSimilarLoansChartData,
  } = useGetLenderSimilarLoansChartDataQuery({
    variables: {
      lenderId,
      dealId,
      timeWindow,
    },
  });
  const lenderSimilarLoansChartData = chartData?.getLenderSimilarLoansChartData ?? chartPreviousData?.getLenderSimilarLoansChartData;

  const {
    data,
    previousData,
    loading: loadingLenderLoans,
  } = useGetLenderLoansQuery({
    variables: {
      lenderId,
      dealId,
      limit: 10,
      sort: {
        field: 'score',
        direction: SortDirection.Asc,
      },
      similarOnly: true,
    },
  });
  const lenderLoans = data?.getLenderLoans.lenderLoans ?? previousData?.getLenderLoans.lenderLoans ?? [];

  const barData = useMemo(() => {
    if (!lenderSimilarLoansChartData?.length) {
      return null;
    }

    const labels: string[] = [];

    return {
      labels,
      datasets: [
        {
          label: 'Similar Investment',
          data: lenderSimilarLoansChartData.map(({ group, label, similar }, inx) => {
            labels.push(label);
            return {
              x: inx,
              y: similar,
              group,
              label,
              isSimilar: true,
            };
          }),
          minBarLength: 3,
          backgroundColor: theme.colors.primary,
        },
        {
          label: 'Other',
          data: lenderSimilarLoansChartData.map(({ group, label, other }, inx) => {
            return {
              x: inx,
              y: other,
              group,
              label,
            };
          }),
          minBarLength: 3,
          backgroundColor: theme.colors.primary20,
          hoverBackgroundColor: theme.colors.primary20,
        },
      ],
    };
  }, [lenderSimilarLoansChartData]);

  const options = {
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        ticks: {
          callback: (value: number) => `$${formatNumber(value)}`,
        },
      },
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (ctx: any) => lenderLoansChartGroupByLabels[ctx[0].raw.group as LenderLoansChartGroupBy],
          label: (ctx: any) => `
            <div style="width: 100%; display: flex;justify-content: space-between">
              <span>
                <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; margin-right: 6px; background-color: ${
                  ctx.raw.isSimilar ? theme.colors.primary : theme.colors.primary20
                };"></span>
                ${ctx.raw.isSimilar ? ctx.raw.label : 'Other'}
              </span>
              <span>$${formatNumber(ctx.raw.y)}</span>
            </div>
          `,
        },
      },
    },
  } as ChartOptions<'bar'>;

  if (isHidden) {
    return null;
  }

  return (
    <>
      <Block utils={{ mb: 6, mt: 10 }}>
        <Heading as="h3" utils={{ fontSize: 'xl' }}>
          Similar Lending
        </Heading>
      </Block>

      {loadingLenderSimilarLoansChartData || loadingLenderLoans ? (
        <NotFound size="xs" utils={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spinner size="xs" variant="primary" utils={{ position: 'static', mr: 4, transform: 'none' }} />
          <Text utils={{ fontWeight: 'bold' }}>Loading...</Text>
        </NotFound>
      ) : (
        <>
          {barData && (
            <>
              <Row utils={{ alignItems: 'center', mb: 8 }} gutter={3}>
                <Col>
                  <StyledCircle utils={{ mr: 3, bgColor: 'primary' }} />
                  <Text as="span" utils={{ fontSize: 'sm' }}>
                    Similar Investment
                  </Text>
                  <StyledCircle utils={{ mr: 3, bgColor: 'primary30', ml: 8 }} />
                  <Text as="span" utils={{ fontSize: 'sm' }}>
                    Other
                  </Text>
                </Col>
                <Col span="auto">
                  <Select
                    size="sm"
                    instanceId="LenderTransactionsTimeSelect"
                    menuAlignment="right"
                    menuWidth="auto"
                    options={lenderLoansChartTimeWindowOptions}
                    value={lenderLoansChartTimeWindowOptions.find(({ value }) => value === timeWindow)}
                    onChange={(value) => {
                      setTimeWindow(value!.value);
                    }}
                    isSearchable={false}
                  />
                </Col>
              </Row>
              <Chart type="bar" data={barData} options={options} id="LenderSimilarLoansChart" style={{ height: 200 }} />
            </>
          )}

          {lenderLoans.length > 0 && (
            <Table size="lg" align="center" gutterY={0} utils={{ mb: 6 }}>
              {data?.getLenderLoans?.lenderLoans?.map((loan, index) => (
                <LenderLoansItem
                  key={index}
                  loan={loan}
                  showScore
                  setInaccuracyLoanId={setInaccuracyLoanId}
                  setLoanPropertiesModalLoanId={setLoanPropertiesModalLoanId}
                />
              ))}
            </Table>
          )}

          {!lenderLoans.length && !barData && (
            <NotFound size="xs">
              <Text as="strong">No similar lending.</Text>{' '}
              <Text as="span" utils={{ color: 'gray700' }}>
                This lender has no similar lending behavior.
              </Text>
            </NotFound>
          )}

          {!lenderLoans.length && barData && (
            <NotFound size="xs">
              <Text as="strong">No similar loans.</Text>{' '}
              <Text as="span" utils={{ color: 'gray700' }}>
                This lender hasn’t made similar loans.
              </Text>
            </NotFound>
          )}
        </>
      )}

      {/* Modals */}
      <ReportLenderLoanInaccuracyModal
        isOpen={Boolean(inaccuracyLoanId)}
        onClose={() => setInaccuracyLoanId(null)}
        lenderId={lenderId}
        loanId={inaccuracyLoanId!}
      />
      <LenderLoanPropertiesModal loanId={loanPropertiesModalLoanId} onClose={() => setLoanPropertiesModalLoanId(null)} />
    </>
  );
};

export default MatchModalSimilarLoans;
