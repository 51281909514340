import { DealMemberFragment, DocFolderFragment } from '~/generated/graphql';
import { NormalizedDocRequest, NormalizedFolderTree } from './getNormalizedFolderTree';

/*
Check folder and all folder's parents for access restrictions and return them.
Returns:
  true - admins only access
  false - no restrictions
  string[] - user ids
*/
export const getFolderLimitAccess = (tree: NormalizedFolderTree, folderId: string): string[] | boolean => {
  const folder = tree.items[folderId]?.data as DocFolderFragment;
  if (folder?.adminsOnlyAccess) {
    return true;
  }
  if (folder?.userIdsWithAccess?.length) {
    return folder.userIdsWithAccess;
  }
  return folder?.parentFolderId ? getFolderLimitAccess(tree, folder.parentFolderId) : false;
};

/*
Check folder and all folder's children for access restrictions.
Returns:
  true - has any access restrictions
  false - no restrictions
*/
export const isChildrenHasLimitAccess = (tree: NormalizedFolderTree, folderId: string, skipCurrent = true): boolean => {
  const folder = tree.items[folderId];
  if (!folder.isFolder) {
    return false;
  }

  if (!skipCurrent) {
    if ((folder?.data as DocFolderFragment)?.adminsOnlyAccess) {
      return true;
    }
    if ((folder?.data as DocFolderFragment)?.userIdsWithAccess?.length) {
      return true;
    }
  }

  return folder?.children?.some((childId) => isChildrenHasLimitAccess(tree, childId, false)) ?? false;
};

const getFolderTaskUserIdsRec = (tree: NormalizedFolderTree, dealAdminIds: string[], folderId: string, ids: string[] = []) => {
  tree.items[folderId]?.children?.forEach((childId) => {
    const child = tree.items[childId];

    if (child.isFolder) {
      ids = ids.concat(getFolderTaskUserIdsRec(tree, dealAdminIds, childId, ids));
    } else {
      const id = (child?.data as NormalizedDocRequest)?.task?.user?._id ?? (child?.data as NormalizedDocRequest)?.task?.invitation?._id;

      if (id && !dealAdminIds?.includes(id!)) {
        ids.push(id);
      }
    }
  });

  return ids;
};

export const getFolderTaskUserIds = (tree: NormalizedFolderTree, dealAdmins: DealMemberFragment[], folderId: string) => {
  const dealAdminIds = dealAdmins?.map((admin) => admin._id) ?? [];

  return [...new Set(getFolderTaskUserIdsRec(tree, dealAdminIds, folderId))];
};

/*
Validate new parent for docReq or folder
Returns:
  'NESTED_ACCESS'
  'ASSIGNED_USER'
  false
*/
export const validateTargetFolder = (tree: NormalizedFolderTree, dealAdmins: DealMemberFragment[], itemId: string, targetId: string) => {
  const targetLimitAccess = getFolderLimitAccess(tree, targetId);
  if (!targetLimitAccess) {
    return false;
  }

  const dealAdminIds = dealAdmins?.map((admin) => admin._id) ?? [];

  const item = tree.items[itemId];
  if (item.isFolder) {
    if (isChildrenHasLimitAccess(tree, itemId, false)) {
      return 'NESTED_ACCESS';
    }

    const itemTaskUserIds = getFolderTaskUserIdsRec(tree, dealAdminIds, itemId);
    return itemTaskUserIds.length > 0 && (targetLimitAccess === true || !itemTaskUserIds.every((userId) => targetLimitAccess.includes(userId)))
      ? 'ASSIGNED_USER'
      : false;
  }

  const assignedUserId = (item.data as NormalizedDocRequest)?.task?.user?._id ?? (item.data as NormalizedDocRequest)?.task?.invitation?._id;
  if (!assignedUserId || dealAdminIds.includes(assignedUserId)) {
    return false;
  }

  return targetLimitAccess === true || !targetLimitAccess.includes(assignedUserId) ? 'ASSIGNED_USER' : false;
};

export const validateUserIdAccess = (
  tree: NormalizedFolderTree,
  dealAdmins: DealMemberFragment[],
  parentFolderId: string | undefined,
  userId: string | undefined,
) => {
  if (!parentFolderId || !userId) {
    return false;
  }

  if (dealAdmins?.some((dealAdmin) => dealAdmin._id === userId)) {
    return false;
  }

  const folderAccess = getFolderLimitAccess(tree, parentFolderId);
  return !(folderAccess === false || (folderAccess !== true && folderAccess.includes(userId)));
};

export const validateNestedFolderAccess = (
  tree: NormalizedFolderTree,
  parentFolderId: string | undefined,
  adminsOnlyAccess: boolean,
  usersWithAccess: any[],
) => {
  if ((!adminsOnlyAccess && !usersWithAccess.length) || !parentFolderId || parentFolderId === 'root') {
    return false;
  }

  return Boolean(getFolderLimitAccess(tree, parentFolderId));
};

export type FOLDER_ACCESS_ERROR = 'NESTED' | 'NESTING' | 'TARGET_FOLDER_TASK' | 'CURRENT_FOLDER_TASK';

export const getFolderAccessErrors = (
  tree: NormalizedFolderTree,
  dealAdmins: DealMemberFragment[],
  folderId: string,
  parentFolderId: string,
  usersWithAccess: string[],
  adminsOnlyAccess: boolean,
) => {
  const errors: FOLDER_ACCESS_ERROR[] = [];

  const hasLimitAccess = usersWithAccess.length > 0 || adminsOnlyAccess;
  const childrenHasLimitAccess = isChildrenHasLimitAccess(tree, folderId, true);
  const taskUserIds = getFolderTaskUserIds(tree, dealAdmins, folderId);
  const parentLimitAccess = getFolderLimitAccess(tree, parentFolderId);

  if (hasLimitAccess && childrenHasLimitAccess) {
    errors.push('NESTED');
  }
  if ((hasLimitAccess || childrenHasLimitAccess) && parentLimitAccess) {
    errors.push('NESTING');
  }
  if (hasLimitAccess && taskUserIds.length && !taskUserIds.every((taskUserId) => usersWithAccess.includes(taskUserId))) {
    errors.push('CURRENT_FOLDER_TASK');
  }
  if (taskUserIds.length && parentLimitAccess && (parentLimitAccess === true || !taskUserIds.every((taskUserId) => parentLimitAccess.includes(taskUserId)))) {
    errors.push('TARGET_FOLDER_TASK');
  }

  return errors;
};
