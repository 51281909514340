import { Coords, fitBounds } from 'google-map-react';
import { getBoundsOfDistance } from 'geolib';

export default function getRadiusBounds(center: Coords, radius: number, maps: any, map: Element) {
  const radiusInMeters = radius * 1609.34;
  const bounds = new maps.LatLngBounds();
  const radiusBounds = getBoundsOfDistance(center, radiusInMeters);

  radiusBounds.forEach((bound) => {
    bounds.extend(new maps.LatLng(bound.latitude, bound.longitude));
  });

  const size = {
    width: map.clientWidth,
    height: map.clientHeight,
  };

  const newBounds = {
    ne: {
      lat: bounds.getNorthEast().lat(),
      lng: bounds.getNorthEast().lng(),
    },
    sw: {
      lat: bounds.getSouthWest().lat(),
      lng: bounds.getSouthWest().lng(),
    },
  };

  return fitBounds(newBounds, size);
}
