import 'chartjs-adapter-date-fns';

import { BaseProps } from '~/components/layout/Base';
import { Chart } from '~/components/vendor';
import { ChartData, ChartOptions } from 'chart.js';
import { FC } from 'react';
import { formatNumber } from '~/helpers';

type ReportsLineChartProps = BaseProps & {
  data: ChartData<'line', { x: string | null; y: number | null }[]>;
};

const ReportsLineChart: FC<ReportsLineChartProps> = ({ data, ...props }) => {
  const options = {
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        ticks: {
          callback: (value: number) => `$${formatNumber(value)}`,
        },
      },
      x: {
        type: 'time',
        time: {
          displayFormats: {
            month: 'MMM',
          },
          tooltipFormat: 'MMM dd, yyyy',
        },
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
  } as ChartOptions<'line'>;

  return <Chart type="line" data={data} options={options} style={{ height: 300 }} {...props} />;
};

export default ReportsLineChart;
