import { LenderLoansChartGroupBy } from '~/generated/graphql';

export const lenderLoansChartGroupByLabels: Record<LenderLoansChartGroupBy, string> = {
  [LenderLoansChartGroupBy.AssetType]: 'Asset Type',
  [LenderLoansChartGroupBy.DealType]: 'Deal Type',
  [LenderLoansChartGroupBy.State]: 'State',
  [LenderLoansChartGroupBy.LoanSize]: 'Loan Size',
  [LenderLoansChartGroupBy.QuarterIssued]: 'Quarter Issued',
  [LenderLoansChartGroupBy.TermLength]: 'Term Length',
};

const lenderLoansChartGroupByOptions: { value: LenderLoansChartGroupBy; label: string }[] = Object.entries(lenderLoansChartGroupByLabels).map(
  ([value, label]) => ({ value: value as LenderLoansChartGroupBy, label }),
);

export default lenderLoansChartGroupByOptions;
