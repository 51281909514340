import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { Block } from '../components/layout';
import { GlobalAlert } from '../components/ui';
import { HeaderFront } from '../containers';
import { useDebounceFn } from 'ahooks';
import { ContactModal } from '../modals';
import { CustomTheme } from '~/@types/styled-components';
import { NextLayout } from '~/pages/_app';
import { useApollo } from '~/lib/useApollo';
import { ApolloProvider } from '@apollo/client';

const GlobalAlerts = dynamic(() => import('~/components/ui/GlobalAlert/GlobalAlerts'), { ssr: false });
const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), { ssr: false });

type ContactContextAlert = {
  visible?: boolean;
  variant?: keyof CustomTheme['colors'];
  text?: string;
};

type ContactContext = {
  contactForm: {
    email: string;
    lenderMatchingForm: any;
  };
  setContactForm: React.Dispatch<React.SetStateAction<ContactContext['contactForm']>>;
  setContactFormEmail: (email: string) => void;
  setDisplayAlert: (alert: ContactContextAlert) => void;
  setIsContactModalOpen: (isContactModalOpen: boolean) => void;
};

export const ContactContext = React.createContext<ContactContext>({
  contactForm: {
    email: '',
    lenderMatchingForm: {},
  },
  setDisplayAlert: () => {},
  setContactFormEmail: () => {},
  setContactForm: () => {},
  setIsContactModalOpen: () => {},
});

export const FrontLayout: NextLayout = ({ children, pageProps }) => {
  const router = useRouter();
  const apolloClient = useApollo(pageProps);
  const [contactForm, setContactForm] = useState<ContactContext['contactForm']>({
    email: '',
    lenderMatchingForm: {},
  });
  const [displayAlert, setDisplayAlert] = useState<ContactContextAlert | null>(null);

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const { run: setContactFormEmail } = useDebounceFn(
    (email: string) => {
      setContactForm((oldContactForm) => ({ ...oldContactForm, email }));
    },
    { wait: 500 },
  );

  useEffect(() => {
    if (router.query.danger) {
      setDisplayAlert({
        visible: true,
        text: router.query.danger as string,
        variant: 'danger',
      });
    }
  }, [router.query.danger]);

  return (
    <ApolloProvider client={apolloClient}>
      <ContactContext.Provider
        value={{
          contactForm,
          setContactForm,
          setDisplayAlert,
          setContactFormEmail,
          setIsContactModalOpen,
        }}
      >
        <HeaderFront />
        <Block utils={{ overflowX: 'clip' }}>{children}</Block>

        {/* Modals */}
        <ContactModal isOpen={isContactModalOpen} onClose={() => setIsContactModalOpen(false)} />

        {/* Alerts */}
        <GlobalAlerts>
          <GlobalAlert
            utils={{ bgColor: displayAlert?.variant ?? 'success' }}
            onClose={() => setDisplayAlert({ visible: false, text: '' })}
            isOpen={displayAlert?.visible}
          >
            {displayAlert?.text}
          </GlobalAlert>
        </GlobalAlerts>

        {/* Tooltip */}
        <Tooltip id="GlobalTooltip" />
      </ContactContext.Provider>
    </ApolloProvider>
  );
};

export default FrontLayout;
