import { SortDirection, SortInput } from '~/generated/graphql';

type sortColumnProps = {
  columnName: string;
  direction?: SortDirection;
  sort: SortInput | null;
  setSort: (sort: SortInput | null) => void;
  resetScroll?: boolean;
};

export default function sortColumn({ columnName, direction = SortDirection.Asc, sort, setSort }: sortColumnProps) {
  const oppositeDirection = direction === SortDirection.Desc ? SortDirection.Asc : SortDirection.Desc;
  let newSorter = sort;

  if (sort?.field !== columnName) {
    newSorter = { field: columnName, direction };
  } else if (sort.direction === direction) {
    newSorter = { field: columnName, direction: oppositeDirection };
  } else if (sort.direction === oppositeDirection) {
    newSorter = null;
  }

  setSort(newSorter);
}
