import React, { MouseEvent } from 'react';
import { ActivityInvitationName } from '~/components/activity/ActivityInvitationName';
import { ActivityItemCollapsible } from '~/components/activity/ActivityItemCollapsible';
import { ActivityItemLink } from '~/components/activity/ActivityItemLink';
import { ActivityItemList } from '~/components/activity/ActivityItemList';
import { ActivityItemText } from '~/components/activity/ActivityItemText';
import { ActivityStatus } from '~/components/activity/ActivityStatus';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import {
  DealActivityType,
  BaseActivityFragment,
  DocReqActivityFragment,
  FileActivityFragment,
  FolderActivityFragment,
  InvitationActivityFragment,
  LenderStatusChangeActivityFragment,
  TaskActivityFragment,
  UserDealActivityFragment,
  DealLenderActivityFragment,
  DealBorrowerActivityFragment,
  DealLenderStatus,
  ActivitiesFragment,
  CompanyFragment,
  DealStatusActivityFragment,
  TaskUnAssignedActivityFragment,
  BaseBorrowerActivityFragment,
  BaseBorrowerContactActivityFragment,
  BorrowerStatusActivityFragment,
  BaseLenderActivityFragment,
  BaseLenderProgramActivityFragment,
  BaseLenderContactActivityFragment,
  LenderResearchStatusChangeActivityFragment,
  ProgramRequestSentActivityFragment,
  BaseQuoteActivityFragment,
  FilesDownloadedActivityFragment,
  LightCompanyFragment,
} from '~/generated/graphql';
import { DealLenderStatusLabels, DealLenderStatusIcons, DealLenderStatusVariants, DealLenderStatusProgress } from '~/data/dealLenderStatusOptions';
import { DealStatusMapped, DealStatusIcons, DealStatusVariants, DealStatusProgress } from '~/data/dealStatus';
import { getTaskUserName } from '~/containers/Tasks/getTaskUserName';
import { ListItem } from '~/components/list';
import { Text } from '~/components/type';
import { useRouter } from 'next/router';
import DocumentRequestName from './DocumentRequestName';
import formatName from '~/helpers/formatName';
import Link from 'next/link';
import ActivityLenderLink from './ActivityLenderLink';
import ActivityBorrowerLink from './ActivityBorrowerLink';
import { borrowerContactLabels, borrowerLabels, lenderContactLabels, lenderLabels, lenderProgramLabels } from '~/data/labels';
import { pluralize } from '~/helpers/pluralize';
import { BorrowerStatusIcons, BorrowerStatusMapped, BorrowerStatusProgress, BorrowerStatusVariants } from '~/data/borrowerStatusOptions';
import { LenderResearchStatusIcons, LenderResearchStatusLabels, LenderResearchStatusVariants } from '~/data/lenderResearchStatusOptions';
import { QuoteTypeLabels } from '~/data/quoteTypeOptions';
import { quoteViewModalVar } from '~/lib/apolloClient';

export const getFields = (labels: Record<string, string>, activity: BaseBorrowerActivityFragment | BaseLenderActivityFragment) => {
  const res = activity.fields.map((field) => labels[field]);
  if (res.length < 3) {
    return res.join(' and ');
  }
  const begin = res.splice(0, 2).join(', ');
  let end = res.join(', ');
  end = end[0].toUpperCase() + end.slice(1);
  return (
    <>
      {begin},{' '}
      <Text
        as="span"
        utils={{ textDecoration: 'underline' }}
        data-tooltip-id="GlobalTooltip"
        data-tooltip-content={end}
        style={{ position: 'relative', zIndex: 1, cursor: 'pointer' }}
      >
        and {res.length} other {pluralize(res.length, 'property', 'properties')}
      </Text>
    </>
  );
};

interface Props {
  activity: ActivitiesFragment;
  isBot?: boolean;
  search?: string;
  openMatchModel?: (lender: CompanyFragment | LightCompanyFragment) => void;
  borrowerId?: string;
  lenderId?: string;
}

const ActivitiesItemBody: React.FC<Props> = ({ activity, isBot, search, openMatchModel, borrowerId, lenderId }) => {
  const router = useRouter();
  const dealId = router.query?.dealId as string;

  switch ((activity as BaseActivityFragment).type) {
    case DealActivityType.DealCreated:
      return <ActivityItemText>{addHighlightWords((activity as BaseActivityFragment).description, search)}</ActivityItemText>;

    case DealActivityType.DealStatusChange: {
      const lender = (activity as DealStatusActivityFragment).selectedLender;
      return (
        <ActivityItemText>
          {addHighlightWords('Updated the deal status to', search)}{' '}
          <ActivityStatus
            icon={DealStatusIcons[(activity as DealStatusActivityFragment).dealStatus]}
            variant={DealStatusVariants[(activity as DealStatusActivityFragment).dealStatus]}
            progress={DealStatusProgress[(activity as DealStatusActivityFragment).dealStatus]}
            label={DealStatusMapped[(activity as DealStatusActivityFragment).dealStatus]}
            search={search}
          />
          {lender ? (
            <>
              {' ('}
              <ActivityLenderLink openMatchModel={openMatchModel} lender={lender} search={search} />
              {')'}
            </>
          ) : null}
          {(activity as DealStatusActivityFragment).dealStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{addHighlightWords(`${(activity as DealStatusActivityFragment).dealStatusReason}`, search)}</Text>
          )}
        </ActivityItemText>
      );
    }

    case DealActivityType.DealPdfFinancingDownload:
      return (
        <ActivityItemText>
          {addHighlightWords("Downloaded a PDF of the deal's", search)}{' '}
          <Link href={`/deal/${dealId}/financing`} passHref legacyBehavior>
            <ActivityItemLink as="a">{addHighlightWords('Financing', search)}</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.DealPdfSummaryDownload:
      return (
        <ActivityItemText>
          {addHighlightWords('Downloaded a PDF of the', search)}{' '}
          <Link href={`/deal/${dealId}/summary`} passHref legacyBehavior>
            <ActivityItemLink as="a">{addHighlightWords('Summary', search)}</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.QuotePresentationPdfDownload:
      return (
        <ActivityItemText>
          {addHighlightWords("Downloaded a PDF of the deal's", search)}{' '}
          <Link href={`/deal/${dealId}/quotes?tab=matrix`} passHref legacyBehavior>
            <ActivityItemLink as="a">{addHighlightWords('Quote Presentation', search)}</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.QuoteMatrixPdfDownload:
      return (
        <ActivityItemText>
          {addHighlightWords('Downloaded a PDF of the', search)}{' '}
          <Link href={`/deal/${dealId}/quotes?tab=matrix`} passHref legacyBehavior>
            <ActivityItemLink as="a">{addHighlightWords('Quote Matrix', search)}</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.FolderCreated: {
      const docReqFolders = (activity as FolderActivityFragment).docReqFolders;
      if (docReqFolders.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Created the folder', search)} <ActivityItemLink>{addHighlightWords(docReqFolders[0].name, search)}</ActivityItemLink>
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Created', search)}
          toggle={
            <ActivityItemLink role="button">
              {docReqFolders.length} {addHighlightWords('folders', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docReqFolders.map((docReqFolder) => (
              <ListItem key={docReqFolder._id}>
                <ActivityItemLink>{addHighlightWords(docReqFolder.name, search)}</ActivityItemLink>
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DocReqCreated: {
      const docRequests = (activity as DocReqActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Created the document request', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Created', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DocReqReviewed: {
      const docRequests = (activity as DocReqActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Approved the latest version of', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Approved', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DocReqRejected: {
      const docRequests = (activity as DocReqActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Rejected the latest version of ', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Rejected', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.FileDownloaded: {
      const docRequests = (activity as FileActivityFragment).docRequests;
      if ((activity as FileActivityFragment).docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Downloaded the latest version of', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Downloaded', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.FileUploaded: {
      const docRequests = (activity as FileActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Uploaded a new file to', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Uploaded', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('new documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.FileUploadedAndReviewed: {
      const docRequests = (activity as FileActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Uploaded to and approved', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Uploaded to and approved', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DocReqCreatedAndUploaded: {
      const docRequests = (activity as FileActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Created and uploaded to', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Created and uploaded to', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DocReqCreatedAndUploadedAndReviewed: {
      const docRequests = (activity as FileActivityFragment).docRequests;
      if (docRequests.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Created, uploaded to, and approved', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequests[0]} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Created, uploaded to, and approved', search)}
          toggle={
            <ActivityItemLink role="button">
              {docRequests.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {docRequests.map((docRequest) => (
              <ListItem key={docRequest._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={docRequest} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.ApprovedFilesDownloaded: {
      if ((activity as FilesDownloadedActivityFragment).docReqFolder) {
        return (
          <ActivityItemText>
            {addHighlightWords('Downloaded a zip of ', search)}
            <ActivityItemLink>{addHighlightWords((activity as FilesDownloadedActivityFragment).docReqFolder!.name, search)}</ActivityItemLink>
            {addHighlightWords(' folder', search)}
          </ActivityItemText>
        );
      }
      return <ActivityItemText>{addHighlightWords('Downloaded a zip of all Approved document', search)}</ActivityItemText>;
    }

    case DealActivityType.InvitationBorrowerCreated: {
      const invitations = (activity as InvitationActivityFragment).invitations;
      if (invitations.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Invited', search)}{' '}
            <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invitations[0]} search={search} />{' '}
            {addHighlightWords('as borrower', search)}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Invited', search)}
          toggle={
            <ActivityItemLink role="button">
              {invitations.length} {addHighlightWords('borrowers', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {invitations.map((invite) => (
              <ListItem key={invite._id}>
                <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invite} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.InvitationLenderCreated: {
      const invitations = (activity as InvitationActivityFragment).invitations;
      if (invitations.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Invited', search)}{' '}
            <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invitations[0]} search={search} />{' '}
            {addHighlightWords('as lender', search)}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Invited', search)}
          toggle={
            <ActivityItemLink role="button">
              {invitations.length} {addHighlightWords('lenders', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {invitations.map((invite) => (
              <ListItem key={invite._id}>
                <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invite} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.InvitationRemoved: {
      const invitations = (activity as InvitationActivityFragment).invitations;
      if (invitations.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Removed the invite to', search)}{' '}
            <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invitations[0]} search={search} />
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Removed', search)}
          toggle={
            <ActivityItemLink role="button">
              {invitations.length} {addHighlightWords('invites', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {invitations.map((invite) => (
              <ListItem key={invite._id}>
                <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invite} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.InvitationFollowUpSent: {
      const invitations = (activity as InvitationActivityFragment).invitations;
      if (invitations.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Sent an email to', search)}{' '}
            <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invitations[0]} search={search} />
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Sent emails to', search)}
          toggle={
            <ActivityItemLink role="button">
              {invitations.length} {addHighlightWords('people', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {invitations.map((invite) => (
              <ListItem key={invite._id}>
                <ActivityInvitationName borrowerId={borrowerId} lenderId={lenderId} openMatchModel={openMatchModel} invitation={invite} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.InvitationAccepted:
      return <ActivityItemText>{addHighlightWords((activity as UserDealActivityFragment).description, search)}</ActivityItemText>;

    case DealActivityType.BorrowerRemoved: {
      const users = (activity as UserDealActivityFragment).users;
      if (users.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Removed the borrower', search)}{' '}
            <ActivityItemLink>{addHighlightWords(formatName(users[0].fullName, { initialLastName: true }), search)}</ActivityItemLink>
            <>
              {' ('}
              {users[0].borrower ? <ActivityBorrowerLink borrower={users[0].borrower} search={search} /> : users[0].email}
              {')'}
            </>
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Removed', search)}
          toggle={
            <ActivityItemLink role="button">
              {users.length} {addHighlightWords('people', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {users.map((user) => (
              <ListItem key={user._id}>
                <ActivityItemLink>{addHighlightWords(formatName(user.fullName, { initialLastName: true }), search)}</ActivityItemLink>
                <>
                  {' ('}
                  {user.borrower ? <ActivityBorrowerLink borrower={user.borrower} search={search} /> : user.email}
                  {')'}
                </>
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.AdminRemoved: {
      const users = (activity as UserDealActivityFragment).users;
      if (users.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Removed the admin', search)}{' '}
            <ActivityItemLink>{addHighlightWords(formatName(users[0].fullName, { initialLastName: true }), search)}</ActivityItemLink>{' '}
            {addHighlightWords(`(${users[0].email})`, search)}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Removed', search)}
          toggle={
            <ActivityItemLink role="button">
              {users.length} {addHighlightWords('admins', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {users.map((user) => (
              <ListItem key={user._id}>
                <ActivityItemLink>{addHighlightWords(formatName(user.fullName, { initialLastName: true }), search)}</ActivityItemLink>{' '}
                {addHighlightWords(`(${user.email})`, search)}
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.LenderRemoved: {
      const users = (activity as UserDealActivityFragment).users;
      if (users.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Removed the lender', search)}{' '}
            <ActivityItemLink>{addHighlightWords(formatName(users[0].fullName, { initialLastName: true }), search)}</ActivityItemLink>
            {!lenderId && (
              <>
                {' ('}
                {users[0].lender ? <ActivityLenderLink openMatchModel={openMatchModel} lender={users[0].lender} search={search} /> : users[0].email}
                {')'}
              </>
            )}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Removed', search)}
          toggle={
            <ActivityItemLink role="button">
              {users.length} {addHighlightWords('people', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {users.map((user) => (
              <ListItem key={user._id}>
                <ActivityItemLink>{addHighlightWords(formatName(user.fullName, { initialLastName: true }), search)}</ActivityItemLink>
                {!lenderId && (
                  <>
                    {' ('}
                    {user.lender ? <ActivityLenderLink openMatchModel={openMatchModel} lender={user.lender} search={search} /> : user.email}
                    {')'}
                  </>
                )}
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.SummaryPublished:
      return (
        <ActivityItemText>
          {addHighlightWords('Published the', search)}{' '}
          <Link href={`/deal/${dealId}/summary`} passHref legacyBehavior>
            <ActivityItemLink as="a">Summary</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.FinancingPublished:
      return (
        <ActivityItemText>
          {addHighlightWords('Published', search)}{' '}
          <Link href={`/deal/${dealId}/financing`} passHref legacyBehavior>
            <ActivityItemLink as="a">Financing</ActivityItemLink>
          </Link>
        </ActivityItemText>
      );

    case DealActivityType.TaskAssigned: {
      const tasks = (activity as TaskActivityFragment).tasks;
      if (tasks.length === 1) {
        const task = tasks[0];
        return (
          <ActivityItemText>
            {addHighlightWords(isBot ? 'Auto assigned' : 'Assigned', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={task.docRequest!} search={search} /> to{' '}
            {task?.user ? getTaskUserName(task?.user) : task?.invitation?.email}
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords(isBot ? 'Auto assigned' : 'Assigned', search)}
          toggle={
            <ActivityItemLink role="button">
              {tasks.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {tasks.map((task) => (
              <ListItem key={task._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={task.docRequest!} search={search} />
                {' to '}
                {task?.user ? getTaskUserName(task?.user) : task?.invitation?.email}
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.TaskUnAssigned: {
      const tasks = (activity as TaskUnAssignedActivityFragment).tasks;
      if (tasks.length === 1) {
        const task = tasks[0];
        return (
          <ActivityItemText>
            {addHighlightWords(isBot ? 'Auto removed assignment of' : 'Removed assignment of', search)}{' '}
            <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={task.docRequest!} search={search} />
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords(isBot ? 'Auto removed assignment of' : 'Removed assignment of', search)}
          toggle={
            <ActivityItemLink role="button">
              {tasks.length} {addHighlightWords('documents', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {tasks.map((task) => (
              <ListItem key={task._id}>
                <DocumentRequestName skipQueryUpdate={Boolean(borrowerId)} documentRequest={task.docRequest!} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.LenderStatusChange: {
      const lenderStatusActivity = activity as LenderStatusChangeActivityFragment;
      return (
        <ActivityItemText>
          {addHighlightWords('Updated', search)}{' '}
          {lenderId ? "this lender's" : <ActivityLenderLink openMatchModel={openMatchModel} lender={lenderStatusActivity.lender} search={search} />}
          's {addHighlightWords('status to', search)}{' '}
          <ActivityStatus
            icon={DealLenderStatusIcons[lenderStatusActivity.lenderStatus]}
            variant={DealLenderStatusVariants[lenderStatusActivity.lenderStatus]}
            label={DealLenderStatusLabels[lenderStatusActivity.lenderStatus]}
            progress={DealLenderStatusProgress[lenderStatusActivity.lenderStatus]}
            search={search}
          />
          {lenderStatusActivity.lenderStatus === DealLenderStatus.Interested && lenderStatusActivity.interestedStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{addHighlightWords(`${lenderStatusActivity.interestedStatusReason}`, search)}</Text>
          )}
          {lenderStatusActivity.lenderStatus === DealLenderStatus.Maybe && lenderStatusActivity.maybeStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{addHighlightWords(`${lenderStatusActivity.maybeStatusReason}`, search)}</Text>
          )}
          {lenderStatusActivity.lenderStatus === DealLenderStatus.Passed && lenderStatusActivity.passedStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{addHighlightWords(`${lenderStatusActivity.passedStatusReason}`, search)}</Text>
          )}
          {lenderStatusActivity.lenderStatus === DealLenderStatus.NotCompetitive && lenderStatusActivity.notCompetitiveStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{addHighlightWords(`${lenderStatusActivity.notCompetitiveStatusReason}`, search)}</Text>
          )}
        </ActivityItemText>
      );
    }

    case DealActivityType.PreviewViewed:
      return <ActivityItemText>{addHighlightWords('Viewed the deal preview.', search)}</ActivityItemText>;

    case DealActivityType.DealLenderAdded: {
      if (lenderId) {
        return <ActivityItemText>Added this lender</ActivityItemText>;
      }
      const lenders = (activity as DealLenderActivityFragment).lenders;
      if (lenders.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Added', search)} <ActivityLenderLink openMatchModel={openMatchModel} lender={lenders[0]} search={search} />{' '}
            {addHighlightWords('lender', search)}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Added', search)}
          toggle={
            <ActivityItemLink role="button">
              {lenders.length} {addHighlightWords('lenders', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {lenders.map((lender) => (
              <ListItem key={lender.id}>
                <ActivityLenderLink openMatchModel={openMatchModel} lender={lender} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DealLenderRemoved: {
      if (lenderId) {
        return <ActivityItemText>Removed this lender</ActivityItemText>;
      }
      const lenders = (activity as DealLenderActivityFragment).lenders;
      if (lenders.length === 1) {
        return (
          <ActivityItemText>
            {addHighlightWords('Removed', search)} <ActivityLenderLink openMatchModel={openMatchModel} lender={lenders[0]} search={search} />{' '}
            {addHighlightWords('lender', search)}
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible
          isOpened={Boolean(search)}
          title={addHighlightWords('Removed', search)}
          toggle={
            <ActivityItemLink role="button">
              {lenders.length} {addHighlightWords('lenders', search)}
            </ActivityItemLink>
          }
        >
          <ActivityItemList>
            {lenders.map((lender) => (
              <ListItem key={lender.id}>
                <ActivityLenderLink openMatchModel={openMatchModel} lender={lender} search={search} />
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.DealBorrowerAdded: {
      if (borrowerId) {
        return <ActivityItemText>Added this borrower</ActivityItemText>;
      }
      const [borrower] = (activity as DealBorrowerActivityFragment).borrowers;
      return (
        <ActivityItemText>
          {addHighlightWords('Added', search)} <ActivityBorrowerLink borrower={borrower} search={search} /> {addHighlightWords('borrower', search)}
        </ActivityItemText>
      );
    }

    case DealActivityType.DealBorrowerRemoved: {
      if (borrowerId) {
        return <ActivityItemText>Removed this borrower</ActivityItemText>;
      }
      const [borrower] = (activity as DealBorrowerActivityFragment).borrowers;
      return (
        <ActivityItemText>
          {addHighlightWords('Removed', search)} <ActivityBorrowerLink borrower={borrower} search={search} /> {addHighlightWords('borrower', search)}
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerCreated:
      return <ActivityItemText>Created this borrower</ActivityItemText>;

    case DealActivityType.BorrowerUpdated:
      return <ActivityItemText>Updated this borrower's {getFields(borrowerLabels, activity as BaseBorrowerActivityFragment)}</ActivityItemText>;

    case DealActivityType.BorrowerDeleted:
      return (
        <ActivityItemText>
          Deleted this borrower
          {(activity as BaseBorrowerActivityFragment).deletionReason && (
            <Text utils={{ mr: 4, color: 'black' }}>{(activity as BaseBorrowerActivityFragment).deletionReason}</Text>
          )}
        </ActivityItemText>
      );

    case DealActivityType.BorrowerContactCreated: {
      const contact = (activity as BaseBorrowerContactActivityFragment).borrowerContact;
      return (
        <ActivityItemText>
          Added the contact{' '}
          <ActivityItemLink>
            {contact.firstName} {contact.lastName}
          </ActivityItemLink>
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerContactUpdated: {
      const contact = (activity as BaseBorrowerContactActivityFragment).borrowerContact;
      return (
        <ActivityItemText>
          Updated{' '}
          <ActivityItemLink>
            {contact.firstName} {contact.lastName}
          </ActivityItemLink>
          's {getFields(borrowerContactLabels, activity as BaseBorrowerActivityFragment)}
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerContactDeleted: {
      const contact = (activity as BaseBorrowerContactActivityFragment).borrowerContact;
      return (
        <ActivityItemText>
          Deleted{' '}
          <ActivityItemLink>
            {contact.firstName} {contact.lastName}
          </ActivityItemLink>
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerContactMoved: {
      const contact = (activity as BaseBorrowerContactActivityFragment).borrowerContact;
      if ((activity as BaseBorrowerContactActivityFragment).borrower._id === borrowerId) {
        return (
          <ActivityItemText>
            Moved{' '}
            <ActivityItemLink>
              {contact.firstName} {contact.lastName}
            </ActivityItemLink>{' '}
            to this borrower from{' '}
            <Link href={`/borrower/${(activity as BaseBorrowerContactActivityFragment).oldBorrower!._id}/overview`} passHref legacyBehavior>
              <ActivityItemLink as="a">{(activity as BaseBorrowerContactActivityFragment).oldBorrower!.name}</ActivityItemLink>
            </Link>
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemText>
          Moved{' '}
          <ActivityItemLink>
            {contact.firstName} {contact.lastName}
          </ActivityItemLink>{' '}
          to{' '}
          <Link href={`/borrower/${(activity as BaseBorrowerContactActivityFragment).borrower._id}/overview`} passHref legacyBehavior>
            <ActivityItemLink as="a">{(activity as BaseBorrowerContactActivityFragment).borrower.name}</ActivityItemLink>
          </Link>{' '}
          from this borrower
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerStatusChange: {
      return (
        <ActivityItemText>
          Updated the borrower status to{' '}
          <ActivityStatus
            icon={BorrowerStatusIcons[(activity as BorrowerStatusActivityFragment).borrowerStatus]}
            variant={BorrowerStatusVariants[(activity as BorrowerStatusActivityFragment).borrowerStatus]}
            label={BorrowerStatusMapped[(activity as BorrowerStatusActivityFragment).borrowerStatus]}
            progress={BorrowerStatusProgress[(activity as BorrowerStatusActivityFragment).borrowerStatus]}
          />
          {(activity as BorrowerStatusActivityFragment).borrowerStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{(activity as BorrowerStatusActivityFragment).borrowerStatusReason}</Text>
          )}
        </ActivityItemText>
      );
    }

    case DealActivityType.BorrowerContactUnreachableEmail: {
      const contact = (activity as BaseBorrowerContactActivityFragment).borrowerContact;
      return (
        <ActivityItemText>
          Removed{' '}
          <ActivityItemLink>
            {contact.firstName} {contact.lastName}
          </ActivityItemLink>{' '}
          ({contact.emails[0]}) because their email bounced
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderCreated:
      return <ActivityItemText>Created this lender</ActivityItemText>;

    case DealActivityType.LenderUpdated:
      return <ActivityItemText>Updated this lender's {getFields(lenderLabels, activity as BaseLenderActivityFragment)}</ActivityItemText>;

    case DealActivityType.LenderDeleted:
      return (
        <ActivityItemText>
          Deleted this lender
          <ActivityItemLink>
            {(activity as BaseLenderActivityFragment).deletionReason && ` with the reason "${(activity as BaseLenderActivityFragment).deletionReason}"`}
          </ActivityItemLink>
        </ActivityItemText>
      );

    case DealActivityType.LenderProgramCreated: {
      const program = (activity as BaseLenderProgramActivityFragment).program;
      return (
        <ActivityItemText>
          Added the <ActivityItemLink>{program.name}</ActivityItemLink> program
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderProgramUpdated: {
      const program = (activity as BaseLenderProgramActivityFragment).program;
      return (
        <ActivityItemText>
          Updated the <ActivityItemLink>{program.name}</ActivityItemLink> program's {getFields(lenderProgramLabels, activity as BaseLenderActivityFragment)}
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderProgramDeleted: {
      const program = (activity as BaseLenderProgramActivityFragment).program;
      return (
        <ActivityItemText>
          Deleted the <ActivityItemLink>{program.name}</ActivityItemLink> program
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderContactCreated: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      return (
        <ActivityItemText>
          Added the contact{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderContactUpdated: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      return (
        <ActivityItemText>
          Updated{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>
          's {getFields(lenderContactLabels, activity as BaseLenderActivityFragment)}
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderContactDeleted: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      return (
        <ActivityItemText>
          Deleted{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderContactZoominfoDeleted: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      const lender = (activity as BaseLenderContactActivityFragment).lender;
      return (
        <ActivityItemText>
          Removed{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>{' '}
          ({contact.emails?.[0]?.value}) because they no longer work at {lender.name}
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderContactMoved: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      if ((activity as BaseLenderContactActivityFragment).lender.id === lenderId) {
        return (
          <ActivityItemText>
            Moved{' '}
            <ActivityItemLink>
              {contact.first_name} {contact.last_name}
            </ActivityItemLink>{' '}
            to this lender from{' '}
            <Link href={`/lender/${(activity as BaseLenderContactActivityFragment).oldLender!.id}/overview`} passHref legacyBehavior>
              <ActivityItemLink as="a">{(activity as BaseLenderContactActivityFragment).oldLender!.name}</ActivityItemLink>
            </Link>
          </ActivityItemText>
        );
      }
      return (
        <ActivityItemText>
          Moved{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>{' '}
          to{' '}
          <Link href={`/lender/${(activity as BaseLenderContactActivityFragment).lender.id}/overview`} passHref legacyBehavior>
            <ActivityItemLink as="a">{(activity as BaseLenderContactActivityFragment).lender.name}</ActivityItemLink>
          </Link>{' '}
          from this lender
        </ActivityItemText>
      );
    }

    case DealActivityType.LenderResearchStatusChange:
      return (
        <ActivityItemText>
          Updated the research status to{' '}
          <ActivityStatus
            icon={LenderResearchStatusIcons[(activity as LenderResearchStatusChangeActivityFragment).researchStatus]}
            variant={LenderResearchStatusVariants[(activity as LenderResearchStatusChangeActivityFragment).researchStatus]}
            label={LenderResearchStatusLabels[(activity as LenderResearchStatusChangeActivityFragment).researchStatus]}
          />
          {(activity as LenderResearchStatusChangeActivityFragment).researchStatusReason && (
            <Text utils={{ mt: 4, color: 'black' }}>{(activity as LenderResearchStatusChangeActivityFragment).researchStatusReason}</Text>
          )}
        </ActivityItemText>
      );

    case DealActivityType.ProgramRequestSent: {
      const contacts = (activity as ProgramRequestSentActivityFragment).contacts;
      if (contacts.length === 1) {
        const contact = contacts[0];
        return (
          <ActivityItemText>
            Sent a program request email to{' '}
            <ActivityItemLink>
              {contact.first_name} {contact.last_name}
            </ActivityItemLink>
          </ActivityItemText>
        );
      }

      return (
        <ActivityItemCollapsible title="Sent program request emails to" toggle={<ActivityItemLink role="button">{contacts.length} contacts</ActivityItemLink>}>
          <ActivityItemList>
            {contacts.map((contact) => (
              <ListItem key={contact.id}>
                {contact.first_name} {contact.last_name}
              </ListItem>
            ))}
          </ActivityItemList>
        </ActivityItemCollapsible>
      );
    }

    case DealActivityType.LenderContactUnreachableEmail: {
      const contact = (activity as BaseLenderContactActivityFragment).lenderContact;
      return (
        <ActivityItemText>
          Removed{' '}
          <ActivityItemLink>
            {contact.first_name} {contact.last_name}
          </ActivityItemLink>{' '}
          ({contact.emails[0].value}) because their email bounced
        </ActivityItemText>
      );
    }

    case DealActivityType.QuoteCreated: {
      const { quote, lender } = activity as BaseQuoteActivityFragment;
      return (
        <ActivityItemText>
          {addHighlightWords('Added a ', search)}
          <ActivityItemLink
            as="span"
            role="button"
            onClick={(e: MouseEvent) => {
              e.preventDefault();
              quoteViewModalVar({ quote, lender });
            }}
          >
            {addHighlightWords(QuoteTypeLabels[quote.type], search)}
          </ActivityItemLink>
          {!lenderId && (
            <>
              {' '}
              from <ActivityLenderLink openMatchModel={openMatchModel} lender={lender} search={search} />
            </>
          )}
        </ActivityItemText>
      );
    }

    default:
      return (
        <ActivityItemText>
          {activity.__typename} {activity.type}
        </ActivityItemText>
      );
  }
};

export default ActivitiesItemBody;
