import React, { Fragment, useEffect, useState, FC, useRef } from 'react';
import { Avatar, Modal } from '../components/ui';
import { Block, FlexRow } from '../components/layout';
import { Button, Feedback, FileInput, Input, FormGroup, Label, InputDismiss, InputGroup, InputDivider } from '../components/form';
import { Card, CardBody, CardHeader } from '../components/card';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import {
  CreateBorrowerContactInput,
  BorrowersElasticSearchDocument,
  BorrowersElasticSearchQuery,
  BorrowerContactFragment,
  useCreateBorrowerContactMutation,
  useGetBorrowerQuery,
  useUpdateBorrowerContactMutation,
  useUploadFileMutation,
  BorrowerFragment,
  useBorrowersSimpleSearchLazyQuery,
  BorrowersSimpleSearchQuery,
} from '~/generated/graphql';
import { Heading, Text } from '../components/type';
import { searchBorrowersVars } from '~/lib/apolloClient';
import { Sticky } from '~/components/layout';
import { Table, TableRow, TableCell } from '~/components/table';
import { useApolloClient } from '@apollo/client';
import { useFormScrollOnError } from '~/hooks/useFormScrollOnError';
import ConfirmModal from '~/modals/ConfirmModal';
import { PatternFormat } from 'react-number-format';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { getBorrowerLogoUrl } from '~/helpers/getBorrowerLogoUrl';
import { Icon, Select } from '~/components/vendor';
import { plus } from '~/components/vendor/Icon/icons';
import BorrowerModal from './BorrowerModal';

type ArrayInput = {
  value: null | string;
  ext?: null | string;
};

type ContactInputs = {
  firstName: string;
  lastName: string;
  emails: { value: string }[];
  phones: ArrayInput[];
  linkedIn: string;
  jobTitle: string;
  avatarId?: string | null;
  notes: string;
  borrower: {
    value: BorrowerFragment;
    label: string;
    type?: 'createBorrower';
  };
};

type BorrowerContactsModalProps = {
  contact?: BorrowerContactFragment | null;
  isOpen: boolean;
  onClose: () => void;
  borrower?: BorrowerFragment | null;
  updateQuery?: ReturnType<typeof useGetBorrowerQuery>['updateQuery'];
  setOpenDeleteContactModal?: (contact: BorrowerContactFragment) => void;
  refetchGetDealMembers?: () => any;
};

const BorrowerContactsModal: FC<BorrowerContactsModalProps> = ({
  contact,
  isOpen,
  onClose,
  borrower,
  updateQuery,
  setOpenDeleteContactModal,
  refetchGetDealMembers,
  ...props
}) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [uploadAvatar, { loading: avatarIsUploading }] = useUploadFileMutation();
  const [createContact, { loading: creatingContact }] = useCreateBorrowerContactMutation({ refetchQueries: ['getBorrowerActivities'] });
  const [updateContact, { loading: updatingContact }] = useUpdateBorrowerContactMutation({ refetchQueries: ['getBorrowerActivities'] });

  const [borrowersSimpleSearch] = useBorrowersSimpleSearchLazyQuery();

  const [tempLogo, setTempLogo] = useState<null | string>(null);
  const [footerButtonPressed, setFooterButtonPressed] = useState(false);
  const [headerButtonPressed, setHeaderButtonPressed] = useState(false);
  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);

  const dividerOption = { type: 'divider', isDivider: true };
  const [createBorrowerOption, setCreateBorrowerOption] = useState({ value: { name: '' }, label: '', type: 'createBorrower' });
  const [borrowers, setBorrowers] = useState<{ value: BorrowersSimpleSearchQuery['borrowersSimpleSearch'][number]; label: string }[]>([]);
  const [borrowerId, setBorrowerId] = useState(borrower?._id);
  const borrowerInputValueRef = useRef<string | null>(null);
  const [openBorrowerModal, setOpenBorrowerModal] = useState(false);

  const contactForm = useForm<ContactInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: contact?.firstName!,
      lastName: contact?.lastName!,
      emails: [],
      phones: [],
      linkedIn: contact?.linkedIn!,
      jobTitle: contact?.jobTitle!,
      avatarId: contact?.avatarId,
      notes: contact?.notes!,
    },
  });

  const formAvatarId = contactForm.watch('avatarId');
  const contactFirstName = contactForm.watch('firstName');
  const contactLastName = contactForm.watch('lastName');

  const isEditOp = contact?._id;

  const {
    formState: { errors: formErrors },
  } = contactForm;
  useFormScrollOnError(formErrors);

  const {
    fields: phonesFields,
    append: appendPhoneField,
    remove: removePhoneField,
  } = useFieldArray({
    control: contactForm.control,
    name: 'phones',
    keyName: 'id',
  });

  const {
    fields: emailsFields,
    append: appendEmailField,
    remove: removeEmailField,
  } = useFieldArray({
    control: contactForm.control,
    name: 'emails',
    keyName: 'id',
  });

  const loadBorrowerOptions = async (query: string) => {
    const { data } = await borrowersSimpleSearch({ variables: { query } });

    const newOptions = (data?.borrowersSimpleSearch ?? []).map((res) => ({ value: res, label: res.name }));
    const newCreateBorrowerOption = { ...createBorrowerOption, value: { name: borrowerInputValueRef.current }, label: borrowerInputValueRef.current };
    const createBorrowerOptions = newOptions?.length > 0 ? [dividerOption, newCreateBorrowerOption] : [newCreateBorrowerOption];
    setBorrowers(newOptions);

    return [...newOptions, ...createBorrowerOptions];
  };

  useEffect(() => {
    contactForm.register('avatarId');
    contactForm.setValue('avatarId', null);

    removePhoneField();
    removeEmailField();

    (contact?.emails?.length ? contact.emails.map((value) => ({ value })) : [{ value: '' }]).forEach((field) => {
      appendEmailField(field, { shouldFocus: false });
    });

    (contact?.phones.length ? contact.phones : [null]).forEach((field) => {
      const parsedValue = field?.trim()?.replaceAll(') ', ')')?.split(' ');
      const value = parsedValue?.[0] ?? null;

      let ext = '';
      for (let index = 1; index < parsedValue?.length!; index++) {
        const element = parsedValue![index];
        ext += element + ' ';
      }
      ext = ext.trim();

      appendPhoneField({ value, ext }, { shouldFocus: false });
    });

    contactForm.setValue('avatarId', contact?.avatarId ?? null);

    contactForm.setValue('firstName', contact?.firstName ?? '');
    contactForm.setValue('jobTitle', contact?.jobTitle ?? '');
    contactForm.setValue('lastName', contact?.lastName ?? '');
    contactForm.setValue('notes', contact?.notes ?? '');
    contactForm.setValue('linkedIn', contact?.linkedIn ?? '');

    contactForm.clearErrors();

    if (borrower) {
      const borrowerOption = { value: borrower, label: borrower.name };
      contactForm.setValue('borrower', borrowerOption);
      setBorrowers([borrowerOption]);
      setBorrowerId(borrower._id);
    } else {
      loadBorrowerOptions('');
    }
  }, [contact, isOpen]);

  async function handleAvatarUpload(files: FileList | File[] | null) {
    try {
      if (files?.[0]) {
        const { data: uploadBorrowerFileData } = await uploadAvatar({ variables: { file: files?.[0] } });

        contactForm.setValue('avatarId', uploadBorrowerFileData!.uploadFile._id);
        setTempLogo(URL.createObjectURL(files?.[0]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function handleFormSubmit(data: ContactInputs) {
    const phones = data.phones?.filter((field) => field.value);
    const emails = data.emails?.filter((field) => field.value);

    const mutationContactValues: CreateBorrowerContactInput = {
      borrowerId: borrowerId!,
      avatarId: data.avatarId,
      firstName: data.firstName?.trim(),
      lastName: data.lastName?.trim(),
      notes: data.notes?.trim(),
      jobTitle: data.jobTitle,
      phones: phones?.filter((phoneField) => phoneField.value).map((phoneField) => `${phoneField.value!.trim()} ${phoneField.ext?.trim()}`.trim()),
      emails: emails.map((emailsField) => emailsField.value.trim().toLowerCase()),
      linkedIn: data.linkedIn,
    };

    if (isEditOp) {
      await updateContact({
        variables: {
          contact: { _id: contact._id, ...mutationContactValues },
        },
        update: (cache, res) => {
          const updatedContact = res?.data?.updateBorrowerContact;
          if (updatedContact) {
            updateQuery?.((prevBorrowerQueryData) => {
              const prevCompany = prevBorrowerQueryData.getBorrower!;
              const contacts = prevCompany.contacts.map((c) => {
                if (c._id === contact._id) {
                  return {
                    ...c,
                    ...mutationContactValues,
                    updatedAt: new Date(),
                  };
                }
                return c;
              });

              const borrowersQuery = client.readQuery<BorrowersElasticSearchQuery>({
                query: BorrowersElasticSearchDocument,
                variables: searchBorrowersVars(),
              });

              if (borrowersQuery) {
                client.writeQuery<BorrowersElasticSearchQuery>({
                  query: BorrowersElasticSearchDocument,
                  variables: searchBorrowersVars(),
                  data: {
                    borrowersElasticSearch: {
                      ...borrowersQuery.borrowersElasticSearch,
                      borrowers: borrowersQuery.borrowersElasticSearch.borrowers.map((b) => {
                        if (prevCompany._id === b._id) {
                          return {
                            ...b,
                            contacts,
                            updatedAt: new Date(),
                          };
                        }
                        return b;
                      }),
                    },
                  },
                });
              }
              return {
                ...prevBorrowerQueryData,
                getBorrower: {
                  ...prevCompany,
                  contacts,
                },
              };
            });
            showGlobalAlert('Contact updated');
          }
        },
      });
    } else {
      await createContact({
        variables: {
          contact: mutationContactValues,
        },
        update: (client, res) => {
          const newContact = res?.data?.createBorrowerContact;
          if (newContact) {
            updateQuery?.((prevBorrowerQueryData) => {
              const prevCompany = prevBorrowerQueryData?.getBorrower!;
              const contacts = [...prevCompany.contacts, newContact];
              const borrowersQuery = client.readQuery<BorrowersElasticSearchQuery>({
                query: BorrowersElasticSearchDocument,
                variables: searchBorrowersVars(),
              });

              if (borrowersQuery) {
                client.writeQuery<BorrowersElasticSearchQuery>({
                  query: BorrowersElasticSearchDocument,
                  variables: searchBorrowersVars(),
                  data: {
                    borrowersElasticSearch: {
                      ...borrowersQuery.borrowersElasticSearch,
                      borrowers: borrowersQuery.borrowersElasticSearch.borrowers.map((b) => {
                        if (prevCompany._id === b._id) {
                          return {
                            ...b,
                            contacts,
                            updatedAt: new Date(),
                          };
                        }
                        return b;
                      }),
                    },
                  },
                });
              }
              return {
                getBorrower: {
                  ...prevCompany,
                  contacts,
                  updatedAt: new Date(),
                },
              };
            });
            showGlobalAlert('Contact has been created');
          }
        },
      });
      refetchGetDealMembers?.();
    }

    setHeaderButtonPressed(false);
    setFooterButtonPressed(false);
    onClose();
  }

  function onModalClose() {
    const formValues = contactForm.getValues();

    const contactEmails = contact?.emails ?? [];
    const formEmails = formValues.emails.map((e) => e.value.trim()).filter(Boolean);
    const formOldEmails = contactEmails.map((e) => e.trim());

    const contactPhones = contact?.phones ?? [];
    const formPhones = (formValues.phones || [])
      .filter((phoneField) => phoneField?.value || phoneField?.ext)
      .map((phone) => `${phone?.value} ${phone?.ext}`?.trim());
    const formOldPhones = contactPhones.map((e) => e.trim().replace(') ', ')'));

    const isFormChanged =
      formValues?.avatarId !== (contact?.avatarId ?? null) ||
      JSON.stringify(formEmails) !== JSON.stringify(formOldEmails) ||
      JSON.stringify(formPhones) !== JSON.stringify(formOldPhones) ||
      formValues?.firstName !== (contact?.firstName ?? '') ||
      formValues?.lastName !== (contact?.lastName ?? '') ||
      (formValues?.jobTitle?.trim() ?? '') !== (contact?.jobTitle ?? '') ||
      (formValues?.notes?.trim() || '') !== (contact?.notes?.trim() || '') ||
      formValues?.linkedIn !== (contact?.linkedIn ?? '');

    if (isFormChanged) {
      setConfirmCloseModelOpened(true);
    } else {
      onClose();
    }
  }

  return (
    <>
      <Modal layout="horizontal" isOpen={isOpen} onClose={onModalClose} {...props}>
        <Card id="contactsModalScrollable" isModalContent>
          <form onSubmit={contactForm.handleSubmit(handleFormSubmit)}>
            <Sticky rootId="contactsModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" type="button" variant="white" utils={{ my: -1 }} onClick={onModalClose}>
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{contact?._id ? 'Edit Contact' : 'Add Contact'}</Heading>
                  <Button
                    size="sm"
                    utils={{ my: -1 }}
                    type="submit"
                    isLoading={headerButtonPressed && (creatingContact || updatingContact)}
                    disabled={creatingContact || updatingContact}
                    onClick={() => setHeaderButtonPressed(true)}
                  >
                    {isEditOp ? 'Save' : 'Add'}
                  </Button>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Personal Info
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Email</Label>
                  </TableCell>
                  <TableCell>
                    {emailsFields.map((field, index) => {
                      return (
                        <FormGroup utils={{ position: 'relative', mt: index > 0 ? 4 : 0, mb: 0 }} key={field.id} isValid={!formErrors.emails?.[index]}>
                          <Controller
                            name={`emails.${index}.value`}
                            render={({ field: { value, ...inputProps } }) => (
                              <Input _size="sm" type="email" placeholder="Email address" value={value!} {...inputProps} />
                            )}
                            control={contactForm.control}
                            defaultValue={field.value ?? ''}
                            rules={{ required: true }}
                          />
                          {emailsFields.length > 1 && <InputDismiss role="button" onClick={() => removeEmailField(index)} />}
                          {formErrors.emails?.[index] && <Feedback>This field is required.</Feedback>}
                        </FormGroup>
                      );
                    })}
                  </TableCell>
                  <TableCell span="auto" utils={{ ml: -8 }}>
                    <Text
                      utils={{ fontSize: 'sm', color: 'primary' }}
                      role="button"
                      onClick={() => appendEmailField({ value: '' })}
                      style={{ visibility: emailsFields?.length < 2 ? 'visible' : 'hidden' }}
                    >
                      + Add
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Name</Label>
                  </TableCell>
                  <FormGroup as={TableCell} isValid={!formErrors.firstName} utils={{ mb: 0 }}>
                    <Controller
                      name="firstName"
                      render={({ field }) => <Input _size="sm" placeholder="First" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.firstName ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.firstName?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                  <FormGroup as={TableCell} isValid={!formErrors.lastName} utils={{ mb: 0 }}>
                    <Controller
                      name="lastName"
                      render={({ field }) => <Input _size="sm" placeholder="Last" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.lastName ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.lastName?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Company</Label>
                  </TableCell>
                  <FormGroup as={TableCell} isValid={!formErrors.borrower} utils={{ mb: 0 }}>
                    <Controller
                      name="borrower"
                      render={({ field: { ref, onChange, ...inputProps } }) => (
                        <Select
                          size="sm"
                          instanceId="borrower"
                          placeholder="Select a borrower"
                          loadOptions={loadBorrowerOptions}
                          defaultOptions={[...borrowers, dividerOption, createBorrowerOption] as any[]}
                          onOptionRender={({ value, type }) => {
                            if (value?._id) {
                              return (
                                <Fragment key={value._id}>
                                  <Avatar
                                    size="sm"
                                    alt={value.name}
                                    src={getBorrowerLogoUrl(value)}
                                    imageProps={{ utils: { border: 1 } }}
                                    utils={{ borderRadius: 'sm', mr: 5 }}
                                  />
                                  {value.name}
                                </Fragment>
                              );
                            }
                            if (type === 'createBorrower') {
                              return (
                                <>
                                  <Avatar
                                    size="sm"
                                    alt={<Icon icon={plus} size="1em" utils={{ display: 'inline-flex' }} />}
                                    utils={{ borderRadius: 'sm', fontSize: 'lg', color: 'primary', mr: 5 }}
                                    titleProps={{ utils: { bgColor: 'primary10' } }}
                                  />
                                  Create new borrower
                                </>
                              );
                            }
                          }}
                          onChange={(option) => {
                            if (option!.type === 'createBorrower') {
                              setOpenBorrowerModal(true);
                            } else {
                              onChange(option);
                              setBorrowerId(option!.value._id);
                            }
                          }}
                          onInputChange={(value, { action }) => {
                            if (action === 'input-change') {
                              borrowerInputValueRef.current = value;
                              setCreateBorrowerOption((borrower) => ({ ...borrower, value: { name: value }, label: value }));
                            }
                          }}
                          async
                          {...inputProps}
                        />
                      )}
                      control={contactForm.control}
                      defaultValue={borrower ? { value: borrower, label: borrower.name } : undefined}
                      rules={{ required: true }}
                    />
                    {formErrors.borrower?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Job Title</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="jobTitle"
                      render={({ field }) => <Input _size="sm" placeholder="Head of Operations" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.jobTitle ?? ''}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="calc(30% - 8px)">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Avatar</Label>
                  </TableCell>
                  <TableCell>
                    <Avatar
                      src={formAvatarId ? (tempLogo ?? `${getBaseApiUrl()}/api/images/${formAvatarId}?width=96&height=96`) : null}
                      alt={`${contactFirstName}${contactLastName}`?.length > 0 ? `${contactFirstName} ${contactLastName}` : '?'}
                      utils={{ mr: 5, borderRadius: 'sm' }}
                    />
                    <FileInput inputProps={{ id: 'uploadContactsAvatar', accept: '.png,.jpg,.jpeg', onChange: (e) => handleAvatarUpload(e.target.files) }}>
                      <Button as="span" size="sm" variant="white" isLoading={avatarIsUploading}>
                        Upload
                      </Button>
                    </FileInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Phone</Label>
                  </TableCell>
                  <TableCell>
                    {phonesFields.map((field, index) => {
                      return (
                        <FormGroup key={field.id} utils={{ position: 'relative', mt: index > 0 ? 4 : 0, mb: 0 }}>
                          <InputGroup size="sm">
                            <Controller
                              name={`phones.${index}.value`}
                              render={({ field }) => (
                                <Input as={PatternFormat} format="(###) ###-####" mask=" " type="tel" placeholder="(123) 456-7890" {...field} />
                              )}
                              control={contactForm.control}
                            />
                            <InputDivider utils={{ px: 3 }} />
                            <Controller
                              name={`phones.${index}.ext`}
                              render={({ field: { value, ...field } }) => (
                                <Input utils={{ maxWidth: '40%' }} placeholder="Ext. ####" value={value!} {...field} />
                              )}
                              control={contactForm.control}
                            />
                          </InputGroup>
                          {phonesFields.length > 1 && <InputDismiss role="button" onClick={() => removePhoneField(index)} />}
                        </FormGroup>
                      );
                    })}
                  </TableCell>
                  <TableCell span="auto" utils={{ ml: -8 }}>
                    <Text
                      utils={{ fontSize: 'sm', color: 'primary' }}
                      role="button"
                      onClick={() => appendPhoneField({ value: '' }, { shouldFocus: false })}
                      style={{ visibility: phonesFields?.length < 2 ? 'visible' : 'hidden' }}
                    >
                      + Add
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>LinkedIn URL</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="linkedIn"
                      render={({ field }) => <Input _size="sm" type="url" placeholder="Enter a LinkedIn URL" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.linkedIn ?? ''}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Note
              </Heading>
              <FormGroup utils={{ mb: 9 }}>
                <Controller
                  name="notes"
                  render={({ field }) => <Input as={Textarea as React.ElementType} minRows={4} placeholder="Leave a note..." {...field} />}
                  control={contactForm.control}
                  defaultValue={contact?.notes ?? ''}
                />
              </FormGroup>
              <Button
                isBlock={true}
                type="submit"
                isLoading={footerButtonPressed && (creatingContact || updatingContact)}
                disabled={creatingContact || updatingContact}
                onClick={() => setFooterButtonPressed(true)}
              >
                {contact?._id ? 'Save Changes' : 'Add Contact'}
              </Button>
              {contact?._id && (
                <Block utils={{ textAlign: 'center', mt: 7 }}>
                  <>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setOpenDeleteContactModal!(contact as BorrowerContactFragment)}
                      role="button"
                    >
                      Delete Contact
                    </Text>
                  </>
                </Block>
              )}
            </CardBody>
          </form>
        </Card>
      </Modal>

      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have unsaved changes"
        text="Are you sure you want to abandon your edits?"
      />

      <BorrowerModal
        isOpen={openBorrowerModal}
        onClose={() => {
          setOpenBorrowerModal(false);
        }}
        borrower={createBorrowerOption.value}
        setBorrower={(borrower) => {
          const borrowerOption = {
            value: borrower,
            label: borrower.name,
          };
          setBorrowers([borrowerOption]);
          setBorrowerId(borrower._id);
          contactForm.setValue('borrower', borrowerOption);
        }}
        updateQuery={updateQuery}
      />
    </>
  );
};

export default BorrowerContactsModal;
