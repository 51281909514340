import React, { useEffect, useRef, useState } from 'react';
import { pluralize } from '~/helpers/pluralize';
import { TopnavLink } from '../nav';
import Circle from './Circle';
import getRadiusBounds from './helpers/getRadiusBounds';
import Map, { Props as MapProps } from 'google-map-react';
import Marker from './Marker';
import MarkerIconDeal from './MarkerIconDeal';
import MarketMapContainer from './MarketMapContainer';
import MarketSubnav from './MarketSubnav';
import { GOOGLE_MAPS_API_KEY } from '../vendor/SelectAddress';

export const radiuses = [1, 3, 5];

type MarketMapProps = MapProps & {
  title: string;
  radius?: number;
  showCircle?: boolean;
  showSubnav?: boolean;
  onRadiusChange?: (radius: number) => void;
};

const MarketMap = ({
  defaultCenter,
  center: incomingCenter,
  defaultZoom = 10,
  radius: incomingRadius,
  title,
  showCircle,
  showSubnav,
  onRadiusChange,
  ...props
}: MarketMapProps) => {
  const mapRef = useRef<Element | null>(null);
  const mapsRef = useRef<any>(null);

  const [radius, setRadius] = useState(incomingRadius ?? radiuses[0]);
  const [center, setCenter] = useState(defaultCenter ?? incomingCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  useEffect(() => {
    if (incomingRadius && incomingRadius !== radius) {
      handleRadiusChange(incomingRadius);
    }
  }, [incomingRadius]);

  useEffect(() => {
    if (incomingCenter) {
      setCenter(incomingCenter);
    }
  }, [incomingCenter]);

  function handleRadiusChange(radius: number) {
    if (center && mapRef.current) {
      const { center: mapCenter, zoom } = getRadiusBounds(center, radius, mapsRef.current, mapRef.current);
      setRadius(radius);
      setCenter(mapCenter);
      setZoom(zoom);
      onRadiusChange?.(radius);
    }
  }

  function handleZoomAnimation(newZoom: number) {
    if (zoom !== newZoom) {
      setZoom(newZoom);
    }
  }

  if (!center) {
    return;
  }

  return (
    <MarketMapContainer>
      <Map
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        onZoomAnimationStart={(zoom: number) => handleZoomAnimation(zoom)}
        onZoomAnimationEnd={(zoom: number) => handleZoomAnimation(zoom)}
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        yesIWantToUseGoogleMapApiInternals={true}
        center={center}
        zoom={zoom}
        onGoogleApiLoaded={({ map, maps, ref }) => {
          if (map && ref) {
            mapRef.current = ref;
            mapsRef.current = maps;

            const { center: mapCenter, zoom } = getRadiusBounds(center, radius, maps, ref);
            setCenter(mapCenter);
            setZoom(zoom > 15 ? 15 : zoom);
          }
        }}
        {...props}
      >
        {/* Circle */}
        {showCircle && <Circle lat={center.lat} lng={center.lng} zoom={zoom} radius={radius} />}

        {/* Deal */}
        <Marker lat={center.lat} lng={center.lng}>
          <MarkerIconDeal>{title}</MarkerIconDeal>
        </Marker>
      </Map>

      {/* Subnab */}
      {showSubnav && (
        <MarketSubnav>
          {radiuses.map((rad) => (
            <TopnavLink key={rad} onClick={() => handleRadiusChange(rad)} isActive={radius === rad} role="button">
              {rad} {pluralize(rad, 'Mile', 'Miles')}
            </TopnavLink>
          ))}
        </MarketSubnav>
      )}
    </MarketMapContainer>
  );
};

export default MarketMap;
