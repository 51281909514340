import { GetDealsForReportsQuery } from '~/generated/graphql';
import getDealData from './getDealData';

type DealsData = {
  amountFinanced: number;
  directRate: number;
  whiteLabelRate: number;
  fee: number;
  avgFee: number;
  margin: number;
  grossRevenue: number;
  closedIn: number;
  closedInEst: number;
  closedInDays: number;
  closedInDaysEst: number;
  avgClosedIn: number;
  avgClosedInEst: number;
  avgClosedInDays: number;
  avgClosedInDaysEst: number;
  dealsCount: number;
};

export default function getDealsData(deals: NonNullable<GetDealsForReportsQuery['getReportDeals']['deals']>, recipientId?: string): DealsData {
  const dealsCount = deals.length;
  const amountFinanced = deals?.map((deal) => getDealData(deal, recipientId)?.amountFinanced).reduce((a: number, b: number) => a + b, 0);
  const directRate = deals?.map((deal) => getDealData(deal, recipientId)?.directRate).reduce((a: number, b: number) => a + b, 0);
  const whiteLabelRate = deals?.map((deal) => getDealData(deal, recipientId)?.whiteLabelRate).reduce((a: number, b: number) => a + b, 0);
  const fee = deals?.map((deal) => getDealData(deal, recipientId)?.fee).reduce((a: number, b: number) => a + b, 0);
  const avgFee = (directRate + whiteLabelRate) / dealsCount;
  const totalFee = deals?.map((deal) => getDealData(deal)?.fee).reduce((a: number, b: number) => a + b, 0);
  const margin = isNaN((fee / totalFee) * 100) ? 0 : Math.round((fee / totalFee) * 100);
  const grossRevenue = deals?.map((deal) => getDealData(deal, recipientId)?.grossRevenue).reduce((a: number, b: number) => a + b, 0);
  const closedIn = deals?.map((deal) => getDealData(deal, recipientId)?.closedIn).reduce((a: number, b: number) => a + b, 0);
  const closedInEst = deals?.map((deal) => getDealData(deal, recipientId)?.closedInEst).reduce((a: number, b: number) => a + b, 0);
  const closedInDays = Math.round(closedIn / (1000 * 60 * 60 * 24));
  const closedInDaysEst = Math.round(closedInEst / (1000 * 60 * 60 * 24));
  const avgClosedIn = dealsCount ? closedIn / dealsCount : 0;
  const avgClosedInEst = dealsCount ? closedInEst / dealsCount : 0;
  const avgClosedInDays = Math.round(avgClosedIn / (1000 * 60 * 60 * 24));
  const avgClosedInDaysEst = Math.round(avgClosedInEst / (1000 * 60 * 60 * 24));

  return {
    amountFinanced,
    directRate,
    whiteLabelRate,
    fee,
    avgFee,
    margin,
    grossRevenue,
    closedIn,
    closedInEst,
    closedInDays,
    closedInDaysEst,
    avgClosedIn,
    avgClosedInEst,
    avgClosedInDays,
    avgClosedInDaysEst,
    dealsCount,
  };
}
