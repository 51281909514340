import React, { FC, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Textarea from 'react-textarea-autosize';

import { Card, CardBody } from '../components/card';
import { Button, Feedback, Form, Input, FormGroup, Label } from '../components/form';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import formatMoney from '../helpers/formatMoney';
import { getBaseApiUrl } from '../helpers/getBaseUrl';
import { ContactContext } from '../layouts/FrontLayout';

type ContactModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ContactModal: FC<ContactModalProps> = ({ isOpen, onClose, ...props }) => {
  const contactContext = useContext(ContactContext);

  const [isLoading, setLoading] = useState(false);

  const form = useForm({
    reValidateMode: 'onChange',
  });
  const {
    formState: { errors: formErrors },
    handleSubmit,
    control,
  } = form;

  useEffect(() => {
    if (isOpen && !form.getValues('email')) {
      form.setValue('email', contactContext.contactForm.email ?? '');
    }
  }, [isOpen, form]);

  const submitForm = async (data: any) => {
    setLoading(true);
    await fetch(`${getBaseApiUrl()}/api/email/landingContactEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data,
      }),
    });
    setLoading(false);
    contactContext.setDisplayAlert({ visible: true, text: 'Message sent! We’ll contact you within 24 hours.' });
    contactContext.setContactForm({
      email: '',
      lenderMatchingForm: {},
    });
    contactContext.setIsContactModalOpen(false);
  };

  const lenderMatchingForm = contactContext.contactForm.lenderMatchingForm;
  const isMatchingFormFilled = lenderMatchingForm?.address;

  let defaultBasics = '';
  if (isMatchingFormFilled) {
    defaultBasics = `- Location: ${lenderMatchingForm.address} \n- Debt Request: ${formatMoney(lenderMatchingForm.loanRequestAmount)}\n- Asset Type: ${
      lenderMatchingForm.assetType.label
    }\n- Deal Type: ${lenderMatchingForm.loanType.label}\n- Capital Type: ${lenderMatchingForm.financingType.label}`;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="mail" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>
            Find your{' '}
            <Text as="span" utils={{ color: 'primary' }}>
              ideal funding source
            </Text>
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>
            Share the basics of your deal and our capital markets team will get your dealroom started.
          </Text>
          <Form onSubmit={handleSubmit(submitForm)}>
            <FormGroup isValid={!formErrors.name}>
              <Label htmlFor="contactModalName">Your name</Label>
              <Controller
                name="name"
                render={({ field }) => (
                  <Input id="contactModalName" type="text" placeholder="Your name" defaultValue={contactContext.contactForm.email} {...field} />
                )}
                control={control}
                defaultValue={defaultBasics}
                rules={{ required: true }}
              />

              {formErrors.name && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <FormGroup isValid={!formErrors.email}>
              <Label htmlFor="contactModalEmail">Your email</Label>
              <Controller
                name="email"
                render={({ field }) => (
                  <Input id="contactModalEmail" type="email" placeholder="Your email" defaultValue={contactContext.contactForm.email} {...field} />
                )}
                control={control}
                defaultValue={defaultBasics}
                rules={{ required: true }}
              />

              {formErrors.email && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <FormGroup isValid={!formErrors.basics}>
              <Label htmlFor="contactModalBasics">What are your deal's basics?</Label>
              <Controller
                name="basics"
                render={({ field: { onChange, value } }) => (
                  <Input
                    as={Textarea as React.ElementType}
                    minRows={4}
                    id="contactModalBasics"
                    name="basics"
                    value={value}
                    onChange={onChange}
                    defaultValue={defaultBasics}
                    placeholder="The location, asset type, capital structure, and financing amount are helpful to get us started."
                  />
                )}
                control={control}
                defaultValue={defaultBasics}
                rules={{ required: true }}
              />

              {formErrors.basics && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <Button variant="primary" isBlock disabled={isLoading} isLoading={isLoading}>
              Send to WelcomeLend
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default ContactModal;
