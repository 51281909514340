import React, { useState, useRef } from 'react';
import TreeCollapse from './TreeCollapse';
import TreeDraggable from './TreeDraggable';
import { BlockProps, ItemProps } from './types';

function TreeBlock<T extends ItemProps>({
  tree,
  flatTree,
  id,
  pid,
  index,
  level,
  itemHeight,
  itemOffset,
  renderItem,
  onCollapse,
  onExpand,
  draggableProps,
  expandFolders,
  ...props
}: BlockProps<T>) {
  const item = tree.items[id];
  const order = flatTree.indexOf(id);
  const isCollapseSmooth = useRef(false);

  // States
  const [isExpanded, setExpanded] = useState(item.isExpanded);
  const isFolderExpanded = expandFolders ? true : isExpanded;

  //
  // Handlers
  //

  function handleCollapse(id: string, isSmooth = true) {
    isCollapseSmooth.current = isSmooth;
    setExpanded(false);
  }

  function handleExpand(id: string, isSmooth = true) {
    isCollapseSmooth.current = isSmooth;
    setExpanded(true);

    // We need to update things two times to make sure
    // items positioning is updated once animaiton is finished
    onExpand(id);
  }

  function handleExpanded() {
    if (expandFolders) {
      return;
    }

    onExpand(id);
    isCollapseSmooth.current = false;
  }

  function handleCollapsed() {
    onCollapse(id);
    isCollapseSmooth.current = false;
  }

  //
  // Return
  //

  // Quit if item is not visible
  if (order === -1) return null;

  return (
    <div {...props}>
      <TreeDraggable
        flatTree={flatTree}
        item={item}
        id={id}
        pid={pid}
        index={index}
        order={order}
        level={level}
        itemHeight={itemHeight}
        itemOffset={itemOffset}
        onCollapse={handleCollapse}
        onExpand={handleExpand}
        isExpanded={Boolean(isExpanded)}
        tabIndex={order + 1}
        {...draggableProps}
      >
        {({ provided, snapshot }) => renderItem({ item, level, order, provided, snapshot })}
      </TreeDraggable>
      <TreeCollapse isSmooth={isCollapseSmooth.current} isExpanded={Boolean(isFolderExpanded)} onExpand={handleExpanded} onCollapse={handleCollapsed}>
        {item.children?.map((cid: string, index: number) => (
          <TreeBlock
            tree={tree}
            flatTree={flatTree}
            id={cid}
            pid={id}
            index={index}
            level={level + 1}
            itemHeight={itemHeight}
            itemOffset={itemOffset}
            renderItem={renderItem}
            onCollapse={onCollapse}
            onExpand={onExpand}
            draggableProps={draggableProps}
            expandFolders={expandFolders}
            key={cid}
          />
        ))}
      </TreeCollapse>
    </div>
  );
};

export default TreeBlock;
