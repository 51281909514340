import { Base } from '~/components/layout';
import { CustomTheme } from '~/@types/styled-components';
import styled, { css } from 'styled-components';

type PreviewModalRightProps = {
  status?: keyof CustomTheme['statusColors'];
};

const PreviewModalRight = styled(Base) <PreviewModalRightProps>`
  ${({ status, theme }) => css`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${theme.spacers[10]}px ${theme.spacers[7]}px;
    background-color: ${theme.previewModalRightBg};
    ${status ? `
    border-top: ${theme.borderWidth * 3}px solid ${theme.colors.gray600};
    border-top-color: ${theme.statusColors[status]};
    ` : ''}
    border-radius: ${theme.cardBorderRadius}px;
    transform: translateX(100%);
    transition: ${theme.transition.lg};
    transition-property: transform, -webkit-transform;
    pointer-events: auto;

    @media (min-width: ${theme.breakpoints.desktop}px) {
      flex: 0 0 ${theme.previewModalRightWidth}px;
      max-width: ${theme.previewModalRightWidth}px;
      padding: ${theme.spacers[10]}px;

      && {
        .ReactCollapse--collapse {
          height: auto;
        }
      }
    }
  `}
`;

export default PreviewModalRight;
