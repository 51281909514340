import React, { FC } from 'react';
import { Avatar, Modal, Divider } from '~/components/ui';
import { Button } from '~/components/form';
import { Heading, Text } from '~/components/type';
import { Block, FlexRow } from '~/components/layout';
import { Card, CardBody } from '~/components/card';
import { Icon } from '~/components/vendor';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { DealWelcomelendTeamAdvisor } from '~/generated/graphql';
import { getBaseApiUrl } from '~/helpers';
import CopyToClipboard from '~/containers/CopyToClipboard';
import { copy } from '~/components/vendor/Icon/icons';

type LenderInterestedModalProps = {
  showJoinDeal?: boolean;
  isOpen: boolean;
  onClose: () => void;
  contact?: DealWelcomelendTeamAdvisor | null;
};

const LenderInterestedModal: FC<LenderInterestedModalProps> = ({ contact, showJoinDeal, isOpen, onClose }) => {
  const router = useRouter();

  const avatarSrc = contact?.fileId ? `${getBaseApiUrl()}/api/file/${contact.fileId}` : undefined;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card icon="box" variant="success" isModalContent>
        <CardBody>
          <Heading utils={{ textAlign: 'center', fontSize: 'xl', mb: 3 }}>
            Good to know you’re{' '}
            <Text as="span" utils={{ color: 'success' }}>
              interested!
            </Text>{' '}
            We’ll be in touch shortly.
          </Heading>
          <Divider size="sm" utils={{ my: 8 }} />
          <Block utils={{ mb: 8 }}>
            <Heading as="h4" utils={{ mb: 1 }}>
              Prefer to reach out to us?
            </Heading>
            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>Feel free to contact me immediately to discuss.</Text>
          </Block>
          {contact && (
            <FlexRow utils={{ alignItems: 'center', mb: 8 }}>
              <Block utils={{ mr: 8 }}>
                <Heading as="h4" utils={{ mb: 1 }}>
                  {contact.fullName}
                </Heading>
                <Text utils={{ wordWrap: 'break-word', fontSize: 'sm', color: 'gray700', mb: 1 }}>
                  {contact.email}
                  <CopyToClipboard text={contact.email} message="Email address copied">
                    <Icon icon={copy} size="1em" utils={{ color: 'gray500', ml: 3 }} role="button" />
                  </CopyToClipboard>
                </Text>
                {contact.phone && <Text utils={{ fontSize: 'sm', color: 'gray700' }}>{contact.phone}</Text>}
              </Block>
              <Avatar src={avatarSrc} size="lg" alt={contact.fullName} utils={{ ml: 'auto' }} />
            </FlexRow>
          )}
          {showJoinDeal && (
            <>
              <Divider utils={{ my: 8 }} />
              <Block utils={{ mb: 8 }}>
                <Heading as="h4" utils={{ mb: 1 }}>
                  Join this deal to get full access.
                </Heading>
                <Text utils={{ fontSize: 'sm', color: 'gray700' }}>View deal documents, get updates, and more.</Text>
              </Block>
              <Link href={`/sign-up?${router.asPath.split('?')[1]}`} legacyBehavior>
                <Button isBlock>Join deal</Button>
              </Link>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default LenderInterestedModal;
