import Base from '~/components/layout/Base';
import styled from 'styled-components';

const MarketMapContainer = styled(Base)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default MarketMapContainer;
