import React, { FC, useEffect, useState } from 'react';
import { Button, Feedback } from '~/components/form';
import { Card, CardBody } from '~/components/card';
import { Close, Modal } from '~/components/ui';
import { FormGroup, Input, Label, Form } from '~/components/form';
import { Heading } from '~/components/type';
import Textarea from 'react-textarea-autosize';
import { Select } from '~/components/vendor';
import { DealLenderPassingContactsOption, dealLenderPassingContactsOptions } from '~/data/dealLenderStatusOptions';
import { Controller, useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { dealLenderStatusVar } from '~/lib/apolloClient';
import { DealLenderStatus } from '~/generated/graphql';

type FormData = {
  passedStatusReason: string;
  contactsAction: DealLenderPassingContactsOption;
};

const LenderPassingAdminModal: FC = () => {
  const [isLoading, setLoading] = useState(false);

  const { lender, onSubmit } = useReactiveVar(dealLenderStatusVar);
  const onClose = () => dealLenderStatusVar({});

  const form = useForm<FormData>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    form.setValue('passedStatusReason', lender?.passedStatusReason ?? '');
    form.setValue('contactsAction', dealLenderPassingContactsOptions[0]);
  }, [lender, form]);

  const onSubmitForm = async (data: FormData) => {
    setLoading(true);
    await onSubmit!(data.passedStatusReason, data.contactsAction.value);
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={lender?.status === DealLenderStatus.Passed} onClose={onClose}>
      <Card icon="box" isModalContent>
        <CardBody>
          <Close onClick={onClose} desktop={{ display: 'none' }} isAbsolute />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 8 }}>Why Is The Lender Passing?</Heading>
          <Form onSubmit={form.handleSubmit(onSubmitForm)}>
            <FormGroup isFloating isValid={!form.formState.errors.passedStatusReason}>
              <Label htmlFor="passedStatusReason">Reason for passing</Label>
              <Controller
                name="passedStatusReason"
                render={({ field: { onChange, value } }) => (
                  <Input
                    as={Textarea as React.ElementType}
                    minRows={4}
                    id="passedStatusReason"
                    name="passedStatusReason"
                    value={value}
                    onChange={onChange}
                    placeholder="Provide a reason for passing..."
                    autoFocus
                  />
                )}
                control={form.control}
                rules={{ required: true }}
              />
              {form.formState.errors.passedStatusReason && <Feedback>This field is required.</Feedback>}
            </FormGroup>
            <FormGroup isFloating>
              <Label htmlFor="contactsAction">What to do with associated contacts?</Label>
              <Controller
                name="contactsAction"
                render={({ field: { onChange, value } }) => (
                  <Select
                    instanceId="contactsAction"
                    options={dealLenderPassingContactsOptions}
                    value={value}
                    onChange={onChange}
                    defaultValue={dealLenderPassingContactsOptions[0]}
                  />
                )}
                control={form.control}
              />
            </FormGroup>
            <Button utils={{ mt: 7 }} isBlock disabled={isLoading} isLoading={isLoading}>
              Confirm
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default LenderPassingAdminModal;
