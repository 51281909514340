import React, { FC, useMemo } from 'react';
import { Block, FlexRow } from '~/components/layout';
import { Text } from '~/components/type';
import { Avatar } from '~/components/ui';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';
import { Select } from '~/components/vendor';
import { DealMemberFragment, GetUsersForFolderQuery, useGetUsersForFolderQuery } from '~/generated/graphql';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { pluralize } from '~/helpers/pluralize';

const adminOption = { value: 'adminsOnly', label: 'WelcomeLend Team', isWL: true };

export type FolderAccessSelectOption = {
  value: string;
  label: string;
  data: GetUsersForFolderQuery['getUsersForFolder'][number] | DealMemberFragment;
};

type FolderAccessSelectExtendedOption = FolderAccessSelectOption | typeof adminOption;

type FolderAccessSelectItemProps = {
  data: GetUsersForFolderQuery['getUsersForFolder'][number] | DealMemberFragment;
};

export const FolderAccessSelectItem: FC<FolderAccessSelectItemProps> = ({ data }) => {
  if (data?.__typename === 'DealMember' || data?.__typename === 'DealUserAndInvitationItem') {
    return (
      <FlexRow utils={{ alignItems: 'center', width: '100%' }}>
        <Block utils={{ mr: 5 }}>
          <UserAvatar user={data.user ?? { email: data.email }} imageProps={{ utils: { border: 1 } }} size="xs" utils={{ borderRadius: 'rounded' }} />
        </Block>
        <Text style={{ flex: 1 }} utils={{ textTruncate: true }}>
          {data.user?.fullName ?? data.email}
        </Text>
      </FlexRow>
    );
  }
  if (data?.__typename === 'LenderForFolder') {
    return (
      <FlexRow utils={{ alignItems: 'center', width: '100%' }}>
        <Block utils={{ mr: 5 }}>
          <Avatar src={getLogoUrl(data)} alt={data.name} imageProps={{ utils: { border: 1 } }} size="xs" utils={{ borderRadius: 'xs' }} />
        </Block>
        <Text style={{ flex: 1 }} utils={{ mr: 5, textTruncate: true }}>
          {data.name}
        </Text>
        <Text utils={{ color: 'gray700', fontSize: 'xs' }}>
          {data.members} {pluralize(data.members, 'member')}
        </Text>
      </FlexRow>
    );
  }
  return (
    <FlexRow
      utils={{ alignItems: 'center', width: '100%' }}
      data-tooltip-id="GlobalTooltip"
      data-tooltip-content="Folder will only be visible to the WelcomeLend team and removes access from all other members."
      data-tooltip-place="right"
    >
      <Block utils={{ mr: 5 }}>
        <Avatar src={'/img/brand/brand.svg'} alt="WelcomeLend" size="xs" imageProps={{ utils: { bgColor: 'transparent' } }} utils={{ borderRadius: 'xs' }} />
      </Block>
      <Text style={{ flex: 1 }} utils={{ mr: 5, textTruncate: true }}>
        WelcomeLend Team
      </Text>
    </FlexRow>
  );
};

type FolderAccessSelectProps = {
  dealId: string;
  isAdmin: boolean;
  usersWithAccess: (GetUsersForFolderQuery['getUsersForFolder'][number] | DealMemberFragment)[];
  adminsOnlyAccess?: boolean;
  onChange: (usersWithAccess: (GetUsersForFolderQuery['getUsersForFolder'][number] | DealMemberFragment)[], adminsOnlyAccess: boolean) => void;
};

const FolderAccessSelect: FC<FolderAccessSelectProps> = ({ dealId, isAdmin, usersWithAccess, adminsOnlyAccess, onChange }) => {
  const { data: candidatesData } = useGetUsersForFolderQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
    skip: !isAdmin,
  });

  const membersSelectOptions = useMemo(() => {
    return (
      (candidatesData?.getUsersForFolder?.map((data) => {
        if (data.__typename === 'DealMember') {
          return { value: data.user?._id ?? data._id, label: data.user?.fullName ?? data.email, data };
        }
        if (data.__typename === 'LenderForFolder') {
          return { value: data.name, label: data.name, data };
        }
      }) as FolderAccessSelectExtendedOption[]) ?? []
    );
  }, [candidatesData]);
  const dropdownOptions = useMemo(() => {
    const options: FolderAccessSelectExtendedOption[] = [...membersSelectOptions];
    options.unshift({
      // @ts-ignore
      dropdownTitle: {
        default: (
          <Text
            utils={{ fontWeight: 'bold', textTransform: 'uppercase', color: 'black', fontSize: '2xs', pl: 6, mt: 4, mb: 4 }}
            style={{ letterSpacing: '0.08em' }}
          >
            Only show folder to:
          </Text>
        ),
        empty: adminsOnlyAccess ? (
          <Text className="react-select__menu-notice" utils={{ textAlign: 'center' }}>
            No members yet
          </Text>
        ) : undefined,
      },
    });
    if (!adminsOnlyAccess) {
      if (membersSelectOptions.length !== usersWithAccess.length) {
        // @ts-ignore
        options.push({ isDivider: true });
      }
      options.push(adminOption);
    }
    return options;
  }, [membersSelectOptions, usersWithAccess, adminsOnlyAccess]);

  const value = adminsOnlyAccess
    ? adminOption
    : usersWithAccess.map(
        (data) =>
          membersSelectOptions.find((option) => {
            if (data.__typename === 'DealMember' || data.__typename === 'DealUserAndInvitationItem') {
              return (data.user?._id ?? data._id) === option.value;
            }
            if (data.__typename === 'LenderForFolder') {
              return data.name === option.value;
            }
          })!,
      );

  return (
    <Select
      options={dropdownOptions}
      onOptionRender={(option) => {
        return <FolderAccessSelectItem data={(option as FolderAccessSelectOption).data} />;
      }}
      placeholder="Limit member access"
      showDropdownIcon
      showAvatar
      isMulti={!adminsOnlyAccess}
      isClearable={adminsOnlyAccess}
      onChange={(options) => {
        options = (Array.isArray(options) ? options : [options]).filter(Boolean);
        if (!options.length) {
          onChange([], false);
          return;
        }
        const adminsOnlyOption = options.find((option) => option.value === 'adminsOnly');
        if (adminsOnlyOption && !adminsOnlyAccess) {
          onChange([], true);
        } else {
          onChange(
            options.map((option) => (option as FolderAccessSelectOption).data),
            false,
          );
        }
      }}
      value={value}
    />
  );
};

export default FolderAccessSelect;
