import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import { EditDocumentRequestForm } from '../containers';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';

type EditDocumentRequestModalProps = {
  request: NormalizedFolderTreeItem;
  isOpen: boolean;
  onClose: (data?: { isOnConfirm?: boolean }) => void;
  isBorrower?: boolean;
};

const EditDocumentRequestModal: FC<EditDocumentRequestModalProps> = ({ isOpen, onClose, isBorrower, request, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="write" isModalContent>
        <CardBody>
          <Close onClick={() => onClose()} isAbsolute desktop={{ display: 'none' }} />
          <Heading as="h3" utils={{ fontSize: 'lg', textAlign: 'center', mb: 3 }}>
            Edit Document Request
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>
            Changing the name, section, or instructions will not remove any files already uploaded.
          </Text>
          <EditDocumentRequestForm isBorrower={isBorrower} request={request} onClose={onClose} />
        </CardBody>
      </Card>
    </Modal>
  );
};

export default EditDocumentRequestModal;
