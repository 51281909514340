import React, { useEffect, useRef } from 'react';
import { TreeData, PlaceholderRefProps, ItemProps } from './types';

interface TreePlaceholderProps<T> {
  tree: TreeData<T>;
  flatTree: string[];
  itemHeight: number;
  placeholder: PlaceholderRefProps;
}

function TreePlaceholder<T extends ItemProps>({ tree, flatTree, itemHeight, placeholder }: TreePlaceholderProps<T>) {
  const ref = useRef<HTMLDivElement>(null);

  //
  // Style
  //

  const style = {
    height: itemHeight,
  };

  //
  // Effects
  //

  useEffect(() => {
    const rect = {
      top: ref.current!.offsetTop,
      right: ref.current!.offsetLeft + ref.current!.offsetWidth,
      bottom: ref.current!.offsetTop + ref.current!.offsetHeight,
      left: ref.current!.offsetLeft,
      width: ref.current!.offsetWidth,
      height: ref.current!.offsetHeight,
    };

    placeholder.current = { ref, pid: tree.rootId, index: tree.items[tree.rootId].children!.length, order: flatTree.length, level: 0, rect };
    return () => {
      (placeholder as any).current = null;
    };
  }, [flatTree]);

  //
  // Return
  //

  return <div ref={ref} style={style} />;
};

export default TreePlaceholder;
