import { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Feedback, Form, FormGroup, Input } from '~/components/form';
import { Select } from '~/components/vendor';
import { DealFieldsFragment, DealUserRole, GetUsersForFolderQuery, useGetDocumentRequestFoldersQuery } from '~/generated/graphql';
import useDocumentFoldersOps from './useDocumentFoldersOps';
import { getFolderOptions, getNormalizedFolderTree } from '~/helpers';
import FolderAccessSelect from './FolderAccessSelect';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { FolderOption } from '~/helpers/getFolderOptions';
import { validateNestedFolderAccess } from '~/helpers/documentsAccess';

type FolderInputs = {
  name: string;
  parentFolder: FolderOption | null;
  usersWithAccess: GetUsersForFolderQuery['getUsersForFolder'];
  adminsOnlyAccess: boolean;
};

type CreateFolderFormProps = {
  deal: DealFieldsFragment;
  onAfterSubmit?: () => void;
};

const CreateFolderForm: FC<CreateFolderFormProps> = ({ deal, onAfterSubmit }) => {
  const showGlobalAlert = useShowGlobalAlert();

  const form = useForm<FolderInputs>({
    defaultValues: {
      name: '',
      parentFolder: null,
      usersWithAccess: [],
      adminsOnlyAccess: false,
    },
    mode: 'onChange',
  });
  const adminsOnlyAccess = form.watch('adminsOnlyAccess');

  const { data: docReqFoldersData } = useGetDocumentRequestFoldersQuery({
    variables: { dealId: deal._id },
    fetchPolicy: 'cache-first',
  });
  const docReqFolders = docReqFoldersData?.getDocumentRequestFolders;

  const tree = useMemo(() => docReqFolders && getNormalizedFolderTree({ ...docReqFolders }), [docReqFolders]);
  const isAdmin = deal.userDealRole === DealUserRole.Admin;

  const {
    createFolder: { createFolder, creatingNewDocumentFolder },
  } = useDocumentFoldersOps({ dealId: deal._id });

  if (!tree) {
    return null;
  }

  const validateUsersWithAccess = (usersWithAccess: GetUsersForFolderQuery['getUsersForFolder']) => {
    return !validateNestedFolderAccess(tree, form.getValues('parentFolder')?.value, form.getValues('adminsOnlyAccess'), usersWithAccess);
  };

  const onSubmit = async (data: FolderInputs) => {
    const usersWithAccess = data.adminsOnlyAccess
      ? []
      : (data.usersWithAccess?.map((userWithAccess) =>
          userWithAccess?.__typename === 'LenderForFolder' ? userWithAccess.domain : userWithAccess?.__typename === 'DealMember' ? userWithAccess?._id : null,
        ) as string[]);

    await createFolder({
      dealId: deal._id,
      name: data.name,
      parentFolderId: data.parentFolder!.value === 'root' ? undefined : data.parentFolder!.value,
      order: tree.items[data.parentFolder!.value].children!.length,
      usersWithAccess,
      adminsOnlyAccess: data.adminsOnlyAccess,
    });

    showGlobalAlert('Folder added');

    form.reset({}, { keepDefaultValues: true });

    onAfterSubmit?.();
  };

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <FormGroup isValid={!form.formState.errors.name}>
        <Controller
          name="name"
          render={({ field }) => <Input type="text" placeholder="Folder name" {...field} />}
          control={form.control}
          rules={{ required: true }}
        />
        {form.formState.errors.name && <Feedback>Please enter a name</Feedback>}
      </FormGroup>

      <FormGroup isValid={!form.formState.errors.parentFolder && !form.formState.errors.usersWithAccess}>
        <Controller
          name="parentFolder"
          render={({ field: { ref, onChange, ...props } }) => (
            <Select
              options={getFolderOptions({ data: tree })}
              placeholder="Select where to insert folder"
              onChange={(option) => {
                onChange(option);
                form.trigger('usersWithAccess');
              }}
              {...props}
            />
          )}
          control={form.control}
          rules={{ required: true }}
        />
        {form.formState.errors.parentFolder && <Feedback>Please select a folder</Feedback>}
        {form.formState.errors.usersWithAccess && <Feedback>Cannot nest folders with limited access</Feedback>}
      </FormGroup>

      {isAdmin && !deal.isTemplate && (
        <FormGroup>
          <Controller
            name="usersWithAccess"
            render={({ field: { value, onChange } }) => (
              <FolderAccessSelect
                dealId={deal._id}
                isAdmin={isAdmin}
                usersWithAccess={value}
                adminsOnlyAccess={adminsOnlyAccess}
                onChange={(usersWithAccess, adminsOnlyAccess) => {
                  form.setValue('adminsOnlyAccess', adminsOnlyAccess);
                  onChange(usersWithAccess);
                }}
              />
            )}
            control={form.control}
            rules={{ validate: validateUsersWithAccess }}
          />
        </FormGroup>
      )}

      <Button variant="primary" type="submit" isBlock disabled={creatingNewDocumentFolder} isLoading={creatingNewDocumentFolder}>
        Add Folder
      </Button>
    </Form>
  );
};

export default CreateFolderForm;
