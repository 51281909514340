import styled, { css } from 'styled-components';
import { Topnav } from '../nav';

const MarketSubnav = styled(Topnav).attrs(() => ({ size: 'auto' }))`
  ${({ theme }) => css`
    position: absolute;
    z-index: 1;
    left: ${theme.marketSubnavSpacer}px;
    bottom: ${theme.marketSubnavSpacer}px;
    padding: ${theme.marketSubnavPadding}px;
    border-radius: ${theme.marketSubnavBorderRadius}px;
    box-shadow: ${theme.marketSubnavBoxShadow};
  `}
`;

export default MarketSubnav;
