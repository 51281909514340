import MarkerIcon from './MarkerIcon';
import styled, { css } from 'styled-components';

const MarkerIconDeal = styled(MarkerIcon)`
  ${({ theme }) => css`
    --market-icon-bg: ${theme.marketIconBgInverse};
    --market-icon-border-color: ${theme.marketIconBorderColorInverse};
    --market-icon-color: ${theme.marketIconColorInverse};
    padding: ${theme.spacers[2]}px ${theme.spacers[5]}px;
  `}
`;

export default MarkerIconDeal;
