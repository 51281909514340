import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';
import { Card, CardBody } from '../components/card';
import { Select } from '../components/vendor';
import { Button, Feedback, Form, FormGroup, Label } from '../components/form';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import useDocumentFoldersOps from '~/containers/Document/useDocumentFoldersOps';
import { AutoUpdateSourceType, GetDocumentRequestQuery, useGetSingleDealQuery } from '~/generated/graphql';

type FormData = {
  source?: {
    value: string;
    type?: AutoUpdateSourceType;
    label: string;
  };
};

type AutoUpdatesDocumentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  documentRequest: NonNullable<GetDocumentRequestQuery['getDocumentRequest']>;
};

const AutoUpdatesDocumentModal: FC<AutoUpdatesDocumentModalProps> = ({ isOpen, onClose, documentRequest, ...props }) => {
  const router = useRouter();
  const [isAutoUpdateSourceEnabled, setIsAutoUpdateSourceEnabled] = useState(false);
  const dealId = router.query?.dealId as string;

  const { data } = useGetSingleDealQuery({
    fetchPolicy: 'cache-only',
    variables: { id: dealId },
    skip: !dealId,
  });

  const {
    setDocumentAutoUpdates: { settingDocumentAutoUpdates, setDocumentAutoUpdates },
  } = useDocumentFoldersOps({ dealId, withServerSync: true });

  const deal = data?.getSingleDeal;

  const autoUpdatesForm = useForm<FormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      source: undefined,
    },
  });

  useEffect(() => {
    if (isOpen) {
      setIsAutoUpdateSourceEnabled(Boolean(documentRequest?.autoUpdateSource));
    } else {
      autoUpdatesForm.reset({ source: undefined });
    }
  }, [isOpen]);

  if (!deal) {
    return null;
  }

  const {
    formState: { errors: formErrors },
  } = autoUpdatesForm;

  const autoUpdateSources = [
    ...(deal?.newFinancing?.isPublished
      ? [
          { value: deal.newFinancing._id, type: AutoUpdateSourceType.Xlsx, label: 'Financing XLS' },
          { value: deal.newFinancing._id, type: AutoUpdateSourceType.Pdf, label: 'Financing PDF' },
        ]
      : []),
    ...(deal?.summaryPublished ? [{ value: deal._id, label: 'Summary PDF' }] : []),
  ];

  async function onFormSubmit(fields: FormData) {
    if (documentRequest?.autoUpdateSource) {
      await setDocumentAutoUpdates(
        {
          id: documentRequest._id,
          name: documentRequest.name,
          parentFolderId: documentRequest.parentFolderId,
          instructions: documentRequest.instructions,
          autoUpdateSource: null,
        },
        null,
        'Auto updates disabled',
      );
    } else if (fields?.source?.value) {
      await setDocumentAutoUpdates(
        {
          id: documentRequest._id,
          name: documentRequest.name,
          parentFolderId: documentRequest.parentFolderId,
          instructions: documentRequest.instructions,
          autoUpdateSource: fields?.source?.value,
          autoUpdateSourceType: fields?.source?.type,
        },
        fields?.source?.label === 'Summary PDF' ? deal! : { _id: fields?.source?.value },
        'Auto updates enabled',
      );
    }

    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="refresh" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>
            {isAutoUpdateSourceEnabled ? 'Turn Off Auto Updates' : 'Setup Auto Updates'}
          </Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 6 }}>
            {isAutoUpdateSourceEnabled ? (
              'Disabling auto updates will stop syncing updated PDF/XLS. The current document and all past versions won’t be removed.'
            ) : (
              <>
                Select a generated PDF/XLS to add it to this document, plus <u>automatically update</u> it when the source is updated.
              </>
            )}
          </Text>
          <Form onSubmit={autoUpdatesForm.handleSubmit(onFormSubmit)}>
            {!isAutoUpdateSourceEnabled && (
              <FormGroup isValid={!formErrors.source} data-testid="source" isFloating>
                <Label>Source</Label>
                <Controller
                  name="source"
                  render={({ field }) => (
                    <Select placeholder="Select a Source" noOptionsMessage={() => 'No sources yet.'} options={autoUpdateSources} {...field} />
                  )}
                  control={autoUpdatesForm.control}
                  rules={{ required: true }}
                />
                {formErrors.source && <Feedback>This field is required.</Feedback>}
              </FormGroup>
            )}

            <Button variant="primary" type="submit" isLoading={settingDocumentAutoUpdates} isBlock>
              {isAutoUpdateSourceEnabled ? 'Turn Off Auto Updates' : 'Add & Enable Auto Updates'}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default AutoUpdatesDocumentModal;
