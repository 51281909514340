import { DealTaskFragmentFragment } from '~/generated/graphql';
import { formatName } from '~/helpers';

export function getTaskAssigneeStatus(task?: DealTaskFragmentFragment | null) {
  let fullName = 'Unassigned';
  if (task?.invitation?.email) {
    fullName = `Assigned to ${task?.invitation?.email}`;
  }
  if (task?.user?.fullName) {
    fullName = `Assigned to ${formatName(task?.user?.fullName, { initialLastName: true })}`;
  }
  if (task?.finishedBy?.fullName) {
    fullName = `Completed by ${formatName(task?.finishedBy?.fullName, { initialLastName: true })}`;
  }
  return fullName;
}

export function getTaskUserName(user: { fullName?: string | null; email: string }, { truncateText } = { truncateText: false }) {
  if (user.fullName) {
    return formatName(user.fullName, { initialLastName: true });
  }

  const email = user?.email;

  if (truncateText && email?.length > 20) {
    const emailParts = email.split('@');
    const emailDomain = `@${emailParts?.[1]}`;
    const emailWithoutDomain = emailParts?.[0]?.length > 13 ? `${emailParts?.[0].slice(0, 10)}...` : emailParts?.[0];

    return emailWithoutDomain + emailDomain;
  }

  return email;
}
