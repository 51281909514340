import { FC, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Feedback, Form, FormGroup, Input } from '~/components/form';
import { Dropdown, DropdownItem, DropdownMenu, Dropzone, Select } from '~/components/vendor';
import { DealFieldsFragment, DealUserRole, useGetDealMembersQuery, useGetDocumentRequestFoldersQuery } from '~/generated/graphql';
import useDocumentFoldersOps from './useDocumentFoldersOps';
import { formatDate, getFolderOptions, getNormalizedFolderTree } from '~/helpers';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { FolderOption } from '~/helpers/getFolderOptions';
import Textarea from 'react-textarea-autosize';
import { Card, CardBody } from '~/components/card';
import { Col, Row } from '~/components/layout';
import { Heading, StretchedLink, Text } from '~/components/type';
import { Toggle } from '~/components/ui';
import { trash2 } from '~/components/vendor/Icon/icons';
import { FolderAccessSelectItem, FolderAccessSelectOption } from './FolderAccessSelect';
import { validateUserIdAccess } from '~/helpers/documentsAccess';

type DocumentRequestInputs = {
  name: string;
  parentFolder: FolderOption | null;
  instructions?: string;
  file: File | null;
  assignTo?: { value: string } | null;
};

type CreateDocumentRequestFormProps = {
  deal: DealFieldsFragment;
  onAfterSubmit?: () => void;
};

const CreateDocumentRequestForm: FC<CreateDocumentRequestFormProps> = ({ deal, onAfterSubmit }) => {
  const showGlobalAlert = useShowGlobalAlert();

  const isLender = deal?.userDealRole === DealUserRole.Lender;
  const isBorrower = deal?.userDealRole === DealUserRole.Borrower;
  const isAdmin = deal.userDealRole === DealUserRole.Admin;

  const { data: docReqFoldersData } = useGetDocumentRequestFoldersQuery({
    variables: { dealId: deal._id },
    fetchPolicy: 'cache-first',
  });
  const docReqFolders = docReqFoldersData?.getDocumentRequestFolders;

  const tree = useMemo(() => docReqFolders && getNormalizedFolderTree({ ...docReqFolders }), [docReqFolders]);

  const { data: dealMembersData } = useGetDealMembersQuery({
    variables: { dealId: deal._id },
    fetchPolicy: 'cache-first',
    skip: isLender,
  });
  const dealAdmins = dealMembersData?.getDealMembers.usersAndInvitations.filter((data) => data.role === DealUserRole.Admin) ?? [];
  const dealTaskCandidates =
    dealMembersData?.getDealMembers.usersAndInvitations.filter((data) => data.role === DealUserRole.Admin || data.role === DealUserRole.Borrower) ?? [];

  const form = useForm<DocumentRequestInputs>({
    defaultValues: {
      name: '',
      parentFolder: null,
      instructions: '',
      file: null,
      assignTo: null,
    },
  });
  const file = form.watch('file');

  useEffect(() => {
    if ((isBorrower || isAdmin) && !deal.isTemplate) {
      form.register('file', { required: isBorrower });
    }
  }, [form, isAdmin, isBorrower, deal]);

  const {
    createRequest: { createRequest, creatingNewRequest },
  } = useDocumentFoldersOps({ dealId: deal._id });

  if (!tree) {
    return null;
  }

  const validateAssignTo = (assignTo: DocumentRequestInputs['assignTo']) => {
    return !validateUserIdAccess(tree, dealAdmins, form.getValues('parentFolder')?.value, assignTo?.value);
  };

  const taskCandidatesDropdownOptions: FolderAccessSelectOption[] = dealTaskCandidates.map((data) => {
    return { value: data._id, label: data.user?.fullName ?? data.email, data };
  });

  const onSubmit = async (data: DocumentRequestInputs) => {
    if (data.file?.size! > 500 * 1024 * 1024) {
      showGlobalAlert('Max allowed size is 500MB', 'danger');
      form.setValue('file', null);
      return;
    }

    const variables = {
      name: data.name,
      parentFolderId: data.parentFolder!.value,
      instructions: data.instructions,
      file: data.file,
      ...(data?.assignTo && { userId: data?.assignTo?.value }),
    };

    const requestOrder = tree.items[data.parentFolder!.value].children!.length + 1;

    await createRequest(variables, requestOrder);

    showGlobalAlert('Document Request added');

    form.reset({}, { keepDefaultValues: true });

    onAfterSubmit?.();
  };

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <FormGroup isValid={!form.formState.errors.name}>
        <Input type="text" placeholder="Document name" {...form.register('name', { required: true })} />
        {form.formState.errors.name && <Feedback>Please enter a name</Feedback>}
      </FormGroup>
      <FormGroup isValid={!form.formState.errors.parentFolder}>
        <Controller
          name="parentFolder"
          render={({ field: { ref, onChange, ...props } }) => (
            <Select
              options={getFolderOptions({ data: tree, ignoreRoot: true })}
              placeholder="Select a folder"
              onChange={(option) => {
                onChange(option);
                form.trigger('assignTo');
              }}
              {...props}
            />
          )}
          control={form.control}
          rules={{ required: true }}
          defaultValue={null}
        />
        {form.formState.errors.parentFolder && <Feedback>Please select a folder</Feedback>}
      </FormGroup>
      {isAdmin && !deal.isTemplate && (
        <FormGroup isValid={!form.formState.errors.assignTo}>
          <Controller
            name="assignTo"
            render={({ field: { ref, ...props } }) => (
              <Select
                options={taskCandidatesDropdownOptions}
                onOptionRender={(option) => {
                  return <FolderAccessSelectItem data={(option as FolderAccessSelectOption).data} />;
                }}
                placeholder="Assign to (Optional):"
                showDropdownIcon
                isClearable
                {...props}
              />
            )}
            control={form.control}
            defaultValue={null}
            rules={{ validate: validateAssignTo }}
          />
          {form.formState.errors.assignTo && <Feedback>Doesn't have access to selected folder</Feedback>}
        </FormGroup>
      )}
      <FormGroup>
        {(isAdmin || isLender) && (
          <Controller
            name="instructions"
            render={({ field }) => <Input as={Textarea as React.ElementType} placeholder="Instructions (optional)" minRows={2} {...field} />}
            control={form.control}
            defaultValue=""
          />
        )}
      </FormGroup>
      {/*
        1. We hide file uploader for template editor
        2. We display file uploader only for admin and borrower
      */}
      {(isAdmin || isBorrower) && !deal.isTemplate && (
        <FormGroup isValid={!form.formState.errors.file}>
          {!file && (
            <>
              <Dropzone
                utils={{ mb: 6 }}
                label={isAdmin ? 'Upload File (Optional)' : 'Upload File'}
                onFileSelect={(files: File[]) => {
                  form.setValue('file', files[0]);
                }}
              />
              {form.formState.errors.file && <Feedback>Please add a file</Feedback>}
            </>
          )}
          {file && (
            <>
              <Card size="sm" utils={{ mb: 6 }}>
                <CardBody utils={{ py: 6 }}>
                  <Row utils={{ alignItems: 'center' }}>
                    <Col>
                      <StretchedLink>
                        <Heading as="h6" utils={{ fontSize: 'base', fontWeight: 'base', textTruncate: true }}>
                          {form.watch('file')!.name}
                        </Heading>
                      </StretchedLink>
                      <Text utils={{ fontSize: 'xs', color: 'gray600', textTruncate: true }}>{formatDate(new Date())} · You</Text>
                    </Col>
                    <Col span="auto">
                      <Dropdown menuButton={<Toggle />}>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              form.setValue('file', null);
                            }}
                            icon={trash2}
                          >
                            Remove
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </>
          )}
        </FormGroup>
      )}
      <Button variant="primary" type="submit" isBlock disabled={creatingNewRequest} isLoading={creatingNewRequest}>
        Add Document
      </Button>
    </Form>
  );
};

export default CreateDocumentRequestForm;
