import React from 'react';
import { chevronDown } from '~/components/vendor/Icon/icons';
import { DealStatus as DealStatusEnum } from '~/generated/graphql';
import { DealStatusMapped, DealStatusIcons, DealStatusVariants, DealStatusProgress } from '~/data/dealStatus';
import { Icon } from '~/components/vendor';
import { Status } from '~/components/ui';
import { StatusProps } from '~/components/ui/Status';

type DealStatusToggleProps = {
  size?: StatusProps['size'];
  status: DealStatusEnum;
};

const DealStatusToggle = ({ size, status }: DealStatusToggleProps) => {
  return (
    <>
      <Status
        size={size}
        icon={DealStatusIcons[status as DealStatusEnum]}
        variant={DealStatusVariants[status as DealStatusEnum]}
        progress={DealStatusProgress[status as DealStatusEnum]}
        utils={{ mr: 4 }}
      />
      {DealStatusMapped[status]} <Icon icon={chevronDown} utils={{ ml: 3, color: 'gray600' }} />
    </>
  );
};

export default DealStatusToggle;
