import React, { FC } from 'react';
import { Card, CardBody } from '../components/card';
import { Button } from '../components/form';
import { FlexRow } from '../components/layout';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';
import { CustomTheme } from '~/@types/styled-components';
import { CardIconType } from '~/components/card/Card';
import { Collapse } from 'react-collapse';

type ConfirmModalProps = {
  isOpen?: boolean;
  onClose: () => any;
  onExited?: () => any;
  onConfirm: () => any;
  onCancel?: () => any;
  question?: string;
  text?: React.ReactNode;
  isLoading?: boolean;
  okText?: string;
  okVariant?: keyof CustomTheme['colors'];
  cancelText?: string;
  cardIcon?: CardIconType | React.ReactNode;
  cardVariant?: keyof CustomTheme['colors'];
  footer?: React.ReactNode;
  shouldAnimateContent?: boolean;
};

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  onClose,
  onExited,
  onConfirm,
  onCancel,
  question,
  text,
  isLoading,
  okText,
  okVariant,
  cancelText,
  cardIcon,
  cardVariant,
  footer,
  shouldAnimateContent,
  ...props
}) => {
  const body = (
    <CardBody>
      <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
      <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>{question || 'Are you sure?'}</Heading>
      <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>{text}</Text>
      <FlexRow>
        {onCancel && (
          <Button type="button" variant="white" onClick={onCancel} isBlock>
            {cancelText || 'Cancel'}
          </Button>
        )}
        <Button type="button" variant={okVariant ?? 'primary'} onClick={onConfirm} isLoading={isLoading} isBlock>
          {okText || 'Okay'}
        </Button>
      </FlexRow>
      {footer}
    </CardBody>
  );

  return (
    <Modal utils={{ zindex: 'dropdown' }} isOpen={Boolean(isOpen)} onExited={onExited} onClose={onClose} {...props}>
      <Card icon={cardIcon ?? 'check'} isModalContent variant={cardVariant}>
        {shouldAnimateContent ? <Collapse isOpened={true}>{body}</Collapse> : body}
      </Card>
    </Modal>
  );
};

export default ConfirmModal;
