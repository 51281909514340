import { SourceItemProps, TargetItemProps, TreeData } from '../types';

export default function updateTreeItems<T>(tree: TreeData<T>, source: SourceItemProps, target: TargetItemProps): TreeData<T> {
  const clone = JSON.parse(JSON.stringify(tree));
  const sourceArr = source.pid ? clone.items[source.pid].children : clone.children;
  const targetArr = target.pid ? clone.items[target.pid].children : clone.children;

  targetArr.splice(target.index, 0, sourceArr.splice(source.index, 1)[0]);
  return clone;
}
