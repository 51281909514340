/**
 * Return parsed string in format MM/DD/YYYY
 */
export default function getTransformedDate(date: Date | string | undefined | null): string | null {
  if (!date) {
    return null;
  }

  date = new Date(date);
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}
