import React, { FC, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input, FormGroup, Feedback } from '../components/form';
import { useUpdateDealMutation } from '~/generated/graphql';

type RenameDealFormProps = {
  deal: {
    _id: string;
    name: string;
  };
  onClick: () => void;
  isTemplate?: boolean;
};

const RenameDealForm: FC<RenameDealFormProps> = (props) => {
  const [renameDeal] = useUpdateDealMutation();
  const [isSaving, setIsSaving] = useState(false);

  const renameDealForm = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name: props.deal.name,
    },
  });

  const { formState: { errors: formErrors } } = renameDealForm;

  const handleSubmit = async (data: { name: string }) => {
    setIsSaving(true);

    await renameDeal({
      variables: { id: props.deal._id, name: data.name },
      refetchQueries: ['currentUser'],
    });
    setIsSaving(false);

    props.onClick();
  };

  return (
    <Form onSubmit={renameDealForm.handleSubmit(handleSubmit)}>
      <FormGroup isValid={!formErrors.name} data-testid="dealEditedName">
        <Controller
          name="name"
          render={({ field }) => {
            return (
              <Input autoFocus type="text" placeholder={`${props.isTemplate ? 'Template' : 'Deal'} name`} maxLength={25} {...field} />
            );
          }}
          control={renameDealForm.control}
          rules={{ required: true }}
        />
        {formErrors.name?.type === 'required' && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <Button type="submit" isLoading={isSaving} isBlock>
        Save
      </Button>
    </Form>
  );
};

export default RenameDealForm;
