import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { DealLenderPassingContactsOptionValue } from '~/data/dealLenderStatusOptions';
import {
  DealLenderStatus,
  DealMemberFragment,
  GetDealMembersDocument,
  GetDealMembersQuery,
  GetSingleDealDocument,
  GetSingleDealQuery,
  GetSingleDealQueryVariables,
  UpdateDealLenderStatusInput,
  useGetDealMembersLazyQuery,
  useRemoveUserFromDealMutation,
  useUpdateDealLenderStatusMutation,
  useUpdateInvitationNotificationStatusMutation,
} from '~/generated/graphql';
import { dealLenderStatusVar, quoteEditModalVar } from '~/lib/apolloClient';

export type UpdatingStatusLender = Omit<UpdateDealLenderStatusInput, 'dealId'>;

export const useUpdateDealMembers = () => {
  const client = useApolloClient();

  const [updateInvitationNotificationStatus] = useUpdateInvitationNotificationStatusMutation({ refetchQueries: ['getLenderActivities'] });
  const [removeUserFromDeal] = useRemoveUserFromDealMutation({ refetchQueries: ['getLenderActivities'] });
  const [getDealMembers] = useGetDealMembersLazyQuery();

  const updateDealMembers = useCallback(
    async (
      dealId: string,
      action: Promise<any>,
      match: (dealMember: DealMemberFragment) => boolean,
      lenderPassingContacts?: DealLenderPassingContactsOptionValue,
    ) => {
      if (!lenderPassingContacts || lenderPassingContacts === 'nothing') {
        await action;
        return;
      }

      const promises = [action];

      let data: GetDealMembersQuery | undefined | null = client.readQuery<GetDealMembersQuery>({
        query: GetDealMembersDocument,
        variables: { dealId },
      });
      if (!data) {
        data = (await getDealMembers({ variables: { dealId } })).data;
      }
      if (data) {
        if (lenderPassingContacts === 'unsubscribe') {
          data.getDealMembers.usersAndInvitations
            .filter((user) => !user.isUnsubscribedFromEmails && match(user))
            .forEach(({ email, itemType }) => {
              promises.push(updateInvitationNotificationStatus({ variables: { dealId, email, itemType } }));
            });
        } else if (lenderPassingContacts === 'remove') {
          data.getDealMembers.usersAndInvitations.filter(match).forEach(({ email, itemType }) => {
            promises.push(removeUserFromDeal({ variables: { dealId, email, itemType } }));
          });
        }

        await Promise.all(promises);

        client.writeQuery<GetDealMembersQuery>({
          query: GetDealMembersDocument,
          variables: { dealId },
          data: {
            getDealMembers: {
              ...data.getDealMembers,
              usersAndInvitations:
                lenderPassingContacts === 'unsubscribe'
                  ? data.getDealMembers.usersAndInvitations.map((user) => ({
                      ...user,
                      isUnsubscribedFromEmails: user.isUnsubscribedFromEmails || match(user),
                    }))
                  : data.getDealMembers.usersAndInvitations.filter((user) => !match(user)),
            },
          },
        });
      }
    },
    [client, removeUserFromDeal, updateInvitationNotificationStatus],
  );

  return updateDealMembers;
};

const useUpdateDealLenderStatus = (dealId?: string | null) => {
  const client = useApolloClient();

  const [updateDealLenderStatus] = useUpdateDealLenderStatusMutation({ refetchQueries: ['getLenderActivities', 'getDealMembers'] });

  useEffect(() => {
    dealLenderStatusVar({});
  }, [dealId]);

  const updateDealMembers = useUpdateDealMembers();

  const handleStatusUpdate = useCallback(
    async (lender: UpdatingStatusLender, reason?: string, lenderPassingContacts?: DealLenderPassingContactsOptionValue) => {
      if (!dealId) {
        return;
      }

      const reasons = {
        ...(lender.status === DealLenderStatus.Interested ? { interestedStatusReason: reason } : {}),
        ...(lender.status === DealLenderStatus.Maybe ? { maybeStatusReason: reason } : {}),
        ...(lender.status === DealLenderStatus.Passed ? { passedStatusReason: reason } : {}),
        ...(lender.status === DealLenderStatus.NotCompetitive ? { notCompetitiveStatusReason: reason } : {}),
      };

      await updateDealMembers(
        dealId,
        updateDealLenderStatus({
          variables: {
            input: {
              dealId,
              lenderId: lender.lenderId,
              status: lender.status,
              ...reasons,
            },
          },
        }),
        (user) => user.lenderContact?.lenderId === lender.lenderId,
        lenderPassingContacts,
      );

      const data = client.readQuery<GetDealMembersQuery>({
        query: GetDealMembersDocument,
        variables: { dealId },
      });

      if (data) {
        client.writeQuery<GetDealMembersQuery>({
          query: GetDealMembersDocument,
          variables: { dealId },
          data: {
            getDealMembers: {
              ...data.getDealMembers,
              lenders: data.getDealMembers.lenders.map((l) => {
                if (lender.lenderId === l.id) {
                  return {
                    ...l,
                    status: lender.status,
                    ...reasons,
                  };
                }
                return l;
              }),
            },
          },
        });
      }
    },
    [dealId, client, updateDealLenderStatus, updateDealMembers],
  );

  const updateLenderStatus = useCallback(
    async (lender: UpdatingStatusLender) => {
      if (!dealId) {
        return;
      }

      if (lender.status === DealLenderStatus.Quoted) {
        const getSingleDealData = client.readQuery<GetSingleDealQuery, GetSingleDealQueryVariables>({
          query: GetSingleDealDocument,
          variables: { id: dealId },
        });
        if (!getSingleDealData?.getSingleDeal.quotes.some((quote) => quote.lenderId === lender.lenderId)) {
          quoteEditModalVar({
            isOpen: true,
            lenderId: lender.lenderId,
            skipLenderStatusUpdate: true,
            onComplete: () => {
              handleStatusUpdate(lender);
            },
          });
          return;
        }
      }

      if ([DealLenderStatus.Interested, DealLenderStatus.Maybe, DealLenderStatus.Passed, DealLenderStatus.NotCompetitive].includes(lender.status)) {
        dealLenderStatusVar({
          lender,
          onSubmit: (reason, lenderPassingContacts) => {
            return handleStatusUpdate(lender, reason, lenderPassingContacts);
          },
        });
        return;
      }

      await handleStatusUpdate(lender);
    },
    [client, dealId, handleStatusUpdate],
  );

  return updateLenderStatus;
};

export default useUpdateDealLenderStatus;
