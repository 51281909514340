import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Button } from '../components/form';
import { Heading, Text } from '../components/type';
import { Close, Modal } from '../components/ui';

type PublishModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onConfirm: () => void;
  title: string;
  description: string;
};

const PublishModal: FC<PublishModalProps> = ({ isLoading, isOpen, onClose, onConfirm, title, description, ...props }) => (
  <Modal isOpen={isOpen} onClose={onClose} {...props}>
    <Card icon="clipboard" isModalContent>
      <CardBody>
        <Close onClick={() => onClose()} isAbsolute desktop={{ display: 'none' }} />
        <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 3 }}>{title}</Heading>
        <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 8 }}>{description}</Text>
        <Button variant="primary" onClick={onConfirm} isLoading={isLoading} isBlock>
          Publish
        </Button>
      </CardBody>
    </Card>
  </Modal>
);

export default PublishModal;
