import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

const PreviewModalDialog = styled(Base)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - ${theme.previewModalLeftGutter * 2}px);
    width: calc(100% - ${theme.previewModalLeftGutter * 2}px);
    margin: ${theme.previewModalLeftGutter}px;
    pointer-events: none;

    // IE bug fix
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &::before {
        content: '';
        min-height: calc(100vh - ${theme.previewModalLeftGutter * 2}px);
      }
    }
  `}
`;

export default PreviewModalDialog;
