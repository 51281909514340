import React, { Fragment } from 'react';
import HighlightedWord from '~/components/ui/HighlightedWord';

export const addHighlightWords = (str: string, searchStr?: string | null) => {
  if (searchStr && searchStr.trim().length !== 0) {
    const searchWords = searchStr.trim().split(' ');
    const strWords = str.split(' ');

    return strWords.map((strWord) => {
      if (searchWords.some((searchWord) => strWord.toLowerCase().includes(searchWord.toLowerCase()))) {
        for (const searchWord of searchWords) {
          const startIndex = strWord.toLowerCase().indexOf(searchWord.toLowerCase());
          if (startIndex !== -1) {
            const strSearchString = strWord.slice(startIndex, startIndex + searchWord.length);

            return (
              <Fragment key={searchWord + startIndex}>
                {strWord?.substring(0, startIndex)}
                <HighlightedWord>{strSearchString}</HighlightedWord>
                {strWord?.substring(startIndex + strSearchString.length, strWord.length)}{' '}
              </Fragment>
            );
          }
        }
      }
      return <Fragment key={strWord}>{strWord} </Fragment>;
    });
  }
  return str;
};
