import React, { FC, useEffect, useState } from 'react';
import { ActivityNoteCard } from '~/components/activity/ActivityNoteCard';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import { Avatar } from '~/components/ui';
import { CardBody } from '~/components/card';
import { Dropdown, DropdownMenu, DropdownItem } from '~/components/vendor';
import { edit, trash2 } from '~/components/vendor/Icon/icons';
import { formatDate } from '~/helpers';
import { Heading, Text } from '~/components/type';
import { ListChainedAnchor, ListItem } from '~/components/list';
import { CompanyFragment, ActivityNoteFragment, NoteFragment, UpdateNoteInput, LightCompanyFragment } from '~/generated/graphql';
import { Row, Col } from '~/components/layout';
import { Toggle } from '~/components/ui';
import { useLeavePageConfirm } from '~/hooks/useLeavePageConfirm';
import formatName from '~/helpers/formatName';
import NoteEditorToolbarAddon from './NoteEditorToolbarAddon';
import ActivityLenderLink from '../Activities/ActivityLenderLink';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';
import { Editor } from '~/components/vendor/Editor';

type NoteProps = {
  lender?: { id: string; name: string };
  borrower?: { name: string };
  dealId?: string;
  note: NoteFragment | ActivityNoteFragment;
  currentUserId: string;
  search?: string | null;
  disabledCrossPosting?: boolean;
  onDelete: (id: string) => void;
  onUpdate: (input: UpdateNoteInput) => Promise<void>;
  setNoteModifyingInProgress?: (isNoteModifyingInProgress: boolean) => void;
  openMatchModel?: (lender: CompanyFragment | LightCompanyFragment) => void;
};

const Note: FC<NoteProps> = ({
  lender,
  borrower,
  dealId,
  note,
  currentUserId,
  search,
  disabledCrossPosting,
  onDelete,
  onUpdate,
  setNoteModifyingInProgress,
  openMatchModel,
}) => {
  const [text, setText] = useState(note.text);
  const [showOnExplore, setShowOnExplore] = useState(false);
  const [isEdit, setEdit] = useState(false);

  useEffect(() => {
    setShowOnExplore(Boolean(note.showOnExplore));
  }, [note.showOnExplore]);

  useLeavePageConfirm(isEdit && text !== note.text, 'Are you sure want to abandon your note?');
  useEffect(() => {
    setNoteModifyingInProgress?.(isEdit && text !== note.text);
  }, [isEdit, text !== note.text]);

  const handleUpdate = async () => {
    await onUpdate({ id: note._id, showOnExplore, text });
    setEdit(false);
  };

  const noteLender = (note as ActivityNoteFragment).noteLender;

  return (
    <ListItem style={{ '--list-gutter': '32px' } as React.CSSProperties}>
      <ListChainedAnchor utils={{ mr: 6 }}>
        {note.createdBy.email === 'bot@welcomelend.com' ? (
          <Avatar size="lg" src="/img/brand/brand-round.svg" alt={note.createdBy.fullName} status={note.createdBy.onlineStatus} utils={{ mt: 5 }} />
        ) : (
          <UserAvatar size="lg" user={note.createdBy} status={note.createdBy.onlineStatus} utils={{ mt: 5 }} />
        )}
      </ListChainedAnchor>
      <ActivityNoteCard isFlush={isEdit} utils={{ width: isEdit ? '100%' : undefined }}>
        <CardBody utils={{ py: 5 }} desktop={{ pr: isEdit ? 0 : 10 }}>
          {!isEdit && (
            <>
              <Row gutter={2} utils={{ alignItems: 'baseline' }}>
                <Col span="auto">
                  <Heading as="h4" utils={{ fontSize: 'base' }}>
                    {addHighlightWords(formatName(note.createdBy.fullName, { initialLastName: true }), search)}
                  </Heading>
                </Col>
                <Col>
                  <Text utils={{ fontSize: 'sm', color: 'gray600' }}>
                    {formatDate(note.createdAt)}
                    {dealId && (!note.dealId || note.showOnExplore) && ` (from ${lender ? 'lender' : 'borrower'} profile)`}
                  </Text>
                </Col>
                {currentUserId === note.createdBy._id && (
                  <Dropdown menuButton={({ open }) => <Toggle utils={{ position: 'absolute', top: 5, right: 4 }} aria-expanded={open} />}>
                    <DropdownMenu>
                      <DropdownItem icon={edit} onClick={() => setEdit(true)}>
                        Edit
                      </DropdownItem>
                      <DropdownItem icon={trash2} onClick={() => onDelete(note._id)}>
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                )}
              </Row>
              {noteLender && lender?.id !== noteLender.id && (
                <Row utils={{ color: 'gray800', mb: 4 }}>
                  <Col span="auto">
                    {addHighlightWords('Added a note to', search)} <ActivityLenderLink openMatchModel={openMatchModel} lender={noteLender} search={search} />
                  </Col>
                </Row>
              )}
            </>
          )}
          <Editor
            placeholder="Add a note"
            value={text}
            search={search}
            onChange={setText}
            onUpdate={handleUpdate}
            onCancel={() => {
              setText(note.text);
              setEdit(false);
            }}
            autoFocus
            isCollapsible
            allowFileAttachments
            toolbarAddons={
              dealId &&
              !disabledCrossPosting && (
                <NoteEditorToolbarAddon
                  id={note._id}
                  name={(lender?.name ?? borrower?.name)!}
                  type={lender ? 'lender' : 'borrower'}
                  checked={showOnExplore}
                  onChange={setShowOnExplore}
                />
              )
            }
            readOnly={!isEdit}
          />
        </CardBody>
      </ActivityNoteCard>
    </ListItem>
  );
};

export default Note;
