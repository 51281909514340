import React, { FC, useEffect, useState } from 'react';
import { Modal } from '~/components/ui';
import { Button, Input, FormGroup } from '~/components/form';
import { Heading, Text } from '~/components/type';
import { FlexRow } from '~/components/layout';
import { Card, CardBody } from '~/components/card';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';

type LenderPassingModalProps = {
  lenderName: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (passedStatusReason: string) => Promise<void>;
};

const LenderPassingModal: FC<LenderPassingModalProps> = ({ lenderName, isOpen, onClose, onSubmit }) => {
  const showGlobalAlert = useShowGlobalAlert();

  const [isLoading, setLoading] = useState(false);
  const [passedStatusReason, setReasonForPassing] = useState('');

  useEffect(() => {
    setReasonForPassing('');
  }, [isOpen]);

  const onSubmitForm = async () => {
    setLoading(true);
    await onSubmit(passedStatusReason.trim());
    setLoading(false);
    onClose();
    showGlobalAlert('Successfully passed on this deal.');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card icon="box" variant="danger" isModalContent>
        <CardBody>
          <Heading utils={{ textAlign: 'center', fontSize: 'xl', mb: 3 }}>
            Confirm you’re{' '}
            <Text as="span" utils={{ color: 'danger' }}>
              not interested.
            </Text>
          </Heading>
          <Text utils={{ textAlign: 'center', color: 'gray700', mb: 8 }}>Are you sure this deal isn’t a match for {lenderName}?</Text>
          <FormGroup>
            <Input
              value={passedStatusReason}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setReasonForPassing(e.target.value)}
              as={Textarea as React.ElementType}
              minRows={4}
              placeholder="Provide an optional reason for passing..."
            />
          </FormGroup>
          <FlexRow>
            <Button variant="white" onClick={onClose} isBlock disabled={isLoading}>
              Cancel
            </Button>
            <Button variant="danger" onClick={onSubmitForm} isBlock disabled={isLoading} isLoading={isLoading}>
              Pass on deal
            </Button>
          </FlexRow>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default LenderPassingModal;
