import { Borrower, BorrowerContact, Company, LenderContact, LenderProgram } from '~/generated/graphql';

export const lenderLabels: Partial<Record<keyof Company, string>> = {
  name: 'name',
  logo: 'avatar',
  lender_type: 'lender type',
  home_state: 'home state',
  about: 'about',
  urls: 'domain',
};

export const lenderProgramLabels: Partial<Record<keyof LenderProgram | 'recourse', string>> = {
  name: 'name',
  attachments: 'attachments',
  asset_types: 'asset types',
  capital_types: 'capital types',
  deal_type: 'deal types',
  lending_states: 'lending states',
  rate_type: 'base rate',
  amortization: 'amortization',
  recourse: 'recourse',
  recourses: 'recourse',
  loan_value_min: 'loan range min',
  loan_value_max: 'loan range max',
  rate_min: 'rate min',
  rate_max: 'rate max',
  origination_fee_min: 'origination fee min',
  origination_fee_max: 'origination fee max',
  exit_fee_min: 'exit fee min',
  exit_fee_max: 'exit fee max',
  other_fees_min: 'processing/other fees',
  other_fees_max: 'processing/other fees',
  term_months_min: 'terms',
  term_months_max: 'terms',
  ltv_max: 'LTV',
  ltc_max: 'LTC',
  dscr: 'DSCR',
  debt_yield_min: 'debt yield',
  notes: 'note',
};

export const lenderContactLabels: Partial<Record<keyof LenderContact, string>> = {
  avatar_id: 'avatar',
  first_name: 'first name',
  last_name: 'last name',
  notes: 'note',
  job_titles: 'job title',
  phones: 'phone',
  emails: 'email',
  websites: 'LinkedIn URL',
};

export const borrowerLabels: Partial<Record<keyof Borrower, string>> = {
  status: 'status',
  name: 'company name',
  url: 'domain',
  about: 'about',
  logo: 'avatar',
  homeState: 'home state',
};

export const borrowerContactLabels: Partial<Record<keyof BorrowerContact, string>> = {
  firstName: 'first name',
  lastName: 'last name',
  emails: 'email',
  phones: 'phone',
  linkedIn: 'linkedIn URL',
  jobTitle: 'job title',
  avatarId: 'avatar',
  notes: 'note',
};
