import { ListItem, ListChainedAnchor } from '~/components/list';
import { Card, CardBody } from '~/components/card';
import { Row, Col } from '~/components/layout';
import { Heading, Text, TextFiller } from '~/components/type';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import { formatDate, formatName } from '~/helpers';
import { BaseActivityFragment, BorrowerFragment, LightCompanyFragment, UserOnlineStatus } from '~/generated/graphql';
import { CompanyFragment } from '~/generated/graphql';
import { dealBorrowerModalBorrowerVar } from '~/lib/apolloClient';
import UserAvatar, { UserData, getUserAvatarSrc, getUserName } from '../ui/Avatar/UserAvatar';

type ActivityListItemProps = {
  createdBy: BaseActivityFragment['createdBy'];
  lender?: CompanyFragment | LightCompanyFragment | null;
  borrower?: BorrowerFragment | null;
  search?: string | null;
  createdAt: Date;
  isCard?: boolean;
  openMatchModel?: (lender: CompanyFragment | LightCompanyFragment) => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

export function ActivityListItemEmpty({ style, infiniteRef }: { style?: React.CSSProperties; infiniteRef?: React.Ref<HTMLLIElement> }) {
  return (
    <ListItem style={style} ref={infiniteRef}>
      <ListChainedAnchor utils={{ mr: 6 }}>
        <TextFiller utils={{ height: '48px', width: '48px', borderRadius: 'rounded' }} />
      </ListChainedAnchor>
      <Card size="xs" utils={{ width: 'fit-content' }} isFlush>
        <CardBody>
          <Row gutter={2} utils={{ alignItems: 'baseline' }}>
            <Col span="auto">
              <TextFiller utils={{ width: '80px' }} />
            </Col>
            <Col span="auto" utils={{ fontSize: 'sm', color: 'gray600' }}>
              <TextFiller utils={{ width: '45px' }} />
            </Col>
          </Row>
          <TextFiller utils={{ width: '200px' }} />
        </CardBody>
      </Card>
    </ListItem>
  );
}

export function ActivityListItem({ createdBy, lender, borrower, search, createdAt, isCard = false, openMatchModel, children, style }: ActivityListItemProps) {
  const isBot = createdBy.email === 'bot@welcomelend.com';
  const onlineStatus = isBot
    ? UserOnlineStatus.Online
    : createdBy.__typename === 'User'
      ? createdBy.onlineStatus
      : createdBy.__typename === 'Invitation'
        ? createdBy.foundUser?.onlineStatus
        : undefined;
  const userData: UserData = {
    user: createdBy.__typename === 'User' ? createdBy : createdBy.__typename === 'Invitation' ? (createdBy.foundUser ?? createdBy) : createdBy,
    lenderContact: createdBy.__typename === 'Invitation' ? createdBy.lenderContact : null,
    borrowerContact: createdBy.__typename === 'Invitation' ? createdBy.borrowerContact : null,
  };

  const creatorName = formatName(getUserName(userData) ?? createdBy.email, { initialLastName: true });
  const isInactive = !onlineStatus && !getUserAvatarSrc(userData);

  return (
    <ListItem style={{ '--list-gutter': isCard ? '32px' : undefined, ...style } as React.CSSProperties}>
      <ListChainedAnchor utils={{ mr: 6 }}>
        <UserAvatar size="lg" status={onlineStatus} utils={{ mt: isCard ? 5 : undefined }} isInactive={isInactive} {...userData} />
      </ListChainedAnchor>
      <Card size="xs" utils={{ width: 'fit-content' }} isFlush={!isCard}>
        <CardBody utils={{ py: isCard ? 5 : undefined }} desktop={{ pr: isCard ? 10 : undefined }}>
          <Row gutter={2} utils={{ alignItems: 'baseline' }}>
            <Col span="auto">
              <Heading as="h5" utils={{ fontSize: 'base' }}>
                {addHighlightWords(creatorName, search)}
              </Heading>
            </Col>
            {lender && openMatchModel && (
              <Col span="auto" utils={{ fontSize: 'sm', color: 'gray700' }}>
                (
                <Text as="span" hover={{ color: 'primary' }} onClick={() => openMatchModel(lender)} role="button">
                  {addHighlightWords(lender.name, search!)}
                </Text>
                )
              </Col>
            )}
            {borrower && (
              <Col span="auto" utils={{ fontSize: 'sm', color: 'gray700' }}>
                (
                <Text as="span" hover={{ color: 'primary' }} onClick={() => dealBorrowerModalBorrowerVar(borrower)} role="button">
                  {addHighlightWords(borrower.name, search!)}
                </Text>
                )
              </Col>
            )}
            <Col span="auto" utils={{ fontSize: 'sm', color: 'gray600' }}>
              {formatDate(createdAt)}
            </Col>
          </Row>
          {children}
        </CardBody>
      </Card>
    </ListItem>
  );
}
