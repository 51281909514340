import { ActivityItemLink } from './ActivityItemLink';
import { addHighlightWords } from './helpers/addHighlightWords';
import { FlexRow } from '~/components/layout';
import Status, { StatusProps } from '~/components/ui/Status';

type ActivityStatusProps = StatusProps & {
  label: string;
  search?: string;
  isActivitiesStatusBreak?: boolean;
};

export function ActivityStatus({ icon, variant, progress, label, search, isActivitiesStatusBreak = false }: ActivityStatusProps) {
  const labelSplit = label.split(' ');
  return (
    <ActivityItemLink
      utils={{
        wordBreak: isActivitiesStatusBreak ? undefined : 'break-word',
        whiteSpace: isActivitiesStatusBreak ? 'nowrap' : undefined,
        fontSize: isActivitiesStatusBreak ? 'sm' : undefined,
      }}
    >
      <FlexRow utils={{ display: 'inline-flex', flexWrap: 'nowrap', alignItems: 'baseline' }}>
        <Status
          icon={icon}
          variant={variant}
          progress={progress}
          utils={{ alignSelf: 'center' }}
          style={{ marginRight: isActivitiesStatusBreak ? 6 : 3, marginLeft: 1, transform: 'translateY(-1px)' }}
        />{' '}
        {addHighlightWords(labelSplit[0], search)}
      </FlexRow>
      {labelSplit.length > 1 && addHighlightWords(` ${labelSplit.slice(1).join(' ')}`, search)}
    </ActivityItemLink>
  );
}
