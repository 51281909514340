import React, { FC, useId, useMemo } from 'react';
import { BaseProps } from '~/components/layout/Base';
import { LenderResponsivenessFragment, LenderResponsivenessLevel } from '~/generated/graphql';
import { responsivenessVariants } from '~/data/responsiveness';
import Funnel from '~/components/icons/funnel/Funnel';
import ResponsivenessTooltip from './ResponsivenessTooltip';

export const getResponsivenessLevel = (data: LenderResponsivenessFragment): LenderResponsivenessLevel => {
  const level =
    data.invited <= 2
      ? LenderResponsivenessLevel.LimitedData
      : data.responded / data.invited >= 0.5
        ? LenderResponsivenessLevel.VeryResponsive
        : data.responded / data.invited >= 0.3
          ? LenderResponsivenessLevel.SomewhatResponsive
          : LenderResponsivenessLevel.NotResponsive;
  return level;
};

interface ResponsivenessProps extends BaseProps, Omit<React.HTMLAttributes<HTMLOrSVGElement>, 'color'> {
  data?: LenderResponsivenessFragment | null;
  showTooltip?: boolean;
}

const Responsiveness: FC<ResponsivenessProps> = ({ data, showTooltip = true, ...props }) => {
  const tooltipId = useId();

  const percentageArr = useMemo(() => {
    return data
      ? [
          data.invited > 0 ? 100 : 0,
          data.invited > 0 ? Math.round((data.viewed / data.invited) * 100) : 0,
          data.invited > 0 ? Math.min(Math.round((data.responded / data.invited) * 100), 100) : data.responded ? 100 : 0,
        ]
      : [0, 0, 0];
  }, [data]);

  if (!data?.invited && !data?.added) {
    return null;
  }

  const level = getResponsivenessLevel(data);

  return (
    <>
      <Funnel
        size={14}
        variant={responsivenessVariants[level]}
        data={percentageArr}
        data-tooltip-id={tooltipId}
        data-tooltip-hidden={!showTooltip}
        utils={{ position: 'relative', minWidth: '14px', ml: 4, ...props.utils }}
        style={{ zIndex: 1, ...props.style }}
        onClick={(e) => e.stopPropagation()}
      />
      <ResponsivenessTooltip id={tooltipId} level={level} data={data} />
    </>
  );
};

export default Responsiveness;
