import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat, NumberFormatValues } from 'react-number-format';
import { Button, Feedback, Form, Input, FormGroup, Label } from '~/components/form';
import { Col, Row } from '~/components/layout';

import { assetTypeOptions, capitalTypeOptions, dealTypeOptions, dealTypeOptionsMap } from '~/data';
import { Select, SelectAddress } from '../../components/vendor';
import isNotNil from '../../helpers/isNotNil';
import { CoordinatesInput, DealCapitalTypeInput, DealProjectAssetTypeInput, DealProjectLoanTypeInput, DealType } from '~/generated/graphql';

export type PreflightInputs = {
  address: string;
  assetType: DealProjectAssetTypeInput;
  coordinates: CoordinatesInput;
  loanType: DealProjectLoanTypeInput;
  financingType: DealCapitalTypeInput;
  loanRequestAmount: number;
  totalCost: number;
  completedValue: number;
};

export type OnCalculateFormSubmit = (data: PreflightInputs) => void;

interface Props {
  isRecalculate?: boolean;
  onSubmit: OnCalculateFormSubmit;
  isLoading?: boolean;
  isModal?: boolean;
}

const CalculateForm: React.FC<Props> = ({ isRecalculate, onSubmit, isLoading, isModal }) => {
  const router = useRouter();
  const queryValues = { ...(router?.query ?? {}), coordinates: JSON.parse((router?.query?.coordinates as string) ?? '{}') } as Record<string, any>;
  const calculateForm = useForm<PreflightInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      address: undefined,
      loanType: undefined,
      coordinates: {
        cityState: undefined,
        lat: undefined,
        lng: undefined,
        placeId: undefined,
        shortAddress: undefined,
      },
      financingType: undefined,
      loanRequestAmount: undefined,
      totalCost: undefined,
      completedValue: undefined,
    },
  });

  useEffect(() => {
    calculateForm.register('coordinates', { required: true });
  }, [calculateForm]);

  const isPreflight = router.pathname === '/preflight';

  useEffect(() => {
    if (isPreflight && router?.query?.coordinates) {
      const allSelectOptions = [...assetTypeOptions, ...capitalTypeOptions];

      Object.keys(queryValues).forEach((queryKey) => {
        if (['assetType', 'loanType', 'financingType'].includes(queryKey)) {
          if (queryKey === 'loanType') {
            calculateForm.setValue(queryKey, dealTypeOptionsMap[queryValues[queryKey] as DealType]);
          } else {
            calculateForm.setValue(
              queryKey as any,
              allSelectOptions.find((option) => option.value === queryValues[queryKey]),
            );
          }
        } else if (['loanRequestAmount', 'totalCost', 'completedValue'].includes(queryKey)) {
          calculateForm.setValue(queryKey as any, parseFloat(queryValues[queryKey]));
        } else {
          calculateForm.setValue(queryKey as any, queryValues[queryKey]);
        }
      });
      if (!isModal) {
        onSubmit!(calculateForm.getValues());
      }
    }
  }, []);

  const {
    formState: { errors: formErrors },
    getValues,
  } = calculateForm;

  function onFormSubmit(data: PreflightInputs) {
    const { assetType, loanType, financingType, coordinates, ...formData } = data;
    router.replace(
      {
        pathname: '/preflight',
        query: {
          ...router?.query,
          ...formData,
          assetType: assetType?.value,
          loanType: loanType?.value,
          financingType: financingType?.value,
          coordinates: JSON.stringify(coordinates),
        },
      },
      undefined,
      { shallow: true },
    );
    onSubmit?.(data);
  }

  return (
    <Form onSubmit={calculateForm.handleSubmit(onFormSubmit)}>
      <FormGroup isFloating isValid={!(formErrors.address || formErrors.coordinates)}>
        <Label>Address</Label>
        <Controller
          name="address"
          render={({ field: { onChange, value } }) => {
            return (
              <SelectAddress
                inputProps={{
                  id: 'address',
                  placeholder: 'Address',
                }}
                value={value ?? queryValues.address}
                onChange={onChange}
                onSelect={({ __typename, ...coordinates }) => calculateForm.setValue('coordinates', coordinates)}
              />
            );
          }}
          control={calculateForm.control}
          rules={{ required: true }}
          defaultValue={getValues().address}
        />
        {(formErrors.address || formErrors.coordinates) && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <FormGroup isFloating isValid={!formErrors.assetType}>
        <Label>Asset Type</Label>
        <Controller
          name="assetType"
          render={({ field: { onChange, value } }) => (
            <Select
              instanceId="assetType"
              data-testid="assetType"
              placeholder="Pick asset types"
              options={assetTypeOptions}
              value={value}
              onChange={onChange}
            />
          )}
          control={calculateForm.control}
          rules={{ required: true }}
        />

        {formErrors.assetType && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <FormGroup isFloating isValid={!formErrors.loanType}>
        <Label>Deal Type</Label>
        <Controller
          name="loanType"
          render={({ field: { onChange, value } }) => (
            <Select instanceId="loanType" data-testid="loanType" placeholder="Select loan type" options={dealTypeOptions} value={value} onChange={onChange} />
          )}
          control={calculateForm.control}
          rules={{ required: true }}
        />

        {formErrors.loanType && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <FormGroup isFloating isValid={!formErrors.financingType}>
        <Label>Capital Type</Label>
        <Controller
          name="financingType"
          render={({ field: { onChange, value } }) => (
            <Select
              instanceId="financingType"
              data-testid="financingType"
              placeholder="Select capital type"
              options={capitalTypeOptions}
              value={value}
              onChange={onChange}
            />
          )}
          control={calculateForm.control}
          rules={{ required: true }}
        />
        {formErrors.financingType && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <FormGroup isFloating isValid={!formErrors.loanRequestAmount}>
        <Label>Debt Request</Label>
        <Controller
          name="loanRequestAmount"
          render={({ field: { onChange, ref, ...props } }) => (
            <Input
              as={NumericFormat}
              placeholder="$1,000,000"
              prefix="$"
              onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
              thousandSeparator
              {...props}
            />
          )}
          control={calculateForm.control}
          rules={{ required: true, min: 1 }}
        />
        {formErrors.loanRequestAmount?.type === 'required' && <Feedback>This field is required.</Feedback>}
        {formErrors.loanRequestAmount?.type === 'min' && <Feedback>This field can&apos;t be zero.</Feedback>}
      </FormGroup>
      <Row gutter={3}>
        <Col>
          <FormGroup isFloating isValid={!formErrors.totalCost}>
            <Label>Total Cost</Label>
            <Controller
              name="totalCost"
              render={({ field: { onChange, ref, ...props } }) => (
                <Input
                  as={NumericFormat}
                  placeholder="$1,000,000"
                  prefix="$"
                  onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                  thousandSeparator
                  {...props}
                />
              )}
              control={calculateForm.control}
              rules={{ required: true, min: 1 }}
            />

            {formErrors.totalCost?.type === 'required' && <Feedback>This field is required.</Feedback>}
            {formErrors.totalCost?.type === 'min' && <Feedback>This field can&apos;t be zero.</Feedback>}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup isFloating isValid={!formErrors.completedValue}>
            <Label>Completed Value</Label>
            <Controller
              name="completedValue"
              render={({ field: { onChange, ref, ...props } }) => (
                <Input
                  as={NumericFormat}
                  placeholder="$3,000,000"
                  prefix="$"
                  onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                  thousandSeparator
                  {...props}
                />
              )}
              control={calculateForm.control}
              rules={{ required: true, min: 1 }}
            />
            {formErrors.completedValue?.type === 'required' && <Feedback>This field is required.</Feedback>}
            {formErrors.completedValue?.type === 'min' && <Feedback>This field can&apos;t be zero.</Feedback>}
          </FormGroup>
        </Col>
      </Row>
      <Button isBlock isLoading={isLoading}>
        {isRecalculate ? 'Recalculate' : 'Calculate'} Lender Suggestions
      </Button>
    </Form>
  );
};

export default CalculateForm;
