import React, { useContext, useEffect, useState } from 'react';
import { print } from 'graphql/language/printer';
import { Controller, useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';

import { assetTypeOptions, capitalTypeOptions, dealTypeOptions } from '~/data';
import { Button, Feedback, Form, Input, FormGroup, Label } from '../components/form';
import { Col, Row } from '../components/layout';
import { List, ListItem } from '../components/list';
import { Heading, Text } from '../components/type';
import { Avatar, Divider, TabPane } from '../components/ui';
import { Select, SelectAddress } from '../components/vendor';
import { SimpleTooltip } from '../components/vendor';
import { getBaseApiUrl } from '../helpers/getBaseUrl';
import isNotNil from '../helpers/isNotNil';
import { ContactContext } from '../layouts/FrontLayout';
import { MatchModal } from '../modals';
import {
  CoordinatesInput,
  DealCapitalTypeInput,
  DealProjectAssetTypeInput,
  DealProjectLoanTypeInput,
  GetSimplifiedMatchingProgramsDocument,
  GetSimplifiedMatchingProgramsQuery,
  GetSimplifiedMatchingProgramsQueryVariables,
} from '~/generated/graphql';
import { CustomTheme } from '~/@types/styled-components';
import { MatchModalDeal, MatchModalLender } from '~/modals/MatchModal/MatchModal';
import { LenderTypeLabels } from '~/data/lenderTypeOptions';

type MatchForm = {
  address: string;
  financingType: DealCapitalTypeInput;
  loanType: DealProjectLoanTypeInput;
  assetType: DealProjectAssetTypeInput;
  loanRequestAmount: string;
  completedValue: string;
  totalCost: string;
  coordinates: CoordinatesInput;
};

export default function TestMatchForm() {
  const contactFormContext = useContext(ContactContext);
  const [isLoading, setLoading] = useState(false);
  const [displayInitialTooltip, setDisplayInitialTooltip] = useState(true);
  const [matchingData, setMatchingData] = useState<GetSimplifiedMatchingProgramsQuery['getSimplifiedMatchingPrograms']>();
  const [matchLender, setMatchLender] = useState<MatchModalLender>(null);
  const [isMatchModalOpen, setIsMatchModalOpen] = useState(false);
  const [deal, setDeal] = useState<MatchModalDeal>(null);
  const matchForm = useForm<MatchForm>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    matchForm.register('coordinates', { required: true });
  }, [matchForm]);

  const {
    formState: { errors: formErrors },
  } = matchForm;

  const onTestLenderMatch = async (data: MatchForm) => {
    setLoading(true);

    const variables: GetSimplifiedMatchingProgramsQueryVariables = {
      input: {
        capitalType: data.financingType,
        loanType: data.loanType,
        assetType: data.assetType,
        loanRequestAmount: parseFloat(data.loanRequestAmount),
        completedValue: parseFloat(data.completedValue),
        totalCost: parseFloat(data.totalCost),
        coordinates: data.coordinates,
      },
    };

    const {
      data: { getSimplifiedMatchingPrograms: matchingData },
    } = (await (
      await fetch(`${getBaseApiUrl()}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          variables,
          query: print(GetSimplifiedMatchingProgramsDocument),
        }),
      })
    ).json()) as { data: GetSimplifiedMatchingProgramsQuery };

    setDeal({
      project: {
        coordinates: data.coordinates,
        loanType: data.loanType,
        assetType: data.assetType,
      },
      financials: {
        loanRequestAmount: parseFloat(data.loanRequestAmount),
        completedValue: parseFloat(data.completedValue),
        totalCost: parseFloat(data.totalCost),
      },
    });

    setLoading(false);

    contactFormContext.setContactForm((oldContactForm) => ({ ...oldContactForm, lenderMatchingForm: data }));

    setMatchingData(matchingData);
  };

  return (
    <>
      {/* Step 1 */}
      <TabPane isActive={!matchingData}>
        <Heading as="h4" utils={{ textAlign: 'center', fontSize: '2xl', mb: 4 }}>
          Simplified Lender Match
        </Heading>
        <Text utils={{ textAlign: 'center', color: 'gray800', mb: 9 }}>These lenders are best matches for your deal.</Text>
        <Divider size="sm" />
        <Form onSubmit={matchForm.handleSubmit(onTestLenderMatch)}>
          <Row gutter={4}>
            <Col span="100%">
              <FormGroup isValid={!(formErrors.address || formErrors.coordinates)}>
                <Label htmlFor="address">Project address</Label>
                <Controller
                  name="address"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <SelectAddress
                        inputProps={{
                          id: 'address',
                          placeholder: 'Enter a location',
                          utils: { boxShadow: 2 },
                        }}
                        value={value}
                        onChange={onChange}
                        onSelect={({ __typename, ...coordinates }) => matchForm.setValue('coordinates', coordinates)}
                      />
                    );
                  }}
                  control={matchForm.control}
                  rules={{ required: true }}
                  defaultValue=""
                />

                {(formErrors.address || formErrors.coordinates) && <Feedback>This field is required.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.assetType}>
                <Label htmlFor="assetType">Asset type</Label>
                <Controller
                  name="assetType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      instanceId="assetType"
                      data-testid="assetType"
                      placeholder="Asset type"
                      options={assetTypeOptions}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={matchForm.control}
                  rules={{ required: true }}
                />
                {formErrors.assetType && <Feedback>This field is required.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.loanType}>
                <Label htmlFor="loanType">Deal type</Label>
                <Controller
                  name="loanType"
                  render={({ field: { onChange, value } }) => (
                    <Select instanceId="loanType" data-testid="loanType" placeholder="Deal type" options={dealTypeOptions} value={value} onChange={onChange} />
                  )}
                  control={matchForm.control}
                  rules={{ required: true }}
                />
                {formErrors.loanType && <Feedback>This field is required.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.financingType} data-testid="financingType">
                <Label htmlFor="financingType">Capital Type</Label>
                <Controller
                  name="financingType"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      instanceId="financingType"
                      data-testid="financingType"
                      placeholder="Capital Type"
                      options={capitalTypeOptions}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                  control={matchForm.control}
                  rules={{ required: true }}
                />
                {formErrors.financingType && <Feedback>This field is required.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.loanRequestAmount} data-testid="loanRequestAmount">
                <Label htmlFor="loanRequestAmount">Debt request</Label>
                <Controller
                  name="loanRequestAmount"
                  render={({ field: { onChange, ...props } }) => (
                    <NumericFormat
                      customInput={Input}
                      placeholder="$0"
                      utils={{ boxShadow: 2 }}
                      prefix="$"
                      onValueChange={(e) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                      thousandSeparator
                      {...props}
                    />
                  )}
                  control={matchForm.control}
                  rules={{ required: true, min: 1 }}
                  defaultValue=""
                />
                {formErrors.loanRequestAmount?.type === 'required' && <Feedback>This field is required.</Feedback>}
                {formErrors.loanRequestAmount?.type === 'min' && <Feedback>This field can't be zero.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.totalCost}>
                <Label htmlFor="totalCost">Total Cost</Label>
                <Controller
                  name="totalCost"
                  render={({ field: { onChange, ...props } }) => (
                    <NumericFormat
                      customInput={Input}
                      utils={{ boxShadow: 2 }}
                      placeholder="$0"
                      prefix="$"
                      onValueChange={(e) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                      thousandSeparator
                      {...props}
                    />
                  )}
                  control={matchForm.control}
                  rules={{ required: true, min: 1 }}
                  defaultValue=""
                />

                {formErrors.totalCost?.type === 'required' && <Feedback>This field is required.</Feedback>}
                {formErrors.totalCost?.type === 'min' && <Feedback>This field can't be zero.</Feedback>}
              </FormGroup>
            </Col>
            <Col span="100%" desktop={{ span: '50%' }}>
              <FormGroup isValid={!formErrors.completedValue}>
                <Label htmlFor="completedValue">Completed value</Label>
                <Controller
                  name="completedValue"
                  render={({ field: { onChange, ...props } }) => (
                    <NumericFormat
                      customInput={Input}
                      utils={{ boxShadow: 2 }}
                      placeholder="$0"
                      prefix="$"
                      onValueChange={(e) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                      thousandSeparator
                      {...props}
                    />
                  )}
                  control={matchForm.control}
                  rules={{ required: true, min: 1 }}
                  defaultValue=""
                />
                {formErrors.completedValue?.type === 'required' && <Feedback>This field is required.</Feedback>}
                {formErrors.completedValue?.type === 'min' && <Feedback>This field can't be zero.</Feedback>}
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" utils={{ mt: 6, boxShadow: 2 }} isBlock disabled={isLoading} isLoading={isLoading}>
            View Suggested Lenders
          </Button>
        </Form>
      </TabPane>

      {/* Step 2 */}
      <TabPane isActive={!!matchingData}>
        <Heading as="h4" utils={{ textAlign: 'center', fontSize: '2xl', mb: 4 }}>
          Top Lender Matches
        </Heading>
        <Text utils={{ textAlign: 'center', color: 'gray800', mb: 9 }}>These lenders are the best match for your deal.</Text>
        <Divider size="sm" />
        <List>
          {matchingData &&
            matchingData.map((program, i) => {
              let badgeBg: keyof CustomTheme['colors'] = 'danger';
              if (program.matchScore!.score >= 80) {
                badgeBg = 'success';
              } else if (program.matchScore!.score >= 60) {
                badgeBg = 'warning';
              }
              const blur = (i < 3 && '.25rem') || undefined;
              const isFirstElement = i === 0;
              return (
                <ListItem utils={{ py: 7 }} key={program.id}>
                  <Row gutter={4} utils={{ alignItems: 'center' }}>
                    <Col span="auto">
                      <Avatar
                        size="lg"
                        src={getLogoUrl({ logo: program?.lender?.logo, website: program?.lender?.urls[0] })}
                        fallbackSrc={program?.lender?.zoominfo_logo}
                        alt={program.lender.name}
                        badge={Math.round(program.matchScore!.score * 10) / 10 + '%'}
                        badgeProps={{ utils: { bgColor: badgeBg } }}
                        imageProps={{ utils: { border: 1 } }}
                        utils={{ borderRadius: 'sm', blur: blur }}
                      />
                    </Col>
                    <Col span="40%" utils={{ mr: 'auto' }}>
                      <Text utils={{ textTruncate: true, blur: blur }}>{program.lender.name}</Text>
                      <Text utils={{ fontSize: 'xs', color: 'gray700', textTruncate: true }}>{LenderTypeLabels[program.lender.lender_type]} Lender</Text>
                    </Col>
                    <Col span="auto">
                      <SimpleTooltip text="Tap to see details of the match" isVisible={isFirstElement && displayInitialTooltip}>
                        <Button
                          size="sm"
                          variant="white"
                          onClick={() => {
                            setMatchLender({ program, lender: program.lender, blur: Boolean(blur) });
                            setIsMatchModalOpen(true);
                            setDisplayInitialTooltip(false);
                          }}
                        >
                          View Match
                        </Button>
                      </SimpleTooltip>
                    </Col>
                  </Row>
                </ListItem>
              );
            })}
        </List>
        <Button utils={{ mt: 6 }} onClick={() => contactFormContext.setIsContactModalOpen(true)} isBlock>
          Get Started with WelcomeLend
        </Button>
      </TabPane>

      {/* Modals */}
      <MatchModal isOpen={isMatchModalOpen} deal={deal} onClose={() => setIsMatchModalOpen(false)} matchLender={matchLender} enableBlur />
    </>
  );
}
