import { Base } from '~/components/layout';
import styled, { css } from 'styled-components';

type MarkerProps = {
  lat: number;
  lng: number;
  isActive?: boolean;
};

const Marker = styled(Base)<MarkerProps>`
  ${({ isActive, theme }) => css`
    position: relative;
    z-index: ${isActive ? 1 : 0};
    display: inline-block;
    font-family: ${theme.fontFamily.base};
    transform: translate(-50%, -100%) translateY(-6px);
  `}
`;

export default Marker;
