import React, { ComponentProps, FC } from 'react';
import { Card, CardBody } from '~/components/card';
import { Close, Modal } from '~/components/ui';
import { DealFieldsFragment, DealStatus as DealStatusMapped } from '~/generated/graphql';
import { DealStatusListHolder } from '~/components/deal';
import { Heading } from '~/components/type';
import { List, ListItem } from '~/components/list';
import { ModalProps } from '~/components/ui/Modal/Modal';
import useUpdateDealStatus from '~/hooks/useUpdateDealStatus';

type DealStatusModalProps = ModalProps & {
  isAdmin: boolean;
  deal: DealFieldsFragment;
};

const StyledListItem = (props: ComponentProps<typeof ListItem>) => <ListItem utils={{ display: 'flex', alignItems: 'center', fontSize: 'sm' }} {...props} />;

const DealStatusModal: FC<DealStatusModalProps> = ({ deal, isAdmin, isOpen, onClose, ...props }) => {
  const updateDealStatus = useUpdateDealStatus(deal?.settings?.closingFeeBreakdown?.lender);

  function handleDealStatusChange(status: DealStatusMapped) {
    updateDealStatus(deal._id, status, deal.status);
    onClose?.();
  }

  // const closingFee = deal?.settings?.closingFee;

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card isModalContent>
        <CardBody utils={{ p: 7 }}>
          <Close isAbsolute onClick={onClose} />
          <Heading utils={{ fontSize: 'lg', mb: 6 }}>Deal Status</Heading>
          <List gutter={5}>
            <DealStatusListHolder
              as={StyledListItem}
              status={deal?.status}
              disabled={!isAdmin}
              onChange={(status) => {
                handleDealStatusChange(status);
              }}
            />
          </List>
          {/* {isAdmin && (
            <>
              <Divider utils={{ my: 4, mx: 6 }} />
              <FlexRow utils={{ py: 3, px: 6 }}>
                <Text utils={{ mr: 'auto', fontSize: 'base', fontWeight: 'bold' }}>{closingFee ? 'Collected Fees' : 'Estimated Fees'}:</Text>
                <Text utils={{ fontSize: 'sm' }}>{deal?.settings?.fee || closingFee ? formatMoney(closingFee ?? deal?.settings?.fee) : '--'}</Text>
              </FlexRow>
            </>
          )} */}
        </CardBody>
      </Card>
    </Modal>
  );
};

export default DealStatusModal;
