import { useClick, useMenuState } from '@szhsin/react-menu';
import React, { FC, MouseEvent, useRef, useState } from 'react';
import { FlexRow, Block } from '~/components/layout';
import { TableCell, TableIcon, TableRow } from '~/components/table';
import { StretchedLink, Text } from '~/components/type';
import { Toggle } from '~/components/ui';
import { DropdownControlled, DropdownItem, DropdownMenu } from '~/components/vendor';
import { download, edit, trash2 } from '~/components/vendor/Icon/icons';
import QuoteThumbnail from '~/containers/Quote/QuoteThumbnail';
import { QuoteTypeLabels } from '~/data/quoteTypeOptions';
import { CompanyFragment, LightCompanyFragment, QuoteFragment } from '~/generated/graphql';
import { formatDate, getBaseApiUrl } from '~/helpers';
import { useStateWithLocalStorage } from '~/hooks';
import { quoteDeleteModalVar, quoteEditModalVar, quoteViewModalVar } from '~/lib/apolloClient';

type MatchModalQuoteProps = {
  lender: CompanyFragment | LightCompanyFragment;
  quotes: QuoteFragment[];
  quote: QuoteFragment;
};

const MatchModalQuote: FC<MatchModalQuoteProps> = ({ lender, quotes, quote }) => {
  const [token] = useStateWithLocalStorage('sessionToken');

  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number } | null>(null);
  const anchorProps = useClick(menuState.state, toggleMenu);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const handleClose = () => {
    toggleMenu(false);
    setAnchorPoint(null);
  };

  return (
    <TableRow key={quote._id}>
      <TableCell utils={{ flexWrap: 'nowrap' }}>
        <QuoteThumbnail quote={quote} />
        <Block style={{ minWidth: 0 }}>
          <StretchedLink onClick={() => quoteViewModalVar({ quotes, quote, lender })} onContextMenu={(e) => handleContextMenu(e)}>
            <FlexRow utils={{ alignItems: 'center' }}>
              <Text utils={{ fontSize: 'base', fontWeight: 'bold', textTruncate: true, color: 'black' }}>{QuoteTypeLabels[quote.type]}</Text>
            </FlexRow>
          </StretchedLink>
          <Text utils={{ fontSize: 'sm', color: 'gray700', textTruncate: true }}>
            <Text as="span">Updated </Text>
            {formatDate(quote.updatedAt)}
          </Text>
        </Block>
      </TableCell>

      <TableIcon mobile={{ ml: -9 }}>
        <Toggle ref={ref} {...anchorProps} />
        <DropdownControlled anchorRef={anchorPoint ? undefined : ref} anchorPoint={anchorPoint ? anchorPoint : undefined} onClose={handleClose} {...menuState}>
          <DropdownMenu>
            <DropdownItem
              onClick={() => {
                window.location.href = `${getBaseApiUrl()}/api/file/${quote.attachment._id}?token=${encodeURIComponent(token)}&attachment=true`;
              }}
              icon={download}
            >
              Download
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                quoteEditModalVar({ quote, isOpen: true });
              }}
              icon={edit}
            >
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                quoteDeleteModalVar({ quote });
              }}
              icon={trash2}
              isDisabled={quote.isClosingQuote}
              disabled={quote.isClosingQuote}
              {...(quote.isClosingQuote
                ? {
                    'data-tooltip-id': 'GlobalTooltip',
                    'data-tooltip-content': 'You cannot delete this quote because it is the Closing Quote and a closed deal.',
                  }
                : {})}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </DropdownControlled>
      </TableIcon>
    </TableRow>
  );
};

export default MatchModalQuote;
