import { Base } from '~/components/layout';
import { Image } from '~/components/ui';
import styled, { css } from 'styled-components';

type MarkerIconProps = {
  isActive?: boolean;
  isHovered?: boolean;
};

const MarkerIcon = styled(Base)<MarkerIconProps>`
  ${({ theme }) => css`
    --market-icon-bg: ${theme.marketIconBg};
    --market-icon-border-radius: ${theme.marketIconBorderRadius}px;
    --market-icon-border-width: ${theme.marketIconBorderWidth}px;
    --market-icon-border-color: ${theme.marketIconBorderColor};
    --market-icon-font-size: ${theme.marketIconFontSize}px;
    --market-icon-font-weight: ${theme.marketIconFontWeight};
    --market-icon-color: ${theme.marketIconColor};
    --market-icon-box-shadow: ${theme.marketIconBoxShadow};
    --market-icon-arrow-size: ${theme.marketIconArrowSize}px;
    position: relative;
    box-shadow: var(--market-icon-box-shadow);
    background-color: var(--market-icon-bg);
    border: var(--market-icon-border-width) solid var(--market-icon-border-color);
    border-radius: var(--market-icon-border-radius);
    font-family: ${theme.fontFamily.base};
    font-weight: var(--market-icon-font-weight);
    font-size: var(--market-icon-font-size);
    white-space: nowrap;
    color: var(--market-icon-color);

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      border-top: calc(var(--market-icon-arrow-size) - var(--market-icon-border-width) * 2) solid var(--market-icon-border-color);
      border-left: calc(var(--market-icon-arrow-size) - var(--market-icon-border-width) * 2) solid transparent;
      border-right: calc(var(--market-icon-arrow-size) - var(--market-icon-border-width) * 2) solid transparent;
    }

    &::before {
      border-top: var(--market-icon-arrow-size) solid var(--market-icon-border-color);
      border-left: var(--market-icon-arrow-size) solid transparent;
      border-right: var(--market-icon-arrow-size) solid transparent;
    }

    ${Image.styledComponent} {
      border-radius: inherit;
    }
  `}
`;

export default MarkerIcon;
