import { FloatRangeInput } from '~/generated/graphql';

const filterComparison = ['LessThan', 'MoreThan', 'IsBetween'] as const;
export type FilterComparison = (typeof filterComparison)[number];

const filterExtendedComparison = [...filterComparison, 'EqualTo'] as const;
export type FilterExtendedComparison = (typeof filterExtendedComparison)[number];

export type FloatRange = {
  min?: number | null;
  max?: number | null;
  comparison: { value: FilterExtendedComparison; label: string };
};

export type DateTimeRange = {
  min?: string | null;
  max?: string | null;
  comparison: { value: FilterComparison; label: string };
};

export const DATE_PATTERN = /^(\d{2})\/(\d{2})\/(\d{4})$/;

export const DealFilterFields = {
  owner: 'Deal Owner',
  adminMembers: 'Deal Team',
  assetType: 'Asset Type',
  dealType: 'Deal Type',
  capitalType: 'Capital Type',
  loanAmount: 'Loan Amount',
  totalFee: 'Fee',
  location: 'Location',
  status: 'Status',
  updatedAt: 'Updated',
  createdAt: 'Created',
};

export const LenderFilterFields = {
  lenderType: 'Lender Type',
  researchStatus: 'Research Status',
  requireContact: 'Require a Contact',
  location: 'Locations',
  responsiveness: 'Responsiveness',
  interestFrequency: 'Interest Frequency',
  updatedAt: 'Updated',
  createdAt: 'Created',
  'program.assetTypes': 'Asset Type',
  'program.dealTypes': 'Deal Type',
  'program.capitalTypes': 'Capital Type',
  'program.loanValueMin': 'Loan Amount (Min)',
  'program.loanValueMax': 'Loan Amount (Max)',
  'program.lendingStates': 'Lending State',
  'program.recourses': 'Recourse',
  'program.depositRequired': 'Deposits',
  'program.ltvMax': 'LTV (Max)',
  'program.ltcMax': 'LTC (Max)',
  'program.dscr': 'DSCR',
  'program.debtYieldMin': 'Debt Yield',
  'program.matchScore': 'Match Score',
  'program.updatedAt': 'Updated',
  'program.createdAt': 'Created',
  'quote.types': 'Type',
  'quote.assetTypes': 'Asset Type',
  'quote.dealTypes': 'Deal Type',
  'quote.capitalTypes': 'Capital Type',
  'quote.loanValue': 'Loan Amount',
  'quote.recourses': 'Recourse',
  'quote.depositRequired': 'Deposits',
  'quote.ltv': 'LTV',
  'quote.ltc': 'LTC',
  'quote.dscr': 'DSCR',
  'quote.debtYield': 'Debt Yield',
  'quote.updatedAt': 'Updated',
  'quote.createdAt': 'Created',
  'deal.owner': DealFilterFields.owner,
  'deal.adminMembers': DealFilterFields.adminMembers,
  'deal.assetType': DealFilterFields.assetType,
  'deal.dealType': DealFilterFields.dealType,
  'deal.capitalType': DealFilterFields.capitalType,
  'deal.loanAmount': DealFilterFields.loanAmount,
  'deal.totalFee': DealFilterFields.totalFee,
  'deal.location': DealFilterFields.location,
  'deal.status': DealFilterFields.status,
  'deal.lenderStatus': 'Lender Status',
  'deal.similarityScore': 'Similarity Score',
  'deal.updatedAt': DealFilterFields.updatedAt,
  'deal.createdAt': DealFilterFields.createdAt,
  'loan.assetTypes': 'Asset Type',
  'loan.dealTypes': 'Deal Type',
  'loan.location': 'Location',
  'loan.loanValue': 'Total Loan Value',
  'loan.executedDate': 'Date Issued',
  'loan.termMonths': 'Term',
  'loan.matchScore': 'Similarity Score',
};

export const QuoteFilterFields = {
  types: 'Type',
  assetTypes: 'Asset Type',
  dealTypes: 'Deal Type',
  capitalTypes: 'Capital Type',
  loanValue: 'Loan Amount',
  recourses: 'Recourse',
  depositRequired: 'Deposits',
  ltv: 'LTV',
  ltc: 'LTC',
  dscr: 'DSCR',
  debtYield: 'Debt Yield',
  updatedAt: 'Updated',
  createdAt: 'Created',
  'lender.lenderType': 'Lender Type',
  'lender.researchStatus': 'Research Status',
  'lender.requireContact': 'Require a Contact',
  'lender.location': 'Locations',
  'lender.responsiveness': 'Responsiveness',
  'lender.interestFrequency': 'Interest Frequency',
  'lender.updatedAt': 'Updated',
  'lender.createdAt': 'Created',
  'deal.owner': DealFilterFields.owner,
  'deal.adminMembers': DealFilterFields.adminMembers,
  'deal.assetType': DealFilterFields.assetType,
  'deal.dealType': DealFilterFields.dealType,
  'deal.capitalType': DealFilterFields.capitalType,
  'deal.loanAmount': DealFilterFields.loanAmount,
  'deal.totalFee': DealFilterFields.totalFee,
  'deal.location': DealFilterFields.location,
  'deal.status': DealFilterFields.status,
  'deal.lenderStatus': 'Lender Status',
  'deal.updatedAt': DealFilterFields.updatedAt,
  'deal.createdAt': DealFilterFields.createdAt,
};

export const DealFilterValueComparisonLabels: Record<FilterExtendedComparison, string> = {
  LessThan: 'Less Than',
  MoreThan: 'More Than',
  IsBetween: 'Is Between',
  EqualTo: 'Equal to',
};

export const DealFilterDateComparisonLabels: Record<FilterExtendedComparison, string> = {
  LessThan: 'Before',
  MoreThan: 'After',
  IsBetween: 'Between',
  EqualTo: 'Equal to',
};

export const filterValueComparisonOptions: { value: FilterComparison; label: string }[] = Object.entries(DealFilterValueComparisonLabels)
  .filter(([value]) => filterComparison.includes(value as FilterComparison))
  .map(([value, label]) => ({
    value: value as FilterComparison,
    label,
  }));

export const filterValueExtendedComparisonOptions: { value: FilterExtendedComparison; label: string }[] = Object.entries(DealFilterValueComparisonLabels)
  .filter(([value]) => filterExtendedComparison.includes(value as FilterExtendedComparison))
  .map(([value, label]) => ({
    value: value as FilterExtendedComparison,
    label,
  }));

export const filterDateComparisonOptions: { value: FilterComparison; label: string }[] = Object.entries(DealFilterDateComparisonLabels)
  .filter(([value]) => filterComparison.includes(value as FilterComparison))
  .map(([value, label]) => ({
    value: value as FilterComparison,
    label,
  }));

export const radiusMilesOptions = [5, 20, 50, 100, 250];

export const getComparison = (
  filter: { min?: number | string | Date | null; max?: number | string | Date | null } | null = {},
  options = filterValueComparisonOptions,
) => {
  const { min, max } = filter ?? {};
  const isMin = typeof min !== 'undefined' && min !== null;
  const isMax = typeof max !== 'undefined' && max !== null;

  let comparison: FilterComparison = 'MoreThan';
  if (isMin && isMax) {
    comparison = 'IsBetween';
  } else if (!isMin && isMax) {
    comparison = 'LessThan';
  }

  return options.find(({ value }) => value === comparison);
};

export const showMin = ({ comparison }: { comparison: { value: FilterExtendedComparison; label: string } }) => {
  return ['MoreThan', 'IsBetween', 'EqualTo'].includes(comparison.value);
};

export const showMax = ({ comparison }: { comparison: { value: FilterExtendedComparison; label: string } }) => {
  return ['LessThan', 'IsBetween'].includes(comparison.value);
};

export const getFilledFieldsCount = (fields: Record<string, any> | null | undefined, exclude?: string[]) => {
  if (!fields) {
    return 0;
  }
  return Object.entries(fields).filter(([key, value]) => {
    if (exclude?.includes(key)) {
      return false;
    }
    if (!value && value !== false) {
      return false;
    }
    if (Array.isArray(value)) {
      return (value as string[]).length;
    }
    if (typeof value === 'object') {
      return Object.keys(value).filter((k) => (value as FloatRangeInput)[k as keyof FloatRangeInput]).length;
    }
    return true;
  }).length;
};
