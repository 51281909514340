import React, { useContext, useEffect, useState } from 'react';
import { Block, Col, Container, FlexRow, Row } from '../components/layout';
import { Button } from '../components/form';
import { Collapse } from 'react-collapse';
import { ContactContext } from '../layouts/FrontLayout';
import { Header, Nav, NavLink, Toggler } from '../components/nav';
import { Heading } from '../components/type';
import { Image, Scrollable } from '../components/ui';
import { Sticky } from '~/components/layout';
import { useRouter } from 'next/router';
import Link from 'next/link';

export default function HeaderFront() {
  const { pathname } = useRouter();
  const [collapsed, setCollapsed] = useState(false);
  const contactFormContext = useContext(ContactContext);

  // Collapse menu on route path change
  useEffect(() => setCollapsed(false), [pathname]);

  return (
    <Sticky style={{ top: 0 }}>
      {(isStuck) => (
        <Block utils={{ width: '100%', borderBottom: 1, borderBottomColor: !collapsed && !isStuck ? 'white' : undefined, mb: 0 }}>
          <Header utils={{ borderTop: 3, borderBottom: 0, borderTopColor: 'primary', mb: 0 }} id="header">
            <Container utils={{ width: '100%' }}>
              <Row utils={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Col span="auto">
                  <Link href="/" passHref legacyBehavior>
                    <FlexRow as="a" utils={{ alignItems: 'center', color: 'black' }}>
                      <Image src="/img/brand/brand.svg" alt="WelcomeLend" style={{ width: 32, height: 'auto' }} />
                      <Heading as="span" utils={{ fontSize: 'base', ml: 4 }}>
                        WelcomeLend
                      </Heading>
                    </FlexRow>
                  </Link>
                </Col>
                <Col mobile={{ display: 'none' }}>
                  <Nav utils={{ justifyContent: 'center' }} isFlush={!isStuck}>
                    <Link href="/" legacyBehavior>
                      <NavLink as="span" isActive={pathname === '/'}>
                        Product
                      </NavLink>
                    </Link>
                    <Link href="/company" legacyBehavior>
                      <NavLink as="span" isActive={pathname === '/company'}>
                        Company
                      </NavLink>
                    </Link>
                    <Link href="/closed-deals" legacyBehavior>
                      <NavLink as="span" isActive={pathname === '/closed-deals'}>
                        Closed Deals
                      </NavLink>
                    </Link>
                  </Nav>
                </Col>
                <Col span="auto">
                  <Button
                    size="sm"
                    utils={{ fontSize: 'sm' }}
                    mobile={{ display: 'none' }}
                    isLink
                    onClick={() => contactFormContext.setIsContactModalOpen(true)}
                  >
                    Contact
                  </Button>
                  <Link href="/sign-in" legacyBehavior>
                    <Button size="sm" variant="primary" utils={{ fontSize: 'sm', boxShadow: 2 }}>
                      Sign In
                    </Button>
                  </Link>
                  <Toggler utils={{ ml: 6 }} desktop={{ display: 'none' }} isActive={collapsed} onClick={() => setCollapsed(!collapsed)} />
                </Col>
              </Row>
            </Container>
          </Header>
          <Collapse isOpened={collapsed}>
            <Header as={Scrollable} size="sm" utils={{ borderTop: 1, borderBottom: 0, mb: 0 }}>
              <Container>
                <Row>
                  <Col>
                    <Nav size="sm" utils={{ justifyContent: 'center' }}>
                      <Link href="/" legacyBehavior>
                        <NavLink as="span" isActive={pathname === '/'}>
                          Product
                        </NavLink>
                      </Link>
                      <Link href="/company" legacyBehavior>
                        <NavLink as="span" isActive={pathname === '/company'}>
                          Company
                        </NavLink>
                      </Link>
                      <Link href="/closed-deals" legacyBehavior>
                        <NavLink as="span" isActive={pathname === '/closed-deals'}>
                          Closed Deals
                        </NavLink>
                      </Link>
                      <NavLink onClick={() => contactFormContext.setIsContactModalOpen(true)}>Contact</NavLink>
                    </Nav>
                  </Col>
                </Row>
              </Container>
            </Header>
          </Collapse>
        </Block>
      )}
    </Sticky>
  );
}
