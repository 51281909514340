import { useCallback, useEffect, useMemo } from 'react';
import useLocalStorage from './useLocalStorage';
import { useRouter } from 'next/router';
import { GetDealSelectedContactsDocument, GetDealSelectedContactsQuery, useGetDealSelectedContactsQuery } from '~/generated/graphql';
import { useApolloClient } from '@apollo/client';

type UseDealSelectedContactReturnType = [string[], (email: string, role: 'borrower' | 'lender') => void, () => void, 'borrower' | 'lender'];

const useDealSelectedEmails = () => {
  const client = useApolloClient();

  const router = useRouter();
  const dealId = router.query?.dealId as string;

  const [localStorageEmails, setLocalStorageEmails] = useLocalStorage<string[]>(`deal_${dealId}_contacts`, []);
  const [localStorageRole, setLocalStorageRole] = useLocalStorage<'borrower' | 'lender'>(`deal_${dealId}_role`, 'borrower');

  const writeQuery = (emails: string[], role: 'borrower' | 'lender') => {
    client.writeQuery<GetDealSelectedContactsQuery>({
      query: GetDealSelectedContactsDocument,
      variables: { dealId },
      data: {
        getDealSelectedContacts: { emails, role },
      },
    });
  };

  const { data } = useGetDealSelectedContactsQuery({ variables: { dealId }, client });
  const emails = data?.getDealSelectedContacts?.emails ?? [];
  const role = data?.getDealSelectedContacts?.role ?? 'lender';

  useEffect(() => {
    writeQuery(localStorageEmails || [], localStorageRole);
  }, [dealId]);

  const toggleEmail = useCallback(
    (email: string, emailRole: 'borrower' | 'lender') => {
      if (role !== emailRole) {
        setLocalStorageRole(emailRole);
        setLocalStorageEmails([email]);
        writeQuery([email], emailRole);
        return;
      }

      const newEmails = emails.includes(email) ? emails.filter((oldEmail) => oldEmail !== email) : [...emails, email];
      setLocalStorageRole(emailRole);
      setLocalStorageEmails(newEmails);
      writeQuery(newEmails, emailRole);
    },
    [dealId, emails],
  );

  const clearEmails = useCallback(() => {
    setLocalStorageEmails([]);
    setLocalStorageRole('lender');
    writeQuery([], 'lender');
  }, []);

  return useMemo<UseDealSelectedContactReturnType>(
    () => [emails, toggleEmail, clearEmails, role as 'borrower' | 'lender'],
    [emails, toggleEmail, clearEmails, role],
  );
};

export default useDealSelectedEmails;
