import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Feedback, Form, Input, FormGroup } from '../components/form';
import { Select } from '../components/vendor';
import { getFolderOptions } from '../helpers';
import getNormalizedFolderTree, { NormalizedFolderTreeItem } from '../helpers/getNormalizedFolderTree';
import {
  DocFolderFragment,
  useGetSingleDealQuery,
  GetUsersForFolderQuery,
  useGetUsersForFolderQuery,
  useGetDealMembersQuery,
  DealUserRole,
} from '~/generated/graphql';
import useDocumentFoldersOps from './Document/useDocumentFoldersOps';
import FolderAccessSelect from './Document/FolderAccessSelect';
import { FOLDER_ACCESS_ERROR, getFolderAccessErrors } from '~/helpers/documentsAccess';

type EditDocumentFolderFormProps = {
  onClose: () => void;
  folder: NormalizedFolderTreeItem;
};

const EditDocumentFolderForm: FC<EditDocumentFolderFormProps> = ({ folder, onClose }) => {
  const dealId = useRouter().query.dealId as string;

  const { data: dealData } = useGetSingleDealQuery({
    variables: { id: dealId },
    fetchPolicy: 'cache-only',
  });

  const deal = dealData?.getSingleDeal;
  const isAdmin = deal?.userDealRole === 'ADMIN';
  const isBorrower = deal?.userDealRole === 'BORROWER';

  const { data: usersForFolderData } = useGetUsersForFolderQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
    skip: !isAdmin,
  });
  const { data: dealMembersData } = useGetDealMembersQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
  });
  const dealAdmins = dealMembersData?.getDealMembers.usersAndInvitations.filter((data) => data.role === DealUserRole.Admin) ?? [];

  const {
    updateDocumentFolder: { updateDocumentFolder, updatingDocumentFolder },
    getCacheDocRequestFolders,
  } = useDocumentFoldersOps({ dealId });

  const data = getNormalizedFolderTree(getCacheDocRequestFolders()!);

  const folderOptions = getFolderOptions({
    data,
    ignoreRoot: false,
    hiddenFolderId: folder.id,
  });

  const [name, setName] = useState(folder.title);
  const [parentFolder, setParentFolder] = useState(
    folder
      ? {
          value: folder.data!.parentFolderId!,
          label: folderOptions.find((o) => o.value === (folder.data!.parentFolderId || 'root'))!.label,
        }
      : null,
  );

  const [adminsOnlyAccess, setAdminsOnlyAccess] = useState(Boolean((folder?.data as DocFolderFragment).adminsOnlyAccess));
  const [usersWithAccess, setUsersWithAccess] = useState<GetUsersForFolderQuery['getUsersForFolder']>([]);

  useEffect(() => {
    setAdminsOnlyAccess(Boolean((folder?.data as DocFolderFragment).adminsOnlyAccess));
    setUsersWithAccess(
      usersForFolderData?.getUsersForFolder
        ? ((folder?.data as DocFolderFragment).usersWithAccess ?? []).map((userWithAccess) => {
            return usersForFolderData?.getUsersForFolder.find((userForFolder) => {
              if (userForFolder.__typename === 'DealMember') {
                return (userForFolder.user?.fullName ?? userForFolder.email) === userWithAccess;
              }
              if (userForFolder.__typename === 'LenderForFolder') {
                return userForFolder.name === userWithAccess || userForFolder.domain === userWithAccess;
              }
            })!;
          })
        : [],
    );
  }, [folder, usersForFolderData]);

  const [errors, setErrors] = useState({
    submitted: false,
    name: false,
    accessErrors: [] as FOLDER_ACCESS_ERROR[],
  });

  const handleEditSingleFolderReq: React.FormEventHandler = async (e) => {
    e.preventDefault();

    const transformedUsersWithAccess = adminsOnlyAccess
      ? []
      : (usersWithAccess.map((userWithAccess) =>
          userWithAccess?.__typename === 'LenderForFolder'
            ? userWithAccess.domain
            : userWithAccess?.__typename === 'DealMember'
              ? (userWithAccess?.user?._id ?? userWithAccess?._id)
              : null,
        ) as string[]);

    const accessErrors = getFolderAccessErrors(data, dealAdmins, folder.id, parentFolder!.value, transformedUsersWithAccess, Boolean(adminsOnlyAccess));
    if (accessErrors.length) {
      setErrors({
        ...errors,
        submitted: true,
        accessErrors,
      });
      return;
    }

    setErrors({ ...errors, submitted: true, accessErrors });

    if (errors.name) {
      return;
    }

    const variables = {
      id: folder.id,
      name,
      parentFolderId: parentFolder!.value === 'root' ? null : parentFolder!.value,
      usersWithAccess: adminsOnlyAccess ? [] : isBorrower ? (folder?.data as DocFolderFragment)?.usersWithAccess : transformedUsersWithAccess,
      adminsOnlyAccess,
    };

    const newOrderValue = data.items[parentFolder!.value]?.children?.length! + 1;

    await updateDocumentFolder(variables, newOrderValue, transformedUsersWithAccess);

    // FIXME: For some reason Apollo doesn't want to update the cache without it. OMG.
    // Investigate this thing. Probably it's because of how modal is written.
    setTimeout(() => {
      onClose();
    }, 20);
  };

  return (
    <Form utils={{ mb: 6 }} onSubmit={handleEditSingleFolderReq}>
      <FormGroup isValid={!(errors.submitted && errors.name)}>
        <Input
          type="text"
          name="folderName"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {errors.submitted && errors.name && <Feedback>A Folder name is required</Feedback>}
      </FormGroup>
      <FormGroup isValid={!(errors.submitted && (errors.accessErrors.includes('NESTING') || errors.accessErrors.includes('TARGET_FOLDER_TASK')))}>
        <Select
          options={folderOptions}
          name="parentFolder"
          onChange={(value) => {
            setParentFolder(value);
          }}
          value={parentFolder}
        />
        {errors.submitted && errors.accessErrors.includes('NESTING') && <Feedback>Cannot nest folders with limited access</Feedback>}
        {errors.submitted && errors.accessErrors.includes('TARGET_FOLDER_TASK') && <Feedback>Assignees don't have access to target folder</Feedback>}
      </FormGroup>
      {isAdmin && !deal?.isTemplate && (
        <FormGroup isValid={!(errors.submitted && (errors.accessErrors.includes('CURRENT_FOLDER_TASK') || errors.accessErrors.includes('NESTED')))}>
          <FolderAccessSelect
            dealId={deal._id}
            isAdmin={isAdmin}
            usersWithAccess={usersWithAccess}
            adminsOnlyAccess={adminsOnlyAccess}
            onChange={(usersWithAccess, adminsOnlyAccess) => {
              setUsersWithAccess(usersWithAccess as GetUsersForFolderQuery['getUsersForFolder'][number][]);
              setAdminsOnlyAccess(adminsOnlyAccess);
            }}
          />
          {errors.submitted && errors.accessErrors.includes('CURRENT_FOLDER_TASK') && (
            <Feedback>All assignees in this folder must be included to limit access</Feedback>
          )}
          {errors.submitted && errors.accessErrors.includes('NESTED') && <Feedback>Cannot nest folders with limited access</Feedback>}
        </FormGroup>
      )}
      <Button variant="primary" isBlock isLoading={updatingDocumentFolder}>
        Save changes
      </Button>
    </Form>
  );
};

export default EditDocumentFolderForm;
