import React from 'react';
import { DealStatus as DealStatusEnum } from '~/generated/graphql';
import { DealStatusMapped, DealStatusIcons, DealStatusVariants, DealStatusProgress } from '~/data/dealStatus';
import { Status } from '~/components/ui';
import { Text } from '~/components/type';
import { DropdownItem } from '~/components/vendor';

type DealStatusListHolderProps = {
  as?: React.FC;
  status?: DealStatusEnum;
  disabled?: boolean;
  onChange?: (status: DealStatusEnum) => void;
};

const DealStatusListHolder = ({ as, disabled, onChange }: DealStatusListHolderProps) => {
  const Component: React.FC | typeof DropdownItem = as ? as : DropdownItem;
  return (
    <>
      {Object.keys(DealStatusMapped).map((dealStatus) => (
        <Component
          key={dealStatus}
          data-testid={dealStatus}
          style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          onClick={() => {
            !disabled && onChange && onChange(dealStatus as DealStatusEnum);
          }}
        >
          <Status
            icon={DealStatusIcons[dealStatus as DealStatusEnum]}
            variant={DealStatusVariants[dealStatus as DealStatusEnum]}
            progress={DealStatusProgress[dealStatus as DealStatusEnum]}
            utils={{ mr: 4 }}
          />
          <Text>{DealStatusMapped[dealStatus as DealStatusEnum]}</Text>
        </Component>
      ))}
    </>
  );
};

export default DealStatusListHolder;
