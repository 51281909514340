import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import { Icon } from '~/components/vendor';
import { useApolloClient } from '@apollo/client';
import { Card, CardBody, CardGroup } from '../components/card';
import { FileIcon, FileStatus } from '../components/file';
import { Button, FileInput } from '../components/form';
import { Block, Col, Row } from '../components/layout';
import { PreviewModal, PreviewModalContent, PreviewModalLeft, PreviewModalRight, PreviewXLS, PreviewPdf, PreviewImage } from '../components/preview';
import { Heading, StretchedLink, Text } from '../components/type';
import { Close, Spinner, Toggle } from '../components/ui';
import { Dropdown, DropdownMenu, DropdownItem, Dropzone } from '../components/vendor';
import { DocumentReviewForm } from '../containers';
import {
  useGetSingleDealQuery,
  useRequestDocumentsUpdatedSubscription,
  useGetLocalUserQuery,
  useUploadDocumentMutation,
  useCreateDocumentPreviewMutation,
  useDeleteDocumentMutation,
  IsLoadingDocument,
  SearchDocumentsDocument,
  useSearchDocumentsQuery,
  useGetDocumentRequestQuery,
  DocumentRequestStatus,
  SearchDocumentsQuery,
  DocRequestFragment,
  IsLoadingQuery,
  DocRequestFragmentDoc,
  useDocumentRequestUpdatedSubscription,
  useGetDocumentRequestFoldersQuery,
} from '../generated/graphql';
import { formatDate, formatExtension, getBaseApiUrl } from '../helpers';
import getNormalizedFolderTree, { NormalizedDocRequest, NormalizedFolderTreeItem } from '../helpers/getNormalizedFolderTree';
import { useStateWithLocalStorage } from '../hooks';
import AutoUpdatesDocumentModal from './AutoUpdatesDocumentModal';
import { getTaskAssigneeStatus, getTaskUserName } from '~/containers/Tasks/getTaskUserName';
import { AssignDocReqDropdown } from '~/containers/Tasks/AssignDocReqDropdown';
import { TaskUserAvatar } from '~/containers/Tasks/TaskUserAvatar';
import ConfirmModal from './ConfirmModal';
import { CustomTheme } from '~/@types/styled-components';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { download, edit, refreshCcw, repeat, trash2 } from '~/components/vendor/Icon/icons';
import { userCircle01 } from '~/components/vendor/Icon/icons';
import { showAutoAssignmentAlert } from '~/containers/Tasks/showAutoAssignmentAlert';

type Document = SearchDocumentsQuery['searchDocuments'][number] & {
  isAutoUpdateInProgress?: boolean;
};

type DocumentsModalProps = {
  activeRequest?: DocRequestFragment | null;
  editedDocRequestId: string | null;
  handleResetDocStatus: (docRequest: { _id: string }, callback?: () => void) => Promise<void>;
  isOpen: boolean;
  onClose?: () => void;
  onExited?: () => void;
  openEditReqModal: () => void;
  setDeleteFolderModal: (data: { isOpen: boolean; item?: NormalizedFolderTreeItem }) => void;
};

const DocumentsModal: FC<DocumentsModalProps> = ({
  activeRequest,
  editedDocRequestId,
  handleResetDocStatus,
  isOpen,
  onClose,
  onExited,
  openEditReqModal,
  setDeleteFolderModal,
  ...props
}) => {
  const client = useApolloClient();
  const router = useRouter();
  const showGlobalAlert = useShowGlobalAlert();
  const dealId = router.query.dealId as string;
  const { data: dealData } = useGetSingleDealQuery({
    variables: { id: dealId },
    fetchPolicy: 'cache-only',
  });

  const { data: docReqFoldersData } = useGetDocumentRequestFoldersQuery({
    fetchPolicy: 'cache-first',
    variables: { dealId },
  });
  const docReqFolders = docReqFoldersData?.getDocumentRequestFolders;

  const [uploadDocument, { loading: docIsUploading, error: fileUploadError }] = useUploadDocumentMutation();
  const [createDocumentPreview, { loading: previewIsGenerating }] = useCreateDocumentPreviewMutation();
  const [deleteDocument, { loading: docIsDeleting }] = useDeleteDocumentMutation();
  const { loading: currentUserDataLoading, data: currentUserData } = useGetLocalUserQuery({ fetchPolicy: 'cache-only' }); // Get Logged in User's data from Apollo Cache
  const currentUserId = currentUserData?.currentUser?._id!;

  function updateDocumentRequestStatus(requestId: string, updatedStatus: { status: string; lastDocumentId?: string }) {
    const cachedRequest = client.readFragment<DocRequestFragment>({
      id: `DocumentRequest:${requestId}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
    });

    client.writeFragment({
      id: `DocumentRequest:${requestId}`,
      fragmentName: 'DocRequest',
      fragment: DocRequestFragmentDoc,
      data: {
        ...cachedRequest,
        ...updatedStatus,
      },
    });
  }

  const editedDeal = dealData?.getSingleDeal;

  const [token] = useStateWithLocalStorage('sessionToken');
  const [deletingDoc, setDeletingDoc] = useState<Document | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAutoUpdateModal, setOpenAutoUpdateModal] = useState(false);
  const [activeKey, setActiveKey] = useState('');
  const [previewContent, setPreviewContent] = useState([]);
  const [uploadingFile, setUploadingFile] = useState<File | null>(null);

  const { loading, data, refetch } = useSearchDocumentsQuery({
    // fetchPolicy: 'network-only',
    variables: {
      docRequestId: editedDocRequestId,
    },
    skip: !editedDocRequestId,
  });

  let documents = data?.searchDocuments ?? [];

  const {
    startPolling: startGetDocumentRequestPolling,
    stopPolling: stopGetDocumentRequestPolling,
    data: documentRequestData,
  } = useGetDocumentRequestQuery({
    variables: {
      docRequestId: editedDocRequestId!,
    },
    skip: !editedDocRequestId,
  });

  const task = documentRequestData?.getDocumentRequest?.task;

  useDocumentRequestUpdatedSubscription({
    variables: {
      dealId,
    },
    skip: !editedDocRequestId,
    onData: ({ client, data: subscriptionData }) => {
      const newDocReq = subscriptionData.data?.documentRequestUpdated;
      if (newDocReq?._id === editedDocRequestId) {
        const prevDocReq = client.readFragment<DocRequestFragment>({
          id: `DocumentRequest:${newDocReq._id}`,
          fragmentName: 'DocRequest',
          fragment: DocRequestFragmentDoc,
        });
        if (request) {
          // If modal is opened and new task is from the opened document request
          // We should display auto-assignment alert
          showAutoAssignmentAlert({
            showGlobalAlert,
            newDocReq,
            prevDocReq: prevDocReq!,
            currentUserId,
          });
        }
      }
    },
    shouldResubscribe: true,
  });

  useRequestDocumentsUpdatedSubscription({
    variables: {
      docRequestId: editedDocRequestId!,
    },
    skip: !editedDocRequestId,
    shouldResubscribe: true,
    onData: ({ client, data: subscriptionData }) => {
      if (subscriptionData?.data?.requestDocumentsUpdated && data?.searchDocuments) {
        const isActiveDocumentDeleted =
          !deletingDoc && data.searchDocuments.length !== 0 && subscriptionData.data.requestDocumentsUpdated.findIndex((doc) => doc!._id === activeKey) === -1;
        if (isActiveDocumentDeleted) {
          showGlobalAlert('This document was just deleted', 'danger');
          if (subscriptionData.data.requestDocumentsUpdated.length !== 0) {
            setActiveKey(subscriptionData.data.requestDocumentsUpdated[subscriptionData.data.requestDocumentsUpdated.length - 1]!._id);
          }
        }
      }
      client.writeQuery<SearchDocumentsQuery>({
        query: SearchDocumentsDocument,
        variables: {
          docRequestId: editedDocRequestId,
        },
        data: { searchDocuments: subscriptionData?.data?.requestDocumentsUpdated as SearchDocumentsQuery['searchDocuments'] },
      });
    },
  });

  // Always get from the server on open
  useEffect(() => {
    if (editedDocRequestId && !loading) {
      refetch({ docRequestId: editedDocRequestId });
    }
  }, [editedDocRequestId, loading, refetch]);

  /**
   * Long polling for document request in case
   * if subscription on autoGenerationFinished is failed
   * https://linear.app/welcomelend/issue/WL-121/fix-infinite-generation-state-when-toggling-between-different-auto
   */
  useEffect(() => {
    const request = documentRequestData?.getDocumentRequest;
    const isModalOpen = editedDocRequestId && isOpen;
    // Decide if we need to start polling
    if (isModalOpen && request) {
      const isAutoUpdatesEnabled = Boolean(autoUpdateSource);
      const isAutoUpdateInProgress = isAutoUpdatesEnabled && !lastDocumentId;

      if (isAutoUpdateInProgress) {
        startGetDocumentRequestPolling(3000);
      } else {
        stopGetDocumentRequestPolling();
      }
    }
    if (!editedDocRequestId || !isOpen) {
      stopGetDocumentRequestPolling();
    }
  }, [editedDocRequestId, isOpen, documentRequestData, documents]);

  useEffect(() => {
    if (editedDocRequestId) {
      setActiveKey(data?.searchDocuments?.find((document) => document.docRequestId === editedDocRequestId)?._id!);
    }
  }, [editedDocRequestId, data?.searchDocuments]);

  useEffect(() => {
    setPreviewContent([]);

    if (!data || !data.searchDocuments) {
      return;
    }

    const documents = data.searchDocuments;
    const doc = documents.length ? documents.find((document) => document._id === activeKey) : null;
    if (!doc || !doc._id) {
      return;
    }

    if (!doc.needsPreview) {
      return;
    }

    if (!doc.hasPreview) {
      createDocumentPreview({
        update: (cache, { data: createDocumentPreviewData }) => {
          cache.writeQuery<SearchDocumentsQuery>({
            query: SearchDocumentsDocument,
            data: {
              searchDocuments: documents.map((d) =>
                d._id === createDocumentPreviewData?.createDocumentPreview?._id ? createDocumentPreviewData?.createDocumentPreview : d,
              ),
            },
            variables: {
              docRequestId: editedDocRequestId,
            },
          });
        },
        variables: {
          documentId: doc._id,
        },
      });
    } else {
      const fetchFile = async () => {
        const data = await fetch(`${getBaseApiUrl()}/api/downloadPreview?attachment=false&docId=${doc._id}&token=${encodeURIComponent(token)}`).then((res) =>
          res.json(),
        );
        setPreviewContent(data);
      };
      fetchFile();
    }
  }, [activeKey, data, createDocumentPreview, editedDocRequestId, token]);

  if (!editedDeal || !docReqFolders) {
    return null;
  }

  const treeData = getNormalizedFolderTree({ ...docReqFolders });
  const request = editedDocRequestId ? (treeData.items?.[editedDocRequestId]?.data ? treeData.items?.[editedDocRequestId]?.data : activeRequest) : null;
  const folder = editedDocRequestId ? treeData.items?.[(request as NormalizedDocRequest)?.parentFolderId!] : undefined;
  const isBorrower = editedDeal.userDealRole === 'BORROWER';
  const isLender = editedDeal.userDealRole === 'LENDER';
  const isAdmin = editedDeal.userDealRole === 'ADMIN';
  const autoUpdateSource = (request as NormalizedDocRequest)?.autoUpdateSource;
  const lastDocumentId = (request as NormalizedDocRequest)?.lastDocumentId;
  const autoUpdateSourceTitle = autoUpdateSource?.__typename === 'Deal' ? 'Deal Summary' : 'Deal Financing';
  const isAutoUpdatesEnabled = Boolean(autoUpdateSource);
  const isAutoUpdateInProgress =
    (Boolean(autoUpdateSource) && !lastDocumentId) || (Boolean(autoUpdateSource) && !documents.find((doc) => doc._id === lastDocumentId));

  client.writeQuery<IsLoadingQuery>({
    query: IsLoadingDocument,
    data: { isLoading: loading || currentUserDataLoading || docIsDeleting },
  });

  const activeDocument = documents.find((document) => document._id === activeKey);

  if (fileUploadError) {
    setUploadingFile(null);
    const fileLimitErr = /size limit/.test(fileUploadError.message);
    documents = documents.filter((d) => d.status !== 'uploading');
    showGlobalAlert(fileLimitErr ? 'Cannot upload files greater than 100mb' : 'Something went wrong', 'danger');
  }

  if (uploadingFile && editedDocRequestId) {
    const uploadingDoc = documents.find((d) => d.status === 'uploading');
    if (!uploadingDoc) {
      documents = [
        {
          __typename: 'Document',
          name: uploadingFile.name,
          hasPreview: false,
          needsPreview: false,
          creatorName: '',
          createdAt: new Date(),
          note: '',
          status: DocumentRequestStatus.Uploading,
          dealId: (request as DocRequestFragment)?.dealId!,
          docRequestId: (request as DocRequestFragment)?._id!,
          _id: '',
        },
        ...documents,
      ];
    }
  }

  const reviewOrApprovedFound = documents.find((d) => d.status === 'review' || d.status === 'approved');
  // E.g. borrower
  const canUserUploadFile = !docIsUploading && !reviewOrApprovedFound;
  const canUserAsAdminUploadFile = isAdmin && !docIsUploading && (request as DocRequestFragment)?.status === 'approved';
  const canFileBeUploaded = (canUserAsAdminUploadFile || canUserUploadFile) && !isAutoUpdatesEnabled;

  const updateDocStatus = (status: DocumentRequestStatus) => {
    updateDocumentRequestStatus(editedDocRequestId!, { status });
  };

  const onFileSelect = async (files: FileList | File[] | null) => {
    if (!canFileBeUploaded) {
      // Make sure file is not uploaded when status is "review" or "approved"
      return;
    }

    const file = files?.[0];

    if (!file || !file?.size) {
      return;
    }

    if (file?.size > 500 * 1024 * 1024) {
      showGlobalAlert('Max allowed size is 500MB', 'danger');
      return;
    }

    setUploadingFile(file);

    client.writeQuery<SearchDocumentsQuery>({
      query: SearchDocumentsDocument,
      data: {
        searchDocuments: [
          {
            dealId,
            __typename: 'Document',
            name: file.name,
            hasPreview: false,
            needsPreview: false,
            creatorName: '',
            createdAt: new Date(),
            note: '',
            status: DocumentRequestStatus.Uploading,
            docRequestId: (request as DocRequestFragment)?._id!,
            _id: 0 as any,
          },
        ],
      },
      variables: {
        docRequestId: (request as DocRequestFragment)?._id!,
      },
    });

    await uploadDocument({
      update: (cache, { data: uploadDocumentData }) => {
        const uploadDocument = uploadDocumentData!.uploadDocument!;
        setUploadingFile(null);
        cache.writeQuery<SearchDocumentsQuery>({
          query: SearchDocumentsDocument,
          data: { searchDocuments: [uploadDocument, ...documents] },
          variables: {
            docRequestId: (request as DocRequestFragment)?._id!,
          },
        });

        updateDocumentRequestStatus((request as DocRequestFragment)?._id!, {
          status: uploadDocument.status,
          lastDocumentId: uploadDocument._id,
        });
      },
      variables: {
        docRequestId: (request as DocRequestFragment)?._id!,
        file,
      },
      refetchQueries: ['getDealActivities', 'searchDocuments'],
    });
  };

  const resetDocStatus = (doc: Document) => {
    client.writeQuery<SearchDocumentsQuery>({
      query: SearchDocumentsDocument,
      data: {
        searchDocuments: documents.map((d) => {
          if (d._id === doc._id) {
            return {
              ...d,
              status: DocumentRequestStatus.Review,
            };
          } else {
            return { ...d };
          }
        }),
      },
      variables: {
        docRequestId: (request as DocRequestFragment)?._id!,
      },
    });
  };

  const getDocumentPath = (doc: Document, attachment = false, saveActivity = false) => {
    const url = `${getBaseApiUrl()}/api/download?docId=${doc?._id}&token=${encodeURIComponent(token)}`;
    return `${url}${attachment ? '&attachment=true' : ''}${saveActivity ? '&saveActivity=true' : ''}`;
  };

  const downloadDocument = (doc: Document, attachment = false) => {
    window.location.href = getDocumentPath(doc, attachment, true);
  };

  const deleteDocumentHandler = (doc: Document) => {
    setDeletingDoc(doc);
    setOpenDeleteModal(true);
  };

  const onConfirmDelete = async () => {
    await deleteDocument({
      update: (cache, { data: deleteDocumentData }) => {
        const { lastDocumentId, status } = deleteDocumentData!.deleteDocument!;

        cache.writeQuery<SearchDocumentsQuery>({
          query: SearchDocumentsDocument,
          data: {
            searchDocuments: documents.filter((d) => d._id !== deletingDoc!._id),
          },
          variables: {
            docRequestId: (request as DocRequestFragment)?._id!,
          },
        });

        setDeletingDoc(null);
        setOpenDeleteModal(false);

        updateDocumentRequestStatus((request as DocRequestFragment)?._id!, {
          status: status,
          lastDocumentId: lastDocumentId!,
        });

        showGlobalAlert('Document deleted', 'success');
      },
      refetchQueries: ['getDealActivities'],
      variables: { documentId: deletingDoc!._id },
    });
  };

  const onCancelDelete = () => {
    setOpenDeleteModal(false);
    setDeletingDoc(null);
  };

  const header = (
    <Row gutter={4} utils={{ alignItems: 'center', mb: 8 }}>
      <Col>
        <Heading utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray600' }}>{folder?.title}</Heading>
        <Heading utils={{ fontSize: '2xl' }}>{request && (request.name ?? activeRequest?.name)}</Heading>

        {(isBorrower || isAdmin) && (
          <Block utils={{ display: 'flex', alignItems: 'center', mt: 3 }}>
            {(isBorrower || isAdmin) && (
              <AssignDocReqDropdown
                task={task}
                docRequestId={editedDocRequestId!}
                disablePortal
                isDisabled={isBorrower && (request as DocRequestFragment)?.status === DocumentRequestStatus.Review}
                toggleButton={
                  <>
                    <Button
                      data-tooltip-id="GlobalTooltip"
                      data-tooltip-content={getTaskAssigneeStatus(task)}
                      data-tooltip-hidden={!task}
                      size="xs"
                      variant="white"
                      utils={{ mr: 3, display: 'flex', alignItems: 'center' }}
                      as="span"
                    >
                      {task ? (
                        <>
                          <TaskUserAvatar size="2xs" task={task} utils={{ mr: 2 }} enableTooltip={false} />
                          {getTaskUserName(task?.finishedBy ?? task?.user ?? task?.invitation!, { truncateText: true })}
                        </>
                      ) : (
                        <>
                          <Icon icon={userCircle01} utils={{ color: 'gray700' }} /> &nbsp;&nbsp;Assign To
                        </>
                      )}
                    </Button>
                  </>
                }
              />
            )}

            {isAdmin && (
              <Button
                data-tooltip-id="GlobalTooltip"
                data-tooltip-content={`Auto updating from ${autoUpdateSourceTitle}`}
                data-tooltip-hidden={!autoUpdateSource}
                onClick={() => setOpenAutoUpdateModal(true)}
                size="xs"
                variant="white"
              >
                <Icon icon={repeat} utils={{ color: autoUpdateSource ? 'success' : 'gray700' }} />
                &nbsp;&nbsp;
                {autoUpdateSource ? autoUpdateSourceTitle : 'Auto Update'}
              </Button>
            )}
          </Block>
        )}
      </Col>
      {(isAdmin || (isBorrower && request?.createdBy === currentUserData?.currentUser?._id && (request as DocRequestFragment)?.status === 'review')) && (
        <Col span="auto">
          <Dropdown menuButton={<Toggle />}>
            <DropdownMenu>
              <DropdownItem onClick={openEditReqModal} icon={edit}>
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setDeleteFolderModal({
                    isOpen: true,
                    item: { id: request?._id!, title: (request as DocRequestFragment)?.name!, isFolder: false, isRequest: true },
                  })
                }
                icon={trash2}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Col>
      )}

      <Col span="auto">
        <Close onClick={() => onClose?.()} />
      </Col>
    </Row>
  );

  const documentInfo = (document: Document) => (
    <>
      <StretchedLink onClick={() => setActiveKey(document._id)} mobile={{ display: 'none' }}>
        <Heading as="h4" utils={{ fontWeight: 'base', color: 'black', textTruncate: true }}>
          {document.name}
        </Heading>
      </StretchedLink>
      <StretchedLink onClick={() => downloadDocument(document)} desktop={{ display: 'none' }}>
        <Heading as="h4" utils={{ fontWeight: 'base', color: 'black', textTruncate: true }}>
          {document.name}
        </Heading>
      </StretchedLink>
      <Text utils={{ fontSize: 'xs', color: 'gray600', textTruncate: true }}>
        {document.status === 'uploading' ? 'Uploading...' : formatDate(document.createdAt)}
        {document.status !== 'uploading' && (
          <Block as="span" utils={{ px: 2 }}>
            ·
          </Block>
        )}
        {document.creatorName}
      </Text>
    </>
  );

  const documentDropdown = (document: Document, key: number) => (
    <Dropdown menuButton={<Toggle style={{ position: 'relative', zIndex: 1 }} />}>
      <DropdownMenu>
        <DropdownItem onClick={() => downloadDocument(document, true)} icon={download}>
          Download
        </DropdownItem>
        {((isBorrower && document.status === 'review') || (isAdmin && key === 0)) && !isAutoUpdatesEnabled && (
          <DropdownItem onClick={() => deleteDocumentHandler(document)} icon={trash2}>
            Delete
          </DropdownItem>
        )}
        {key === 0 && isAdmin && document.status === 'approved' && !isAutoUpdatesEnabled && (
          <DropdownItem onClick={() => handleResetDocStatus(request as { _id: string }, () => resetDocStatus(document))} icon={refreshCcw}>
            Undo Approval
          </DropdownItem>
        )}
        {key === 0 && isAdmin && document.status === 'rejected' && !isAutoUpdatesEnabled && (
          <DropdownItem onClick={() => handleResetDocStatus(request as { _id: string }, () => resetDocStatus(document))} icon={refreshCcw}>
            Undo Rejection
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );

  const documentHeader = (document: Document, key: number) => {
    if (document.isAutoUpdateInProgress) {
      return (
        <CardBody data-testid="documentHeader">
          <Row gutter={4} utils={{ alignItems: 'center' }}>
            <Col span="auto" mobile={{ display: 'none' }}>
              <FileIcon>{formatExtension(document.name, true)}</FileIcon>
            </Col>
            <Col span="auto" utils={{ mr: 'auto' }}>
              <Heading as="h4" utils={{ fontWeight: 'base', color: 'black', textTruncate: true }}>
                {document.name}
              </Heading>
              <Text utils={{ fontSize: 'xs', color: 'gray600', textTruncate: true }}>Generating from {autoUpdateSourceTitle}</Text>
            </Col>
          </Row>
        </CardBody>
      );
    }
    return (
      <CardBody data-testid="documentHeader">
        <Row gutter={4} utils={{ alignItems: 'center' }}>
          <Col span="auto" mobile={{ display: 'none' }}>
            <FileIcon>{formatExtension(document.name, true)}</FileIcon>
          </Col>
          <Col span="60%" utils={{ mr: 'auto' }}>
            {documentInfo(document)}
          </Col>
          <Col span="auto">
            <FileStatus status={document.status} isLender={isLender} />
          </Col>
          <Col span="auto">{document.status !== 'uploading' ? documentDropdown(document, key) : null}</Col>
        </Row>
      </CardBody>
    );
  };

  const documentFooter = (document: Document) => (
    <Collapse isOpened={activeDocument?._id === document._id}>
      <CardBody style={{ borderRadius: 0 }}>
        {document.status !== 'review' && Boolean(document.note) && (
          <>
            <Heading utils={{ fontSize: '2xs', textTransform: 'uppercase', color: document.status === 'rejected' ? 'danger' : 'black', mb: 2 }}>
              {document.status === 'rejected' ? 'Rejection notes' : 'Notes'}
            </Heading>
            <Text utils={{ fontSize: 'sm' }}>{document.note}</Text>
          </>
        )}
        {document.status === 'review' && isAdmin && <DocumentReviewForm updateStatus={updateDocStatus} document={document} />}
      </CardBody>
    </Collapse>
  );

  const body = (
    [
      ...(isAutoUpdateInProgress
        ? [
            {
              createdAt: new Date('2021-12-03T09:08:05.200Z'),
              isAutoUpdateInProgress,
              creatorName: 'Test User',
              name: autoUpdateSource?.__typename === 'Deal' ? `${autoUpdateSource?.name} OM.pdf` : `${editedDeal?.name} Financing.xlsx`,
              status: DocumentRequestStatus.Uploading,
              note: '',
              _id: '',
              __typename: 'Document',
              dealId: '',
              hasPreview: false,
              needsPreview: false,
              docRequestId: '',
            },
          ]
        : []),
      ...documents,
    ] as Document[]
  ).map((document, key) => {
    // For lender we show only the latest approved doc
    // See: https://linear.app/welcomelend/issue/WL-365/enable-document-preview-for-lenders
    if (isLender && key >= 1) {
      return null;
    }
    return (
      <CardGroup size="sm" key={key}>
        {key === 1 && (
          <Heading as="h6" utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700', mb: 6 }}>
            Previous Versions
          </Heading>
        )}
        <Card
          size="sm"
          progress={document.status === 'uploading' && docIsUploading ? -1 : isAutoUpdateInProgress && key === 0 ? -1 : false}
          utils={{ boxShadow: activeDocument?._id !== document._id && document.status !== 'uploading' ? 'none' : 4, transition: 'base' }}
          hover={{ bgColor: activeDocument?._id !== document._id && document.status !== 'uploading' ? 'gray100' : undefined }}
          style={{ transitionProperty: 'box-shadow' }}
        >
          {documentHeader(document, key)}
          {((document.status !== 'review' && Boolean(document.note)) || (document.status === 'review' && isAdmin)) && documentFooter(document)}
        </Card>
      </CardGroup>
    );
  });

  const footer = (
    <CardGroup size="sm">
      <Card size="sm" isInactive>
        <CardBody>
          <Heading utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700', mb: 2 }}>Instructions:</Heading>
          <Text utils={{ fontSize: 'sm', whiteSpace: 'pre-wrap' }}>{request && (request as DocRequestFragment)!.instructions}</Text>
        </CardBody>
      </Card>
    </CardGroup>
  );

  const previewLoading = (
    <PreviewModalContent>
      <Spinner variant="white"></Spinner>
    </PreviewModalContent>
  );

  const previewNone = (
    <PreviewModalContent>
      <Heading utils={{ fontSize: '5xl', color: 'white', textAlign: 'center' }}>No Document Yet</Heading>
      <Text utils={{ color: 'gray500', textAlign: 'center', mb: 6 }}>You will get a preview once you’ve uploaded a document.</Text>
      <Block utils={{ textAlign: 'center' }}>
        <FileInput as="span" inputProps={{ id: 'uploadDoc', onChange: (e) => onFileSelect(e.target.files) }}>
          <Button as="span">Upload File</Button>
        </FileInput>
      </Block>
    </PreviewModalContent>
  );

  const previewUnavailable = () => {
    const isExcel = /.*\.xlsx$/i.test(activeDocument?.name!) || /.*\.xls$/i.test(activeDocument?.name!) || /.*\.xlsm$/i.test(activeDocument?.name!);
    const isDocx = /.*\.docx$/i.test(activeDocument?.name!);

    const text = !activeDocument?.needsPreview && (isExcel || isDocx) ? 'This file is too large to preview.' : 'We can’t preview this file type yet.';

    return (
      <PreviewModalContent>
        <Heading utils={{ fontSize: '5xl', color: 'white', textAlign: 'center' }}>Preview Unavailable</Heading>
        <Block utils={{ mb: 6 }}>
          <Text utils={{ color: 'gray500', textAlign: 'center' }}>{text}</Text>
        </Block>
        <Block utils={{ textAlign: 'center' }}>
          <Button variant="primary" onClick={() => downloadDocument(activeDocument!)}>
            Download File
          </Button>
        </Block>
      </PreviewModalContent>
    );
  };

  const previewPDF = () => {
    const url = getDocumentPath(activeDocument!);

    return <PreviewPdf url={url} />;
  };

  const previewUploading = (
    <PreviewModalContent>
      <Spinner variant="white">Uploading...</Spinner>
    </PreviewModalContent>
  );

  const previewGenerating = (
    <PreviewModalContent>
      <Spinner variant="white">Generating preview...</Spinner>
    </PreviewModalContent>
  );

  const previewImages = <PreviewImage url={getDocumentPath(activeDocument!)} />;

  const previewXLS = () => {
    if (activeDocument?.hasPreview) {
      if (previewContent.length) {
        return (
          <PreviewModalContent isFill>
            <PreviewXLS data={previewContent} onClose={() => onClose?.()} />
          </PreviewModalContent>
        );
      } else {
        return (
          <PreviewModalContent>
            <Spinner variant="white" />
          </PreviewModalContent>
        );
      }
    } else {
      return previewUnavailable();
    }
  };

  const preview = () => {
    if (!isOpen) {
      return <></>;
    }

    if (isAutoUpdateInProgress) {
      return (
        <PreviewModalContent>
          <Spinner variant="white">Generating from {autoUpdateSourceTitle}</Spinner>
        </PreviewModalContent>
      );
    }

    if (loading) {
      return previewLoading;
    }

    if (!documents.length) {
      return previewNone;
    }

    if (docIsUploading) {
      return previewUploading;
    }

    if (previewIsGenerating) {
      return previewGenerating;
    }

    const extension = formatExtension(activeDocument?.name);
    const fileSize = activeDocument?.fileSize;
    const pdfMaxPreviewFileSize = 1024 * 1014 * 50;
    const isPdfPreviewEnabled = fileSize ? fileSize <= pdfMaxPreviewFileSize : true;
    let previewTemplate;

    if (['png', 'jpg', 'jpeg'].includes(extension)) {
      previewTemplate = previewImages;
    } else if (extension === 'pdf' && isPdfPreviewEnabled) {
      previewTemplate = previewPDF();
    } else if (extension === 'xls' || extension === 'xlsx' || extension === 'xlsm' || extension === 'docx') {
      previewTemplate = previewXLS();
    } else {
      previewTemplate = previewUnavailable();
    }

    return previewTemplate;
  };

  return (
    <>
      <PreviewModal isOpen={isOpen} onClose={() => onClose?.()} onExited={() => onExited?.()} {...props}>
        <>
          <PreviewModalLeft onClose={() => onClose?.()}>{preview()}</PreviewModalLeft>
          <PreviewModalRight status={activeDocument?.status as keyof CustomTheme['statusColors']}>
            {header}
            {loading && <Spinner variant="primary" desktop={{ display: 'none' }} />}
            {!loading && canFileBeUploaded && (
              <Dropzone
                utils={{ mb: 6 }}
                label={canUserAsAdminUploadFile ? 'Upload Revision' : 'Drop file here to upload'}
                onFileSelect={onFileSelect}
                style={{ minHeight: '93px' }}
              />
            )}
            {!loading && body}
            {!loading && request && (request as DocRequestFragment)!.instructions && footer}
          </PreviewModalRight>
        </>
      </PreviewModal>
      <ConfirmModal
        text="Document will be deleted"
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}
        isOpen={openDeleteModal}
      />
      <AutoUpdatesDocumentModal isOpen={openAutoUpdateModal} onClose={() => setOpenAutoUpdateModal(false)} documentRequest={request as any} />
    </>
  );
};

export default DocumentsModal;
