import React from 'react';
import dynamic from 'next/dynamic';

import { Text } from '~/components/type';
import { DealTaskFragmentFragment } from '~/generated/graphql';
import { getTaskAssigneeStatus } from './getTaskUserName';
import { Icon } from '~/components/vendor';
import { BaseProps } from '~/components/layout/Base';
import { userCircle01 } from '~/components/vendor/Icon/icons';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), { ssr: false });

type Props = BaseProps & {
  task?: DealTaskFragmentFragment | null;
  enableTooltip?: boolean;
  size?: '3xs' | '2xs' | '2xl' | 'base' | 'xs' | 'sm' | 'lg' | 'xl';
};

export const TaskUserAvatar: React.FC<Props> = ({ task, size = '2xs', enableTooltip = true, ...props }) => {
  const dataFor = task?._id + 'assignedUser';
  const taskUserName = getTaskAssigneeStatus(task);
  const isUnassigned = !task?.user && !task?.invitation;
  return (
    <div data-tooltip-id={dataFor} data-tooltip-hidden={!enableTooltip} style={{ display: 'flex' }}>
      {isUnassigned ? (
        <Icon icon={userCircle01} size={20} utils={{ color: 'gray500' }} role="button" style={{ zIndex: 1 }} />
      ) : (
        <UserAvatar
          size={size}
          user={task?.finishedBy ?? task?.user ?? task?.invitation}
          status={task?.finishedBy?.onlineStatus ?? task?.user?.onlineStatus}
          isInactive={!task?.user}
          {...props}
        />
      )}
      <Tooltip id={dataFor}>
        <Text utils={{ textAlign: 'left', fontSize: 'sm' }}>{taskUserName}</Text>
      </Tooltip>
    </div>
  );
};

export default TaskUserAvatar;
