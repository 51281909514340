import React, { useEffect, useState } from 'react';
import { PreviewModalContent } from '~/components/preview';
import { Spinner } from '~/components/ui';

type Props = {
  url: string;
};

const PreviewPdf: React.FC<Props> = ({ url }) => {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    async function getPdf() {
      const pdf = await (await fetch(url, { signal })).blob();
      setPdfUrl(URL.createObjectURL(pdf));
    }
    getPdf();
    return () => {
      controller.abort('aborted');
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [url]);

  if (!pdfUrl) {
    return (
      <PreviewModalContent isFill>
        <Spinner variant="white" />
      </PreviewModalContent>
    );
  }

  return (
    <PreviewModalContent isFill>
      <object data={pdfUrl} type="application/pdf" width="100%" height="100%" />
    </PreviewModalContent>
  );
};

export default PreviewPdf;
