import { DealStatus } from '~/generated/graphql';
import { StatusProps } from '~/components/ui/Status';

export const DealStatusMapped: Record<DealStatus, string> = {
  Opportunity: 'Opportunity',
  Screened: 'Screened',
  AgreementSigned: 'Agreement Signed',
  Packaged: 'Packaged',
  Launched: 'Launched',
  TermsSent: 'Quotes Sent',
  TermsSigned: 'Terms Signed',
  LoanApproved: 'Loan Approved',
  LoanDocsSent: 'Loan Docs Sent',
  Closed: 'Closed',
  Paused: 'Paused',
  Dead: 'Dead',
};

export const DealStatusIcons: Record<DealStatus, StatusProps['icon']> = {
  Opportunity: null,
  Screened: null,
  AgreementSigned: null,
  Packaged: null,
  Launched: null,
  TermsSent: null,
  TermsSigned: null,
  LoanApproved: null,
  LoanDocsSent: null,
  Closed: 'check',
  Paused: 'ellipsis',
  Dead: 'x',
};

export const DealStatusVariants: Record<DealStatus, StatusProps['variant']> = {
  Opportunity: 'circle-dashed',
  Screened: 'primary',
  AgreementSigned: 'primary',
  Packaged: 'primary',
  Launched: 'primary',
  TermsSent: 'primary',
  TermsSigned: 'primary',
  LoanApproved: 'primary',
  LoanDocsSent: 'primary',
  Closed: 'success',
  Paused: 'warning',
  Dead: 'danger',
};

export const DealStatusProgress: Record<DealStatus, StatusProps['progress']> = {
  Opportunity: null,
  Screened: Math.round((100 / 9) * 1),
  AgreementSigned: Math.round((100 / 9) * 2),
  Packaged: Math.round((100 / 9) * 3),
  Launched: Math.round((100 / 9) * 4),
  TermsSent: Math.round((100 / 9) * 5),
  TermsSigned: Math.round((100 / 9) * 6),
  LoanApproved: Math.round((100 / 9) * 7),
  LoanDocsSent: Math.round((100 / 9) * 8),
  Closed: null,
  Paused: null,
  Dead: null,
};

export const inactiveDealStatuses = [DealStatus.Closed, DealStatus.Paused, DealStatus.Dead];

export type DealStatusOption = {
  value: DealStatus;
  label: string;
};

export const dealStatusOptions: DealStatusOption[] = Object.entries(DealStatusMapped).map(([value, label]) => ({
  value: value as DealStatus,
  label,
}));

export const dealActiveStatusOptions = dealStatusOptions.filter(({ value }) => !inactiveDealStatuses.includes(value));

export const dealInactiveStatusOptions = dealStatusOptions.filter(({ value }) => inactiveDealStatuses.includes(value));

export default dealStatusOptions;
