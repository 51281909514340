import React, { useEffect, useRef } from 'react';
import { Spinner } from '../ui';
import { Block } from '../layout';

type TableInfiniteProps<T> = {
  items: T[];
  totalItems?: number;
  rootId?: string;
  renderItem: (item: T) => React.ReactElement;
  onLoadedItemsScrollEnd?: (index: number) => void;
};

export default function TableInfinite<T>({ items, totalItems, rootId, renderItem, onLoadedItemsScrollEnd }: TableInfiniteProps<T>) {
  const toLoadItemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tableInfiniteEl = toLoadItemsRef.current;
    if (tableInfiniteEl) {
      const root = rootId ? document.getElementById(rootId) : null;
      const handler: IntersectionObserverCallback = (entries) => {
        entries.forEach((entry) => {
          // Abort if no item is visible
          if (!entry.isIntersecting) {
            return;
          }

          // Reach end of loaded items list
          onLoadedItemsScrollEnd?.(items.length);
        });
      };
      const options = {
        root,
      };
      const observer = new IntersectionObserver(handler, options);
      observer?.observe(tableInfiniteEl);
      return () => observer?.unobserve(tableInfiniteEl);
    }
  }, [items, totalItems, rootId, onLoadedItemsScrollEnd]);

  return (
    <>
      {items.map((item, index) => {
        return <React.Fragment key={index}>{renderItem(item)}</React.Fragment>;
      })}
      {typeof totalItems === 'number' && totalItems > items.length && (
        <Block ref={toLoadItemsRef} utils={{ position: 'relative', py: 5 }}>
          <Spinner size="sm" variant="gray700" />
        </Block>
      )}
    </>
  );
}
