import React, { useEffect, useState } from 'react';
import { Avatar, Modal } from '../components/ui';
import { Block, FlexRow } from '../components/layout';
import { Button, Feedback, FileInput, Input, FormGroup, Label } from '../components/form';
import { Card, CardBody, CardHeader } from '../components/card';
import { ConfirmModal } from '~/modals';
import { Controller, useForm } from 'react-hook-form';
import { useDebounceFn } from 'ahooks';
import { getDomain } from '~/helpers/lender/getDomain';
import {
  GetSearchDocument,
  GetSearchQuery,
  GetDomainDataQuery,
  BorrowersElasticSearchDocument,
  BorrowersElasticSearchQuery,
  BorrowerFragment,
  UpdateBorrowerInput,
  useCreateBorrowerMutation,
  useGetBorrowerQuery,
  useUpdateBorrowerMutation,
  useUploadFileMutation,
} from '~/generated/graphql';
import { Heading, Text } from '../components/type';
import { searchBorrowersVars } from '~/lib/apolloClient';
import { lendingStatesOptions } from '~/data';
import { Select } from '../components/vendor';
import { Sticky } from '~/components/layout';
import { Table, TableRow, TableCell } from '~/components/table';
import { useApolloClient } from '@apollo/client';
import { useFormScrollOnError } from '~/hooks/useFormScrollOnError';
import { useGetDomainDataQuery } from '~/generated/graphql';
import { useRouter } from 'next/router';
import DeleteBorrowerModal from '~/modals/DeleteBorrowerModal';
import Link from 'next/link';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { getBorrowerLogoUrl } from '~/helpers/getBorrowerLogoUrl';
import { withHttp } from '~/helpers';

type BorrowerInputs = {
  name?: string;
  url?: string;
  about?: string;
  logo?: string;
  homeState?: { value: string; label: string } | null;
};

const URL_REGEXP = /^(https?:\/\/)?(www\.)?.+\..+$/i;

type BorrowerModalProps = {
  borrower?: Partial<BorrowerFragment> | null;
  borrowerId?: string;
  isOpen: boolean;
  onClose: () => void;
  setBorrower?: (borrower: BorrowerFragment) => void;
  updateQuery?: ReturnType<typeof useGetBorrowerQuery>['updateQuery'];
};

const BorrowerModal: React.FC<BorrowerModalProps> = ({ borrower, borrowerId, isOpen, onClose, setBorrower, updateQuery, ...props }) => {
  const [borrowerData, setBorrowerData] = useState(borrower);
  const router = useRouter();
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();

  const { refetch: getBorrower } = useGetBorrowerQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: borrowerId!,
    },
    skip: true,
  });

  useEffect(() => {
    if (!isOpen) {
      setBorrowerData(null);
      return;
    }
    if (borrower) {
      setBorrowerData(borrower);
    } else if (borrowerId) {
      getBorrower().then(({ data }) => {
        setBorrowerData(data.getBorrower);
      });
    }
  }, [borrower, borrowerId, isOpen]);

  const [uploadAvatar, { loading: avatarIsUploading }] = useUploadFileMutation();
  const [updateBorrower, { loading: updatingBorrower }] = useUpdateBorrowerMutation({ refetchQueries: ['getBorrowerActivities', 'borrowersElasticSearch'] });
  const [createBorrower, { loading: creatingBorrower }] = useCreateBorrowerMutation({ refetchQueries: ['borrowersElasticSearch'] });
  const { refetch: getDomainData } = useGetDomainDataQuery({ fetchPolicy: 'network-only', skip: true });

  const [tempLogo, setTempLogo] = useState<null | string>(null);
  const [openDeleteBorrowerModal, setOpenDeleteContactModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [footerButtonPressed, setFooterButtonPressed] = useState(false);
  const [headerButtonPressed, setHeaderButtonPressed] = useState(false);
  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);
  const [formDomain, setFormDomain] = useState(borrowerData?.url ? withHttp(borrowerData?.url) : null);
  const [domainData, setDomainData] = useState<GetDomainDataQuery['getDomainData']>();
  const isEditOp = borrowerData?._id;

  const borrowerForm = useForm<BorrowerInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: borrowerData?.name,
      url: borrowerData?.url ? withHttp(borrowerData?.url) : undefined,
      about: borrowerData?.about ?? undefined,
      logo: borrowerData?.logo ?? undefined,
      homeState: borrowerData?.homeState ? lendingStatesOptions.find((s) => s.value === borrowerData?.homeState) : null,
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    borrowerForm.register('logo');
    borrowerForm.setValue('logo', undefined);

    borrowerForm.setValue('name', borrowerData?.name);

    if (isEditOp) {
      borrowerForm.setValue('url', borrowerData?.url ? withHttp(borrowerData?.url) : undefined);
      borrowerForm.setValue('about', borrowerData?.about ?? undefined);
      borrowerForm.setValue('logo', borrowerData?.logo ?? undefined);
      borrowerForm.setValue('homeState', borrowerData?.homeState ? lendingStatesOptions.find((s) => s.value === borrowerData?.homeState) : null);
    }
  }, [borrowerData, isOpen]);

  const borrowerLogo = borrowerForm.watch('logo');
  const borrowerName = borrowerForm.watch('name');
  const urlFormValue = borrowerForm.watch('url');

  const { run: delayedDomainUpdate } = useDebounceFn(
    (domainValue: string) => {
      setFormDomain(domainValue);
    },
    { wait: 600 },
  );

  useEffect(() => {
    delayedDomainUpdate(urlFormValue!);
  }, [urlFormValue]);

  const {
    formState: { errors: formErrors },
  } = borrowerForm;
  useFormScrollOnError(formErrors);

  async function handleAvatarUpload(files: FileList | null) {
    try {
      if (files?.[0]) {
        const { data: uploadFileData } = await uploadAvatar({ variables: { file: files?.[0] } });
        const { _id: fileId } = uploadFileData!.uploadFile;

        borrowerForm.setValue('logo', fileId);
        setTempLogo(URL.createObjectURL(files?.[0]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onDomainBlur = async () => {
    if (getDomain(urlFormValue!) === borrowerData?.url || urlFormValue === domainData?.domain || !URL_REGEXP.test(urlFormValue!)) {
      return;
    }

    const fetchedDomainData = (await getDomainData({ domain: urlFormValue! })).data.getDomainData;

    setDomainData(fetchedDomainData);

    if (fetchedDomainData.name && !borrowerForm.getValues('name')) {
      borrowerForm.setValue('name', fetchedDomainData.name);
    }
    if (fetchedDomainData.about && !borrowerForm.getValues('about')) {
      borrowerForm.setValue('about', fetchedDomainData.about);
    }
    if (fetchedDomainData.logo && !borrowerForm.getValues('logo')) {
      borrowerForm.setValue('logo', fetchedDomainData.logo);
    }
    if (fetchedDomainData.state && !borrowerForm.getValues('homeState')) {
      const state = lendingStatesOptions.find(({ label }) => label === fetchedDomainData.state);
      if (state) {
        borrowerForm.setValue('homeState', state);
      }
    }
  };

  async function handleFormSubmit(data: BorrowerInputs) {
    setFormLoading(true);

    if (getDomain(urlFormValue!) !== borrowerData?.url) {
      if (domainData?.domain === formDomain) {
        if (domainData?.borrowerName) {
          borrowerForm.setError('url', {
            type: 'duplicate',
            message: '',
          });
          setDomainData(domainData);
          setHeaderButtonPressed(false);
          setFooterButtonPressed(false);
          setFormLoading(false);
          return;
        } else {
          borrowerForm.clearErrors('url');
        }
      }
    }

    const borrowerMutationValues: Omit<UpdateBorrowerInput, '_id'> = {
      name: data.name?.trim()!,
      logo: data.logo,
      homeState: data.homeState?.value,
      about: data.about,
      url: getDomain(data.url!)!,
    };

    if (isEditOp) {
      await updateBorrower({
        variables: {
          borrower: { _id: borrowerData._id!, ...borrowerMutationValues },
        },
        update: (client, res) => {
          const updatedBorrower = res?.data?.updateBorrower;
          if (updatedBorrower) {
            updateQuery?.((prevBorrowerQueryData) => {
              const prevBorrower = prevBorrowerQueryData?.getBorrower;
              return {
                getBorrower: {
                  ...prevBorrower!,
                  ...borrowerMutationValues,
                  logo: tempLogo ?? borrowerMutationValues?.logo,
                },
              };
            });

            const borrowersQuery = client.readQuery<BorrowersElasticSearchQuery>({
              query: BorrowersElasticSearchDocument,
              variables: searchBorrowersVars(),
            });

            if (borrowersQuery) {
              client.writeQuery<BorrowersElasticSearchQuery>({
                query: BorrowersElasticSearchDocument,
                variables: searchBorrowersVars(),
                data: {
                  borrowersElasticSearch: {
                    ...borrowersQuery?.borrowersElasticSearch,
                    borrowers: borrowersQuery?.borrowersElasticSearch.borrowers.map((b) => {
                      if (borrowerData?._id === b._id) {
                        return {
                          ...b,
                          name: borrowerMutationValues.name,
                          logo: borrowerMutationValues.logo,
                          updatedAt: new Date(),
                        };
                      }
                      return b;
                    }),
                  },
                },
              });
            }

            showGlobalAlert('Borrower updated');
          }
        },
      });
    } else {
      await createBorrower({
        variables: {
          borrower: borrowerMutationValues,
        },
        update: (client, res) => {
          const newBorrower = res?.data?.createBorrower;
          if (newBorrower) {
            const borrowersQuery = client.readQuery<BorrowersElasticSearchQuery>({
              query: BorrowersElasticSearchDocument,
              variables: searchBorrowersVars(),
            });

            if (borrowersQuery) {
              client.writeQuery<BorrowersElasticSearchQuery>({
                query: BorrowersElasticSearchDocument,
                variables: searchBorrowersVars(),
                data: {
                  borrowersElasticSearch: {
                    ...borrowersQuery.borrowersElasticSearch,
                    count: borrowersQuery.borrowersElasticSearch.count + 1,
                    borrowers: [...borrowersQuery.borrowersElasticSearch.borrowers, newBorrower].sort((a, b) => {
                      if (a.name! < b.name!) {
                        return -1;
                      }
                      if (a.name! > b.name!) {
                        return 1;
                      }
                      return 0;
                    }),
                  },
                },
              });
              if (!setBorrower) {
                router.push('/borrower/[borrowerId]/overview', `/borrower/${newBorrower._id}/overview`);
              }
            }

            showGlobalAlert('Borrower created');

            setBorrower?.(newBorrower);
          }
        },
      });
    }

    setFormLoading(false);
    setHeaderButtonPressed(false);
    setFooterButtonPressed(false);
    setTempLogo(null);
    onClose();
  }

  function handleDeleteBorrower() {
    if (borrowerData?._id) {
      const getSearchQuery = client.readQuery<GetSearchQuery>({
        query: GetSearchDocument,
      });

      if (getSearchQuery) {
        client.writeQuery<GetSearchQuery>({
          query: GetSearchDocument,
          data: {
            ...getSearchQuery,
            getSearch: getSearchQuery.getSearch.filter((s) => (s as { _id: string })._id !== borrowerData._id),
          },
        });
      }

      if (router.query?.borrowerId) {
        // Maybe do we need here back button feature?
        router.back();
      }
    }
    setOpenDeleteContactModal(false);
    onClose();
  }

  const dirtyFields = borrowerForm.formState.dirtyFields;

  function onModalClose() {
    const newBorrowerLogo = borrowerForm.getValues('logo') ?? null;
    const oldBorrowerLogo = borrowerData?.logo ?? null;

    if (Object.keys(dirtyFields).length !== 0 || oldBorrowerLogo !== newBorrowerLogo) {
      setConfirmCloseModelOpened(true);
    } else {
      setTempLogo(null);
      onClose();
    }
  }

  return (
    <>
      <Modal layout="horizontal" isOpen={isOpen} onClose={onModalClose} {...props}>
        <Card id="borrowerModalScrollable" isModalContent>
          <div>
            <Sticky rootId="borrowerModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" variant="white" utils={{ my: -1 }} onClick={onModalClose} type="button">
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{isEditOp ? 'Edit' : 'Add'} Borrower</Heading>
                  <Button
                    size="sm"
                    utils={{ my: -1 }}
                    isLoading={headerButtonPressed && (updatingBorrower || creatingBorrower || formLoading)}
                    disabled={updatingBorrower || creatingBorrower || formLoading}
                    onClick={() => {
                      setHeaderButtonPressed(true);
                      borrowerForm.handleSubmit(handleFormSubmit)();
                    }}
                  >
                    {isEditOp ? 'Save' : 'Add'}
                  </Button>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Borrower Info
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow as={FormGroup} isValid={!formErrors.url} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Domain</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="url"
                      render={({ field: { onBlur, ...inputProps } }) => (
                        <Input
                          _size="sm"
                          placeholder="Enter a URL"
                          onBlur={() => {
                            onBlur();
                            onDomainBlur();
                          }}
                          {...inputProps}
                        />
                      )}
                      control={borrowerForm.control}
                      defaultValue={borrowerData?.url ?? ''}
                      rules={{
                        validate: (data) => !data || (URL_REGEXP.test(data) ? undefined : 'Please enter a valid URL.'),
                      }}
                    />
                    {formErrors.url?.type === 'duplicate' && domainData && (
                      <Feedback utils={{ ml: -4 }}>
                        This domain is already associated with the borrower&nbsp;
                        <Link
                          passHref
                          href={{
                            pathname: `/borrower/${domainData.borrowerId}/overview`,
                          }}
                          style={{ color: 'inherit', textDecoration: 'underline' }}
                        >
                          {domainData.borrowerName}
                        </Link>
                      </Feedback>
                    )}
                    {formErrors.url?.type !== 'duplicate' && formErrors.url?.message && <Feedback utils={{ ml: -4 }}>{formErrors.url.message}</Feedback>}
                    {formErrors.url?.type === 'required' && <Feedback utils={{ ml: -4 }}>Please enter a valid URL.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.name} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Company name</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="name"
                      render={({ field }) => <Input _size="sm" placeholder="Company name" {...field} />}
                      control={borrowerForm.control}
                      defaultValue={borrowerData?.name ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.name?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="calc(30% - 8px)">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Avatar</Label>
                  </TableCell>
                  <TableCell>
                    <Avatar
                      alt={borrowerName?.length! > 0 ? borrowerName : '?'}
                      src={getBorrowerLogoUrl({ logo: tempLogo ?? borrowerLogo, url: formDomain! })}
                      utils={{ borderRadius: 'sm', mr: 5 }}
                    />
                    <FileInput
                      inputProps={{
                        id: 'uploadBorrowerAvatar',
                        accept: '.png,.jpg,.jpeg',
                        onChange: (e) => handleAvatarUpload(e.target.files),
                      }}
                    >
                      <Button as="span" size="sm" variant="white" isLoading={avatarIsUploading}>
                        Upload
                      </Button>
                    </FileInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Home State</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="homeState"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="homeState" placeholder="Select state" options={lendingStatesOptions} value={value} onChange={onChange} />
                      )}
                      control={borrowerForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>About</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="about"
                      render={({ field }) => <Input as={Textarea as React.ElementType} size="sm" minRows={4} placeholder="Leave a note..." {...field} />}
                      control={borrowerForm.control}
                      defaultValue={borrowerData?.about ?? ''}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Button
                isBlock={true}
                isLoading={footerButtonPressed && (updatingBorrower || creatingBorrower || formLoading)}
                disabled={updatingBorrower || creatingBorrower || formLoading}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setFooterButtonPressed(true);
                  borrowerForm.handleSubmit(handleFormSubmit)();
                }}
              >
                {isEditOp ? 'Save Changes' : 'Add Borrower'}
              </Button>
              {isEditOp && (
                <Block utils={{ textAlign: 'center', mt: 7 }}>
                  <>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setOpenDeleteContactModal(true)}
                      role="button"
                    >
                      Delete Borrower
                    </Text>
                  </>
                </Block>
              )}
            </CardBody>
          </div>
        </Card>
      </Modal>

      <DeleteBorrowerModal
        isOpen={openDeleteBorrowerModal}
        onClose={() => {
          setOpenDeleteContactModal(false);
        }}
        onDelete={() => {
          handleDeleteBorrower();
          setOpenDeleteContactModal(false);
        }}
        borrowerId={borrowerData?._id}
      />
      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have unsaved changes"
        text="Are you sure you want to abandon your edits?"
      />
    </>
  );
};

export default BorrowerModal;
