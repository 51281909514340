import { List, ListItem } from '~/components/list';
import styled, { css } from 'styled-components';

export const ActivityItemList = styled(List).attrs(() => ({ gutter: 2 }))`
  ${({ theme }) => css`
    list-style-type: none;
    padding-top: ${theme.spacers[4]}px;
    color: ${theme.colors.gray800};

    & > ${ListItem} {
      position: relative;
      padding-left: ${theme.spacers[6]}px;
    }

    & > ${ListItem}::before {
      content: '';
      position: absolute;
      left: 0;
      top: ${theme.spacers[5] - theme.spacers[1]}px;
      width: ${theme.spacers[2]}px;
      height: ${theme.spacers[2]}px;
      margin-right: ${theme.spacers[5]}px;
      background-color: ${theme.colors.gray500};
      border-radius: 50%;
    }
  `}
`;
