import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input, FormGroup, Feedback } from '../components/form';

type RenameAttachmentFormProps = {
  name: string;
  onClick: (name?: string) => void;
};

const RenameAttachmentForm: FC<RenameAttachmentFormProps> = ({ name, onClick }) => {
  const renameAttachmentForm = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name,
    },
  });

  const { formState: { errors: formErrors } } = renameAttachmentForm;

  const handleSubmit = (data: { name: string }) => {
    onClick(data.name);
  };

  return (
    <Form onSubmit={renameAttachmentForm.handleSubmit(handleSubmit)}>
      <FormGroup isValid={!formErrors.name} data-testid="attachmentEditedName">
        <Controller
          name="name"
          render={({ field }) => <Input autoFocus type="text" placeholder={'Enter name'} {...field} />}
          control={renameAttachmentForm.control}
          rules={{ required: true, min: 1 }}
        />
        {formErrors.name?.type === 'required' && <Feedback>This field is required.</Feedback>}
      </FormGroup>
      <Button type="submit" isBlock>
        Save
      </Button>
    </Form>
  );
};

export default RenameAttachmentForm;
