import React, { FC, MouseEvent } from 'react';
import { ActivityItemLink } from '~/components/activity/ActivityItemLink';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import { BorrowerFragment } from '~/generated/graphql';
import { dealBorrowerModalBorrowerVar } from '~/lib/apolloClient';

type ActivityBorrowerLinkProps = {
  borrower: BorrowerFragment;
  search?: string | null;
};

const ActivityBorrowerLink: FC<ActivityBorrowerLinkProps> = ({ borrower, search }) => {

  return (
    <ActivityItemLink
      as="span"
      role="button"
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        dealBorrowerModalBorrowerVar(borrower);
      }}
    >
      {addHighlightWords(borrower.name, search)}
    </ActivityItemLink>
  );
};

export default ActivityBorrowerLink;
