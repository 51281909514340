import React, { FC } from 'react';
import { Card, CardBody } from '../components/card';
import { Close, Modal } from '../components/ui';
import { TestMatchForm } from '../containers';

type TestMatchModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TestMatchModal: FC<TestMatchModalProps> = ({ isOpen, onClose, ...props }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card icon="clipboard" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute desktop={{ display: 'none' }} />
          <TestMatchForm />
        </CardBody>
      </Card>
    </Modal>
  );
};

export default TestMatchModal;
