import { GetDealsForReportsQuery } from '~/generated/graphql';
import { padNumber } from '~/helpers';
import getDealData from './getDealData';
import getDealsData from './getDealsData';

type DealProps = GetDealsForReportsQuery['getReportDeals']['deals'][number];

type Props = {
  closedDeals: DealProps[];
  currentClosedDeals: DealProps[];
  timeWindow: number;
  type: 'amountFinanced' | 'fee';
  recipientId?: string;
};

export function getTimeAxisArray(start: string, end: string, year: string | number) {
  const isLeap = (year: number) => new Date(year, 1, 29).getDate() === 29;
  const febDays = isLeap(year as number) ? 29 : 28;

  const jan = new Array(31).fill('').map((val, i) => `${year}-01-${padNumber(i + 1)}`);
  const feb = new Array(febDays).fill('').map((val, i) => `${year}-02-${padNumber(i + 1)}`);
  const mar = new Array(31).fill('').map((val, i) => `${year}-03-${padNumber(i + 1)}`);
  const apr = new Array(30).fill('').map((val, i) => `${year}-04-${padNumber(i + 1)}`);
  const may = new Array(31).fill('').map((val, i) => `${year}-05-${padNumber(i + 1)}`);
  const jun = new Array(30).fill('').map((val, i) => `${year}-06-${padNumber(i + 1)}`);
  const jul = new Array(31).fill('').map((val, i) => `${year}-07-${padNumber(i + 1)}`);
  const aug = new Array(31).fill('').map((val, i) => `${year}-08-${padNumber(i + 1)}`);
  const sep = new Array(30).fill('').map((val, i) => `${year}-09-${padNumber(i + 1)}`);
  const oct = new Array(31).fill('').map((val, i) => `${year}-10-${padNumber(i + 1)}`);
  const nov = new Array(30).fill('').map((val, i) => `${year}-11-${padNumber(i + 1)}`);
  const dec = new Array(31).fill('').map((val, i) => `${year}-12-${padNumber(i + 1)}`);
  const arr = jan.concat(feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec);

  return arr.slice(arr.indexOf(start), arr.indexOf(end) + 1);
}

export default function getChartData({ closedDeals, currentClosedDeals, timeWindow, type, recipientId }: Props) {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const currentDate = now.getDate();

  const currentEnd = currentYear === timeWindow ? `${padNumber(currentMonth)}-${padNumber(currentDate)}` : '12-31';
  const currentData = getDataPerDate({ deals: currentClosedDeals, start: '01-01', end: currentEnd, year: `${timeWindow}`, type, recipientId });
  const allTimeData = getAllTimeDataPerDate({ deals: closedDeals, type, recipientId });
  const finalData = timeWindow ? currentData : allTimeData;

  const previousDeals = closedDeals.filter((deal: DealProps) => new Date(deal.closedAt!).getFullYear() === timeWindow - 1);
  const previousData = getDataPerDate({ deals: previousDeals, start: '01-01', end: '12-31', year: `${timeWindow}`, type, recipientId });

  const predictionData = getPredictionDataPerDate({ deals: closedDeals, currentData: finalData, type, recipientId });

  type DataPerDateProps = {
    deals: DealProps[];
    start: string;
    end: string;
    year: string;
    initial?: number;
    type: Props['type'];
    recipientId?: Props['recipientId'];
  };

  function getDataPerDate({ deals, start, end, year, initial = 0, type, recipientId }: DataPerDateProps) {
    if (!deals.length) return [];

    const dealsWithData = deals.filter((deal) => getDealData(deal, recipientId)?.[type]);
    const xAxis = getTimeAxisArray(`${year}-${start}`, `${year}-${end}`, year);
    const yAxis = new Array(xAxis.length).fill(0);

    dealsWithData.forEach((deal) => {
      const closedAt = new Date(deal.closedAt!);
      const date = `${year}-${padNumber(closedAt.getMonth() + 1)}-${padNumber(closedAt.getDate())}`;
      yAxis[xAxis.indexOf(date)] += getDealData(deal, recipientId)?.[type];
    });

    return xAxis.map((value, i) => ({ x: value, y: yAxis.slice(0, i + 1).reduce((a, b) => a + b, initial) }));
  }

  type AllTimeDataPerDataProps = {
    deals: DealProps[];
    type: Props['type'];
    recipientId?: Props['recipientId'];
  };

  function getAllTimeDataPerDate({ deals, type, recipientId }: AllTimeDataPerDataProps) {
    const timeWindows: Array<number | string> = deals.map((deal: DealProps) => new Date(deal.closedAt!).getFullYear());
    const uniqueTimeWindows: Array<number | string> = [...new Set(timeWindows)].sort();
    const dataPerTimeWindow: ReturnType<typeof getDataPerDate>[] = [];

    uniqueTimeWindows.forEach((timeWindow, index) => {
      const currentDeals = deals.filter((deal: DealProps) => new Date(deal.closedAt!).getFullYear() === timeWindow);
      const currentEnd = currentYear === timeWindow ? `${padNumber(currentMonth)}-${padNumber(currentDate)}` : '12-31';
      const previousTimeWindowData = dataPerTimeWindow[index - 1];
      const initial = index > 0 ? previousTimeWindowData[previousTimeWindowData.length - 1].y : 0;

      dataPerTimeWindow[index] = getDataPerDate({ deals: currentDeals, start: '01-01', end: currentEnd, year: `${timeWindow}`, initial, type, recipientId });
    });

    const dataPerDate = dataPerTimeWindow.flat();
    const dataPerDateTrimmed = dataPerDate.filter((value) => value.y);

    return dataPerDateTrimmed;
  }

  type PredictionDataPerDateProps = {
    deals: DealProps[];
    currentData: Array<{ x: string; y: number }>;
    type: Props['type'];
    recipientId?: Props['recipientId'];
  };

  function getPredictionDataPerDate({ deals, currentData, type, recipientId }: PredictionDataPerDateProps) {
    const latestDealData = currentData[currentData.length - 1];

    if (!deals.length || !currentData.length || latestDealData.x === `${currentYear}-12-31` || (timeWindow && timeWindow !== currentYear)) return [];

    const closedAtTime = (deal: DealProps) => new Date(deal.closedAt!).getTime();
    const time = now.getTime();
    const dealsWithData = deals.filter((deal: DealProps) => getDealData(deal, recipientId)?.[type]);
    const deals90 = dealsWithData.filter((deal: DealProps) => time - closedAtTime(deal) < 7776000000);
    const deals180 = dealsWithData.filter((deal: DealProps) => time - closedAtTime(deal) < 15552000000);
    const deals360 = dealsWithData.filter((deal: DealProps) => time - closedAtTime(deal) < 31104000000);

    const avg90 = deals90.length ? getDealsData(deals90, recipientId)?.[type] / 90 : 0;
    const avg180 = deals180.length ? getDealsData(deals180, recipientId)?.[type] / 180 : 0;
    const avg360 = deals360.length ? getDealsData(deals360, recipientId)?.[type] / 360 : 0;
    const avg = Math.round((avg90 + avg180 + avg360) / 3);

    const xAxis = getTimeAxisArray(`${latestDealData.x}`, `${currentYear}-12-31`, currentYear);
    const predictionData = xAxis.map((value, i) => ({ x: value, y: Math.round(latestDealData.y + avg * i) }));

    const currentDataNulled = currentData.map(() => ({ y: null, x: null }));
    const data = ([] as { x: string | null; y: number | null }[]).concat(currentDataNulled, predictionData);

    return data;
  }

  return { current: finalData, previous: previousData, prediction: predictionData };
}
