import React from 'react';
import { useRouter } from 'next/router';

import useDocumentFoldersOps from '../containers/Document/useDocumentFoldersOps';
import ConfirmModal from './ConfirmModal';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';

interface Props {
  isOpen: boolean;
  item: NormalizedFolderTreeItem;
  onClose: (data?: { isOnConfirm?: boolean }) => void;
}

const DocumentsDeleteModal = ({ item, isOpen, onClose }: Props) => {
  const router = useRouter();
  const dealId = router.query.dealId as string;
  const {
    deleteTreeItem: { deleteTreeItem, deletingTreeItem },
  } = useDocumentFoldersOps({ dealId });

  return (
    <ConfirmModal
      text={getDeleteModalDescription(item)}
      isOpen={isOpen}
      onClose={onClose}
      onCancel={onClose}
      isLoading={deletingTreeItem}
      okText={getDeleteModalOkText(item)}
      onConfirm={async () => {
        await deleteTreeItem({ id: item.id, isFolder: item.isFolder!, isRequest: item.isRequest! });
        onClose({ isOnConfirm: true });
      }}
    />
  );
};

function getDeleteModalOkText(item: NormalizedFolderTreeItem) {
  if (!item) {
    return 'Okay';
  }
  if (item.isFolder && item.children!.length !== 0) {
    return 'Delete All';
  }
  return 'Okay';
}

function getDeleteModalDescription(item: NormalizedFolderTreeItem) {
  if (!item) {
    return null;
  }
  if (item.isFolder) {
    if (item.children!.length !== 0) {
      return (
        <>
          Deleting "{item.title}" folder will permanently delete this folder and <u>all its contents</u>!
        </>
      );
    }
    return `"${item.title}" folder will be deleted.`;
  }
  return `"${item.title}" document will be deleted.`;
}

export default DocumentsDeleteModal;
