import { BaseProps } from '~/components/layout/Base';
import { ChartData } from 'chart.js';
import { FC } from 'react';
import { getChartData } from './helpers';
import { GetDealsForReportsQuery } from '~/generated/graphql';
import { Theme as theme } from '~/theme';
import ReportsLineChart from './ReportsLineChart';

type DealProps = GetDealsForReportsQuery['getReportDeals']['deals'][number];

type ReportsFinancedChartProps = BaseProps & {
  closedDeals: DealProps[];
  currentClosedDeals: DealProps[];
  timeWindow: number;
};

const ReportsFinancedChart: FC<ReportsFinancedChartProps> = ({ closedDeals, currentClosedDeals, timeWindow, ...props }) => {
  const chartData = getChartData({ closedDeals, currentClosedDeals, timeWindow, type: 'amountFinanced' });

  const data: ChartData<'line', { x: string | null; y: number | null }[]> = {
    datasets: [
      {
        label: 'Closed',
        data: chartData.current,
        borderColor: theme.colors.primary,
        backgroundColor: theme.colors.primary30,
        hidden: !chartData.current.length,
      },
      {
        label: 'Prediction',
        data: chartData.prediction,
        borderDash: [4],
        borderDashOffset: 4,
        borderColor: theme.colors.primary,
        backgroundColor: theme.colors.purple30,
        hidden: !chartData.prediction.length,
      },
      {
        label: 'Prev Year',
        data: chartData.previous,
        borderColor: theme.colors.blue,
        backgroundColor: theme.colors.blue30,
        hidden: !chartData.previous.length,
      },
    ],
  };

  return <ReportsLineChart data={data} {...props} />;
};

export default ReportsFinancedChart;
