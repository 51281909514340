import { LenderLoansChartTimeWindow } from '~/generated/graphql';

export const lenderLoansChartTimeWindowLabels: Record<LenderLoansChartTimeWindow, string> = {
  [LenderLoansChartTimeWindow.Past_3Months]: 'Past 3 Months',
  [LenderLoansChartTimeWindow.Past_6Months]: 'Past 6 Months',
  [LenderLoansChartTimeWindow.PastYear]: 'Past Year',
  [LenderLoansChartTimeWindow.Past_2Years]: 'Past 2 Years',
  [LenderLoansChartTimeWindow.AllTime]: 'All Time',
};

const lenderLoansChartTimeWindowOptions: { value: LenderLoansChartTimeWindow; label: string }[] = Object.entries(lenderLoansChartTimeWindowLabels).map(
  ([value, label]) => ({ value: value as LenderLoansChartTimeWindow, label }),
);

export default lenderLoansChartTimeWindowOptions;
