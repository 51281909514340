import React, { useRef } from 'react';
import { ContextMenuSourceProps } from '~/containers/DocumentRequestTree';
import { DropdownControlled, DropdownMenu, DropdownItem } from '~/components/vendor';
import { Toggle } from '~/components/ui';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { download, edit, trash2 } from '~/components/vendor/Icon/icons';

interface Props {
  isUserCreatedItem: boolean;
  item: NormalizedFolderTreeItem;
  contextMenuSource: ContextMenuSourceProps;
  setEditFolderModal: (arg: { isOpen: boolean; folder: NormalizedFolderTreeItem }) => void;
  setDeleteFolderModal: (arg: { isOpen: boolean; item: NormalizedFolderTreeItem }) => void;
  onDropdownMenuClose: () => void;
  downloadFolder: (folderId: string) => Promise<void>;
}

const FolderDropdownAdmin = ({ item, contextMenuSource, setEditFolderModal, setDeleteFolderModal, onDropdownMenuClose, downloadFolder }: Props) => {
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  function handleClose() {
    toggleMenu(false);
    onDropdownMenuClose();
  }

  if (contextMenuSource && contextMenuSource.id === item.id) {
    toggleMenu(true);
  }

  return (
    <>
      <Toggle ref={ref} {...anchorProps} />
      <DropdownControlled
        anchorRef={contextMenuSource ? undefined : ref}
        anchorPoint={contextMenuSource ? contextMenuSource.anchorPoint : undefined}
        onClose={handleClose}
        {...menuState}
      >
        <DropdownMenu>
          {item.hasApprovedChildren && (
            <DropdownItem onClick={() => downloadFolder(item.id)} icon={download}>
              Download
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              setEditFolderModal({
                isOpen: true,
                folder: item,
              });
            }}
            icon={edit}
          >
            Edit
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              setDeleteFolderModal({
                isOpen: true,
                item,
              });
            }}
            icon={trash2}
          >
            Delete
          </DropdownItem>
        </DropdownMenu>
      </DropdownControlled>
    </>
  );
};

export default FolderDropdownAdmin;
