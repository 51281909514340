import React, { FC, useEffect, useState } from 'react';
import {
  assetTypeOptions,
  capitalTypeOptions,
  lendingStatesOptions,
  rateTypeOptions,
  programAmortizationOptions,
  programRecourseOptions,
  dealTypeGroupedOptions,
  dealTypeOptionsMap,
} from '~/data';
import { Block, Col, FlexRow, Row } from '../components/layout';
import { Button, Feedback, FileInput, Input, FormGroup, Label, InputGroup, InputAddon } from '../components/form';
import { Card, CardBody, CardHeader } from '../components/card';
import { ConfirmModal, RenameAttachmentModal } from '~/modals';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Badge, Modal, Toggle } from '../components/ui';
import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import {
  AmountValueType,
  CapitalType,
  DealType,
  LendersElasticSearchDocument,
  LendersElasticSearchQuery,
  LenderProgramAttachmentInput,
  LenderProgramFragment,
  ProgramAmortization,
  ProgramRecourse,
  RateType,
  useCreateLenderProgramMutation,
  useGetLenderQuery,
  useGetLocalUserQuery,
  useUpdateLenderProgramMutation,
  useUploadLenderFileMutation,
} from '~/generated/graphql';
import { Heading, StretchedLink, Text } from '../components/type';
import { searchLendersVars } from '~/lib/apolloClient';
import { Icon, Select } from '../components/vendor';
import { Sticky } from '~/components/layout';
import { Table, TableRow, TableCell, TableIcon } from '~/components/table';
import { useApolloClient } from '@apollo/client';
import { useFormScrollOnError } from '~/hooks/useFormScrollOnError';
import { Dropdown, DropdownMenu, DropdownItem } from '~/components/vendor';
import formatDate from '~/helpers/formatDate';
import formatName from '~/helpers/formatName';
import isNotNil from '~/helpers/isNotNil';
import { NumberFormatBase, NumericFormat, NumberFormatValues } from 'react-number-format';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { chevronDown, edit, trash2 } from '~/components/vendor/Icon/icons';
import { depositRequiredOptions } from '~/data/depositRequiredOptions';
import { AmountValueTypeOption, amountValueTypeOptions } from '~/data/amountValueTypeOptions';

type ProgramInputs = {
  name: string;
  asset_types: { value: string; label: string }[];
  attachments: LenderProgramAttachmentInput[];
  capital_types: { value: CapitalType; label: string }[];
  deal_type: { value: DealType; label: string }[];
  dscr: any;
  lending_states: { value: string; label: string }[];
  loan_value_max: any;
  loan_value_min: any;
  ltc_max: any;
  ltv_max: any;
  notes: any;
  origination_fee_max: any;
  origination_fee_min: any;
  rate_type?: { value: RateType; label: string } | null;
  rate_max: any;
  rate_min: any;
  term_months_max: any;
  term_months_min: any;
  exit_fee_min: any;
  exit_fee_max: any;
  other_fees_min: any;
  other_fees_max: any;
  amortization?: { value: ProgramAmortization; label: string } | null;
  recourses?: { value: ProgramRecourse; label: string }[] | null;
  debt_yield_min: any;
  deposit: { required?: boolean | null; type: AmountValueTypeOption; amount?: number | null };
};

const programAssetTypes = [{ value: 'All', label: 'All' }, ...assetTypeOptions];

type ProgramModalProps = {
  program: boolean | (Omit<LenderProgramFragment, 'id'> & { id?: string });
  lenderId: string;
  updateQuery: ReturnType<typeof useGetLenderQuery>['updateQuery'];
  setOpenDeleteProgramModal: (program: LenderProgramFragment) => void;
  onClose: () => void;
};

const ProgramModal: FC<ProgramModalProps> = ({ program, lenderId, updateQuery, setOpenDeleteProgramModal, onClose, ...props }) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [openDeleteAttachmentModal, setOpenDeleteAttachmentModal] = useState(false);
  const [openRenameAttachmentModal, setOpenRenameAttachmentModal] = useState(false);
  const [footerButtonPressed, setFooterButtonPressed] = useState(false);
  const [headerButtonPressed, setHeaderButtonPressed] = useState(false);
  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);
  const [activeAttachment, setActiveAttachment] = useState<{ name: string; index: number }>({ name: '', index: -1 });

  const [uploadFile, { loading: fileIsUploading }] = useUploadLenderFileMutation();
  const [createProgram, { loading: creatingProgram }] = useCreateLenderProgramMutation({ refetchQueries: ['getLenderActivities'] });
  const [updateProgram, { loading: updatingProgram }] = useUpdateLenderProgramMutation({ refetchQueries: ['getLenderActivities'] });

  const { data: currentUserData } = useGetLocalUserQuery({ fetchPolicy: 'cache-only' });

  const programData = (typeof program === 'object' ? program : {}) as Partial<LenderProgramFragment>;

  const isEditOp = programData.id;

  const programForm = useForm<ProgramInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: programData.name ?? '',
      attachments: programData.attachments ?? [],
      dscr: programData.dscr,
      loan_value_max: programData.loan_value_max,
      loan_value_min: programData.loan_value_min,
      ltc_max: programData.ltc_max,
      ltv_max: programData.ltv_max,
      notes: programData.notes ?? '',
      origination_fee_max: programData.origination_fee_max,
      origination_fee_min: programData.origination_fee_min,
      rate_max: programData.rate_max,
      rate_min: programData.rate_min,
      term_months_max: programData.term_months_max,
      term_months_min: programData.term_months_min,
      exit_fee_min: programData.exit_fee_min,
      exit_fee_max: programData.exit_fee_max,
      other_fees_min: programData.other_fees_min,
      other_fees_max: programData.other_fees_max,
      debt_yield_min: programData.debt_yield_min,
      deposit: {
        ...programData.deposit,
        type: amountValueTypeOptions.find((option) => option.value === programData.deposit?.type) ?? amountValueTypeOptions[0],
      },
    },
  });
  const formRateType = programForm.watch('rate_type')?.value;
  const isFlatRate = !formRateType || formRateType === 'FLAT_RATE';
  const deposit = programForm.watch('deposit');

  const {
    fields: attachments,
    append: appendAttachment,
    remove: removeAttachment,
    insert: insertAttachment,
  } = useFieldArray({
    control: programForm.control,
    name: 'attachments',
    keyName: 'id',
  });

  useEffect(() => {
    removeAttachment();

    if (typeof program === 'object') {
      (program.attachments ?? []).forEach((field) => {
        appendAttachment({
          ...field,
          __typename: undefined,
        } as LenderProgramAttachmentInput);
      });

      Object.keys(program).forEach((key) => {
        if (
          [
            'name',
            'dscr',
            'loan_value_max',
            'loan_value_min',
            'ltc_max',
            'ltv_max',
            'notes',
            'origination_fee_max',
            'origination_fee_min',
            'rate_max',
            'rate_min',
            'term_months_max',
            'term_months_min',
            'exit_fee_min',
            'exit_fee_max',
            'other_fees_min',
            'other_fees_max',
            'debt_yield_min',
          ].includes(key)
        ) {
          programForm.setValue(key as keyof ProgramInputs, program[key as keyof ProgramInputs]);
        }
      });
      programForm.setValue(
        'asset_types',
        program.asset_types?.map((type) => programAssetTypes.find((asset) => asset.label === type)!),
      );
      programForm.setValue(
        'capital_types',
        program.capital_types?.map((type) => capitalTypeOptions.find((capitalType) => capitalType.value === type)!),
      );
      programForm.setValue(
        'deal_type',
        program.deal_type?.map((type) => dealTypeOptionsMap[type]),
      );
      programForm.setValue(
        'lending_states',
        program.lending_states?.map((state) => lendingStatesOptions.find((s) => s.value === state)!),
      );
      programForm.setValue(
        'rate_type',
        rateTypeOptions.find((s) => s.value === program.rate_type),
      );
      programForm.setValue(
        'amortization',
        programAmortizationOptions.find((s) => s.value === program.amortization),
      );
      programForm.setValue(
        'recourses',
        programRecourseOptions.filter((s) => program.recourses?.includes(s.value)),
      );
      programForm.setValue('deposit', {
        ...program.deposit,
        type: amountValueTypeOptions.find((option) => option.value === program.deposit?.type) ?? amountValueTypeOptions[0],
      });
    } else {
      programForm.reset({}, { keepDefaultValues: true });
    }
  }, [program]);

  const {
    formState: { errors: formErrors },
  } = programForm;
  useFormScrollOnError(formErrors);

  function handleDeleteAttachment() {
    removeAttachment(activeAttachment?.index);
    setOpenDeleteAttachmentModal(false);
  }

  async function handleAttachmentUpload(files: FileList) {
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const { data: uploadLenderFileData } = await uploadFile({ variables: { file: files?.[0] } });

      appendAttachment({
        s3Key: uploadLenderFileData?.uploadLenderFile!,
        name: file.name,
        createdAt: new Date(),
        createdBy: currentUserData?.currentUser?.fullName,
        userId: currentUserData?.currentUser?._id,
      });
    }
  }

  async function handleFormSubmit(data: ProgramInputs) {
    function sortAlpha(a: string, b: string) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    }
    const mutationProgramValues = {
      ...data,
      lenderId,
      attachments: attachments?.map(({ id, ...attachment }) => attachment as LenderProgramAttachmentInput),
      asset_types: data.asset_types?.map((v) => v.label)?.sort(sortAlpha),
      capital_types: data.capital_types?.map((v) => v.value).sort(sortAlpha),
      deal_type: data.deal_type?.map((v) => v.value)?.sort(sortAlpha),
      lending_states: data.lending_states?.map((v) => v.value)?.sort(sortAlpha),
      rate_type: data.rate_type?.value || null,
      amortization: data.amortization?.value || null,
      recourses: data.recourses?.map((v) => v.value),
      loan_value_min: data.loan_value_min || null,
      loan_value_max: data.loan_value_max || null,
      rate_min: data.rate_min || null,
      rate_max: data.rate_max || null,
      origination_fee_min: data.origination_fee_min || null,
      origination_fee_max: data.origination_fee_max || null,
      exit_fee_min: data.exit_fee_min || null,
      exit_fee_max: data.exit_fee_max || null,
      other_fees_min: data.other_fees_min || null,
      other_fees_max: data.other_fees_max || null,
      term_months_min: data.term_months_min || null,
      term_months_max: data.term_months_max || null,
      ltv_max: data.ltv_max || null,
      ltc_max: data.ltc_max || null,
      dscr: data.dscr || null,
      debt_yield_min: data.debt_yield_min || null,
      deposit:
        data.deposit.required === true
          ? {
              required: true,
              type: data.deposit.type.value,
              amount: data.deposit.amount,
            }
          : data.deposit.required === false
            ? { required: false }
            : null,
    };

    const isEditOp = (program as LenderProgramFragment)?.id;

    if (isEditOp) {
      await updateProgram({
        variables: {
          program: { id: (program as LenderProgramFragment).id, ...mutationProgramValues },
        },
        update: (client, res) => {
          const updatedProgram = res?.data?.updateLenderProgram;
          if (updatedProgram) {
            updateQuery((prevLenderQueryData) => {
              const prevCompany = prevLenderQueryData?.getLender!;
              return {
                getLender: {
                  ...prevCompany,
                  programs: prevCompany.programs!.map((p) => {
                    if (p?.id === (program as LenderProgramFragment).id) {
                      return updatedProgram;
                    }
                    return p;
                  }),
                },
              };
            });
            showGlobalAlert('Program updated');
          }
        },
      });
    } else {
      await createProgram({
        variables: {
          program: mutationProgramValues,
        },
        update: (cache, res) => {
          const newProgram = res?.data?.createLenderProgram;
          if (newProgram) {
            updateQuery((prevLenderQueryData) => {
              const prevCompany = prevLenderQueryData?.getLender!;
              const programs = [...prevCompany.programs!, newProgram];
              const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
                query: LendersElasticSearchDocument,
                variables: searchLendersVars(),
              });

              if (lendersQuery) {
                client.writeQuery<LendersElasticSearchQuery>({
                  query: LendersElasticSearchDocument,
                  variables: searchLendersVars(),
                  data: {
                    lendersElasticSearch: {
                      ...lendersQuery?.lendersElasticSearch,
                      lenders: lendersQuery?.lendersElasticSearch.lenders.map((l) => {
                        if (prevCompany?.id === l.id) {
                          return {
                            ...l,
                            programs,
                            updatedAt: new Date(),
                          };
                        }
                        return l;
                      }),
                    },
                  },
                });
              }
              return {
                getLender: {
                  ...prevCompany,
                  programs,
                },
              };
            });
            showGlobalAlert('Program has been created');
          }
        },
      });
    }

    setHeaderButtonPressed(false);
    setFooterButtonPressed(false);
    onClose();
  }

  // We need to ready formState before the func because it's proxy
  const dirtyFields = { ...programForm.formState.dirtyFields };

  function onModalClose() {
    const formValues = programForm.getValues();

    let isSomeFieldsWasChanged = Object.keys(dirtyFields).some((fieldKey) => {
      if (fieldKey === 'attachments') {
        return false;
      }

      if (fieldKey === 'deposit') {
        return (
          (formValues.deposit.required ?? null) !== (programData.deposit?.required ?? null) ||
          formValues.deposit.type.value !== (programData.deposit?.type ?? amountValueTypeOptions[0].value) ||
          (formValues.deposit.amount ?? 0) !== (programData.deposit?.amount ?? 0)
        );
      }

      if (Array.isArray(formValues[fieldKey as keyof ProgramInputs])) {
        const programDataArr = programData?.[fieldKey as keyof ProgramInputs] as string[];
        return formValues[fieldKey as keyof ProgramInputs].some((selectOption: { value: string; label: string }) => {
          return !programDataArr?.includes(selectOption.value) && !programDataArr?.includes(selectOption.label);
        });
      }

      let formValue = formValues[fieldKey as keyof ProgramInputs] ?? null;
      if (typeof formValue === 'string' && formValue?.length === 0) {
        formValue = null;
      }
      if (formValue?.value) {
        formValue = formValue.value;
      }
      let programValue = programData[fieldKey as keyof ProgramInputs] ?? null;
      if (typeof programValue === 'string' && programValue?.length === 0) {
        programValue = null;
      }

      return formValue !== programValue;
    });

    const allOldS3Keys = programData?.attachments?.map((a) => a.s3Key) ?? [];
    const allOldNames = programData?.attachments?.map((a) => a.name) ?? [];

    const allS3Keys = attachments?.map((a) => a.s3Key) ?? [];
    const allNames = attachments?.map((a) => a.name) ?? [];

    if (JSON.stringify(allS3Keys) !== JSON.stringify(allOldS3Keys) || JSON.stringify(allNames) !== JSON.stringify(allOldNames)) {
      isSomeFieldsWasChanged = true;
    }

    if (Object.keys(dirtyFields).length !== 0 && isSomeFieldsWasChanged) {
      setConfirmCloseModelOpened(true);
    } else {
      onClose();
    }
  }

  return (
    <>
      <Modal layout="horizontal" isOpen={Boolean(program)} onClose={onModalClose} {...props}>
        <Card id="programModalScrollable" isModalContent>
          <form onSubmit={programForm.handleSubmit(handleFormSubmit)}>
            <Sticky rootId="programModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" variant="white" utils={{ my: -1 }} onClick={onModalClose} type="button">
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{programData?.id ? 'Edit Program' : 'Add Program'}</Heading>
                  <Button
                    size="sm"
                    utils={{ my: -1 }}
                    type="submit"
                    isLoading={headerButtonPressed && (creatingProgram || updatingProgram)}
                    disabled={creatingProgram || updatingProgram}
                    onClick={() => setHeaderButtonPressed(true)}
                  >
                    {isEditOp ? 'Save' : 'Add'}
                  </Button>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Overview
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow as={FormGroup} isValid={!formErrors.name} gutter={5}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Program Name</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="name"
                      render={({ field }) => <Input _size="sm" maxLength={50} id="name" placeholder="Program Name" {...field} />}
                      control={programForm.control}
                      rules={{ required: true }}
                    />
                    {formErrors.name && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.capital_types} gutter={5}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Capital Types</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="capital_types"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="capital_types"
                          placeholder="Select Capital Types"
                          isMulti
                          options={capitalTypeOptions}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      control={programForm.control}
                    />
                    {formErrors.capital_types && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.deal_type} gutter={5}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Deal Types</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="deal_type"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="deal_type"
                          placeholder="Select Deal Types"
                          isMulti={true}
                          options={dealTypeGroupedOptions}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      control={programForm.control}
                    />
                    {formErrors.deal_type && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.asset_types} gutter={5}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Asset Types</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="asset_types"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="asset_types"
                          placeholder="Select Asset Types"
                          isMulti={true}
                          options={programAssetTypes}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      control={programForm.control}
                    />
                    {formErrors.asset_types && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.lending_states} gutter={5}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Lending States</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="lending_states"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="lending_states"
                          placeholder="Select option"
                          isMulti={true}
                          options={lendingStatesOptions}
                          value={value}
                          onChange={onChange}
                        />
                      )}
                      control={programForm.control}
                    />
                    {formErrors.lending_states && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Loan Range</TableCell>
                  <TableCell as={FormGroup} isValid={!formErrors.loan_value_min}>
                    <Controller
                      name="loan_value_min"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          prefix="$"
                          thousandSeparator
                          placeholder="$0"
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                  <TableCell span="auto">
                    <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>to</Text>
                  </TableCell>
                  <TableCell as={FormGroup} isValid={!formErrors.loan_value_max}>
                    <Controller
                      name="loan_value_max"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          prefix="$"
                          thousandSeparator
                          placeholder="$0"
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Terms
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow gutter={5}>
                  <TableCell span="30%">Rate</TableCell>
                  <TableCell utils={{ pl: 0 }}>
                    <Row gutter={3} utils={{ alignItems: 'center' }}>
                      <Col span="100%" mobile={{ mb: 4 }} desktop={{ span: 'calc(50% + 20px)' }}>
                        <Row gutter={3} utils={{ alignItems: 'center' }}>
                          <FormGroup as={Col} utils={{ mb: 0 }}>
                            <Controller
                              name="rate_type"
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  size="sm"
                                  id="rate_type"
                                  options={rateTypeOptions}
                                  placeholder="Base Rate"
                                  value={value}
                                  onChange={onChange}
                                  isClearable
                                />
                              )}
                              control={programForm.control}
                            />
                          </FormGroup>
                          <Col span="auto">
                            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>{isFlatRate ? 'of' : '+'}</Text>
                          </Col>
                        </Row>
                      </Col>
                      <Col span="100%" desktop={{ span: true }}>
                        <Row gutter={3} utils={{ alignItems: 'center' }}>
                          <FormGroup as={Col} utils={{ mb: 0 }}>
                            <Controller
                              name="rate_min"
                              render={({ field: { onChange, ...props } }) => (
                                <InputGroup size="sm">
                                  {isFlatRate ? (
                                    <Input
                                      as={NumericFormat}
                                      _size="sm"
                                      placeholder="0%"
                                      suffix="%"
                                      onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                                      {...props}
                                    />
                                  ) : (
                                    <Input
                                      as={NumberFormatBase}
                                      _size="sm"
                                      placeholder="0"
                                      onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                                      format={(val: string) => val && (parseFloat(val) * 100).toString()}
                                      removeFormatting={(val: string) => val && (parseFloat(val) / 100).toString()}
                                      {...props}
                                    />
                                  )}
                                  <InputAddon>
                                    {!isFlatRate && <Text utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700' }}>BPS</Text>}
                                  </InputAddon>
                                </InputGroup>
                              )}
                              control={programForm.control}
                              defaultValue={null}
                            />
                          </FormGroup>
                          <Col span="auto">
                            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>to</Text>
                          </Col>
                          <FormGroup as={Col} utils={{ mb: 0 }}>
                            <Controller
                              name="rate_max"
                              render={({ field: { onChange, ...props } }) => (
                                <InputGroup size="sm">
                                  {isFlatRate ? (
                                    <Input
                                      as={NumericFormat}
                                      _size="sm"
                                      placeholder="0%"
                                      suffix="%"
                                      onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                                      {...props}
                                    />
                                  ) : (
                                    <Input
                                      as={NumberFormatBase}
                                      _size="sm"
                                      placeholder="0"
                                      onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                                      format={(val: string) => val && (parseFloat(val) * 100).toString()}
                                      removeFormatting={(val: string) => val && (parseFloat(val) / 100).toString()}
                                      {...props}
                                    />
                                  )}
                                  <InputAddon>
                                    {!isFlatRate && <Text utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700' }}>BPS</Text>}
                                  </InputAddon>
                                </InputGroup>
                              )}
                              control={programForm.control}
                              defaultValue={null}
                            />
                          </FormGroup>
                        </Row>
                      </Col>
                    </Row>
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Origination Fee</TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="origination_fee_min"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          suffix="%"
                          placeholder="0%"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                  <TableCell span="auto">
                    <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>to</Text>
                  </TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="origination_fee_max"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          suffix="%"
                          placeholder="0%"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Exit fee</TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="exit_fee_min"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          suffix="%"
                          placeholder="0%"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                  <TableCell span="auto">
                    <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>to</Text>
                  </TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="exit_fee_max"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          suffix="%"
                          placeholder="0%"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Other Fees</TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="other_fees_min"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          prefix="$"
                          thousandSeparator
                          placeholder="$0"
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                  <TableCell span="auto">
                    <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>to</Text>
                  </TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="other_fees_max"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          prefix="$"
                          thousandSeparator
                          placeholder="$0"
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Amortization</TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="amortization"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="amortization"
                          placeholder="Select Amortization"
                          options={programAmortizationOptions}
                          value={value}
                          onChange={onChange}
                          isClearable
                        />
                      )}
                      control={programForm.control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Recourse</TableCell>
                  <TableCell as={FormGroup}>
                    <Controller
                      name="recourses"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          id="recourses"
                          placeholder="Select Recourse"
                          options={programRecourseOptions}
                          value={value}
                          onChange={onChange}
                          isClearable
                          isMulti
                        />
                      )}
                      control={programForm.control}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">
                    Terms{' '}
                    <Text as="span" utils={{ color: 'gray700', ml: 2 }}>
                      (In Months)
                    </Text>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="term_months_min"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          placeholder="0"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                  <TableCell span="auto">
                    <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>to</Text>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="term_months_max"
                      render={({ field: { onChange, ...props } }) => (
                        <Input
                          as={NumericFormat}
                          _size="sm"
                          placeholder="0"
                          onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                          {...props}
                        />
                      )}
                      control={programForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow gutter={5}>
                  <TableCell span="30%">Deposits</TableCell>
                  <TableCell>
                    <Controller
                      name="deposit.required"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          size="sm"
                          options={depositRequiredOptions}
                          value={depositRequiredOptions.find((option) => option.value === value)}
                          onChange={(option) => {
                            onChange(option?.value);
                          }}
                          isClearable
                          placeholder="Select requirement"
                        />
                      )}
                      control={programForm.control}
                    />
                  </TableCell>
                  {deposit.required && (
                    <>
                      <TableCell span="auto">
                        <Text utils={{ alignSelf: 'baseline', fontSize: 'sm', color: 'gray700' }}>min of</Text>
                      </TableCell>
                      <TableCell>
                        <InputGroup size="sm">
                          <Controller
                            name="deposit.amount"
                            render={({ field: { onChange, ...props } }) => (
                              <Input
                                as={NumericFormat}
                                _size="sm"
                                onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                                prefix={deposit.type.value === AmountValueType.Flat ? '$' : undefined}
                                suffix={deposit.type.value === AmountValueType.Percentage ? '%' : undefined}
                                thousandSeparator
                                placeholder={deposit.type.value === AmountValueType.Flat ? '$0' : '0%'}
                                {...props}
                              />
                            )}
                            control={programForm.control}
                          />
                          <InputAddon>
                            <Controller
                              name="deposit.type"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  <Dropdown
                                    menuButton={
                                      <Badge utils={{ bgColor: 'white', border: 1, textTransform: 'uppercase', color: 'gray700' }} role="button">
                                        <Icon icon={value.icon} />
                                        <Icon icon={chevronDown} utils={{ ml: 2 }} />
                                      </Badge>
                                    }
                                  >
                                    <DropdownMenu>
                                      {amountValueTypeOptions.map((option) => (
                                        <DropdownItem key={option.value} icon={option.icon} onClick={() => onChange(option)}>
                                          {option.label}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </>
                              )}
                              control={programForm.control}
                            />
                          </InputAddon>
                        </InputGroup>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </Table>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Risk & Return Targets
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <Row>
                  <Col span="100%" desktop={{ span: '50%' }}>
                    <TableRow>
                      <TableCell>LTV</TableCell>
                      <FormGroup as={TableCell} span="100px" isValid={!formErrors.ltv_max}>
                        <Controller
                          name="ltv_max"
                          render={({ field: { onChange, ...props } }) => (
                            <Input
                              as={NumericFormat}
                              _size="sm"
                              placeholder="< 0%"
                              prefix="< "
                              suffix="%"
                              onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                              {...props}
                            />
                          )}
                          control={programForm.control}
                          rules={{ max: 100 }}
                          defaultValue={null}
                        />
                      </FormGroup>
                    </TableRow>
                  </Col>
                  <Col span="100%" desktop={{ span: '50%' }}>
                    <TableRow>
                      <TableCell>LTC</TableCell>
                      <FormGroup as={TableCell} span="100px" isValid={!formErrors.ltc_max}>
                        <Controller
                          name="ltc_max"
                          render={({ field: { onChange, ...props } }) => (
                            <Input
                              as={NumericFormat}
                              _size="sm"
                              placeholder="< 0%"
                              prefix="< "
                              suffix="%"
                              onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                              {...props}
                            />
                          )}
                          control={programForm.control}
                          rules={{ max: 100 }}
                          defaultValue={null}
                        />
                      </FormGroup>
                    </TableRow>
                  </Col>
                </Row>
                <Row>
                  <Col span="100%" desktop={{ span: '50%' }}>
                    <TableRow>
                      <TableCell>DSCR</TableCell>
                      <TableCell span="100px">
                        <Controller
                          name="dscr"
                          render={({ field: { onChange, ...props } }) => (
                            <Input
                              as={NumericFormat}
                              _size="sm"
                              placeholder="> 1.00x"
                              prefix="> "
                              suffix="x"
                              onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                              {...props}
                            />
                          )}
                          control={programForm.control}
                          defaultValue={null}
                        />
                      </TableCell>
                    </TableRow>
                  </Col>
                  <Col span="100%" desktop={{ span: '50%' }}>
                    <TableRow>
                      <TableCell>Debt Yield</TableCell>
                      <TableCell span="100px">
                        <Controller
                          name="debt_yield_min"
                          render={({ field: { onChange, ...props } }) => (
                            <Input
                              as={NumericFormat}
                              _size="sm"
                              placeholder="> 0%"
                              suffix="%"
                              prefix="> "
                              onValueChange={(e: NumberFormatValues) => onChange(isNotNil(e.floatValue) ? e.floatValue : '')}
                              {...props}
                            />
                          )}
                          control={programForm.control}
                          defaultValue={null}
                        />
                      </TableCell>
                    </TableRow>
                  </Col>
                </Row>
              </Table>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Note
              </Heading>
              <FormGroup utils={{ mb: 9 }}>
                <Controller
                  name="notes"
                  render={({ field }) => <Input as={Textarea as React.ElementType} minRows={4} placeholder="Leave a note..." {...field} />}
                  control={programForm.control}
                  defaultValue={programData?.notes ?? null}
                />
              </FormGroup>
              <FlexRow utils={{ alignItems: 'center', mb: 5 }}>
                <Block utils={{ mr: 'auto' }}>
                  <Heading as="h2" utils={{ fontSize: 'xl' }}>
                    Attachments
                  </Heading>
                </Block>
                <FileInput
                  inputProps={{
                    id: 'uploadAttachment',
                    accept: '.pdf,.jpg,.jpeg,.png',
                    onChange: (e) => handleAttachmentUpload(e.target.files!),
                  }}
                  key={attachments?.length}
                >
                  <Button as="span" size="sm" variant="white" isLoading={fileIsUploading}>
                    Add
                  </Button>
                </FileInput>
              </FlexRow>
              {attachments?.length === 0 && (
                <Card size="sm" isInactive={true} utils={{ borderStyle: 'dashed', mb: 9 }}>
                  <CardBody utils={{ textAlign: 'center' }}>
                    <Heading utils={{ fontSize: 'base' }}>No Attachments Yet.</Heading>
                    <Text utils={{ fontSize: 'sm', color: 'gray700' }}>Add tear sheets or program PDFs.</Text>
                  </CardBody>
                </Card>
              )}
              {attachments.length > 0 && (
                <Card size="sm" utils={{ mb: 9 }}>
                  <CardBody utils={{ py: 0, px: 7 }}>
                    <Table size="lg" align="center" gutterY={0} isBorderFlush>
                      {attachments.map((attachment, index) => {
                        return (
                          <TableRow key={attachment.id}>
                            <TableCell>
                              <Block>
                                <Heading as="h6" utils={{ fontSize: 'base', fontWeight: 'base' }}>
                                  <StretchedLink
                                    as="a"
                                    utils={{ color: 'black' }}
                                    role="button"
                                    href={`${getBaseApiUrl()}/api/lender/file/${attachment.s3Key}?isDownload=true&name=${attachment.name}`}
                                    target="_blank"
                                  >
                                    {attachment.name}
                                  </StretchedLink>
                                </Heading>
                                <Text utils={{ fontSize: 'xs', color: 'gray600' }}>
                                  {formatDate(attachment.createdAt!)} · {formatName(attachment?.createdBy!, { initialLastName: true })}
                                </Text>
                              </Block>
                            </TableCell>
                            <TableIcon>
                              <Dropdown menuButton={<Toggle />}>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setActiveAttachment({ name: attachment.name!, index });
                                      setOpenRenameAttachmentModal(true);
                                    }}
                                    icon={edit}
                                  >
                                    Rename
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setActiveAttachment({ name: attachment.name!, index });
                                      setOpenDeleteAttachmentModal(true);
                                    }}
                                    icon={trash2}
                                  >
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </TableIcon>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </CardBody>
                </Card>
              )}
              <Button
                isBlock={true}
                type="submit"
                isLoading={footerButtonPressed && (creatingProgram || updatingProgram)}
                disabled={creatingProgram || updatingProgram}
                onClick={() => setFooterButtonPressed(true)}
              >
                {programData?.id ? 'Save Changes' : 'Add Program'}
              </Button>
              {programData?.id && (
                <Block utils={{ textAlign: 'center', mt: 7 }}>
                  <>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setOpenDeleteProgramModal(program as LenderProgramFragment)}
                      role="button"
                    >
                      Delete Program
                    </Text>
                  </>
                </Block>
              )}
            </CardBody>
          </form>
        </Card>
      </Modal>
      <ConfirmModal
        isOpen={openDeleteAttachmentModal}
        onClose={() => setOpenDeleteAttachmentModal(false)}
        onConfirm={handleDeleteAttachment}
        onCancel={() => setOpenDeleteAttachmentModal(false)}
        text={activeAttachment?.name ? `Are you sure? “${activeAttachment?.name}” will be deleted` : ''}
      />
      <RenameAttachmentModal
        name={activeAttachment?.name}
        isOpen={openRenameAttachmentModal}
        onClose={(newName) => {
          if (newName?.length! > 0) {
            const updatedAttachment = { ...attachments?.[activeAttachment?.index], name: newName };
            removeAttachment(activeAttachment?.index);
            insertAttachment(activeAttachment?.index, updatedAttachment as LenderProgramAttachmentInput);
          }
          setOpenRenameAttachmentModal(false);
        }}
      />
      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have unsaved changes"
        text="Are you sure you want to abandon your edits?"
      />
    </>
  );
};

export default ProgramModal;
