import React, { FC, useState } from 'react';
import Textarea from 'react-textarea-autosize';
import { Button, Feedback, Form, Input, FormGroup } from '../components/form';
import { Col, Row } from '../components/layout';
import { Text } from '../components/type';
import { DocumentRequestStatus, SearchDocumentsDocument, SearchDocumentsQuery, useSetDocumentStatusMutation } from '~/generated/graphql';

type DocumentReviewFormProps = {
  document: SearchDocumentsQuery['searchDocuments'][number];
  updateStatus: (status: DocumentRequestStatus) => void;
};

const DocumentReviewForm: FC<DocumentReviewFormProps> = ({ document, updateStatus }) => {
  const maxChars = 500;

  const [rejecting, setRejecting] = useState(false);
  const [approving, setApproving] = useState(false);

  const [value, setValue] = useState('');
  const [counter, setCounter] = useState(0);
  const [rejectionWithEmptyNote, setRejectionWithEmptyNote] = useState(false);

  const [setDocumentStatus] = useSetDocumentStatusMutation();

  const handleUpdateDocStatus = async (status: DocumentRequestStatus, cb: () => void) => {
    if (!value && status === 'rejected') {
      setRejectionWithEmptyNote(true);
      cb();
      return;
    }

    setRejectionWithEmptyNote(false);

    await setDocumentStatus({
      update: (cache) => {
        const searchDocumentsData = cache.readQuery<SearchDocumentsQuery>({
          query: SearchDocumentsDocument,
          variables: { docRequestId: document.docRequestId },
        });

        if (searchDocumentsData) {
          const newDocs = searchDocumentsData?.searchDocuments?.map((doc) => {
            if (doc._id === document._id) {
              return { ...doc, status, note: value };
            } else {
              return { ...doc };
            }
          });
          cache.writeQuery<SearchDocumentsQuery>({
            query: SearchDocumentsDocument,
            data: { searchDocuments: newDocs },
            variables: { id: document.docRequestId },
          });
        }

        updateStatus(status);

        cb();
      },
      variables: { id: document._id, note: value, status },
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setRejectionWithEmptyNote(false);
    setValue(inputValue.substring(0, maxChars));
    setCounter(inputValue.length <= maxChars ? inputValue.length : maxChars);
  };

  const reject = () => {
    setRejecting(true);
    handleUpdateDocStatus(DocumentRequestStatus.Rejected, () => setRejecting(false));
  };

  const approve = () => {
    setApproving(true);
    handleUpdateDocStatus(DocumentRequestStatus.Approved, () => setApproving(false));
  };

  return (
    <Form>
      <FormGroup isValid={!rejectionWithEmptyNote}>
        <Input
          as={Textarea as React.ElementType}
          size="sm"
          minRows={4}
          placeholder="Notes on the Approval / Rejection"
          value={value}
          autoFocus
          onChange={handleChange}
          isFlush
        />
        {rejectionWithEmptyNote && <Feedback>A note is required for a rejection</Feedback>}
      </FormGroup>
      <Row gutter={4} utils={{ alignItems: 'center' }}>
        <Col>
          <Text utils={{ fontSize: 'sm', color: 'gray600' }}>
            {counter}/{maxChars}
          </Text>
        </Col>
        <Col span="auto">
          <Button type="reset" size="sm" variant="white" onClick={reject} isLoading={rejecting}>
            Reject
          </Button>
          <Button type="reset" size="sm" variant="primary" onClick={approve} isLoading={approving}>
            Approve
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default DocumentReviewForm;
