import { DependencyList, useCallback, useRef } from 'react';

const useAsyncDebounceCallback = <T>(
  callback: (...args: any[]) => Promise<T>,
  debounceTime: number,
  deps: DependencyList,
) => {
  const lastResultRef = useRef<T>();
  const promiseRejectRef = useRef<(() => void) | null>(null);

  return useCallback(async (...args: any[]) => {
    if (promiseRejectRef.current) {
      promiseRejectRef.current();
    }
    try {
      await new Promise<void>((resolve, reject) => {
        promiseRejectRef.current = reject;
        setTimeout(() => {
          resolve();
        }, debounceTime);
      }).then(() => {
        promiseRejectRef.current = null;
      });
    } catch {
      return lastResultRef.current!;
    }

    lastResultRef.current = await callback(...args);
    return lastResultRef.current;
  }, [debounceTime, ...deps]);
};

export default useAsyncDebounceCallback;
