import { LenderResponsivenessInterestLevel, LenderResponsivenessLevel } from '~/generated/graphql';
import theme from '~/theme/Theme';

export type ResponsivenessOptions = {
  value: LenderResponsivenessLevel;
  label: string;
};

export type ResponsivenessInterestOptions = {
  value: LenderResponsivenessInterestLevel;
  label: string;
};

export const responsivenessLabels: Record<LenderResponsivenessLevel, string> = {
  LIMITED_DATA: 'Limited Data',
  NOT_RESPONSIVE: 'Not Responsive',
  SOMEWHAT_RESPONSIVE: 'Somewhat Responsive',
  VERY_RESPONSIVE: 'Very Responsive',
};

export const responsivenessVariants: Record<LenderResponsivenessLevel, keyof typeof theme.colors> = {
  LIMITED_DATA: 'gray600',
  NOT_RESPONSIVE: 'danger',
  SOMEWHAT_RESPONSIVE: 'warning',
  VERY_RESPONSIVE: 'success',
};

export const responsivenessInterestLabels: Record<LenderResponsivenessInterestLevel, string> = {
  LIMITED_DATA: 'Limited Data',
  RARELY_INTERESTED: 'Rarely Interested',
  SOMETIMES_INTERESTED: 'Sometimes Interested',
  OFTEN_INTERESTED: 'Often Interested',
};

export const responsivenessInterestVariants: Record<LenderResponsivenessInterestLevel, keyof typeof theme.colors> = {
  LIMITED_DATA: 'gray600',
  RARELY_INTERESTED: 'danger',
  SOMETIMES_INTERESTED: 'warning',
  OFTEN_INTERESTED: 'success',
};

export const responsivenessOptions: ResponsivenessOptions[] = Object.entries(responsivenessLabels).map(([value, label]) => ({
  value: value as LenderResponsivenessLevel,
  label,
}));

export const responsivenessInterestOptions: ResponsivenessInterestOptions[] = Object.entries(responsivenessInterestLabels).map(([value, label]) => ({
  value: value as LenderResponsivenessInterestLevel,
  label,
}));
