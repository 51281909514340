import React, { Fragment, useEffect, useState, useRef, FC } from 'react';
import { Avatar, Modal, Spinner } from '../components/ui';
import { Block, FlexRow } from '../components/layout';
import { Button, Feedback, FileInput, Input, FormGroup, Label, InputDismiss, InputGroup, InputDivider, InputAddon } from '../components/form';
import { Card, CardBody, CardHeader } from '../components/card';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import {
  CreateLenderContactInput,
  GetLenderContactDataQuery,
  GetLenderQuery,
  LendersElasticSearchDocument,
  LendersElasticSearchQuery,
  LenderContactFragment,
  LendersSimpleSearchQuery,
  useCreateLenderContactMutation,
  useGetLenderContactDataLazyQuery,
  useGetLenderQuery,
  useLendersSimpleSearchLazyQuery,
  useUpdateLenderContactMutation,
  useUploadLenderFileMutation,
} from '~/generated/graphql';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { Heading, Text } from '../components/type';
import { searchLendersVars } from '~/lib/apolloClient';
import { Select, Icon } from '~/components/vendor';
import { Sticky } from '~/components/layout';
import { Table, TableRow, TableCell } from '~/components/table';
import { useApolloClient } from '@apollo/client';
import { useFormScrollOnError } from '~/hooks/useFormScrollOnError';
import ConfirmModal from '~/modals/ConfirmModal';
import LenderModal from '~/modals/LenderModal';
import { PatternFormat } from 'react-number-format';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { plus } from '~/components/vendor/Icon/icons';
import { useDebounceFn } from 'ahooks';
import useAsyncDebounceCallback from '~/hooks/ useAsyncDebounceCallback';

type ArrayInput = {
  source: string;
  value: null | string;
  ext?: null | string;
};

type ContactInputs = {
  avatarId?: string | null;
  emails: ArrayInput[];
  first_name: string;
  job_titles: string;
  company: {
    value: LendersSimpleSearchQuery['lendersSimpleSearch'][number];
    label: string;
    type?: 'createLender';
  };
  last_name: string;
  notes: string;
  phones: ArrayInput[];
  website: string;
};

type LenderContactsModalProps = {
  contact?: Partial<LenderContactFragment> & { profileFileId?: string };
  onClose: () => void;
  lender?: GetLenderQuery['getLender'];
  updateQuery?: ReturnType<typeof useGetLenderQuery>['updateQuery'];
  setOpenDeleteContactModal?: (contact: LenderContactFragment) => void;
  refetchGetDealMembers?: () => any;
};

const LenderContactsModal: FC<LenderContactsModalProps> = ({
  contact,
  onClose,
  lender,
  updateQuery,
  setOpenDeleteContactModal,
  refetchGetDealMembers,
  ...props
}) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [uploadAvatar, { loading: avatarIsUploading }] = useUploadLenderFileMutation();
  const [createContact, { loading: creatingContact }] = useCreateLenderContactMutation({ refetchQueries: ['getLenderActivities'] });
  const [updateContact, { loading: updatingContact }] = useUpdateLenderContactMutation({ refetchQueries: ['getLenderActivities'] });

  const [lendersSimpleSearch] = useLendersSimpleSearchLazyQuery();

  const [tempLogo, setTempLogo] = useState<null | string>(null);
  const [footerButtonPressed, setFooterButtonPressed] = useState(false);
  const [headerButtonPressed, setHeaderButtonPressed] = useState(false);
  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);
  const [lenders, setLenders] = useState<{ value: LendersSimpleSearchQuery['lendersSimpleSearch'][number]; label: string }[]>([]);
  const [lenderId, setLenderId] = useState(lender?.id);

  const dividerOption = { type: 'divider', isDivider: true };
  const [openLenderModal, setOpenLenderModal] = useState(false);
  const [createLenderOption, setCreateLenderOption] = useState({ value: { name: '' }, label: '', type: 'createLender' });
  const lenderInputValueRef = useRef<string | null>(null);

  const contactForm = useForm<ContactInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      avatarId: contact?.avatar_id,
      emails: contact?.emails ?? [{ source: 'welcomelend', value: null }],
      first_name: contact?.first_name!,
      job_titles: contact?.job_titles?.[0]?.value,
      last_name: contact?.last_name!,
      notes: contact?.notes ?? '',
      phones: contact?.phones ?? [{ source: 'welcomelend', value: null }],
      website: contact?.websites?.[0]?.value,
    },
  });

  const formAvatarId = contactForm.watch('avatarId');
  const contactFirstName = contactForm.watch('first_name');
  const contactLastName = contactForm.watch('last_name');

  const isEditOp = contact?.id;

  const {
    formState: { errors: formErrors },
  } = contactForm;
  useFormScrollOnError(formErrors);

  const loadLenderOptions = useAsyncDebounceCallback(
    async (query: string) => {
      const { data } = await lendersSimpleSearch({ variables: { query } });

      const newOptions = (data?.lendersSimpleSearch ?? []).map((res) => ({ value: res, label: res.name }));
      const newCreateLenderOption = { ...createLenderOption, value: { name: lenderInputValueRef.current }, label: lenderInputValueRef.current };
      const createLenderOptions = newOptions?.length > 0 ? [dividerOption, newCreateLenderOption] : [newCreateLenderOption];
      setLenders(newOptions);

      return [...newOptions, ...createLenderOptions];
    },
    300,
    [],
  );

  const [getLenderContactData, { loading: lenderContactDataLoading }] = useGetLenderContactDataLazyQuery({ fetchPolicy: 'network-only' });
  const [lenderContactData, setLenderContactData] = useState<GetLenderContactDataQuery['getLenderContactData'] | null>(null);

  const fetchEmailData = async (email: string, initialCall = false) => {
    if ((email === contact?.emails?.[0]?.value && !initialCall) || email === lenderContactData?.email || !/.+@.+\..+/.test(email!)) {
      return;
    }

    const fetchedEmailData = (await getLenderContactData({ variables: { lenderId, email: email! } })).data!.getLenderContactData;

    setLenderContactData(fetchedEmailData);

    if (fetchedEmailData.firstName && !contactForm.getValues('first_name')) {
      contactForm.setValue('first_name', fetchedEmailData.firstName);
    }
    if (fetchedEmailData.lastName && !contactForm.getValues('last_name')) {
      contactForm.setValue('last_name', fetchedEmailData.lastName);
    }
    if (fetchedEmailData.phones?.length && !contactForm.getValues('phones.0.value')) {
      contactForm.setValue(
        'phones',
        fetchedEmailData.phones.map((phone) => {
          const [value, ext] = phone.split(' ');
          return { source: 'welcomelend', value, ext };
        }),
      );
    }
    if (fetchedEmailData.website && !contactForm.getValues('website')) {
      contactForm.setValue('website', fetchedEmailData.website);
    }
    if (fetchedEmailData.jobTitle && !contactForm.getValues('job_titles')) {
      contactForm.setValue('job_titles', fetchedEmailData.jobTitle);
    }
    if (fetchedEmailData.avatarId && !contactForm.getValues('avatarId')) {
      contactForm.setValue('avatarId', fetchedEmailData.avatarId);
    }

    if (isEditOp || !lender) {
      if (fetchedEmailData?.lenderId) {
        const domainLender = {
          value: {
            id: fetchedEmailData.lenderId,
            name: fetchedEmailData.lenderName!,
            logo: fetchedEmailData.lenderLogo!,
          },
          label: fetchedEmailData.lenderName!,
        };
        setLenders([domainLender]);
        setLenderId(fetchedEmailData.lenderId);
        contactForm.setValue('company', domainLender);
      }
    }
  };

  const { run: onEmailChange } = useDebounceFn(fetchEmailData, { wait: 600 });

  useEffect(() => {
    contactForm.register('avatarId');
    contactForm.setValue('avatarId', null);

    removePhoneField();
    removeEmailField();

    (contact?.emails?.length ? contact.emails : [{ source: 'welcomelend', value: null }]).forEach((field) => {
      appendEmailField(field, { shouldFocus: false });
    });

    (contact?.phones ?? [{ source: 'welcomelend', value: null }]).forEach((field) => {
      const parsedValue = field.value?.trim()?.replaceAll(') ', ')')?.split(' ');
      const value = parsedValue?.[0] ?? null;

      let ext = '';
      for (let index = 1; index < parsedValue?.length!; index++) {
        const element = parsedValue![index];
        ext += element + ' ';
      }
      ext = ext.trim();

      appendPhoneField({ ...field, value, ext }, { shouldFocus: false });
    });

    contactForm.setValue('avatarId', contact?.avatar_id ?? null);

    contactForm.setValue('first_name', contact?.first_name ?? '');
    contactForm.setValue('job_titles', contact?.job_titles?.[0]?.value ?? '');
    contactForm.setValue('last_name', contact?.last_name ?? '');
    contactForm.setValue('notes', contact?.notes ?? '');
    contactForm.setValue('website', contact?.websites?.[0]?.value ?? '');

    contactForm.clearErrors();

    loadLenderOptions('a');
    if (lender) {
      setLenderId(lender.id);
    }
    if (contact?.emails?.[0]?.value) {
      fetchEmailData(contact.emails[0].value, true);
    }
  }, [contact]);

  const {
    fields: phonesFields,
    append: appendPhoneField,
    remove: removePhoneField,
  } = useFieldArray({
    control: contactForm.control,
    name: 'phones',
    keyName: 'id',
  });

  const {
    fields: emailsFields,
    append: appendEmailField,
    remove: removeEmailField,
  } = useFieldArray({
    control: contactForm.control,
    name: 'emails',
    keyName: 'id',
  });

  async function handleAvatarUpload(files: FileList | File[] | null) {
    try {
      if (files?.[0]) {
        const { data: uploadLenderFileData } = await uploadAvatar({ variables: { file: files?.[0] } });

        contactForm.setValue('avatarId', uploadLenderFileData!.uploadLenderFile);
        setTempLogo(URL.createObjectURL(files?.[0]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (contact?.profileFileId) {
      const url = `${getBaseApiUrl()}/api/file/${contact.profileFileId}`;
      fetch(url).then(async (res) => {
        if (res.status === 200) {
          const blob = await res.blob();
          const file = new File([blob], res.headers.get('Content-Disposition')!.split('=')[1].replace(/"/g, ''), { type: blob.type });
          handleAvatarUpload([file]);
        }
      });
    }
  }, [contact?.profileFileId]);

  async function handleFormSubmit(data: ContactInputs) {
    const phones = data?.phones?.filter((field) => field.value);
    const emails = data?.emails?.filter((field) => field.value);

    const mutationContactValues: CreateLenderContactInput = {
      lenderId: lenderId!,
      avatar_id: data?.avatarId,
      first_name: data?.first_name?.trim(),
      last_name: data?.last_name?.trim(),
      notes: data?.notes?.trim(),
      job_titles: data?.job_titles ? [{ value: data?.job_titles, source: 'welcomelend' }] : [],
      phones: phones
        ?.filter((phoneField) => phoneField.value)
        .map((phoneField) => ({ value: `${phoneField.value?.trim()} ${phoneField?.ext?.trim()}`.trim(), source: phoneField.source })),
      emails: emails
        .filter((emailsField) => emailsField.value)
        .map((emailsField) => ({ value: emailsField.value?.trim().toLowerCase()!, source: emailsField.source })),
      websites: data?.website ? [{ value: data?.website, source: 'welcomelend' }] : [],
    };

    if (isEditOp) {
      await updateContact({
        variables: {
          contact: { id: contact.id!, ...mutationContactValues },
        },
        update: (cache, res) => {
          const updatedContact = res?.data?.updateLenderContact;
          if (updatedContact) {
            updateQuery?.((prevLenderQueryData) => {
              const prevCompany = prevLenderQueryData.getLender;
              const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
                query: LendersElasticSearchDocument,
                variables: searchLendersVars(),
              });
              if (lendersQuery) {
                client.writeQuery<LendersElasticSearchQuery>({
                  query: LendersElasticSearchDocument,
                  variables: searchLendersVars(),
                  data: {
                    lendersElasticSearch: {
                      ...lendersQuery.lendersElasticSearch,
                      lenders: lendersQuery.lendersElasticSearch.lenders.map((l) => {
                        if (prevCompany?.id === l.id) {
                          return {
                            ...l,
                            updatedAt: new Date(),
                          };
                        }
                        return l;
                      }),
                    },
                  },
                });
              }
              if (lender!.id !== lenderId) {
                return {
                  getLender: {
                    ...prevCompany!,
                    contacts: prevCompany!.contacts.filter((cc) => cc.id !== contact.id),
                  },
                };
              }
              return prevLenderQueryData;
            });
            showGlobalAlert('Contact updated');
          }
        },
      });
    } else {
      await createContact({
        variables: {
          contact: mutationContactValues,
        },
        update: (client, res) => {
          const newContact = res?.data?.createLenderContact;
          if (newContact) {
            updateQuery?.((prevLenderQueryData) => {
              const prevCompany = prevLenderQueryData?.getLender!;
              const contacts = [...prevCompany.contacts, newContact];
              const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
                query: LendersElasticSearchDocument,
                variables: searchLendersVars(),
              });

              if (lendersQuery) {
                client.writeQuery<LendersElasticSearchQuery>({
                  query: LendersElasticSearchDocument,
                  variables: searchLendersVars(),
                  data: {
                    lendersElasticSearch: {
                      ...lendersQuery.lendersElasticSearch,
                      lenders: lendersQuery.lendersElasticSearch.lenders.map((l) => {
                        if (prevCompany?.id === l.id) {
                          return {
                            ...l,
                            contacts,
                            updatedAt: new Date(),
                          };
                        }
                        return l;
                      }),
                    },
                  },
                });
              }
              return {
                getLender: {
                  ...prevCompany,
                  contacts,
                  updatedAt: new Date(),
                },
              };
            });
            showGlobalAlert('Contact has been created');
          }
        },
      });
      refetchGetDealMembers?.();
    }

    setHeaderButtonPressed(false);
    setFooterButtonPressed(false);
    onClose();
  }

  function onModalClose() {
    const formValues = contactForm.getValues();

    const contactEmails = contact?.emails ?? [];
    const formEmails = formValues.emails.map((e) => e?.value?.trim()).filter(Boolean);
    const formOldEmails = contactEmails.map((e) => e?.value?.trim());

    const contactPhones = contact?.phones ?? [];
    const formPhones = (formValues.phones || [])
      .filter((phoneField) => phoneField?.value || phoneField?.ext)
      .map((phone) => `${phone?.value} ${phone?.ext}`?.trim());
    const formOldPhones = contactPhones.map((e) => e?.value?.trim()?.replace(') ', ')'));

    const isFormChanged =
      formValues?.avatarId !== (contact?.avatar_id ?? null) ||
      JSON.stringify(formEmails) !== JSON.stringify(formOldEmails) ||
      JSON.stringify(formPhones) !== JSON.stringify(formOldPhones) ||
      formValues?.first_name !== (contact?.first_name ?? '') ||
      formValues?.last_name !== (contact?.last_name ?? '') ||
      (formValues?.job_titles?.trim() ?? '') !== (contact?.job_titles?.[0]?.value ?? '') ||
      (formValues?.notes?.trim() || '') !== (contact?.notes?.trim() || '') ||
      (formValues?.website?.trim()?.length === 0 ? undefined : formValues?.website?.trim()) !== (contact?.websites?.[0]?.value ?? undefined);

    if (isFormChanged) {
      setConfirmCloseModelOpened(true);
    } else {
      onClose();
    }
  }

  return (
    <>
      <Modal layout="horizontal" isOpen={Boolean(contact)} onClose={onModalClose} {...props}>
        <Card id="contactsModalScrollable" isModalContent>
          <form onSubmit={contactForm.handleSubmit(handleFormSubmit)}>
            <Sticky rootId="contactsModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" type="button" variant="white" utils={{ my: -1 }} onClick={onModalClose}>
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{contact?.id ? 'Edit Contact' : 'Add Contact'}</Heading>
                  <Button
                    size="sm"
                    utils={{ my: -1 }}
                    type="submit"
                    isLoading={headerButtonPressed && (creatingContact || updatingContact)}
                    disabled={creatingContact || updatingContact}
                    onClick={() => setHeaderButtonPressed(true)}
                  >
                    {isEditOp ? 'Save' : 'Add'}
                  </Button>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Personal Info
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Email</Label>
                  </TableCell>
                  <TableCell>
                    {emailsFields.map((field, index) => {
                      contactForm.register(`emails.${index}.source`, { value: field.source });
                      return (
                        <FormGroup utils={{ position: 'relative', mt: index > 0 ? 4 : 0, mb: 0 }} key={field.id} isValid={!formErrors.emails?.[index]}>
                          <Controller
                            name={`emails.${index}.value`}
                            render={({ field: { onBlur, onChange, value, ...inputProps } }) => (
                              <InputGroup size="sm">
                                <Input
                                  _size="sm"
                                  type="email"
                                  placeholder="Email address"
                                  onBlur={(e) => {
                                    fetchEmailData(e.target.value);
                                    onBlur();
                                  }}
                                  onChange={(e) => {
                                    onChange(e);
                                    onEmailChange(e.target.value);
                                  }}
                                  value={value!}
                                  {...inputProps}
                                />
                                <InputAddon>
                                  <Spinner utils={{ display: lenderContactDataLoading ? undefined : 'none', pr: 8 }} size="xs" variant="primary" />
                                </InputAddon>
                              </InputGroup>
                            )}
                            control={contactForm.control}
                            defaultValue={field.value ?? ''}
                            rules={{ required: true }}
                          />
                          {emailsFields.length > 1 && <InputDismiss role="button" onClick={() => removeEmailField(index)} />}
                          {formErrors.emails?.[index] && <Feedback>This field is required.</Feedback>}
                        </FormGroup>
                      );
                    })}
                  </TableCell>
                  <TableCell span="auto" utils={{ ml: -8 }}>
                    <Text
                      utils={{
                        fontSize: 'sm',
                        color: 'primary',
                      }}
                      role="button"
                      onClick={() => appendEmailField({ source: 'welcomelend', value: null })}
                      style={{ visibility: emailsFields?.length < 2 ? 'visible' : 'hidden' }}
                    >
                      + Add
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Name</Label>
                  </TableCell>
                  <FormGroup as={TableCell} isValid={!formErrors.first_name} utils={{ mb: 0 }}>
                    <Controller
                      name="first_name"
                      render={({ field }) => <Input _size="sm" placeholder="First" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.first_name ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.first_name?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                  <FormGroup as={TableCell} isValid={!formErrors.last_name} utils={{ mb: 0 }}>
                    <Controller
                      name="last_name"
                      render={({ field }) => <Input _size="sm" placeholder="Last" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.last_name ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.last_name?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Company</Label>
                  </TableCell>
                  <FormGroup as={TableCell} isValid={!formErrors.company} utils={{ mb: 0 }}>
                    <Controller
                      name="company"
                      render={({ field: { ref, onChange, ...inputProps } }) => (
                        <Select
                          size="sm"
                          instanceId="company"
                          placeholder="Select a lender"
                          loadOptions={loadLenderOptions}
                          defaultOptions={[...lenders, dividerOption, createLenderOption] as any[]}
                          onOptionRender={({ value, type }) => {
                            if (value?.id) {
                              return (
                                <Fragment key={value.id}>
                                  <Avatar
                                    size="sm"
                                    alt={value.name}
                                    src={getLogoUrl(value)}
                                    fallbackSrc={value?.zoominfo_logo}
                                    imageProps={{ utils: { border: 1 } }}
                                    utils={{ borderRadius: 'sm', mr: 5 }}
                                  />
                                  {value.name}
                                </Fragment>
                              );
                            }
                            if (type === 'createLender') {
                              return (
                                <>
                                  <Avatar
                                    size="sm"
                                    alt={<Icon icon={plus} size="1em" utils={{ display: 'inline-flex' }} />}
                                    utils={{ borderRadius: 'sm', fontSize: 'lg', color: 'primary', mr: 5 }}
                                    titleProps={{ utils: { bgColor: 'primary10' } }}
                                  />
                                  Create new lender
                                </>
                              );
                            }
                          }}
                          onChange={(option) => {
                            if (option!.type === 'createLender') {
                              setOpenLenderModal(true);
                            } else {
                              onChange(option);
                              setLenderId(option!.value.id);
                            }
                          }}
                          onInputChange={(value, { action }) => {
                            if (action === 'input-change') {
                              lenderInputValueRef.current = value;
                              setCreateLenderOption((lender) => ({ ...lender, value: { name: value }, label: value }));
                            }
                          }}
                          async
                          {...inputProps}
                        />
                      )}
                      control={contactForm.control}
                      defaultValue={lender ? { value: lender, label: lender.name } : undefined}
                      rules={{ required: true }}
                    />
                    {formErrors.company?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </FormGroup>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Job Title</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="job_titles"
                      render={({ field }) => <Input _size="sm" placeholder="Head of Operations" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.job_titles?.[0]?.value ?? ''}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="calc(30% - 8px)">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Avatar</Label>
                  </TableCell>
                  <TableCell>
                    <Avatar
                      src={formAvatarId ? (tempLogo ?? `${getBaseApiUrl()}/api/lender/file/${formAvatarId}?width=96&height=96`) : null}
                      alt={`${contactFirstName}${contactLastName}`?.length > 0 ? `${contactFirstName} ${contactLastName}` : '?'}
                      utils={{ borderRadius: 'sm', mr: 5 }}
                    />
                    <FileInput inputProps={{ id: 'uploadContactsAvatar', accept: '.png,.jpg,.jpeg', onChange: (e) => handleAvatarUpload(e.target.files) }}>
                      <Button as="span" size="sm" variant="white" isLoading={avatarIsUploading}>
                        Upload
                      </Button>
                    </FileInput>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Phone</Label>
                  </TableCell>
                  <TableCell>
                    {phonesFields.map((field, index) => {
                      contactForm.register(`phones.${index}.source`, { value: field.source });
                      return (
                        <FormGroup key={field.id} utils={{ position: 'relative', mt: index > 0 ? 4 : 0, mb: 0 }}>
                          <InputGroup size="sm">
                            <Controller
                              name={`phones.${index}.value`}
                              render={({ field }) => (
                                <Input as={PatternFormat} format="(###) ###-####" mask=" " type="tel" placeholder="(123) 456-7890" {...field} />
                              )}
                              control={contactForm.control}
                            />
                            <InputDivider utils={{ px: 3 }} />
                            <Controller
                              name={`phones.${index}.ext`}
                              render={({ field: { value, ...field } }) => (
                                <Input utils={{ maxWidth: '40%' }} placeholder="Ext. ####" value={value!} {...field} />
                              )}
                              control={contactForm.control}
                            />
                          </InputGroup>
                          {phonesFields.length > 1 && <InputDismiss role="button" onClick={() => removePhoneField(index)} />}
                        </FormGroup>
                      );
                    })}
                  </TableCell>
                  <TableCell span="auto" utils={{ ml: -8 }}>
                    <Text
                      utils={{ fontSize: 'sm', color: 'primary' }}
                      role="button"
                      onClick={() => appendPhoneField({ source: 'welcomelend', value: null }, { shouldFocus: false })}
                      style={{ visibility: phonesFields?.length < 2 ? 'visible' : 'hidden' }}
                    >
                      + Add
                    </Text>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>LinkedIn URL</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="website"
                      render={({ field }) => <Input _size="sm" type="url" placeholder="Enter a LinkedIn URL" {...field} />}
                      control={contactForm.control}
                      defaultValue={contact?.websites?.[0]?.value ?? ''}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Note
              </Heading>
              <FormGroup utils={{ mb: 9 }}>
                <Controller
                  name="notes"
                  render={({ field }) => <Input as={Textarea as React.ElementType} minRows={4} placeholder="Leave a note..." {...field} />}
                  control={contactForm.control}
                  defaultValue={contact?.notes ?? ''}
                />
              </FormGroup>
              <Button
                isBlock={true}
                type="submit"
                isLoading={footerButtonPressed && (creatingContact || updatingContact)}
                disabled={creatingContact || updatingContact}
                onClick={() => setFooterButtonPressed(true)}
              >
                {contact?.id ? 'Save Changes' : 'Add Contact'}
              </Button>
              {contact?.id && (
                <Block utils={{ textAlign: 'center', mt: 7 }}>
                  <>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setOpenDeleteContactModal!(contact as LenderContactFragment)}
                      role="button"
                    >
                      Delete Contact
                    </Text>
                  </>
                </Block>
              )}
            </CardBody>
          </form>
        </Card>
      </Modal>

      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have unsaved changes"
        text="Are you sure you want to abandon your edits?"
      />
      <LenderModal
        isOpen={openLenderModal}
        onClose={() => {
          setOpenLenderModal(false);
        }}
        lender={createLenderOption.value}
        setLender={(lender) => {
          const lenderOption = {
            value: lender,
            label: lender.name,
          };
          setLenders([lenderOption]);
          setLenderId(lender.id);
          contactForm.setValue('company', lenderOption);
        }}
        updateQuery={updateQuery}
      />
    </>
  );
};

export default LenderContactsModal;
