import ActivityLenderLink from '~/containers/Activities/ActivityLenderLink';
import { ActivityItemLink } from './ActivityItemLink';
import { addHighlightWords } from './helpers/addHighlightWords';
import { CompanyFragment, ActivityInvitationFragment, DealMemberFragment, LightCompanyFragment } from '~/generated/graphql';
import ActivityBorrowerLink from '~/containers/Activities/ActivityBorrowerLink';

type ActivityInvitationNameProps = {
  invitation: DealMemberFragment | ActivityInvitationFragment;
  search?: string | null;
  openMatchModel?: (lender: CompanyFragment | LightCompanyFragment) => void;
  borrowerId?: string;
  lenderId?: string;
};

export function ActivityInvitationName({ invitation, search, openMatchModel, borrowerId, lenderId }: ActivityInvitationNameProps) {
  const emailOrCompany = !lenderId && !borrowerId && (
    <>
      {' ('}
      {invitation.lenderContact?.lender ? (
        <ActivityLenderLink openMatchModel={openMatchModel} lender={invitation.lenderContact.lender} search={search} />
      ) : invitation.borrowerContact?.borrower ? (
        <ActivityBorrowerLink borrower={invitation.borrowerContact.borrower} />
      ) : (
        addHighlightWords(invitation.email, search)
      )}
      {')'}
    </>
  );

  if (invitation.foundUser) {
    return (
      <>
        <ActivityItemLink>{addHighlightWords(invitation.foundUser.fullName, search)}</ActivityItemLink>
        {emailOrCompany}
      </>
    );
  }

  if (invitation.lenderContact) {
    return (
      <>
        <ActivityItemLink>{addHighlightWords(`${invitation.lenderContact.first_name} ${invitation.lenderContact.last_name}`, search)}</ActivityItemLink>
        {emailOrCompany}
      </>
    );
  }

  if (invitation.borrowerContact) {
    return (
      <>
        <ActivityItemLink>{addHighlightWords(`${invitation.borrowerContact.firstName} ${invitation.borrowerContact.lastName}`, search)}</ActivityItemLink>
        {emailOrCompany}
      </>
    );
  }

  return addHighlightWords(invitation.email, search);
}
