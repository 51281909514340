import React, { FC, useMemo } from 'react';
import { Block } from '~/components/layout';
import { NotFound } from '~/components/not-found';
import { Heading, Text } from '~/components/type';
import { getResponsivenessLevel } from '~/containers/Responsiveness';
import { getResponsivenessInterestLevel } from '~/containers/Responsiveness/ResponsivenessTooltip';
import { responsivenessInterestLabels, responsivenessLabels } from '~/data/responsiveness';
import { CompanyFragment, DealFieldsFragment, DealLenderStatus, LenderDealsQuery } from '~/generated/graphql';
import { Table } from '~/components/table';
import styled from 'styled-components';
import LenderDeal, { getDealMatchData } from '~/containers/Matching/LenderDeal';

const DealDescription = styled(Text)`
  &::first-letter {
    text-transform: uppercase;
  }
`;

type MatchModalSimilarDealsProps = {
  deal: DealFieldsFragment;
  lenderDeals?: LenderDealsQuery['lenderDeals']['lenderDeals'];
  lender?: CompanyFragment | null;
  isHidden?: boolean;
};

const MatchModalSimilarDeals: FC<MatchModalSimilarDealsProps> = ({ deal, lenderDeals, lender, isHidden }) => {
  const description = useMemo(() => {
    if (!lenderDeals || lenderDeals.length <= 3) {
      return '';
    }

    const similarResponsiveness = {
      invited: lenderDeals.length,
      viewed: 0,
      responded: lenderDeals.filter(({ status }) => status !== DealLenderStatus.NoAnswer).length,
      responses: lenderDeals.map(({ status }) => ({ status, count: 1 })),
    };

    const level = getResponsivenessLevel(similarResponsiveness);
    const interestLevel = getResponsivenessInterestLevel(similarResponsiveness);

    let comparisonCondition = ' and';
    if (
      (level === 'VERY_RESPONSIVE' && interestLevel === 'RARELY_INTERESTED') ||
      (level === 'SOMEWHAT_RESPONSIVE' && (interestLevel === 'OFTEN_INTERESTED' || interestLevel === 'RARELY_INTERESTED')) ||
      (level === 'NOT_RESPONSIVE' && (interestLevel === 'OFTEN_INTERESTED' || interestLevel === 'SOMETIMES_INTERESTED'))
    ) {
      comparisonCondition = ', but';
    }

    return `${responsivenessLabels[level]}${comparisonCondition} ${responsivenessInterestLabels[interestLevel]} in the ${lenderDeals.length} similar deals we’ve invited them to.`.toLowerCase();
  }, [lenderDeals]);

  if (!lenderDeals || isHidden) {
    return null;
  }

  const dealMatchData = getDealMatchData(deal);

  return (
    <>
      <Block utils={{ mb: 6, mt: 10 }}>
        <Heading as="h3" utils={{ fontSize: 'xl' }}>
          Similar Deals
        </Heading>
        {description && <DealDescription utils={{ fontSize: 'sm' }}>{description}</DealDescription>}
      </Block>
      {lenderDeals.length === 0 ? (
        <NotFound size="xs">
          <Text as="strong">No similar deals.</Text>{' '}
          <Text as="span" utils={{ color: 'gray700' }}>
            This lender hasn’t been invited to similar deals.
          </Text>
        </NotFound>
      ) : (
        <>
          <Table size="lg" align="center" gutterY={0} utils={{ mb: 10 }}>
            {lenderDeals.map((lenderDeal) => (
              <LenderDeal key={lenderDeal.deal._id} lenderDeal={lenderDeal} deal={dealMatchData} lender={lender!} />
            ))}
          </Table>
        </>
      )}
    </>
  );
};

export default MatchModalSimilarDeals;
