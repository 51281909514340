import { GetDealsForReportsQuery, ClosingFeeSplitType, DealFieldsFragment } from '~/generated/graphql';

function getPart(deal: NonNullable<GetDealsForReportsQuery['getReportDeals']['deals']>[0] | DealFieldsFragment, recipientId?: string) {
  const amountFinanced = deal?.settings?.closingFeeBreakdown?.amountFinanced || 0;
  const directRate = deal?.settings?.closingFeeBreakdown?.directRate || 0;
  const whiteLabelRate = deal?.settings?.closingFeeBreakdown?.whiteLabelRate || 0;

  // Total fees
  const directFee = (amountFinanced * directRate) / 100;
  const whiteLabelFee = (amountFinanced * whiteLabelRate) / 100;

  // User splits
  const userSplits = deal?.settings?.closingFeeBreakdown?.splits?.filter((split) => split?.recipient?._id === recipientId);
  const userDirectSplit = userSplits?.filter((split) => split.type === ClosingFeeSplitType.Direct)[0]?.value || 0;
  const userWhiteLabelSplit = userSplits?.filter((split) => split.type === ClosingFeeSplitType.WhiteLabel)[0]?.value || 0;

  // Results
  const totalCommission = directFee + whiteLabelFee;
  const userCommission = (userDirectSplit * directFee + userWhiteLabelSplit * whiteLabelFee) / 100;

  // Part
  return userCommission / totalCommission;
}

type DealData = {
  createdAt: Date;
  closedAt: Date | string;
  closedAtEst: Date | null | undefined;
  closedIn: number;
  closedInEst: number;
  closedInDays: number;
  closedInDaysEst: number;
  amountFinanced: number;
  directRate: number;
  whiteLabelRate: number;
  grossRevenue: number;
  directFee: number;
  whiteLabelFee: number;
  fee: number;
};

export default function getDealData(deal: NonNullable<GetDealsForReportsQuery['getReportDeals']['deals']>[0] | DealFieldsFragment, recipientId?: string): DealData {
  let part = recipientId ? getPart(deal, recipientId) : 1;
  part = !isNaN(part) ? part : 1;
  const closingFeeBreakdown = deal?.settings?.closingFeeBreakdown;

  const createdAt = deal?.createdAt ?? '0';
  const closedAt = deal?.closedAt ?? '0';
  const closedAtEst = deal?.settings?.estimatedCloseDate;
  const closedIn = new Date(closedAt).getTime() - new Date(createdAt).getTime();
  const closedInEst = closedAtEst ? new Date(closedAtEst).getTime() - new Date().getTime() : 0;
  const closedInDays = Math.round(closedIn / (1000 * 60 * 60 * 24));
  const closedInDaysEst = Math.round(closedInEst / (1000 * 60 * 60 * 24));

  const amountFinanced = closingFeeBreakdown?.amountFinanced ?? 0;

  const directRate = closingFeeBreakdown?.directRate ?? 0;
  const whiteLabelRate = closingFeeBreakdown?.whiteLabelRate ?? 0;

  const directFee = (amountFinanced * directRate) / 100;
  const whiteLabelFee = (amountFinanced * whiteLabelRate) / 100;
  const fee = directFee + whiteLabelFee;

  const grossRevenue = deal?.settings?.closingFee ?? 0;

  return {
    createdAt,
    closedAt,
    closedAtEst,
    closedIn,
    closedInEst,
    closedInDays,
    closedInDaysEst,
    amountFinanced: amountFinanced * part,
    directRate,
    whiteLabelRate,
    grossRevenue,
    directFee,
    whiteLabelFee,
    fee: fee * part,
  };
}
