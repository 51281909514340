import React, { FC, useState } from 'react';
import { Divider, Status } from '~/components/ui';
import { Button } from '~/components/form';
import { CompanyFragment, DealUserAndInvitationItemTypeEnum, GetDealMembersQuery, UserOnlineStatus, useCurrentUserQuery } from '~/generated/graphql';
import { FlexCol, FlexRow, Row, Col } from '~/components/layout';
import { formatDate, formatName } from '~/helpers';
import { Heading, StretchedLink, Text } from '~/components/type';
import { Icon } from '~/components/vendor';
import { link } from '~/components/vendor/Icon/icons';
import { List, ListItem } from '~/components/list';
import { NotFound } from '~/components/not-found';
import { removeHttp } from '~/helpers/removeHttp';
import { Table, TableCollapse, TableCollapseBody, TableRow, TableCell } from '~/components/table';
import { UserOnlineStatusMapped, UserOnlineStatusVariants } from '~/data/userOnlineStatus';
import { useRouter } from 'next/router';
import CheckboxSquare from '~/components/form/CheckboxSquare';
import CopyToClipboard from '~/containers/CopyToClipboard';
import LenderFindContactsModal from '../LenderFindContactsModal';
import LinkedIn from '~/components/icons/IconLinkedin';
import Responsiveness from '~/containers/Responsiveness';
import useDealSelectedEmails from '~/hooks/useDealSelectedEmails';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';
import UpdatedBy from '~/containers/UpdatedBy';

type MatchModalContactsProps = {
  isTestMatch?: boolean;
  enableBlur?: boolean;
  lender: CompanyFragment;
  handleInviteClick?: (contact: { email: string }) => void;
  dealMembers?: GetDealMembersQuery['getDealMembers'];
};

const MatchModalContacts: FC<MatchModalContactsProps> = ({ isTestMatch, enableBlur, lender, handleInviteClick, dealMembers }) => {
  const router = useRouter();
  const dealId = router.query?.dealId as string;

  const [selectedEmails, toggleEmail] = useDealSelectedEmails();
  const [openFindContactsModal, setOpenFindContactsModal] = useState(false);

  const { data: currentUserData } = useCurrentUserQuery({ fetchPolicy: 'cache-only' });

  return (
    <>
      {lender.contacts.length! > 0 ? (
        <>
          <FlexRow utils={{ alignItems: 'center', mb: 6 }}>
            <Heading as="h3" utils={{ fontSize: 'xl' }}>
              Contacts
            </Heading>
            {currentUserData?.currentUser && (
              <Button size="sm" variant="white" utils={{ ml: 'auto' }} onClick={() => setOpenFindContactsModal(true)}>
                Find
              </Button>
            )}
          </FlexRow>
          <Table size="lg" align="center" gutterY={0} utils={{ position: 'relative' }} style={{ zIndex: 0 }}>
            {lender.contacts.map((contact) => {
              const linkedInUrls = contact.websites?.filter((website) => website.value?.includes('linkedin.com')) ?? [];

              const emails = contact.emails?.filter((email: { source: string }) => {
                return ['welcomelend', 'airtable'].includes(email.source);
              });

              const phones = contact.phones?.filter((phone: { source: string }) => {
                return ['welcomelend', 'airtable'].includes(phone.source);
              });

              const invite = emails?.[0]?.value ? dealMembers?.usersAndInvitations?.find(({ email }) => email === emails[0].value) : undefined;
              const fullName = `${contact.first_name} ${contact.last_name}`;

              return (
                <TableCollapse key={contact.id}>
                  {(isOpen, setOpen) => (
                    <>
                      <TableRow utils={{ blur: (enableBlur && '4px') || undefined }} isActive={isOpen}>
                        {dealId && (
                          <TableCell span="auto" utils={{ mr: -8 }} style={{ position: 'relative', zIndex: 1 }}>
                            <CheckboxSquare
                              inputProps={{
                                checked: selectedEmails.includes(emails?.[0]?.value),
                                disabled: Boolean(invite) || !emails?.[0]?.value,
                                onChange: () => toggleEmail(emails?.[0]?.value, 'lender'),
                              }}
                              data-tooltip-id="GlobalTooltip"
                              data-tooltip-content={
                                invite
                                  ? `${formatName(fullName, { initialLastName: true })} has already been invited to this deal.`
                                  : !emails?.[0]?.value
                                    ? 'This contact doesn`t have an email.'
                                    : undefined
                              }
                              data-tooltip-hidden={!(Boolean(invite) || !emails?.[0]?.value)}
                            />
                          </TableCell>
                        )}
                        <TableCell span="auto" utils={{ mr: -10 }}>
                          <UserAvatar
                            user={invite?.foundUser ?? invite}
                            lenderContact={contact}
                            utils={{ mr: 6 }}
                            status={invite && invite.itemType === DealUserAndInvitationItemTypeEnum.User ? invite.user!.onlineStatus : undefined}
                          />
                        </TableCell>
                        <TableCell>
                          <FlexCol utils={{ minWidth: 0 }}>
                            {enableBlur ? (
                              <Heading as="h3" utils={{ fontSize: 'base', fontWeight: 'base', color: 'black', textTruncate: true }}>
                                {fullName}
                              </Heading>
                            ) : (
                              <StretchedLink onClick={() => setOpen(!isOpen)}>
                                <FlexRow utils={{ alignItems: 'center' }}>
                                  <Heading as="h3" utils={{ fontSize: 'base', color: 'black', textTruncate: true }}>
                                    {fullName}
                                  </Heading>
                                  <Responsiveness
                                    data={contact.responsiveness}
                                    utils={{ position: 'relative', minWidth: '14px', ml: 4 }}
                                    style={{ zIndex: 1 }}
                                    onClick={(e) => e.stopPropagation()}
                                  />
                                  {linkedInUrls?.map((url) => (
                                    <Text
                                      key={url.value}
                                      as="a"
                                      utils={{ position: 'relative', display: 'inline-flex', color: 'gray500', ml: 2 }}
                                      hover={{ color: 'primary' }}
                                      style={{ position: 'relative', zIndex: 1 }}
                                      href={url.value}
                                      target="_blank"
                                    >
                                      <LinkedIn width="16" height="16" />
                                    </Text>
                                  ))}
                                </FlexRow>
                              </StretchedLink>
                            )}
                            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>{contact?.job_titles?.map((j) => j.value).join(', ')}</Text>
                          </FlexCol>
                        </TableCell>
                        {!isTestMatch && (
                          <TableCell span="auto" mobile={{ position: 'relative' }} style={{ zIndex: 1 }}>
                            {invite ? (
                              invite.itemType !== DealUserAndInvitationItemTypeEnum.User ? (
                                <>
                                  <Status size={10} variant="circle-dashed" />
                                  <Text
                                    desktop={{ display: 'none' }}
                                    utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}
                                    data-tooltip-id="GlobalTooltip"
                                    data-tooltip-content={`Invited ${formatDate(invite.createdAt).toLowerCase()}`}
                                  >
                                    Invited
                                  </Text>
                                  <Text mobile={{ display: 'none' }} utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}>
                                    Invited {formatDate(invite.createdAt).toLowerCase()}
                                  </Text>
                                </>
                              ) : invite.user!.onlineStatus === UserOnlineStatus.Online ? (
                                <>
                                  <Status size={10} variant={UserOnlineStatusVariants[invite.user!.onlineStatus]} />
                                  <Text
                                    desktop={{ display: 'none' }}
                                    utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}
                                    data-tooltip-id="GlobalTooltip"
                                    data-tooltip-content={`${UserOnlineStatusMapped[invite.user!.onlineStatus]}`}
                                  >
                                    {UserOnlineStatusMapped[invite.user!.onlineStatus]}
                                  </Text>
                                  <Text mobile={{ display: 'none' }} utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}>
                                    {UserOnlineStatusMapped[invite.user!.onlineStatus]}
                                  </Text>
                                </>
                              ) : (
                                <>
                                  <Status size={10} variant={UserOnlineStatusVariants[invite.user!.onlineStatus]} />
                                  <Text
                                    desktop={{ display: 'none' }}
                                    utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}
                                    data-tooltip-id="GlobalTooltip"
                                    data-tooltip-content={`Active ${formatDate(invite.user!.lastSeenAt!)}`}
                                  >
                                    Active
                                  </Text>
                                  <Text mobile={{ display: 'none' }} utils={{ fontSize: 'sm', color: 'gray700', ml: 4 }}>
                                    Active {formatDate(invite.user!.lastSeenAt!)}
                                  </Text>
                                </>
                              )
                            ) : (
                              <div
                                data-tooltip-id="GlobalTooltip"
                                data-tooltip-content={
                                  invite
                                    ? `${formatName(fullName, { initialLastName: true })} has already been invited to this deal.`
                                    : !emails?.[0]?.value
                                      ? 'This contact doesn`t have an email.'
                                      : undefined
                                }
                                data-tooltip-hidden={!!emails?.[0]?.value}
                                style={{ position: 'relative', zIndex: 1 }}
                              >
                                <Button size="sm" disabled={!emails?.[0]?.value} onClick={() => handleInviteClick?.({ email: emails?.[0]?.value })}>
                                  {contact.wasInvited ? 'Reinvite' : 'Invite'}
                                </Button>
                              </div>
                            )}
                          </TableCell>
                        )}
                        <TableCollapseBody isOpen={isOpen}>
                          <TableCell>
                            {contact.notes && (
                              <>
                                <Heading as="h5" utils={{ fontSize: '2xs', textTransform: 'uppercase', color: 'gray700', mb: 3 }}>
                                  Notes:
                                </Heading>
                                <Text utils={{ fontSize: 'sm', mb: 7 }}>{contact.notes}</Text>
                              </>
                            )}
                            <List gutter={3} utils={{ fontSize: 'sm', mb: 0 }}>
                              {emails?.map((email, index: number) => (
                                <ListItem key={email.value}>
                                  <Row gutter={3} utils={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                      Email {index > 0 ? '(Alt)' : ''}
                                    </Col>
                                    <Col>
                                      <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                                    </Col>
                                    <Col span="auto" utils={{ textTruncate: true }}>
                                      <Text as="a" utils={{}} href={`mailto:${email.value}`}>
                                        {email.value}
                                      </Text>
                                    </Col>
                                    <Col span="auto">
                                      {!enableBlur && (
                                        <CopyToClipboard text={email.value} message="Copied!">
                                          <Text
                                            as="span"
                                            utils={{ color: 'gray700' }}
                                            data-tooltip-id="GlobalTooltip"
                                            data-tooltip-content="Copy email"
                                            role="button"
                                          >
                                            <Icon icon={link} />
                                          </Text>
                                        </CopyToClipboard>
                                      )}
                                    </Col>
                                  </Row>
                                </ListItem>
                              ))}
                              {phones?.map((phone, index) => {
                                const parsedValue = phone?.value?.trim()?.replaceAll(') ', ')')?.split(' ');
                                const phoneValue = parsedValue?.[0];
                                return (
                                  <ListItem key={index}>
                                    <Row gutter={3} utils={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                      <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                        Phone {index > 0 ? '(Alt)' : ''}
                                      </Col>
                                      <Col>
                                        <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                                      </Col>
                                      <Col span="auto" utils={{ textTruncate: true }}>
                                        <Text as="a" href={`tel:${phoneValue}`}>
                                          {phone.value}
                                        </Text>
                                      </Col>
                                      <Col span="auto">
                                        {!enableBlur && (
                                          <CopyToClipboard text={phoneValue} message="Copied!">
                                            <Text
                                              as="span"
                                              utils={{ color: 'gray700' }}
                                              data-tooltip-id="GlobalTooltip"
                                              data-tooltip-content="Copy phone number"
                                              role="button"
                                            >
                                              <Icon icon={link} />
                                            </Text>
                                          </CopyToClipboard>
                                        )}
                                      </Col>
                                    </Row>
                                  </ListItem>
                                );
                              })}
                              {contact.websites?.length > 0 && (
                                <ListItem>
                                  <Row gutter={3} utils={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <Col span="auto" utils={{ fontWeight: 'bold' }}>
                                      LinkedIn
                                    </Col>
                                    <Col>
                                      <Divider utils={{ borderColor: 'gray400', borderStyle: 'dotted', my: 0 }} />
                                    </Col>
                                    <Col span="auto" utils={{ textTruncate: true }}>
                                      <Text as="a" href={contact.websites[0].value} target="_blank">
                                        {removeHttp(contact.websites[0].value)}
                                      </Text>
                                    </Col>
                                    <Col span="auto">
                                      {!enableBlur && (
                                        <CopyToClipboard text={removeHttp(contact.websites[0].value)} message="Copied!">
                                          <Text
                                            as="span"
                                            utils={{ color: 'gray700' }}
                                            data-tooltip-id="GlobalTooltip"
                                            data-tooltip-content="Copy URL"
                                            role="button"
                                          >
                                            <Icon icon={link} />
                                          </Text>
                                        </CopyToClipboard>
                                      )}
                                    </Col>
                                  </Row>
                                </ListItem>
                              )}
                            </List>
                            <Divider utils={{ my: 7 }} />
                            <UpdatedBy user={contact.updatedBy} createdAt={contact.createdAt} updatedAt={contact.updatedAt} />
                          </TableCell>
                        </TableCollapseBody>
                      </TableRow>
                    </>
                  )}
                </TableCollapse>
              );
            })}
          </Table>
        </>
      ) : (
        <NotFound
          title="No Contacts Yet"
          description={
            <>
              <Text as="span" utils={{ color: 'primary' }} onClick={() => setOpenFindContactsModal(true)} role="button">
                Find contacts
              </Text>{' '}
              to improve this lender.
            </>
          }
          image="gallery"
        />
      )}

      {/* Modals */}
      {currentUserData?.currentUser && lender && (
        <LenderFindContactsModal
          isOpen={openFindContactsModal}
          onClose={() => setOpenFindContactsModal(false)}
          lender={lender}
          refetchQueries={['getDealMembers']}
        />
      )}
    </>
  );
};

export default MatchModalContacts;
