import React, { useState, useMemo, useEffect, FC } from 'react';
import { closeDealEventModalVar } from '~/lib/apolloClient';
import { ConfirmModal, DealSettingsModal } from '~/modals';
import { DealTabs } from '~/@types/deal-tabs';
import { DealSearchResultFragment, useCurrentUserQuery, useDeleteDealMutation } from '~/generated/graphql';
import { useRouter } from 'next/router';
import { Table, TableWindow } from '~/components/table';
import DealsListItem from '~/containers/Deals/DealsListItem';
import DuplicateDealModal from '~/modals/DuplicateDealModal';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import useRemoveDealFromList from '~/hooks/useRemoveDealFromList';
import useUserSorting from '~/hooks/useUserSorting';
import DealsListHeader from './DealsListHeader';

type ItemProps = {
  isBorrower?: boolean;
  isAdmin?: boolean;
  isCreatorColumnVisible?: boolean;
  setOpenDealSettingsModal: (deal: DealSearchResultFragment) => void;
  setCloseDealModalOpen: (isOpen: boolean) => void;
  handleDeleteDeal: (deal: DealSearchResultFragment) => void;
  setOpenDuplicateDealModal: (deal: DealSearchResultFragment) => void;
  loadNextPage: (startIndex: number, endIndex: number) => void;
  isNextPageLoading: boolean;
};

const Item =
  ({ isBorrower, isAdmin, isCreatorColumnVisible, setOpenDealSettingsModal, setCloseDealModalOpen, handleDeleteDeal, setOpenDuplicateDealModal }: ItemProps) =>
  // eslint-disable-next-line react/display-name
  ({ item, style }: { item?: DealSearchResultFragment; style: React.CSSProperties }) => {
    return (
      <DealsListItem
        key={item?._id || 'loading'}
        item={item}
        style={style}
        isBorrower={isBorrower}
        isAdmin={isAdmin}
        isCreatorColumnVisible={isCreatorColumnVisible}
        setOpenDealSettingsModal={setOpenDealSettingsModal}
        setCloseDealModalOpen={setCloseDealModalOpen}
        handleDeleteDeal={handleDeleteDeal}
        setOpenDuplicateDealModal={setOpenDuplicateDealModal}
      />
    );
  };

const sortingKeys: Partial<Record<DealTabs, 'dealsSorting' | 'inactiveDealsSorting'>> = {
  [DealTabs.Active]: 'dealsSorting',
  [DealTabs.Inactive]: 'inactiveDealsSorting',
};

type DealsListProps = {
  deals: DealSearchResultFragment[];
  isNextPageLoading: boolean;
  loadNextPage: (startIndex: number, endIndex: number) => void;
  totalCount: number;
};

const DealsList: FC<DealsListProps> = ({ deals, isNextPageLoading, loadNextPage, totalCount }) => {
  const loadMoreItems = (index: number) => {
    if (!isNextPageLoading) {
      loadNextPage(0, index);
    }
  };

  const router = useRouter();
  const { data: currentUserData } = useCurrentUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = currentUserData?.currentUser;

  const showGlobalAlert = useShowGlobalAlert();

  const { setIsOpen: setCloseDealModalOpen } = closeDealEventModalVar();

  const removeDealFromList = useRemoveDealFromList();

  const tab = (router.query?.tab as DealTabs) ?? DealTabs.Active;

  const [deletingDeal, setDeletingDeal] = useState<DealSearchResultFragment | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openDealSettingsModal, setOpenDealSettingsModal] = useState<DealSearchResultFragment | false>(false);
  const [openDuplicateDealModal, setOpenDuplicateDealModal] = useState<DealSearchResultFragment | false>(false);

  const [sort, setSort] = useUserSorting(sortingKeys[tab]!);

  const isAdmin = currentUser?.isAdmin;
  const isLender = currentUser?.isLender;
  const isBorrower = currentUser?.isBorrower;
  const isSuperAdmin = currentUser?.isSuperAdmin;

  const isCreatorColumnVisible = isSuperAdmin || isBorrower || isLender;

  const [deleteDeal, { loading: loadingDeleteDeal }] = useDeleteDealMutation();

  useEffect(() => {
    window.scrollTo(0, Math.max(window.scrollY - 1, 0));
  }, []);

  const handleDeleteDeal = (deal: DealSearchResultFragment) => {
    setDeletingDeal(deal);
    setOpenDeleteModal(true);
  };

  const onConfirmDelete = async () => {
    await deleteDeal({
      update: () => {
        removeDealFromList(deletingDeal!._id, tab);

        setOpenDeleteModal(false);
        setDeletingDeal(null);

        showGlobalAlert('Deal deleted');
      },
      variables: { id: deletingDeal!._id },
      refetchQueries: ['currentUser'],
    });
  };

  const onCancelDelete = () => {
    setOpenDeleteModal(false);
    setDeletingDeal(null);
  };

  const TableItem = useMemo(
    () =>
      Item({
        isBorrower: Boolean(isBorrower),
        isAdmin: Boolean(isAdmin),
        isCreatorColumnVisible: Boolean(isCreatorColumnVisible),
        setOpenDealSettingsModal,
        setCloseDealModalOpen,
        handleDeleteDeal,
        setOpenDuplicateDealModal,
        loadNextPage,
        isNextPageLoading,
      }),
    [isBorrower, isAdmin, isCreatorColumnVisible, setCloseDealModalOpen, deals],
  );

  return (
    <>
      {/* List */}
      <Table size="lg" align="center" gutterY={0}>
        <DealsListHeader sort={sort} setSort={setSort} isAdmin={isAdmin} isCreatorColumnVisible={isCreatorColumnVisible} />
        <TableWindow
          items={deals}
          totalItems={totalCount}
          itemHeight={90}
          renderItem={(item, style) => <TableItem key={item?._id || 'loading'} item={item} style={style} />}
          onLoadedItemsScrollEnd={loadMoreItems}
        />
      </Table>

      {/* Modals */}
      <ConfirmModal
        isLoading={loadingDeleteDeal}
        isOpen={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        onConfirm={onConfirmDelete}
        onCancel={onCancelDelete}
        text="Deal will be deleted"
      />
      <DealSettingsModal
        isOpen={Boolean(openDealSettingsModal)}
        deal={openDealSettingsModal ? openDealSettingsModal : undefined}
        onClose={() => setOpenDealSettingsModal(false)}
      />
      <DuplicateDealModal
        isOpen={Boolean(openDuplicateDealModal)}
        onClose={() => setOpenDuplicateDealModal(false)}
        deal={openDuplicateDealModal ? openDuplicateDealModal : undefined}
      />
    </>
  );
};

export default DealsList;
