import React, { useRef } from 'react';
import { Badge } from '~/components/ui';
import { Block } from '~/components/layout';
import { DealStatus as DealStatusEnum } from '~/generated/graphql';
import { DropdownControlled, DropdownMenu } from '~/components/vendor';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { useRouter } from 'next/router';
import DealStatusListHolder from './DealStatusListHolder';
import DealStatusToggle from './DealStatusToggle';

interface DealStatusProps {
  status: DealStatusEnum;
  onChange?: (status: DealStatusEnum) => void;
  disabled?: boolean;
}

const DealStatus: React.FC<DealStatusProps> = ({ status, onChange, disabled }) => {
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  const router = useRouter();

  return (
    <>
      <Block utils={{ display: 'inline-block', minWidth: '200px', ...(router.query?.dealId && { textAlign: 'right' }) }}>
        <Badge
          ref={ref}
          size="lg"
          utils={{ bgColor: menuState.state === 'open' || menuState.state === 'opening' ? 'gray200' : 'transparent', color: 'gray800' }}
          hover={{ bgColor: 'gray200' }}
          style={{ position: 'relative', zIndex: 1 }}
          data-testid="dealStatusDropdown"
          role="button"
          {...anchorProps}
        >
          <DealStatusToggle status={status} />
        </Badge>
        <DropdownControlled align="center" gap={8} anchorRef={ref} onClose={() => toggleMenu(false)} {...menuState}>
          <DropdownMenu>
            <DealStatusListHolder
              onChange={(dealStatus) => {
                toggleMenu(false);
                onChange?.(dealStatus);
              }}
              disabled={disabled}
              status={status}
            />
            {/* {isAdmin && (
              <>
                <Divider utils={{ my: 4, mx: 6 }} />
                <FlexRow utils={{ py: 3, px: 6 }}>
                  <Text utils={{ mr: 'auto', fontSize: 'sm', fontWeight: 'bold' }}>{closingFee ? 'Collected Fees' : 'Estimated Fees'}:</Text>
                  <Text utils={{ fontSize: 'sm' }}>{fee || closingFee ? formatMoney(closingFee ?? fee) : '--'}</Text>
                </FlexRow>
              </>
            )} */}
          </DropdownMenu>
        </DropdownControlled>
      </Block>
    </>
  );
};

export default DealStatus;
