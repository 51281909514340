import React, { useEffect, useState } from 'react';
import { Avatar, Modal, Spinner } from '../components/ui';
import { Block, FlexRow } from '../components/layout';
import { Button, Feedback, FileInput, Input, FormGroup, Label, InputGroup, InputAddon } from '../components/form';
import { Card, CardBody, CardHeader } from '../components/card';
import { ConfirmModal, DissolutionModal } from '~/modals';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useDebounceFn } from 'ahooks';
import { getDomain } from '~/helpers/lender/getDomain';
import {
  GetSearchDocument,
  GetSearchQuery,
  GetDomainDataQuery,
  GetLenderQuery,
  LendersElasticSearchDocument,
  LendersElasticSearchQuery,
  UpdateCompanyInput,
  useCreateLenderMutation,
  useGetDomainDataLazyQuery,
  useGetLenderQuery,
  useUpdateLenderMutation,
  useUploadLenderFileMutation,
} from '~/generated/graphql';
import { getLogoUrl } from '~/helpers/lender/getLogoUrl';
import { Heading, Text } from '../components/type';
import { searchLendersVars } from '~/lib/apolloClient';
import { lenderTypeOptions, lendingStatesOptions } from '~/data';
import { Select } from '../components/vendor';
import { Sticky } from '~/components/layout';
import { Table, TableRow, TableCell } from '~/components/table';
import { useApolloClient } from '@apollo/client';
import { useFormScrollOnError } from '~/hooks/useFormScrollOnError';
import { useRouter } from 'next/router';
import DeleteLenderModal from '~/modals/DeleteLenderModal';
import Link from 'next/link';
import Textarea from 'react-textarea-autosize';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { LenderTypeOption } from '~/data/lenderTypeOptions';
import { withHttp } from '~/helpers';

type SelectValue = {
  value: string;
  label: string;
};

type LenderInputs = {
  logo?: string | null;
  name?: string;
  lender_type?: LenderTypeOption | null;
  home_state?: SelectValue | null;
  website?: string | null;
  about?: string | null;
};

const URL_REGEXP = /^(https?:\/\/)?(www\.)?.+\..+$/i;

type LenderModalProps = {
  lender?: Partial<GetLenderQuery['getLender']>;
  lenderId?: string;
  isOpen: boolean;
  onClose: () => void;
  setLender?: (lender: any) => void;
  updateQuery?: ReturnType<typeof useGetLenderQuery>['updateQuery'];
};

const LenderModal: React.FC<LenderModalProps> = ({ lender, lenderId, isOpen, onClose, setLender, updateQuery, ...props }) => {
  const [lenderData, setLenderData] = useState(lender);
  const router = useRouter();
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();

  const { refetch: getLender } = useGetLenderQuery({
    fetchPolicy: 'cache-first',
    variables: {
      id: lenderId!,
    },
    skip: true,
  });

  useEffect(() => {
    if (!isOpen) {
      setLenderData(null);
      return;
    }
    if (lender) {
      setLenderData(lender);
    } else if (lenderId) {
      getLender().then(({ data }) => {
        setLenderData(data?.getLender);
      });
    }
  }, [lender, lenderId, isOpen]);

  const [uploadAvatar, { loading: avatarIsUploading }] = useUploadLenderFileMutation();
  const [updateLender, { loading: updatingLender }] = useUpdateLenderMutation({ refetchQueries: ['getLenderActivities'] });
  const [createLender, { loading: creatingLender }] = useCreateLenderMutation();
  const [getDomainData, { loading: domainDataLoading }] = useGetDomainDataLazyQuery({ fetchPolicy: 'network-only' });

  const [tempLogo, setTempLogo] = useState<null | string>(null);
  const [openDeleteLenderModal, setOpenDeleteContactModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [isConfirmCloseModalOpened, setConfirmCloseModelOpened] = useState(false);
  const [dissolutionModalOpen, setDissolutionModalOpen] = useState(false);
  const [domainData, setDomainData] = useState<GetDomainDataQuery['getDomainData'] | null>(null);
  const isEditOp = lenderData?.id;
  const activeEvent = lenderData?.dissolution_events?.[0];

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.keyCode === 27 && isOpen) {
      if (dissolutionModalOpen) {
        setDissolutionModalOpen(false);
        return;
      }
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp, false);

    return () => {
      document.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  const lenderForm = useForm<LenderInputs>({
    reValidateMode: 'onChange',
    defaultValues: {
      logo: lenderData?.logo,
      name: lenderData?.name,
      lender_type: lenderData?.lender_type ? lenderTypeOptions.find(({ value }) => value === lenderData.lender_type) : null,
      home_state: lenderData?.home_state ? lendingStatesOptions.find((s) => s.value === lenderData?.home_state) : null,
      website: lenderData?.urls?.[0] ? withHttp(lenderData?.urls?.[0]) : null,
      about: lenderData?.about,
    },
    shouldUnregister: true,
  });

  useEffect(() => {
    lenderForm.register('logo');
    lenderForm.setValue('logo', null);
    lenderForm.setValue('name', lenderData?.name);

    if (isEditOp) {
      lenderForm.setValue('logo', lenderData?.logo);
      lenderForm.setValue('lender_type', lenderData?.lender_type ? lenderTypeOptions.find(({ value }) => value === lenderData.lender_type) : null);

      lenderForm.setValue('home_state', lenderData?.home_state ? lendingStatesOptions.find((s) => s.value === lenderData?.home_state) : null);
      lenderForm.setValue('website', lenderData?.urls?.[0] ? withHttp(lenderData?.urls?.[0]) : null);
      lenderForm.setValue('about', lenderData?.about);
    }

    setDomainData(null);
  }, [lenderData, isOpen]);

  const lenderLogo = lenderForm.watch('logo');
  const lenderName = lenderForm.watch('name');

  const {
    formState: { errors: formErrors },
  } = lenderForm;
  useFormScrollOnError(formErrors);

  async function handleAvatarUpload(files: FileList | null) {
    try {
      if (files?.[0]) {
        const { data: uploadLenderFileData } = await uploadAvatar({ variables: { file: files?.[0] } });
        const fileId = uploadLenderFileData!.uploadLenderFile;

        lenderForm.setValue('logo', fileId);
        setTempLogo(URL.createObjectURL(files?.[0]));
      }
    } catch (error) {
      console.error(error);
    }
  }

  const lenderFormWebsite = lenderForm.watch('website');
  const validateDomain = () => {
    if (domainData?.lenderName && domainData?.domain === lenderFormWebsite) {
      lenderForm.setError('website', {
        type: 'duplicate',
        message: '',
      });
      return false;
    } else {
      lenderForm.clearErrors('website');
    }
  };

  useEffect(() => {
    validateDomain();
  }, [lenderFormWebsite, domainData]);

  const fetchDomainData = async () => {
    const domain = lenderForm.getValues('website');

    if (getDomain(domain!) === lenderData?.urls?.[0] || domain === domainData?.domain || !URL_REGEXP.test(domain!)) {
      return;
    }

    const fetchedDomainData = (await getDomainData({ variables: { domain: domain!, isLender: true } })).data!.getDomainData;

    setDomainData(fetchedDomainData);

    if (fetchedDomainData.name && !lenderForm.getValues('name')) {
      lenderForm.setValue('name', fetchedDomainData.name);
    }
    if (fetchedDomainData.about && !lenderForm.getValues('about')) {
      lenderForm.setValue('about', fetchedDomainData.about);
    }
    if (fetchedDomainData.logo && !lenderForm.getValues('logo')) {
      lenderForm.setValue('logo', fetchedDomainData.logo);
    }
    if (fetchedDomainData.state && !lenderForm.getValues('home_state')) {
      const state = lendingStatesOptions.find(({ label }) => label === fetchedDomainData.state);
      if (state) {
        lenderForm.setValue('home_state', state);
      }
    }
    if (fetchedDomainData.lenderType && !lenderForm.getValues('lender_type')) {
      const lenderType = lenderTypeOptions.find(({ value }) => value === fetchedDomainData.lenderType);
      if (lenderType) {
        lenderForm.setValue('lender_type', lenderType);
      }
    }
  };

  const { run: onDomainChange } = useDebounceFn(fetchDomainData, { wait: 600 });

  async function handleFormSubmit(data: LenderInputs) {
    if (validateDomain() === false) {
      return;
    }

    setFormLoading(true);

    const lenderMutationValues: Omit<UpdateCompanyInput, 'id'> = {
      name: data?.name?.trim()!,
      logo: data?.logo,
      lender_type: data?.lender_type?.value!,
      home_state: data?.home_state?.value,
      about: data?.about,
      urls: [],
    };

    if (data?.website) {
      lenderMutationValues.urls = [getDomain(data?.website!)!];
    }

    if (isEditOp) {
      await updateLender({
        variables: {
          lender: { id: lenderData.id!, ...lenderMutationValues },
        },
        update: (client, res) => {
          const updatedLender = res?.data?.updateLender;
          if (updatedLender) {
            updateQuery?.((prevLenderQueryData) => {
              const prevCompany = prevLenderQueryData?.getLender;
              return {
                getLender: {
                  ...prevCompany!,
                  ...lenderMutationValues,
                  logo: tempLogo ?? lenderMutationValues?.logo,
                },
              };
            });

            const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
              query: LendersElasticSearchDocument,
              variables: searchLendersVars(),
            });

            if (lendersQuery) {
              client.writeQuery<LendersElasticSearchQuery>({
                query: LendersElasticSearchDocument,
                variables: searchLendersVars(),
                data: {
                  lendersElasticSearch: {
                    ...lendersQuery?.lendersElasticSearch,
                    lenders: lendersQuery?.lendersElasticSearch.lenders.map((l) => {
                      if (lenderData?.id === l.id) {
                        return {
                          ...l,
                          name: lenderMutationValues.name,
                          logo: lenderMutationValues.logo,
                          lender_type: lenderMutationValues.lender_type,
                          updatedAt: new Date(),
                        };
                      }
                      return l;
                    }),
                  },
                },
              });
            }

            showGlobalAlert('Lender updated');
          }
        },
      });
    } else {
      await createLender({
        variables: {
          lender: lenderMutationValues,
        },
        update: (client, res) => {
          const newLender = res?.data?.createLender;
          if (newLender) {
            const lendersQuery = client.readQuery<LendersElasticSearchQuery>({
              query: LendersElasticSearchDocument,
              variables: searchLendersVars(),
            });

            if (lendersQuery) {
              client.writeQuery<LendersElasticSearchQuery>({
                query: LendersElasticSearchDocument,
                variables: searchLendersVars(),
                data: {
                  lendersElasticSearch: {
                    ...lendersQuery.lendersElasticSearch,
                    count: lendersQuery.lendersElasticSearch.count + 1,
                    lenders: [...lendersQuery.lendersElasticSearch.lenders, newLender].sort((a, b) => {
                      if (a.name! < b.name!) {
                        return -1;
                      }
                      if (a.name! > b.name!) {
                        return 1;
                      }
                      return 0;
                    }),
                  },
                },
              });
              if (!setLender) {
                router.push('/lender/[lenderId]/overview', `/lender/${newLender.id}/overview`);
              }
            }

            showGlobalAlert('Lender created');

            setLender?.(newLender);
          }
        },
      });
    }

    setFormLoading(false);
    setTempLogo(null);
    onClose();
  }

  function handleDeleteLender() {
    if (lenderData?.id) {
      const getSearchQuery = client.readQuery<GetSearchQuery>({
        query: GetSearchDocument,
      });

      if (getSearchQuery) {
        client.writeQuery<GetSearchQuery>({
          query: GetSearchDocument,
          data: {
            ...getSearchQuery.getSearch,
            getSearch: getSearchQuery.getSearch.filter((s) => (s as { id: string }).id !== lenderData.id),
          },
        });
      }

      if (router.query?.lenderId) {
        // Maybe do we need here back button feature?
        router.back();
      }
    }
    setOpenDeleteContactModal(false);
    onClose();
  }

  const dirtyFields = lenderForm.formState.dirtyFields;

  function onModalClose() {
    const newLenderLogo = lenderForm.getValues('logo');
    const oldLenderLogo = lenderData?.logo ?? null;

    if (Object.keys(dirtyFields).length !== 0 || oldLenderLogo !== newLenderLogo) {
      setConfirmCloseModelOpened(true);
    } else {
      setTempLogo(null);
      onClose();
    }
  }

  return (
    <>
      <Modal layout="horizontal" isOpen={isOpen} onClose={onModalClose} {...props}>
        <Card id="lenderModalScrollable" isModalContent>
          <form onSubmit={lenderForm.handleSubmit(handleFormSubmit)}>
            <Sticky rootId="lenderModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" variant="white" utils={{ my: -1 }} onClick={onModalClose} type="button">
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>{isEditOp ? 'Edit' : 'Add'} Lender</Heading>
                  <Button
                    size="sm"
                    utils={{ my: -1 }}
                    isLoading={updatingLender || creatingLender || formLoading}
                    disabled={updatingLender || creatingLender || formLoading || domainDataLoading}
                  >
                    {isEditOp ? 'Save' : 'Add'}
                  </Button>
                </CardHeader>
              )}
            </Sticky>
            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Lender Info
              </Heading>
              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow as={FormGroup} isValid={!formErrors.website} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Domain</Label>
                  </TableCell>
                  <TableCell utils={{ mr: 4 }}>
                    <Controller
                      name="website"
                      render={({ field: { onChange, onBlur, value, ...inputProps } }) => (
                        <InputGroup size="sm">
                          <Input
                            placeholder="Enter a URL"
                            onChange={(e) => {
                              onChange(e);
                              onDomainChange();
                            }}
                            onBlur={() => {
                              onBlur();
                              fetchDomainData();
                            }}
                            value={value!}
                            {...inputProps}
                          />
                          <InputAddon>
                            <Spinner utils={{ display: domainDataLoading ? undefined : 'none', pr: 8 }} size="xs" variant="primary" />
                          </InputAddon>
                        </InputGroup>
                      )}
                      control={lenderForm.control}
                      defaultValue={lenderData?.urls?.[0] ?? ''}
                      rules={{
                        required: true,
                        validate: (data) => (URL_REGEXP.test(data!) ? undefined : 'Please enter a valid URL.'),
                      }}
                    />
                    {formErrors.website?.type === 'duplicate' && domainData && (
                      <Feedback utils={{ ml: -4 }}>
                        This domain is already associated with the lender&nbsp;
                        <Link
                          passHref
                          href={{
                            pathname: `/lender/${domainData.lenderId}/overview`,
                          }}
                          style={{ color: 'inherit', textDecoration: 'underline' }}
                        >
                          {domainData.lenderName}
                        </Link>
                      </Feedback>
                    )}
                    {formErrors.website?.type !== 'duplicate' && formErrors.website?.message && (
                      <Feedback utils={{ ml: -4 }}>{formErrors.website.message}</Feedback>
                    )}
                    {formErrors.website?.type === 'required' && <Feedback utils={{ ml: -4 }}>Please enter a valid URL.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.name} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Name</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="name"
                      render={({ field }) => <Input _size="sm" placeholder="Lender name" {...field} />}
                      control={lenderForm.control}
                      defaultValue={lenderData?.name ?? ''}
                      rules={{ required: true }}
                    />
                    {formErrors.name?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="calc(30% - 8px)">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Avatar</Label>
                  </TableCell>
                  <TableCell utils={{ alignItems: 'center' }}>
                    <Avatar
                      alt={lenderName?.length! > 0 ? lenderName : '?'}
                      src={getLogoUrl({ logo: tempLogo ?? lenderLogo, urls: lenderData?.urls, website: domainData?.domain })}
                      fallbackSrc={lenderData?.zoominfo_logo}
                      utils={{ borderRadius: 'sm', mr: 5 }}
                    />
                    <FileInput
                      inputProps={{
                        id: 'uploadLenderAvatar',
                        accept: '.png,.jpg,.jpeg',
                        onChange: (e) => handleAvatarUpload(e.target.files),
                      }}
                    >
                      <Button as="span" size="sm" variant="white" isLoading={avatarIsUploading}>
                        Upload
                      </Button>
                    </FileInput>
                  </TableCell>
                </TableRow>
                <TableRow as={FormGroup} isValid={!formErrors.lender_type} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Lender Type</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="lender_type"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="lender_type" placeholder="Select lender type" options={lenderTypeOptions} value={value} onChange={onChange} />
                      )}
                      control={lenderForm.control}
                      rules={{ required: true }}
                      defaultValue={null}
                    />
                    {(formErrors.lender_type as FieldError)?.type === 'required' && <Feedback utils={{ ml: -4 }}>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Home State</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="home_state"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="home_state" placeholder="Select state" options={lendingStatesOptions} value={value} onChange={onChange} />
                      )}
                      control={lenderForm.control}
                      defaultValue={null}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>About</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="about"
                      render={({ field }) => <Input as={Textarea as React.ElementType} size="sm" minRows={4} placeholder="Leave a note..." {...field} />}
                      control={lenderForm.control}
                      defaultValue={lenderData?.about ?? ''}
                    />
                  </TableCell>
                </TableRow>
              </Table>
              <Button
                isBlock={true}
                isLoading={updatingLender || creatingLender || formLoading}
                disabled={updatingLender || creatingLender || formLoading || domainDataLoading}
              >
                {isEditOp ? 'Save Changes' : 'Add Lender'}
              </Button>
              {isEditOp && (
                <Block utils={{ textAlign: 'center', mt: 7 }}>
                  <>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setOpenDeleteContactModal(true)}
                      role="button"
                    >
                      Delete Lender
                    </Text>
                    <Text as="span" utils={{ color: 'gray400', mx: 4 }}>
                      |
                    </Text>
                    <Text
                      as="span"
                      utils={{ fontSize: 'sm', color: 'gray700' }}
                      hover={{ color: 'danger' }}
                      onClick={() => setDissolutionModalOpen(true)}
                      role="button"
                    >
                      {activeEvent ? 'Edit Dissolution Event' : 'Add Dissolution Event'}
                    </Text>
                  </>
                </Block>
              )}
            </CardBody>
          </form>
        </Card>
      </Modal>

      <DeleteLenderModal
        isOpen={openDeleteLenderModal}
        onClose={() => {
          setOpenDeleteContactModal(false);
        }}
        onDelete={() => {
          handleDeleteLender();
          setOpenDeleteContactModal(false);
        }}
        lenderId={lenderData?.id}
      />
      <ConfirmModal
        isLoading={false}
        isOpen={isConfirmCloseModalOpened}
        onClose={() => setConfirmCloseModelOpened(false)}
        onConfirm={() => {
          setConfirmCloseModelOpened(false);
          onClose();
        }}
        okText="Yes"
        onCancel={() => setConfirmCloseModelOpened(false)}
        question="You have unsaved changes"
        text="Are you sure you want to abandon your edits?"
      />
      <DissolutionModal
        isOpen={dissolutionModalOpen}
        onClose={() => setDissolutionModalOpen(false)}
        onLenderModalClose={() => onClose()}
        lender={lenderData as GetLenderQuery['getLender']}
      />
    </>
  );
};

export default LenderModal;
