import { BaseSpan } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

export const ActivityItemLink = styled(BaseSpan)`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold};
    color: ${theme.colors.black};

    &[href]:hover,
    &[role='button']:hover {
      color: ${theme.colors.primary};
    }
  `}
`;
