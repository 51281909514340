import React, { useEffect, useRef, useState } from 'react';
import { fadeIn } from '~/helpers';
import Base, { BaseNav, BaseSpan, BaseHOCPropsWithoutRef } from '~/components/layout/Base';
import styled, { css } from 'styled-components';

type Data = {
  content: string;
  sheetName: string;
  [key: string]: unknown;
};

//
// Styled container
//

const StyledContainer = styled(Base)`
  ${({ theme }) => css`
    position: absolute;
    top: -${theme.previewModalLeftGutter}px;
    right: -${theme.previewModalLeftGutter}px;
    bottom: -${theme.previewModalLeftGutter}px;
    left: -${theme.previewModalLeftGutter}px;
    display: flex;
    overflow: auto;
  `}
`;

//
// Styled window
//

type StyledWindowProps = {
  hasData: boolean;
};

const StyledWindow = styled(Base)<StyledWindowProps>`
  ${({ hasData, theme }) => css`
    position: relative;
    margin: auto;
    padding: ${theme.spacers[8]}px;
    padding-bottom: ${hasData ? '70px' : null};
  `}
`;

//
// Styled preview
//

const StyledPreview = styled(Base)`
  ${({ theme }) => css`
    padding: ${theme.spacers[8]}px;
    background-color: ${theme.colors.white};
    border-radius: ${theme.borderRadius.base}px;
  `}
`;

//
// Styled nav
//

const StyledNav = styled(BaseNav)`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: ${theme.zindex.fixed};
    display: flex;
    max-width: 100%;
    padding: ${theme.spacers[4]}px;
    background-color: ${fadeIn(theme.colors.black, 0.95)};
    border-radius: ${theme.borderRadius.rounded}px;
    overflow: auto;
    transform: translateX(-50%);

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

//
// Styled nav
//

type StyledNavLinkProps = {
  isActive?: boolean;
};

const StyledNavLink = styled(BaseSpan)<StyledNavLinkProps>`
  ${({ isActive, theme }) => css`
    display: block;
    padding: ${theme.spacers[2]}px ${theme.spacers[4]}px;
    font-size: ${theme.fontSize.sm};
    white-space: nowrap;
    background-color: ${isActive ? theme.colors.gray900 : null};
    border-radius: inherit;
    color: ${isActive ? theme.colors.white : theme.colors.gray500};

    & + & {
      margin-left: ${theme.spacers[2]}px;
    }

    &:hover {
      color: ${theme.colors.white};
    }
  `}
`;

//
// Preview nav
//

type PreviewNavInnerProps = {
  data: Data[];
  activePage: number;
  onActivePageChange: (page: number) => void;
};

type PreviewNavProps = BaseHOCPropsWithoutRef<'nav', PreviewNavInnerProps>;

const PreviewNav = ({ data, activePage, onActivePageChange, ...props }: PreviewNavProps) => {
  return (
    <StyledNav {...props}>
      {data.map((page, key: number) => (
        <StyledNavLink
          role="button"
          className="button"
          key={key}
          isActive={activePage === key}
          onClick={(e) => {
            e.preventDefault();
            onActivePageChange(key);
          }}
        >
          {page.sheetName}
        </StyledNavLink>
      ))}
    </StyledNav>
  );
};

type PreviewXLSProps = {
  data: Data[];
  onClose: () => void;
};

const PreviewXLS = ({ data, onClose }: PreviewXLSProps) => {
  const [activePage, setActivePage] = useState(0);
  const containerEl = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    if (containerEl.current) {
      containerEl.current.scrollTop = 0;
      containerEl.current.scrollLeft = 0;
    }
  });

  return (
    <>
      <StyledContainer ref={containerEl} onClick={handleClick}>
        <StyledWindow hasData={data.length > 0} onClick={handleClick}>
          <StyledPreview dangerouslySetInnerHTML={{ __html: data[activePage].content }} />
        </StyledWindow>
      </StyledContainer>
      {data.length > 1 && <PreviewNav data={data} activePage={activePage} onActivePageChange={(page: number) => setActivePage(page)} />}
    </>
  );
};

export default PreviewXLS;
