import React, { FC, useEffect, useMemo, useState } from 'react';
import { CardBody, CardHeader } from '~/components/card';
import { Close } from '~/components/ui';
import { Col, FlexRow, Row, Sticky } from '~/components/layout';
import { Heading, Text } from '~/components/type';
import { Icon, Select } from '~/components/vendor';
import { Input, InputAddon, InputGroup } from '~/components/form';
import { search } from '~/components/vendor/Icon/icons';
import { SortDirection, useGetLenderLoansQuery } from '~/generated/graphql';
import { Table } from '~/components/table';
import LenderLoansItem from './LenderLoansItem';
import TableInfinite from '~/components/table/TableInfinite';
import ReportLenderLoanInaccuracyModal from '~/modals/ReportLenderLoanInaccuracyModal.tsx';
import LenderLoanPropertiesModal from '~/modals/LenderLoanPropertiesModal';

export type LenderLoansSort = 'score' | 'closest' | 'mostRecent' | 'largestLoanValue';

type LenderLoansSortOption = { value: LenderLoansSort; label: string };

export const commonLenderLoansSortOptions: LenderLoansSortOption[] = [
  { label: 'Most Recent', value: 'mostRecent' },
  { label: 'Largest Loans', value: 'largestLoanValue' },
];

const LIMIT_PER_PAGE = 50;

type LenderLoansListProps = {
  lenderId: string;
  dealId?: string;
  isModal?: boolean;
  onClose?: () => void;
  setCount?: (count: number) => void;
  isHidden?: boolean;
  sort?: LenderLoansSort;
  setSort?: (sort: LenderLoansSort) => void;
};

const LenderLoansList: FC<LenderLoansListProps> = ({
  lenderId,
  dealId,
  isModal,
  onClose,
  setCount,
  isHidden,
  sort: externalSort,
  setSort: externalSetSort,
}) => {
  const [searchString, setSearchString] = useState('');
  const [internalSort, internalSetSort] = useState<LenderLoansSort>(dealId ? 'score' : 'mostRecent');
  const [isLoading, setLoading] = useState(false);
  const [inaccuracyLoanId, setInaccuracyLoanId] = useState<string | null>(null);
  const [loanPropertiesModalLoanId, setLoanPropertiesModalLoanId] = useState<string | null>(null);

  const sort = externalSort ?? internalSort;
  const setSort = externalSetSort ?? internalSetSort;

  const lenderLoansSortOptions = useMemo(() => {
    const options: LenderLoansSortOption[] = [...commonLenderLoansSortOptions];
    if (dealId) {
      options.unshift({ label: 'Most Similar', value: 'score' });
      options.push({ label: 'Closest', value: 'closest' });
    }
    return options;
  }, [dealId]);

  const { data, previousData, fetchMore } = useGetLenderLoansQuery({
    variables: {
      lenderId,
      dealId,
      limit: LIMIT_PER_PAGE,
      skip: 0,
      search: searchString,
      sort: {
        field: sort,
        direction: SortDirection.Asc,
      },
    },
    fetchPolicy: 'cache-and-network',
  });
  const lenderLoansData = data ?? previousData;

  useEffect(() => {
    if (setCount) {
      setCount(lenderLoansData?.getLenderLoans.count ?? 0);
    }
  }, [lenderLoansData?.getLenderLoans, setCount]);

  function handleLoadMoreItems(index: number) {
    if (isLoading || (lenderLoansData?.getLenderLoans.count ?? 0) <= (lenderLoansData?.getLenderLoans.lenderLoans.length ?? 0)) {
      return;
    }
    setLoading(true);
    fetchMore({ variables: { skip: index, limit: LIMIT_PER_PAGE } }).then(() => {
      setLoading(false);
    });
  }

  if (!lenderLoansData?.getLenderLoans || isHidden) {
    return;
  }

  const table = (
    <Table size="lg" align="center" gutterY={0} isBorderFlush={isModal}>
      <TableInfinite
        items={lenderLoansData?.getLenderLoans.lenderLoans}
        totalItems={lenderLoansData?.getLenderLoans.count}
        renderItem={(loan) => (
          <LenderLoansItem
            key={loan?.loanId || 'loading'}
            loan={loan}
            setInaccuracyLoanId={setInaccuracyLoanId}
            setLoanPropertiesModalLoanId={setLoanPropertiesModalLoanId}
          />
        )}
        onLoadedItemsScrollEnd={handleLoadMoreItems}
      />
    </Table>
  );

  return (
    <>
      {isModal ? (
        <Sticky rootId="lenderLoansModalScrollable" style={{ top: 0 }}>
          {(isStuck) => (
            <CardHeader utils={{ px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
              <Row utils={{ alignItems: 'center', mb: 5 }}>
                <Col as={FlexRow} utils={{ alignItems: 'baseline' }}>
                  <Heading as="h2" utils={{ fontSize: 'xl' }}>
                    Loans
                  </Heading>
                  <Text as="span" utils={{ fontSize: 'sm', color: 'gray600', ml: 2 }}>
                    {lenderLoansData?.getLenderLoans.count}
                  </Text>
                </Col>
                <Col span="auto">
                  <Close onClick={onClose} utils={{ ml: 'auto' }} />
                </Col>
              </Row>
              <Row gutter={2}>
                <Col>
                  <InputGroup size="sm" utils={{ borderRadius: 'rounded' }}>
                    <InputAddon>
                      <Icon icon={search} />
                    </InputAddon>
                    <Input
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchString(e.target.value);
                      }}
                      value={searchString}
                    />
                  </InputGroup>
                </Col>
                <Col span="180px">
                  <Select
                    size="sm"
                    options={lenderLoansSortOptions}
                    value={lenderLoansSortOptions.find(({ value }) => value === sort)}
                    onChange={(option) => setSort(option!.value)}
                    menuWidth="auto"
                    menuAlignment="right"
                    isSearchable={false}
                  />
                </Col>
              </Row>
            </CardHeader>
          )}
        </Sticky>
      ) : (
        <Row utils={{ alignItems: 'center', mb: 6 }}>
          <Col>
            <Heading as="h3" utils={{ fontSize: 'xl', mr: 'auto' }}>
              All Loans
            </Heading>
          </Col>
          <Col span="180px">
            <Select
              size="sm"
              options={lenderLoansSortOptions}
              value={lenderLoansSortOptions.find(({ value }) => value === sort)}
              onChange={(option) => setSort(option!.value)}
              menuWidth="auto"
              menuAlignment="right"
              isSearchable={false}
            />
          </Col>
        </Row>
      )}
      {isModal ? (
        <CardBody utils={{ px: 7, pt: 0, pb: 8 }} desktop={{ px: 10 }}>
          {table}
        </CardBody>
      ) : (
        table
      )}

      {/* Modals */}
      <ReportLenderLoanInaccuracyModal
        isOpen={Boolean(inaccuracyLoanId)}
        onClose={() => setInaccuracyLoanId(null)}
        lenderId={lenderId}
        loanId={inaccuracyLoanId!}
      />
      <LenderLoanPropertiesModal loanId={loanPropertiesModalLoanId} onClose={() => setLoanPropertiesModalLoanId(null)} />
    </>
  );
};

export default LenderLoansList;
