import React, { FC, useEffect, useRef, useState } from 'react';
import { useDebounceFn } from 'ahooks';
import { Input, InputAddon, InputGroup } from '~/components/form';
import { Block, Col, FlexRow, Row, Sticky } from '~/components/layout';
import { Table, TableCell, TableRow } from '~/components/table';
import { Heading, Text, TextFiller } from '~/components/type';
import { CompanyFragment, LenderQuoteFragment, SortDirection, useLenderQuotesQuery } from '~/generated/graphql';
import { Icon, Select } from '~/components/vendor';
import { CardBody, CardHeader } from '~/components/card';
import { Close } from '~/components/ui';
import { search } from '~/components/vendor/Icon/icons';
import useIntersectionObserver from '~/hooks/useIntersectionObserver';
import { NotFound } from '~/components/not-found';
import LenderQuote from './LenderQuote';

export type QuotesSortOption = {
  value: string;
  label: string;
  direction: SortDirection;
};

export const quotesSortOptions: QuotesSortOption[] = [
  { value: 'createdAt', label: 'Most Recent', direction: SortDirection.Desc },
  { value: 'type', label: 'Type', direction: SortDirection.Asc },
  { value: 'loanValue', label: 'Loan Amount', direction: SortDirection.Desc },
];

type ListItemProps = {
  quotes: LenderQuoteFragment[];
  quote?: LenderQuoteFragment;
  lender: CompanyFragment;
  refCallback?: (node: HTMLDivElement) => void;
};

const ListItem: FC<ListItemProps> = ({ quotes, quote, lender, refCallback }) => {
  if (quote) {
    return <LenderQuote key={quote._id} quotes={quotes} quote={quote} lender={lender} refCallback={refCallback} />;
  }
  return (
    <TableRow ref={refCallback}>
      <TableCell utils={{ flexWrap: 'nowrap' }}>
        <TextFiller utils={{ height: '40px', width: '40px', mr: 6 }} />
        <Block>
          <Block>
            <TextFiller utils={{ height: '14px', width: '150px' }} />
          </Block>
          <Block>
            <TextFiller utils={{ height: '12px', width: '120px' }} />
          </Block>
        </Block>
      </TableCell>
      <TableCell span="auto" style={{ alignSelf: 'stretch' }}>
        <TextFiller utils={{ height: '12px', width: '50px' }} />
      </TableCell>
    </TableRow>
  );
};

const LIMIT_PER_PAGE = 30;

type LenderQuotesListProps = {
  lender: CompanyFragment;
  isModal?: boolean;
  onClose?: () => void;
  isHidden?: boolean;
  sort?: QuotesSortOption;
  setSort?: (sort: QuotesSortOption) => void;
  setCount?: (count: number) => void;
};

const LenderQuotesList: FC<LenderQuotesListProps> = ({ lender, isModal, onClose, isHidden, sort: externalSort, setSort: externalSetSort, setCount }) => {
  const [searchString, setSearchString] = useState('');
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [internalSort, internalSetSort] = useState<QuotesSortOption>(quotesSortOptions[0]);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [isLoading, setLoading] = useState(false);

  const sort = externalSort ?? internalSort;
  const setSort = externalSetSort ?? internalSetSort;

  const { data, previousData, fetchMore } = useLenderQuotesQuery({
    variables: {
      lenderId: lender.id,
      limit: LIMIT_PER_PAGE,
      skip: 0,
      search: searchString,
      sort: {
        field: sort.value,
        direction: sort.direction,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const quotesData = data ?? previousData;

  useEffect(() => {
    if (setCount) {
      setCount(quotesData?.quotes.count ?? 0);
    }
  }, [setCount, quotesData]);

  const entry = useIntersectionObserver(node, {});
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (!isVisible || isLoading || (quotesData?.quotes.count ?? 0) <= (quotesData?.quotes?.quotes.length ?? 0)) {
      return;
    }
    setLoading(true);
    fetchMore({
      variables: {
        skip: quotesData?.quotes.quotes.length,
        limit: LIMIT_PER_PAGE,
      },
    }).then(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  }, [isVisible, isLoading, quotesData, fetchMore]);

  const { run: changeSearchString } = useDebounceFn(
    (value: string) => {
      setSearchString(value);
    },
    { wait: 300 },
  );

  if (isHidden) {
    return null;
  }

  const table =
    quotesData?.quotes.count === 0 ? (
      <NotFound
        size="sm"
        title="No matches"
        description={
          <>
            Update or{' '}
            <Text
              as="span"
              utils={{ color: 'primary' }}
              onClick={() => {
                setSearchString('');
                searchInputRef.current!.value = '';
              }}
              role="button"
            >
              clear search
            </Text>
          </>
        }
        utils={{ border: 0, my: 'auto', pt: 0 }}
      />
    ) : (
      <Table size="lg" align="center" gutterY={0} isBorderFlush={isModal}>
        {Array.from({ length: quotesData?.quotes.count ?? 0 }).map((_, inx) => (
          <ListItem
            key={inx}
            quotes={quotesData?.quotes.quotes ?? []}
            quote={quotesData?.quotes.quotes[inx]}
            lender={lender}
            refCallback={inx === quotesData?.quotes.quotes.length ? setNode : undefined}
          />
        ))}
      </Table>
    );

  return (
    <Block utils={{ mb: 10 }}>
      {isModal ? (
        <Sticky rootId="quotesModalScrollable" style={{ top: 0 }}>
          {(isStuck) => (
            <CardHeader utils={{ px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
              <Row utils={{ alignItems: 'center', mb: 5 }}>
                <Col as={FlexRow} utils={{ alignItems: 'baseline' }}>
                  <Heading as="h2" utils={{ fontSize: 'xl' }}>
                    Quotes
                  </Heading>
                  <Text as="span" utils={{ fontSize: 'sm', color: 'gray600', ml: 2 }}>
                    {quotesData?.quotes.count}
                  </Text>
                </Col>
                <Col span="auto">
                  <Close onClick={onClose} utils={{ ml: 'auto' }} />
                </Col>
              </Row>
              <Row gutter={2}>
                <Col>
                  <InputGroup size="sm" utils={{ borderRadius: 'rounded' }}>
                    <InputAddon>
                      <Icon icon={search} />
                    </InputAddon>
                    <Input
                      placeholder="Search"
                      onChange={(e) => {
                        changeSearchString(e.target.value);
                      }}
                      ref={searchInputRef}
                    />
                  </InputGroup>
                </Col>
                <Col span="180px">
                  <Select
                    size="sm"
                    options={quotesSortOptions}
                    value={quotesSortOptions.find(({ value }) => value === sort.value)}
                    onChange={(option) => setSort(option!)}
                    menuWidth="auto"
                    menuAlignment="right"
                    isSearchable={false}
                  />
                </Col>
              </Row>
            </CardHeader>
          )}
        </Sticky>
      ) : (
        <Row utils={{ alignItems: 'center', mb: 6 }}>
          <Col>
            <Heading as="h3" utils={{ fontSize: 'xl', mr: 'auto' }}>
              Quotes
            </Heading>
          </Col>
          <Col span="180px">
            <Select
              size="sm"
              options={quotesSortOptions}
              value={quotesSortOptions.find(({ value }) => value === sort.value)}
              onChange={(option) => setSort(option!)}
              menuWidth="auto"
              menuAlignment="right"
              isSearchable={false}
            />
          </Col>
        </Row>
      )}
      {isModal && quotesData?.quotes.count !== 0 ? (
        <CardBody utils={{ px: 7, py: 0 }} desktop={{ px: 10 }}>
          {table}
        </CardBody>
      ) : (
        table
      )}
    </Block>
  );
};

export default LenderQuotesList;
