import { useBeforeUnload } from 'react-use';
import { useEffect } from 'react';
import Router from 'next/router';

export const useLeavePageConfirm = (
  isConfirm = true,
  message = 'Are you sure want to leave this page?',
  skipRouteChangeConfirmation = false,
  ignoreQuery = false,
) => {
  useBeforeUnload(isConfirm, message);
  useEffect(() => {
    const handler = (asPath: string) => {
      if (ignoreQuery) {
        if (asPath.split('?')[0] === Router.asPath.split('?')[0]) {
          return;
        }
      }
      if (isConfirm && !skipRouteChangeConfirmation) {
        if (!window.confirm(message)) {
          Router.events.emit('routeChangeError');
          throw 'Route Canceled';
        }
      }
    };
    Router.events.on('routeChangeStart', handler);
    return () => {
      Router.events.off('routeChangeStart', handler);
    };
  }, [isConfirm, message, skipRouteChangeConfirmation]);
};
