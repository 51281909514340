import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { GetGlobalAlertDocument, GetGlobalAlertQuery } from '~/generated/graphql';

export type ShowGlobalAlertFn = (message: string, variant?: 'success' | 'warning' | 'danger' | 'black') => void;

const useShowGlobalAlert = () => {
  const client = useApolloClient();

  const showGlobalAlert = useCallback<ShowGlobalAlertFn>((message, variant = 'success') => {
    client.writeQuery<GetGlobalAlertQuery>({
      query: GetGlobalAlertDocument,
      data: {
        getGlobalAlert: {
          __typename: 'AlertData',
          show: true,
          message,
          variant,
        },
      },
    });
  }, [client]);

  return showGlobalAlert;
};

export default useShowGlobalAlert;
