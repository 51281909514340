import React, { useRef, useState } from 'react';

import { ContextMenuSourceProps } from '~/containers/DocumentRequestTree';
import { Toggle } from '~/components/ui';
import { DropdownControlled, DropdownMenu, DropdownItem } from '~/components/vendor';
import { NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import { download } from '~/components/vendor/Icon/icons';

interface Props {
  isUserCreatedItem: boolean;
  item: NormalizedFolderTreeItem;
  contextMenuSource: ContextMenuSourceProps;
  onDropdownMenuClose: () => void;
  downloadFolder: (folderId: string) => Promise<void>;
}

const FolderDropdownLender = ({ item, contextMenuSource, onDropdownMenuClose, downloadFolder }: Props) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
    onDropdownMenuClose();
  }

  if (!item.hasApprovedChildren) {
    return null;
  }

  return (
    <>
      <Toggle ref={ref} onMouseDown={() => setOpen((isOpen) => (isOpen ? false : true))} style={{ position: 'relative', zIndex: 1 }} />
      <DropdownControlled
        state={isOpen || contextMenuSource?.id === item.id ? 'open' : 'closed'}
        anchorRef={ref}
        anchorPoint={contextMenuSource?.anchorPoint}
        onClose={handleClose}
        submenuOpenDelay={0}
        submenuCloseDelay={0}
      >
        <DropdownMenu>
          <DropdownItem onClick={() => downloadFolder(item.id)} icon={download}>
            Download
          </DropdownItem>
        </DropdownMenu>
      </DropdownControlled>
    </>
  );
};

export default FolderDropdownLender;
