import { StatusProps } from '~/components/ui/Status';
import { LenderResearchStatus } from '~/generated/graphql';
import { CustomTheme } from '~/@types/styled-components';

export const LenderResearchStatusLabels: Record<LenderResearchStatus, string> = {
  [LenderResearchStatus.NeverResearched]: 'Never Researched',
  [LenderResearchStatus.ResearchInProgress]: 'Research In Progress',
  [LenderResearchStatus.ResearchUpToDate]: 'Research Up To Date',
  [LenderResearchStatus.NeedsResearch]: 'Needs Research',
};

export const LenderResearchStatusVariants: Record<LenderResearchStatus, keyof CustomTheme['colors']> = {
  [LenderResearchStatus.NeverResearched]: 'gray600',
  [LenderResearchStatus.ResearchInProgress]: 'warning',
  [LenderResearchStatus.ResearchUpToDate]: 'success',
  [LenderResearchStatus.NeedsResearch]: 'danger',
};

export const LenderResearchStatusIcons: Record<LenderResearchStatus, StatusProps['icon']> = {
  [LenderResearchStatus.NeverResearched]: 'minus',
  [LenderResearchStatus.ResearchInProgress]: 'ellipsis',
  [LenderResearchStatus.ResearchUpToDate]: 'check',
  [LenderResearchStatus.NeedsResearch]: 'x',
};

export const lenderResearchStatusOptions: ({ value: LenderResearchStatus; label: string } & StatusProps)[] = Object.entries(LenderResearchStatusLabels).map(
  ([value, label]) => ({
    value: value as LenderResearchStatus,
    label,
    variant: LenderResearchStatusVariants[value as LenderResearchStatus],
    icon: LenderResearchStatusIcons[value as LenderResearchStatus],
  }),
);
