import React, { FC, MouseEvent } from 'react';
import { ActivityItemLink } from '~/components/activity/ActivityItemLink';
import { addHighlightWords } from '~/components/activity/helpers/addHighlightWords';
import { CompanyFragment, LightCompanyFragment } from '~/generated/graphql';

type ActivityLenderLinkProps = {
  openMatchModel?: (lender: CompanyFragment | LightCompanyFragment) => void;
  lender: CompanyFragment | LightCompanyFragment;
  search?: string | null;
};

const ActivityLenderLink: FC<ActivityLenderLinkProps> = ({ openMatchModel, lender, search }) => {
  return (
    <ActivityItemLink
      as="span"
      role="button"
      onClick={(e: MouseEvent) => {
        e.preventDefault();
        openMatchModel?.(lender);
      }}
    >
      {addHighlightWords(lender.name, search)}
    </ActivityItemLink>
  );
};

export default ActivityLenderLink;
