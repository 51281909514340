import React from 'react';
import { useRouter } from 'next/router';
import {
  useAssignDocRequestsMutation,
  DealTaskFragmentFragment,
  DealTaskCandidateUserFragment,
  useGetDealMembersQuery,
  DealUserRole,
  DealMemberFragment,
  useGetDocumentRequestFoldersQuery,
} from '~/generated/graphql';
import { DropdownMenu, DropdownItem, Icon } from '~/components/vendor';
import { getTaskUserName } from './getTaskUserName';
import { userCircle01 } from '~/components/vendor/Icon/icons';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

type Task = DealTaskFragmentFragment;

type TaskCandidate = NonNullable<DealMemberFragment | DealMemberFragment['user']>;

type Props = {
  task?: Task | { docReq: string } | null;
  docRequestId?: string;
  closeMenu?: () => void;
};

export const AssignDocReqDropdownMenu: React.FC<Props> = ({ task, docRequestId, closeMenu }) => {
  const router = useRouter();
  const dealId = router.query?.dealId as string;
  const [assignDocRequests] = useAssignDocRequestsMutation();

  const { data: docReqFoldersData } = useGetDocumentRequestFoldersQuery({
    fetchPolicy: 'cache-first',
    variables: { dealId },
  });
  const docReqFolders = docReqFoldersData?.getDocumentRequestFolders;

  const { data: dealMembersData } = useGetDealMembersQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
  });

  const onAssignDocReq =
    ({ userId, invitationId }: { userId?: string; invitationId?: string }) =>
    async () => {
      /**
       * 1. Assign user to task
       * 2. Assign invitation to task
       * 3. Unassign user from task
       */
      const docReqId = docRequestId ?? task!.docReq;

      await assignDocRequests({
        variables: {
          dealId,
          docReqIds: [docReqId!],
          userId,
          invitationId,
        },
      });
      closeMenu?.();
    };

  const documentReqId = task?.docReq ?? docRequestId;
  const documentRequest = docReqFolders?.requests.find((request) => request._id === documentReqId);
  const documentParentFolderId = documentRequest?.parentFolderId;
  const dealAdmins = dealMembersData?.getDealMembers.usersAndInvitations.filter((data) => data.role === DealUserRole.Admin).map(({ _id }) => _id) ?? [];
  const getLimitedAccess = (folderId?: string): string[] => {
    if (!folderId) {
      return [];
    }
    const parentFolder = docReqFolders?.folders.find((folder) => folder._id === folderId)!;
    if (parentFolder.adminsOnlyAccess) {
      return dealAdmins;
    }
    if (parentFolder.userIdsWithAccess?.length > 0) {
      return parentFolder.userIdsWithAccess;
    }
    if (parentFolder?.parentFolderId) {
      return getLimitedAccess(parentFolder?.parentFolderId);
    }

    return [];
  };
  const limitedAccess = getLimitedAccess(documentParentFolderId);

  const dealTaskCandidates: TaskCandidate[] = [];
  // Cannot assign a document that’s “In Review” to a non-admin
  const reviewNotAdminCandidates: TaskCandidate[] = [];
  // Cannot assign a document to a user that doesn’t have access to the document's parent folder (limited visibility)
  const parentFolderAccess: TaskCandidate[] = [];

  // Remove currently assigned user from the list
  dealMembersData?.getDealMembers.usersAndInvitations
    .filter(
      (candidate) =>
        [DealUserRole.Admin, DealUserRole.Borrower].includes(candidate.role) &&
        candidate._id !== (task as Task)?.user?._id &&
        candidate._id !== (task as Task)?.invitation?._id,
    )
    .forEach((candidate) => {
      if (documentRequest?.status === 'review' && !dealAdmins.includes(candidate?._id!)) {
        reviewNotAdminCandidates.push(candidate.user ?? candidate);
        return;
      }
      if (
        limitedAccess.length > 0 &&
        !limitedAccess.includes(candidate._id) &&
        !limitedAccess.includes(candidate.user?._id!) &&
        !dealAdmins.includes(candidate._id) &&
        !dealAdmins.includes(candidate.user?._id!)
      ) {
        parentFolderAccess.push(candidate.user ?? candidate);
        return;
      }
      dealTaskCandidates.push(candidate.user ?? candidate);
    });

  return (
    <DropdownMenu>
      <DropdownItem key="unassigned" onClick={onAssignDocReq({})}>
        <Icon icon={userCircle01} size={20} utils={{ color: 'gray500' }} /> Unassigned
      </DropdownItem>
      {dealTaskCandidates.map((candidate) => {
        if (candidate.__typename === 'DealUserAndInvitationItem') {
          return (
            <DropdownItem key={candidate._id} onClick={onAssignDocReq({ invitationId: candidate._id })}>
              <UserAvatar size="2xs" user={candidate} isInactive /> {candidate.email}
            </DropdownItem>
          );
        }
        return (
          <DropdownItem key={candidate._id} onClick={onAssignDocReq({ userId: candidate._id })}>
            <UserAvatar size="2xs" user={candidate} status={(candidate as DealTaskCandidateUserFragment).onlineStatus} />
            {getTaskUserName(candidate)}
          </DropdownItem>
        );
      })}
      {reviewNotAdminCandidates.map((candidate) => {
        const isInvitation = candidate.__typename === 'DealUserAndInvitationItem';
        return (
          <>
            <DropdownItem
              key={candidate._id}
              isCursorEnabled
              isDisabled
              data-tooltip-id="GlobalTooltip"
              data-tooltip-content='Only admins can be assigned "In Review" documents.'
              onClick={(e) => e.syntheticEvent.stopPropagation()}
            >
              <UserAvatar size="2xs" user={candidate} status={isInvitation ? 'offline' : (candidate as DealTaskCandidateUserFragment).onlineStatus} />
              {getTaskUserName(candidate)}
            </DropdownItem>
          </>
        );
      })}
      {parentFolderAccess.map((candidate) => {
        const isInvitation = candidate.__typename === 'DealUserAndInvitationItem';
        return (
          <>
            <DropdownItem
              key={candidate._id}
              isCursorEnabled
              isDisabled
              data-tooltip-id="GlobalTooltip"
              data-tooltip-content="Doesn’t have access to document’s parent folder"
              onClick={(e) => e.syntheticEvent.stopPropagation()}
            >
              <UserAvatar size="2xs" user={candidate} status={isInvitation ? 'offline' : (candidate as DealTaskCandidateUserFragment).onlineStatus} />
              {getTaskUserName(candidate)}
            </DropdownItem>
          </>
        );
      })}
    </DropdownMenu>
  );
};

export default AssignDocReqDropdownMenu;
