import { DealsSortFields } from '~/data/sorts';
import { SortDirection, SortInput } from '~/generated/graphql';
import { Sticky } from '~/components/layout';
import { TableRow, TableHeader } from '~/components/table';
import sortColumn from '~/helpers/sortColumns';
import TableSortDropdown from '../../components/table/TableSortDropdown';

type DealsListHeaderProps = {
  sort: SortInput | null;
  setSort: (newSort: SortInput | null) => void;
  isAdmin?: boolean | null;
  isCreatorColumnVisible?: boolean | null;
};

export default function DealsListHeader({ sort, setSort, isAdmin, isCreatorColumnVisible }: DealsListHeaderProps) {
  return (
    <Sticky style={{ top: 60 }}>
      {(isStuck) => (
        <TableRow mobile={{ display: 'none' }} isStuck={isStuck}>
          <TableHeader
            role="button"
            onClick={() => sortColumn({ columnName: 'name', sort, setSort })}
            isSortable
            isAsc={sort?.field === 'name' && sort?.direction === SortDirection.Asc}
            isDesc={sort?.field === 'name' && sort?.direction === SortDirection.Desc}
          >
            Name
          </TableHeader>

          {isAdmin && (
            <TableHeader
              span="12%"
              role="button"
              onClick={() => sortColumn({ columnName: 'fee', sort, setSort })}
              isSortable
              isAsc={sort?.field === 'fee' && sort?.direction === SortDirection.Asc}
              isDesc={sort?.field === 'fee' && sort?.direction === SortDirection.Desc}
            >
              Fee
            </TableHeader>
          )}

          {isCreatorColumnVisible && (
            <TableHeader
              span="15%"
              role="button"
              onClick={() => sortColumn({ columnName: 'owner', sort, setSort })}
              isSortable
              isAsc={sort?.field === 'owner' && sort?.direction === SortDirection.Asc}
              isDesc={sort?.field === 'owner' && sort?.direction === SortDirection.Desc}
            >
              Owner
            </TableHeader>
          )}

          <TableHeader
            span="15%"
            role="button"
            onClick={() => sortColumn({ columnName: 'updatedAt', sort, setSort })}
            isSortable
            isAsc={sort?.field === 'updatedAt' && sort?.direction === SortDirection.Asc}
            isDesc={sort?.field === 'updatedAt' && sort?.direction === SortDirection.Desc}
          >
            Updated
          </TableHeader>

          {isAdmin && (
            <TableHeader
              span="20%"
              role="button"
              utils={{ px: 9 }}
              onClick={() => sortColumn({ columnName: 'status', direction: SortDirection.Desc, sort, setSort })}
              isSortable
              isAsc={sort?.field === 'status' && sort?.direction === SortDirection.Asc}
              isDesc={sort?.field === 'status' && sort?.direction === SortDirection.Desc}
            >
              Status
            </TableHeader>
          )}

          <TableSortDropdown
            fields={DealsSortFields}
            sort={sort}
            defaultSort={{ field: 'createdAt', direction: SortDirection.Asc }}
            onSort={setSort}
            isAdmin={isAdmin}
            isStuck={isStuck}
          />
        </TableRow>
      )}
    </Sticky>
  );
}
