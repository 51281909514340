import Link from 'next/link';
import React, { ChangeEvent, FC, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { ApolloError, useApolloClient } from '@apollo/client';
import { Card, CardBody } from '../components/card';
import { Button, FileInput, Form, Input, FormGroup, Label } from '../components/form';
import { Block, FlexRow } from '../components/layout';
import { Heading, Text } from '../components/type';
import { Close, Divider, Modal } from '../components/ui';
import {
  GetLocalUserDocument,
  GetLocalUserQuery,
  IsLoadingDocument,
  IsLoadingQuery,
  UserFieldsFragment,
  useUpdateProfileMutation,
  useUploadProfileImageMutation,
} from '~/generated/graphql';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';

type AccountSettingsProps = {
  isOpen: boolean;
  onClose: () => void;
  currentUser: UserFieldsFragment;
  singout: () => void;
};

const AccountSettings: FC<AccountSettingsProps> = ({ isOpen, onClose, currentUser, singout, ...props }) => {
  const client = useApolloClient();
  const showGlobalAlert = useShowGlobalAlert();
  const [email, setEmail] = useState(currentUser.email);
  const [fullName, setFullName] = useState(currentUser.fullName);
  const [phone, setPhone] = useState(currentUser.contact && currentUser.contact.phone ? currentUser.contact.phone : '');
  const [lastError, setLastError] = useState<ApolloError>();

  const [updateProfile, { loading }] = useUpdateProfileMutation();
  const [uploadProfileImage, { loading: fileIsUploading, error: fileUploadError }] = useUploadProfileImageMutation();

  const isAdmin = currentUser.role === 'admin' || currentUser.role === 'superAdmin';

  client.writeQuery<IsLoadingQuery>({
    query: IsLoadingDocument,
    data: { isLoading: loading },
  });

  if (fileUploadError && lastError !== fileUploadError) {
    setLastError(fileUploadError);
    const fileLimitErr = /Please upload PNG or JPG/.test(fileUploadError.message);
    showGlobalAlert(fileLimitErr ? 'Please upload PNG or JPG' : 'Something went wrong', 'danger');
  }

  const updateProfileHandler: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    client.writeQuery<IsLoadingQuery>({ query: IsLoadingDocument, data: { isLoading: true } });

    updateProfile({
      update: (cache, { data: updateProfileData }) => {
        client.writeQuery<IsLoadingQuery>({ query: IsLoadingDocument, data: { isLoading: false } });

        cache.writeQuery<GetLocalUserQuery>({
          query: GetLocalUserDocument,
          data: {
            currentUser: {
              ...currentUser,
              ...updateProfileData!.updateProfile!,
              isAdmin: updateProfileData!.updateProfile!.role === 'admin' || updateProfileData!.updateProfile!.role === 'superAdmin',
              updatedAt: new Date(),
            },
          },
        });

        showGlobalAlert('Account changes saved');
      },
      variables: {
        email,
        fullName,
        phone,
      },
    });
  };

  const onFileSelect = async (files: FileList | null) => {
    const file = files?.[0];

    if (!file || !file?.size) {
      return;
    }

    if (file.size > 1 * 1024 * 1024) {
      showGlobalAlert('Max allowed size is 1MB', 'danger');
      return;
    }
    await uploadProfileImage({
      update: (cache) => {
        cache.writeQuery<GetLocalUserQuery>({
          query: GetLocalUserDocument,
          data: {
            currentUser: {
              ...currentUser,
              profileFileId: URL.createObjectURL(file),
              profileImageSet: true,
              updatedAt: new Date(),
            },
          },
        });

        showGlobalAlert('Avatar updated');
      },
      variables: {
        file,
      },
    });
  };

  const onRequestClose = () => {
    onClose();
    setEmail(currentUser.email);
    setFullName(currentUser.fullName);
  };

  return (
    <Modal isOpen={isOpen} onClose={onRequestClose} {...props}>
      <Card icon="clipboard" isModalContent>
        <CardBody>
          <Close onClick={onRequestClose} isAbsolute />
          <Heading utils={{ textAlign: 'center', fontSize: 'xl', mb: 3 }}>Account Settings</Heading>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray800', mb: 10 }}>Change your name or email.</Text>
          <Divider />
          <FlexRow utils={{ alignItems: 'center' }}>
            <Block utils={{ mr: 7 }}>
              <UserAvatar user={currentUser} />
            </Block>
            <Block utils={{ mr: 7 }}>
              <Text>Your avatar</Text>
              <Text utils={{ fontSize: 'sm', color: 'gray700' }}>JPG or PNG</Text>
            </Block>
            <Block utils={{ ml: 'auto' }}>
              <FileInput inputProps={{ id: 'uploadAvatar', accept: '.png,.jpg,.jpeg', onChange: (e) => onFileSelect(e.target.files) }}>
                <Button as="span" size="sm" isLoading={fileIsUploading}>
                  Upload File
                </Button>
              </FileInput>
            </Block>
          </FlexRow>
          <Divider />
          <Form utils={{ mb: 8 }} onSubmit={updateProfileHandler}>
            <FormGroup>
              <Label>Full name</Label>
              <Input name="accountFullName" value={fullName} onChange={(e) => setFullName(e.target.value)} type="text" required />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <Input name="accountEmail" value={email} onChange={(e) => setEmail(e.target.value)} type="email" required />
            </FormGroup>
            {isAdmin && (
              <FormGroup>
                <Label htmlFor="phone">Phone Number</Label>
                <Input
                  name="accountPhoneNumber"
                  as={PatternFormat}
                  format="(###) ###-####"
                  mask=" "
                  type="tel"
                  value={phone}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                  placeholder="(123) 123-1234"
                  required
                />
              </FormGroup>
            )}
            <Button type="submit" variant="primary" isLoading={loading} isBlock>
              Update
            </Button>
          </Form>
          <Text utils={{ fontSize: 'sm', textAlign: 'center', color: 'gray700' }}>
            <Link href="/change-password">Change your password</Link>
          </Text>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default AccountSettings;
