import { getBaseApiUrl } from '~/helpers/getBaseUrl';
import { getDomain } from './lender/getDomain';

type GetLogoUrlArgs = {
  logo?: string | null;
  url?: string | null;
};

export function getBorrowerLogoUrl({ logo, url }: GetLogoUrlArgs): string | null {
  if (logo?.startsWith('http') || logo?.includes('brandfetch') || logo?.includes('blob')) {
    return logo;
  }

  if (logo && !logo.includes('logo.clearbit.com')) {
    return `${getBaseApiUrl()}/api/images/${logo}?width=96&height=96`;
  }

  if (url) {
    return `//logo.clearbit.com/${getDomain(url)}?size=80`;
  }

  return null;
}
