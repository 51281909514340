import React, { FC, useRef } from 'react';
import { Badge } from '~/components/ui';
import { BaseProps } from '~/components/layout/Base';
import { LightCompanyFragment, DealLenderStatus } from '~/generated/graphql';
import { dealLenderStatusOptions } from '~/data/dealLenderStatusOptions';
import { DropdownControlled, DropdownMenu, DropdownItem, Icon } from '~/components/vendor';
import { Status } from '~/components/ui';
import { Text } from '~/components/type';
import { useClick, useMenuState } from '@szhsin/react-menu';
import { chevronDown } from '~/components/vendor/Icon/icons';
import { closeDealEventModalVar } from '~/lib/apolloClient';

type LenderStatusProps = {
  dealId: string;
  lenderId: string;
  status?: DealLenderStatus | null;
  isMatchModal?: boolean;
  onChange: (status: DealLenderStatus) => void;
  closingLender?: LightCompanyFragment | null;
  isContactInvited?: boolean | null;
};

const LenderStatus: FC<LenderStatusProps> = ({ dealId, lenderId, status, isMatchModal, closingLender, onChange, isContactInvited }) => {
  const { setIsOpen } = closeDealEventModalVar();
  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const anchorProps = useClick(menuState.state, toggleMenu);

  const current = dealLenderStatusOptions.find(({ value }) => value === (status ?? DealLenderStatus.NoAnswer))!;

  const isActive = status !== DealLenderStatus.Closed;

  const badgeDefaultStyles: BaseProps = {
    utils: { fontSize: 'sm', bgColor: menuState.state === 'open' || menuState.state === 'opening' ? 'gray200' : 'transparent', color: 'gray800' },
    ...(isActive ? { hover: { bgColor: 'gray200' } } : {}),
  };

  const badgeBaseStyles: BaseProps = {
    mobile: { height: 'auto', p: 3 },
  };

  const badgeModalStyles: BaseProps = {
    mobile: {
      height: '32px',
      width: '100%',
      justifyContent: 'center',
      bgColor: 'white',
      borderRadius: 'base',
      border: 1,
      boxShadow: 2,
      mt: 6,
    },
  };

  const badgeStyles = { ...badgeDefaultStyles, ...(isMatchModal ? badgeModalStyles : badgeBaseStyles) };

  return (
    <>
      <Badge
        ref={ref}
        size="lg"
        role="button"
        {...badgeStyles}
        {...(isActive
          ? anchorProps
          : {
              'data-tooltip-id': 'GlobalTooltip',
              'data-tooltip-content': `This deal is closed with ${closingLender?.name}. To edit their status, first remove them as the lender in Closing Fees.`,
            })}
      >
        <Status icon={current.icon} variant={current.variant} progress={current.progress} desktop={{ ml: '-3' }} mobile={{ width: '16px', height: '16px' }} />
        <Text as="span" utils={{ ml: 4 }} mobile={{ display: isMatchModal ? undefined : 'none' }}>
          {current.label}
          {isActive && <Icon icon={chevronDown} utils={{ display: 'inline-flex', ml: 4, color: 'gray600' }} />}
        </Text>
      </Badge>
      {isActive && (
        <DropdownControlled align="center" gap={4} anchorRef={ref} onClose={() => toggleMenu(false)} {...menuState}>
          <DropdownMenu utils={{ width: '100%' }}>
            {dealLenderStatusOptions.map(({ value, label, variant, progress, icon }) => (
              <DropdownItem
                key={value}
                onClick={() => {
                  if (value === DealLenderStatus.Closed) {
                    setIsOpen({
                      _id: dealId,
                      isClosing: true,
                      lenderId,
                    });
                  } else {
                    onChange(value);
                  }
                  toggleMenu();
                }}
                {...(closingLender && closingLender.id !== lenderId && value === DealLenderStatus.Closed
                  ? {
                      isDisabled: Boolean(closingLender) && value === DealLenderStatus.Closed,
                      disabled: Boolean(closingLender) && value === DealLenderStatus.Closed,
                      'data-tooltip-id': 'GlobalTooltip',
                      'data-tooltip-content': `This deal is already closed with ${closingLender.name}`,
                    }
                  : {})}
                {...(isContactInvited && value === DealLenderStatus.NoAnswer
                  ? {
                      isDisabled: true,
                      disabled: true,
                      'data-tooltip-id': 'GlobalTooltip',
                      'data-tooltip-content': 'Cannot return to a shortlisted status once a contact has been invited.',
                    }
                  : {})}
              >
                <Status icon={icon} variant={variant} progress={progress} utils={{ mr: 4 }} />
                {label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </DropdownControlled>
      )}
    </>
  );
};

export default LenderStatus;
