import React from 'react';
import { AssignDocReqDropdownMenu } from './AssignDocReqDropdownMenu';
import { Dropdown, DropdownButton } from '~/components/vendor';
import { DealTaskFragmentFragment } from '~/generated/graphql';
import TaskUserAvatar from './TaskUserAvatar';

type Props = {
  task?: DealTaskFragmentFragment | null;
  toggleButton?: React.ReactNode;
  docRequestId?: string;
  isDisabled?: boolean;
  disablePortal?: boolean;
  avatarSize?: '3xs' | '2xs' | '2xl' | 'base' | 'xs' | 'sm' | 'lg' | 'xl';
};

export const AssignDocReqDropdown: React.FC<Props> = ({
  task,
  toggleButton,
  // disablePortal = false,
  docRequestId,
  isDisabled = false,
  avatarSize = 'xs',
}) => {
  return (
    <Dropdown
      menuButton={
        <DropdownButton onClick={(e) => e.stopPropagation()} disabled={isDisabled}>
          {toggleButton ?? <TaskUserAvatar task={task} size={avatarSize} />}
        </DropdownButton>
      }
    >
      <AssignDocReqDropdownMenu task={task} docRequestId={docRequestId} />
    </Dropdown>
  );
};

export default AssignDocReqDropdown;
