import { DealTabs } from '~/@types/deal-tabs';
import { inactiveDealStatuses } from '~/data/dealStatus';
import { DealStatus } from '~/generated/graphql';

const getDealTabByStatus = (status: DealStatus) => {
  if (inactiveDealStatuses.includes(status)) {
    return DealTabs.Inactive;
  }
  return DealTabs.Active;
};

export default getDealTabByStatus;
