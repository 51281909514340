import { useMemo, useState } from 'react';
import { BaseProps } from '~/components/layout/Base';
import { Block, Col, Row } from '~/components/layout';
import { Chart, Select } from '~/components/vendor';
import { ChartOptions } from 'chart.js';
import { formatMoney, formatNumber } from '~/helpers';
import { Heading, Text } from '~/components/type';
import { LenderLoansChartGroupBy, LenderLoansChartTimeWindow, useGetLenderLoansChartDataQuery } from '~/generated/graphql';
import { StyledCircle } from '../Reports/ReportsCharts';
import { pluralize } from '~/helpers/pluralize';
import lenderLoansChartGroupByOptions from '~/data/lenderLoansChartGroupByOptions';
import lenderLoansChartTimeWindowOptions from '~/data/lenderLoansChartTimeWindowOptions';

type LoansProps = BaseProps & {
  lenderId: string;
  isHidden?: boolean;
};

export default function LenderLoans({ lenderId, isHidden, ...props }: LoansProps) {
  const [timeWindow, setTimeWindow] = useState(LenderLoansChartTimeWindow.PastYear);
  const [groupBy, setGroupBy] = useState(LenderLoansChartGroupBy.AssetType);

  const { data: chartData, previousData: previousChartData } = useGetLenderLoansChartDataQuery({
    variables: {
      lenderId,
      timeWindow,
      groupBy,
    },
  });
  const lenderTransactionsChartData = chartData?.getLenderLoansChartData ?? previousChartData?.getLenderLoansChartData;

  const data = useMemo(() => {
    if (!lenderTransactionsChartData?.length) {
      return null;
    }

    const labels: string[] = [];

    return {
      labels,
      datasets: [
        {
          label: 'Investment',
          data: lenderTransactionsChartData.map(({ label, value, count }, inx) => {
            labels.push(label);
            return {
              x: inx,
              y: value,
              count,
            };
          }),
        },
      ],
    };
  }, [lenderTransactionsChartData]);

  const options = {
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        ticks: {
          callback: (value: number) => `$${formatNumber(value)}`,
        },
      },
      x: {
        ticks: {
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (ctx: any) => `
              <div style="width: 100%; text-align: center;">
                <span>${formatMoney(ctx.parsed.y, { maximumFractionDigits: 0 })}</span>&nbsp;<span style="opacity: .6;">(${ctx.raw.count} ${pluralize(
            ctx.raw.count,
            'loan',
          )})</span>
              </div>
            `,
        },
      },
    },
  } as ChartOptions<'bar'>;

  if (!data || isHidden) {
    return null;
  }

  return (
    <Block as="section" {...props}>
      <Heading as="h3" utils={{ fontSize: '2xl', mb: 5 }}>
        Loans
      </Heading>
      <Row utils={{ alignItems: 'center', mb: 8 }} gutter={3}>
        <Col>
          <StyledCircle utils={{ mr: 3, bgColor: 'primary' }} />
          <Text as="span" utils={{ fontSize: 'sm' }}>
            Investment
          </Text>
        </Col>
        <Col span="auto">
          <Select
            size="sm"
            instanceId="LenderTransactionsGroupSelect"
            menuAlignment="right"
            menuWidth="auto"
            options={lenderLoansChartGroupByOptions}
            value={lenderLoansChartGroupByOptions.find(({ value }) => value === groupBy)}
            onChange={(value) => {
              setGroupBy(value!.value);
            }}
            isSearchable={false}
          />
        </Col>
        <Col span="auto">
          <Select
            size="sm"
            instanceId="LenderTransactionsTimeSelect"
            menuAlignment="right"
            menuWidth="auto"
            options={lenderLoansChartTimeWindowOptions}
            value={lenderLoansChartTimeWindowOptions.find(({ value }) => value === timeWindow)}
            onChange={(value) => {
              setTimeWindow(value!.value);
            }}
            isSearchable={false}
          />
        </Col>
      </Row>
      <Chart type="bar" data={data} options={options} id="LenderLoansChart" style={{ height: 250 }} {...props} />
    </Block>
  );
}
