import React, { FC, MouseEvent, useRef, useState } from 'react';
import { Toggle } from '~/components/ui';
import { Block, Col, FlexRow, Row } from '~/components/layout';
import { CompanyFragment, LenderQuoteFragment } from '~/generated/graphql';
import { download, edit, trash2 } from '~/components/vendor/Icon/icons';
import { abbreviateMoney, getBaseApiUrl } from '~/helpers';
import { StretchedLink, Text, TextFiller } from '~/components/type';
import { DropdownControlled, DropdownItem, DropdownMenu } from '~/components/vendor';
import { TableRow, TableCell, TableIcon } from '~/components/table';
import { QuoteTypeLabels } from '~/data/quoteTypeOptions';
import { quoteDeleteModalVar, quoteEditModalVar, quoteViewModalVar } from '~/lib/apolloClient';
import { BaseProps } from '~/components/layout/Base';
import { useStateWithLocalStorage } from '~/hooks';
import { useClick, useMenuState } from '@szhsin/react-menu';
import QuoteThumbnail from '../Quote/QuoteThumbnail';
import { DealTypeLabels, dealTypeMatching } from '~/data';
import { getQuoteRateValue } from '~/helpers/lender/getProgramRateValue';

type LenderQuoteProps = BaseProps & {
  quotes: LenderQuoteFragment[];
  quote: LenderQuoteFragment;
  lender: CompanyFragment;
  style?: React.CSSProperties;
  refCallback?: (node: HTMLDivElement) => void;
};

const LenderQuote: FC<LenderQuoteProps> = ({ quotes, quote, lender, style, refCallback }) => {
  const [token] = useStateWithLocalStorage('sessionToken');

  const ref = useRef(null);
  const [menuState, toggleMenu] = useMenuState({ transition: true });
  const [anchorPoint, setAnchorPoint] = useState<{ x: number; y: number } | null>(null);
  const anchorProps = useClick(menuState.state, toggleMenu);

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    toggleMenu(true);
  };

  const handleClose = () => {
    toggleMenu(false);
    setAnchorPoint(null);
  };

  return (
    <TableRow ref={refCallback} style={style}>
      <TableCell utils={{ flexWrap: 'nowrap' }}>
        {!quote ? (
          <Row gutter={0} utils={{ alignItems: 'center', maxWidth: '100%' }}>
            <Col span="auto" utils={{ mr: 6 }}>
              <TextFiller utils={{ height: '60px', width: '40px' }} />
            </Col>
            <Col>
              <TextFiller utils={{ display: 'block', mb: 2, height: '12px', width: '120px' }} />
              <TextFiller utils={{ width: '100px' }} />
            </Col>
          </Row>
        ) : (
          <>
            <QuoteThumbnail quote={quote} />
            <Block>
              <StretchedLink onClick={() => quoteViewModalVar({ quotes, quote, lender, showDealInfo: true })} onContextMenu={(e) => handleContextMenu(e)}>
                <FlexRow utils={{ alignItems: 'center' }}>
                  <Text utils={{ fontSize: 'base', fontWeight: 'bold', textTruncate: true, color: 'black' }}>{QuoteTypeLabels[quote.type]}</Text>
                </FlexRow>
              </StretchedLink>
              <Text utils={{ fontSize: 'sm', color: 'gray700', textTruncate: true }}>
                {quote.deal.project?.coordinates?.cityState}
                {' · '}
                {quote.deal.project?.assetType?.label} {DealTypeLabels[dealTypeMatching[quote.dealType] ?? quote.dealType]}
              </Text>
            </Block>
          </>
        )}
      </TableCell>

      <TableCell span="auto" utils={{ flexWrap: 'nowrap', textAlign: 'right', pr: 8 }}>
        <Block>
          {!quote ? (
            <>
              <TextFiller utils={{ display: 'block', mb: 2, height: '12px', width: '50px' }} />
              <TextFiller utils={{ width: '50px' }} />
            </>
          ) : (
            <>
              <Text utils={{ fontSize: 'base', fontWeight: 'bold', textTruncate: true, color: 'black' }}>{abbreviateMoney(quote.loanValue)}</Text>
              <Text utils={{ fontSize: 'sm', color: 'gray800', textTruncate: true }}>{getQuoteRateValue(quote.rateType, quote.rate)}</Text>
            </>
          )}
        </Block>
      </TableCell>

      <TableIcon mobile={{ ml: -9 }}>
        <Toggle ref={ref} {...anchorProps} />
        {quote && (
          <DropdownControlled
            anchorRef={anchorPoint ? undefined : ref}
            anchorPoint={anchorPoint ? anchorPoint : undefined}
            onClose={handleClose}
            {...menuState}
          >
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  window.location.href = `${getBaseApiUrl()}/api/file/${quote.attachment._id}?token=${encodeURIComponent(token)}&attachment=true`;
                }}
                icon={download}
              >
                Download
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  quoteEditModalVar({ quote, isOpen: true });
                }}
                icon={edit}
              >
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  quoteDeleteModalVar({ quote });
                }}
                icon={trash2}
                isDisabled={quote.isClosingQuote}
                disabled={quote.isClosingQuote}
                {...(quote.isClosingQuote
                  ? {
                      'data-tooltip-id': 'GlobalTooltip',
                      'data-tooltip-content': 'You cannot delete this quote because it is the Closing Quote and a closed deal.',
                    }
                  : {})}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </DropdownControlled>
        )}
      </TableIcon>
    </TableRow>
  );
};

export default LenderQuote;
