import React, { useState, useRef, useEffect, FC } from 'react';
import { v4 as uuid } from 'uuid';
import { AssignDocReqDropdown } from '~/containers/Tasks/AssignDocReqDropdown';
import { EditFolderModal } from '~/modals';
import { Icon } from '~/components/vendor';
import { FileStatus } from '~/components/file';
import { FlexRow } from '~/components/layout';
import { FolderLendersList } from '~/components/list';
import { GlobalAlert, Spinner, Badge, Toggle } from '~/components/ui';
import { StretchedLink, Text } from '~/components/type';
import { Table, TableRow, TableCell, TableIcon, TableHandle, TableFolder } from '../components/table';
import { Tree, updateTreeItems, updateTreeFiles } from '~/components/tree';
import { SourceItemProps, TargetItemProps, TreeProps } from '~/components/tree/types';
import { useRouter } from 'next/router';
import DocumentTreeItemDropdown from './Document/DocumentTreeItemDropdown';
import dynamic from 'next/dynamic';
import HighlightedWord from '~/components/ui/HighlightedWord';
import ImportDealDocumentsModal from '~/modals/ImportDealDocumentsModal';
import useDocumentFoldersOps from '~/containers/Document/useDocumentFoldersOps';
import {
  DealFieldsFragment,
  DealTaskFragmentFragment,
  DealUserRole,
  DocFolderFragment,
  DocRequestFragment,
  DocumentRequestStatus,
  useGetDealMembersQuery,
  useGetLocalUserQuery,
} from '~/generated/graphql';
import { NormalizedDocRequest, NormalizedFolderTree, NormalizedFolderTreeItem } from '~/helpers/getNormalizedFolderTree';
import useShowGlobalAlert from '~/hooks/useGlobalAlert';
import { userCircle01, eyeOff } from '~/components/vendor/Icon/icons';
import { validateTargetFolder } from '~/helpers/documentsAccess';

const GlobalAlerts = dynamic(() => import('~/components/ui/GlobalAlert/GlobalAlerts'), { ssr: false });
const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), { ssr: false });

interface FileItemProps {
  id?: string;
  pid?: string;
  index?: number;
  file?: File;
  entry: FileSystemEntry;
  isProcessed?: boolean;
}

export interface ContextMenuSourceProps {
  id: string;
  anchorPoint: { x: number; y: number };
}

type DocumentRequestTreeProps = {
  data: NormalizedFolderTree;
  deal: DealFieldsFragment;
  setDeleteFolderModal: (arg: { isOpen: boolean; item: NormalizedFolderTreeItem }) => void;
  disableReqPreviewOpen: boolean;
  isSearchEnabled: boolean;
  searchInput: string;
  onDocRequestRowClick: (data: { requestId: string }) => void;
  setEditRequestModal: (arg: { isOpen: boolean; request: NormalizedFolderTreeItem }) => void;
  onFolderCollapse: (folderId: string) => void;
  onFolderExpand: (folderId: string) => void;
  downloadFolder: (folderId: string) => Promise<void>;
};

const DocumentRequestTree: FC<DocumentRequestTreeProps> = ({
  data,
  deal,
  disableReqPreviewOpen,
  isSearchEnabled,
  onDocRequestRowClick,
  onFolderCollapse,
  onFolderExpand,
  searchInput,
  setDeleteFolderModal,
  setEditRequestModal,
  downloadFolder,
}) => {
  const router = useRouter();
  const dealId = Array.isArray(router.query.dealId) ? router.query.dealId[0] : (router.query.dealId as string);
  const isTemplatePage = router.asPath.startsWith(`/deal/${deal._id}/template`) || router.asPath.includes('template');
  const showGlobalAlert = useShowGlobalAlert();

  const [editFolderModal, setEditFolderModal] = useState<{ isOpen: boolean; folder?: NormalizedFolderTreeItem }>({
    isOpen: false,
  });

  const [isFolderDragError, setIsFolderDragError] = useState(false);
  const [isDocReqDragError, setIsDocReqDragError] = useState(false);
  const [displayImportDocumentsModal, setDisplayImportDocumentsModal] = useState<{ isOpen: boolean; folder?: NormalizedFolderTreeItem }>({
    isOpen: false,
  });

  const { data: currentUserData } = useGetLocalUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = currentUserData?.currentUser;

  const isAdmin = deal.userDealRole === DealUserRole.Admin;
  const isLender = deal.userDealRole === DealUserRole.Lender;
  const isBorrower = deal.userDealRole === DealUserRole.Borrower;

  const { data: dealMembersData } = useGetDealMembersQuery({
    variables: { dealId },
    fetchPolicy: 'cache-first',
    skip: !isAdmin && !isBorrower,
  });
  const dealAdmins = dealMembersData?.getDealMembers.usersAndInvitations.filter((data) => data.role === DealUserRole.Admin) ?? [];

  const {
    createFolder: { createFolder },
    createRequest: { createRequest },
    updateTree,
  } = useDocumentFoldersOps({ dealId });

  // Context menu
  const [contextMenuSource, setContextMenuSource] = useState<ContextMenuSourceProps | null>(null);

  // File uploads
  const [uploadInProgress, setUploadInProgress] = useState(false);

  const uploadQueue = useRef<FileItemProps[]>([]);
  const uploadStartCount = useRef(0);
  const MAX_FILE_SIZE = 500 * 1024 * 1024;

  // We don't want unnecessary rerenders so final tree is calculated on rerender
  const uploadPendingCount = uploadStartCount.current + uploadQueue.current.length - Object.keys(data.items).length;
  const uploadProcessedCount = Object.keys(data.items).length - uploadStartCount.current;
  const tree = uploadInProgress ? getDataTree(data, uploadQueue.current.slice(uploadProcessedCount)) : data;

  //
  // Effects
  //

  useEffect(() => {
    window.onbeforeunload = () => {
      if (uploadQueue.current.length) {
        return 'You have files being uploaded. Are you sure you want to leave?';
      }
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [tree]);

  //
  // Handlers
  //

  function openDrawer(item: NormalizedFolderTreeItem) {
    if (item.isRequest && !disableReqPreviewOpen) {
      onDocRequestRowClick({
        requestId: item.id,
      });
    }
  }

  function handleExpand(id: string) {
    onFolderExpand && onFolderExpand(id);
  }

  function handleCollapse(id: string) {
    onFolderCollapse && onFolderCollapse(id);
  }

  async function handleItemDrop(source: SourceItemProps, target: TargetItemProps) {
    if (isLender) return;

    // Quit if nothing was moved
    if (source.pid === target.pid && source.index === target.index) {
      return;
    }

    const sourceParent = tree.items[source.pid!];
    const dragTarget = tree.items[sourceParent.children![source.index]];

    const error = validateTargetFolder(tree, dealAdmins, dragTarget.id, target.pid!);
    if (error === 'NESTED_ACCESS') {
      setIsFolderDragError(true);
    }
    if (error === 'ASSIGNED_USER') {
      setIsDocReqDragError(true);
    }
    if (error) {
      return;
    }

    const updatedTree = updateTreeItems(data, source, target);
    updatedTree.items[dragTarget.id].data!.parentFolderId = target.pid;

    source.parentId = source.pid;
    target.parentId = target.pid;

    // trigger mutation
    await updateTree(updatedTree, { dragTarget, destination: target });
  }

  async function handleFileDrop(dataTransfer: DataTransfer, target: TargetItemProps) {
    if (isLender) {
      return;
    }

    const rootItems = getRootFileEntries(dataTransfer.items, target);
    if (rootItems.find((item: FileItemProps) => item.entry && item.entry.isFile) && target.pid === 'root') {
      showGlobalAlert('Documents must be within a folder', 'danger');
      return;
    }

    const items = await getAllFileEntries(dataTransfer.items, target);
    if (items.find((item: FileItemProps) => !item.entry)) {
      showGlobalAlert('Unknown file format', 'danger');
      return;
    }

    if (items.find((item: FileItemProps) => item.entry && item.entry.isFile && item.file!.size > MAX_FILE_SIZE)) {
      showGlobalAlert('Max allowed size is 500MB', 'danger');
      return;
    }

    uploadQueue.current = items;
    uploadStartCount.current = Object.keys(data.items).length;
    setUploadInProgress(true);

    const queue = uploadQueue.current.map((item) => item.id);
    while (queue.length > 0) {
      const id = queue.shift();
      const item = uploadQueue.current.find((item) => item.id === id)!;
      const { _id } = item.entry.isFile
        ? await createRequest({ name: item.entry.name, parentFolderId: item.pid!, file: item.file }, item.index!)
        : await createFolder({ dealId, name: item.entry.name, parentFolderId: item.pid !== 'root' ? item.pid : undefined, order: item.index! });

      uploadQueue.current.filter((current) => current.pid === item.id).forEach((current) => (current.pid = _id));
      item.id = _id;
    }

    uploadQueue.current = [];
    uploadStartCount.current = 0;
    showGlobalAlert(`Document${uploadQueue.current.length > 1 ? 's' : ''} successfully uploaded`, 'success');
    setUploadInProgress(false);
  }

  function handleNestError() {
    showGlobalAlert('Documents must be within a folder', 'danger');
  }

  function handleContextMenu(e: React.MouseEvent, id: string) {
    e.preventDefault();
    setContextMenuSource({ id, anchorPoint: { x: e.clientX, y: e.clientY } });
  }

  //
  // Helpers
  //

  function getDataTree(data: NormalizedFolderTree, items: FileItemProps[]) {
    let tree = data;
    for (const item of items) {
      if (tree.items[item.pid!]) {
        const source: NormalizedFolderTreeItem = {
          id: item.id!,
          children: item.entry.isDirectory ? [] : undefined,
          title: item.entry.name,
          isDisabled: true,
          isDroppable: item.entry.isDirectory,
          isExpanded: item.entry.isDirectory && item.pid === 'root' ? true : false,
        };
        tree = updateTreeFiles(tree, [source], { pid: item.pid, index: item.index! }) as NormalizedFolderTree;
      }
    }
    return tree;
  }

  // Drop handler function to get root file entries
  function getRootFileEntries(dataTransferItemList: DataTransferItemList, target: TargetItemProps) {
    const result: FileItemProps[] = [];
    const dataTransferItemArray = Array.from(dataTransferItemList);

    dataTransferItemArray.forEach((item, index) => {
      result.push({ id: uuid(), pid: target.pid, index: target.index + index, entry: item.webkitGetAsEntry()! });
    });

    return result;
  }

  // Drop handler function to get all file entries
  async function getAllFileEntries(dataTransferItemList: DataTransferItemList, target: TargetItemProps) {
    const queue: FileItemProps[] = [];
    const result: FileItemProps[] = [];
    const dataTransferItemArray = Array.from(dataTransferItemList);

    dataTransferItemArray.forEach((item, index) => {
      queue.push({ id: uuid(), pid: target.pid, index: target.index + index, entry: item.webkitGetAsEntry()! });
    });

    while (queue.length > 0) {
      const item = queue.shift()!;
      const file = item.entry && item.entry.isFile ? await getFilePromise(item.entry as FileSystemFileEntry) : null;

      if (file) {
        item.file = file;
      }

      result.push(item);

      if (item.entry && item.entry.isDirectory) {
        const childEntries = await readAllDirectoryEntries((item.entry as FileSystemDirectoryEntry).createReader());
        const childItems = childEntries.map((entry, index) => ({ id: uuid(), pid: item.id, index, entry }));
        queue.push(...childItems);
      }
    }

    return result;
  }

  // Get all the entries (files or sub-directories) in a directory
  // by calling readEntries until it returns empty array
  async function readAllDirectoryEntries(directoryReader: FileSystemDirectoryReader) {
    const entries = [];
    let readEntries = await readEntriesPromise(directoryReader);

    while (readEntries!.length > 0) {
      entries.push(...readEntries!);
      readEntries = await readEntriesPromise(directoryReader);
    }

    return entries;
  }

  // Wrap readEntries in a promise to make working with readEntries easier
  // readEntries will return only some of the entries in a directory
  // e.g. Chrome returns at most 100 entries at a time
  async function readEntriesPromise(directoryReader: FileSystemDirectoryReader) {
    try {
      return await new Promise<FileSystemEntry[]>((resolve, reject) => {
        directoryReader.readEntries(resolve, reject);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Wrap file in a promise to make working with files easier
  async function getFilePromise(entry: FileSystemFileEntry) {
    try {
      return await new Promise<File>((resolve, reject) => {
        entry.file(resolve, reject);
      });
    } catch (err) {
      console.log(err);
    }
  }

  //
  // Render
  //

  const renderItem: TreeProps<NormalizedFolderTreeItem>['renderItem'] = ({ item, level, order, provided, snapshot }) => {
    const isDropping = item.isDroppable && Boolean(snapshot.isDropping);
    const isNotApprovedDocReq = item.isRequest && (item.data as DocRequestFragment)!.status !== 'approved';
    const folderHasRestriction =
      item.isDroppable && ((item?.data as DocFolderFragment)?.adminsOnlyAccess || (item?.data as DocFolderFragment)?.usersWithAccess?.length! > 0);

    // TODO: Quick solution, refactor me later
    let title: React.ReactNode = item.title;

    if (searchInput.length !== 0) {
      const searchString = searchInput.trim();
      const startIndex = item.title.toLowerCase().indexOf(searchString.toLowerCase());

      if (startIndex !== -1) {
        const titleSearchString = item.title.slice(startIndex, startIndex + searchString.length);

        title = (
          <>
            {item.title.substring(0, startIndex)}
            <HighlightedWord>{titleSearchString}</HighlightedWord>
            {item.title.substring(startIndex + titleSearchString.length, item.title.length)}
          </>
        );
      }
    }

    return (
      <TableRow
        utils={{ borderTop: order === 0 ? 0 : undefined }}
        isDisabled={item.isDisabled}
        isDragging={snapshot.isDragging}
        isDropping={isDropping}
        onContextMenu={(e) => handleContextMenu(e, item.id)}
      >
        {!uploadInProgress && !isLender && !isSearchEnabled && (
          <TableIcon mobile={{ display: 'none' }} isAbsolute isFlush isHover {...provided.dragHandleProps}>
            <TableHandle />
          </TableIcon>
        )}
        <TableCell>
          <FlexRow>
            {item.isDroppable && (
              <TableFolder
                size={level === 0 ? 'base' : 'sm'}
                count={item.children!.length}
                utils={{ mr: 6 }}
                {...(!isSearchEnabled ? provided.collapseProps : {})}
                variant={item.adminsOnlyAccess ? 'black' : 'primary'}
              />
            )}

            {/* Title */}
            <StretchedLink
              utils={{ mr: 'auto' }}
              data-testid={item.title.split(' ').join('_')}
              isDisabled={(isLender && !snapshot.isDragging && isNotApprovedDocReq) || (isSearchEnabled && item.isDroppable)}
              // @ts-ignore
              onClick={() => item.isRequest && !disableReqPreviewOpen && openDrawer(item)}
              {...(!item.isRequest && item.children ? provided.collapseProps : {})}
            >
              <Text utils={{ wordWrap: 'break-word', fontSize: level === 0 ? 'lg' : 'base', fontWeight: level === 0 ? 'bold' : 'base', color: 'black' }}>
                {title}
              </Text>
            </StretchedLink>
          </FlexRow>
        </TableCell>
        <TableCell span="auto" style={{ position: 'relative', zIndex: 1 }}>
          {!item.isDisabled && (
            <>
              {/* Folder lender list */}
              {folderHasRestriction && !isLender && (
                <>
                  <FlexRow utils={{ mr: 4 }} data-tooltip-id={item.id} style={{ zIndex: 1, cursor: 'pointer', width: '20px' }}>
                    <Icon icon={eyeOff} utils={{ color: 'gray500' }} />
                  </FlexRow>
                  <Tooltip id={item.id}>
                    <Text utils={{ textAlign: 'left', fontSize: 'sm' }}>
                      This folder is only visible to:
                      <FolderLendersList>
                        {(item?.data as DocFolderFragment)?.adminsOnlyAccess ? (
                          <li>WelcomeLend Team</li>
                        ) : (
                          (item?.data as DocFolderFragment)?.usersWithAccess!.map((userWithAccess, index) => <li key={index}>{userWithAccess}</li>)
                        )}
                      </FolderLendersList>
                    </Text>
                  </Tooltip>
                </>
              )}

              {/* Status */}
              {!item.isDroppable && !isTemplatePage && (
                <FileStatus status={(item.data as DocRequestFragment)!.status} isLender={isLender} utils={{ position: 'static', mr: 4 }} />
              )}

              {/* Assignee */}
              {!isLender && item.isRequest && !isTemplatePage && (
                <FlexRow utils={{ mr: 4 }} data-tooltip-id={item.id + 'assignedUser'}>
                  <AssignDocReqDropdown
                    task={(item.data as NormalizedDocRequest)?.task ?? ({ _id: item.id } as DealTaskFragmentFragment)}
                    avatarSize="2xs"
                    docRequestId={item.id}
                    isDisabled={isBorrower && (item.data as NormalizedDocRequest).status === DocumentRequestStatus.Review}
                  />
                </FlexRow>
              )}

              {/* Dropdown */}
              <FlexRow
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DocumentTreeItemDropdown
                  deal={deal}
                  currentUserId={currentUser?._id!}
                  item={item as NormalizedFolderTreeItem}
                  contextMenuSource={contextMenuSource!}
                  setEditFolderModal={setEditFolderModal}
                  setEditRequestModal={setEditRequestModal}
                  setDeleteFolderModal={uploadInProgress ? () => {} : setDeleteFolderModal}
                  onDocRequestRowClick={onDocRequestRowClick}
                  onDropdownMenuClose={() => setContextMenuSource(null)}
                  downloadFolder={downloadFolder}
                />
              </FlexRow>
            </>
          )}

          {/* Upload placeholder */}
          {item.isDisabled && (
            <>
              {!item.isDroppable && (
                <>
                  <Badge utils={{ bgColor: 'gray500', mr: 4 }}>Uploading</Badge>
                  <Icon icon={userCircle01} size={20} utils={{ color: 'gray500', mr: 4 }} role="button" />
                </>
              )}
              <Toggle />
            </>
          )}
        </TableCell>
      </TableRow>
    );
  };

  //
  // Render
  //

  return (
    <>
      <Table align="center" gutterY={0}>
        <Tree
          tree={tree}
          renderItem={renderItem}
          itemHeight={72}
          itemOffset={40}
          nestFiles={true}
          expandFolders={isSearchEnabled}
          disableItemDrop={uploadInProgress}
          disableFileDrop={isLender}
          onItemDrop={handleItemDrop}
          onFileDrop={handleFileDrop}
          onExpand={handleExpand}
          onCollapse={handleCollapse}
          onNestError={handleNestError}
        />
      </Table>

      {/* Modals */}
      <EditFolderModal
        folder={editFolderModal.folder!}
        isOpen={editFolderModal.isOpen}
        onClose={() => setEditFolderModal((oldState) => ({ ...oldState, isOpen: false }))}
      />

      <ImportDealDocumentsModal
        isOpen={displayImportDocumentsModal?.isOpen}
        onClose={() => setDisplayImportDocumentsModal({ isOpen: false })}
        defaultFolderId={displayImportDocumentsModal?.folder?.id}
      />

      {/* Alerts */}
      <GlobalAlerts>
        <GlobalAlert utils={{ alignItems: 'center', bgColor: 'black' }} isOpen={uploadInProgress ? true : false}>
          <Spinner size="xs" variant="white" utils={{ mr: 4 }} isAbsolute={false} />
          Uploading {uploadQueue.current.length > 1 ? `${uploadPendingCount} document${uploadPendingCount > 1 ? 's' : ''}` : 'document'}
        </GlobalAlert>
        <GlobalAlert utils={{ bgColor: 'danger' }} isOpen={isFolderDragError} onClose={() => setIsFolderDragError(false)}>
          Folder move failed. Cannot nest folders with limited access
        </GlobalAlert>
        <GlobalAlert utils={{ bgColor: 'danger' }} isOpen={isDocReqDragError} onClose={() => setIsDocReqDragError(false)}>
          Move failed. Assigned user doesn't have access to parent folder.
        </GlobalAlert>
      </GlobalAlerts>
    </>
  );
};

export default DocumentRequestTree;
