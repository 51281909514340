import { dollarSign, percent } from '~/components/vendor/Icon/icons';
import { AmountValueType } from '~/generated/graphql';

export const AmountValueTypeLabels: Record<AmountValueType, string> = {
  [AmountValueType.Percentage]: 'Percentage of loan',
  [AmountValueType.Flat]: 'Flat fee',
};

export const AmountValueTypeIcons: Record<AmountValueType, string> = {
  [AmountValueType.Percentage]: percent,
  [AmountValueType.Flat]: dollarSign,
};

export type AmountValueTypeOption = { value: AmountValueType; label: string; icon: string };

export const amountValueTypeOptions: AmountValueTypeOption[] = Object.entries(AmountValueTypeLabels).map(([value, label]) => ({
  value: value as AmountValueType,
  label,
  icon: AmountValueTypeIcons[value as AmountValueType],
}));
