import { useState } from 'react';
import { Card, CardBody } from '~/components/card';
import { Input, InputAddon, InputGroup } from '~/components/form';
import { FlexCol, FlexRow } from '~/components/layout';
import { List, ListItem } from '~/components/list';
import { NotFound } from '~/components/not-found';
import { TableWindow } from '~/components/table';
import { Heading, Text } from '~/components/type';
import { Badge, Close, Modal } from '~/components/ui';
import { Icon } from '~/components/vendor';
import { search as searchIcon } from '~/components/vendor/Icon/icons';
import { useGetLenderLocationsQuery } from '~/generated/graphql';

type LenderLocationsModalProps = {
  lenderId: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function LenderLocationsModal({ lenderId, isOpen, onClose, ...props }: LenderLocationsModalProps) {
  const [search, setSearch] = useState('');
  const { data } = useGetLenderLocationsQuery({
    variables: { lenderId },
    skip: !lenderId,
  });
  const lenderLocations = (data?.getLenderLocations || [])
    .map((location) => ({ ...location, address: `${location.street}, ${location.city}, ${location.state}` }))
    .sort((a) => (a.isMainOffice ? -1 : 1))
    .filter((location) => location.address.toLowerCase().includes(search.toLowerCase()));

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <Card size="sm" isModalContent>
        <CardBody>
          <Close onClick={onClose} isAbsolute />
          <FlexRow utils={{ alignItems: 'baseline', mb: 6 }}>
            <Heading utils={{ fontSize: 'lg', mr: 3, mb: 0 }}>Locations</Heading>
            <Text utils={{ fontSize: 'sm', color: 'gray700' }}>{lenderLocations.length}</Text>
          </FlexRow>
          <InputGroup size="sm" utils={{ borderRadius: 'rounded', mb: 8 }}>
            <InputAddon>
              <Icon icon={searchIcon} />
            </InputAddon>
            <Input placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
          </InputGroup>
          <FlexCol id="lenderLocationsModalScrollable" utils={{ height: '350px', overflowY: 'auto', mx: -8, px: 8 }}>
            {lenderLocations.length > 0 ? (
              <List gutter={0} utils={{ fontSize: 'sm', my: -4 }}>
                <TableWindow
                  items={lenderLocations}
                  totalItems={lenderLocations.length}
                  itemHeight={37}
                  rootId="lenderLocationsModalScrollable"
                  renderItem={(location, style) => (
                    <ListItem style={style} utils={{ py: 4, textTruncate: true }}>
                      {location?.address}
                      {location?.isMainOffice && <Badge utils={{ bgColor: 'primary20', color: 'primary', ml: 3 }}>HQ</Badge>}
                    </ListItem>
                  )}
                />
              </List>
            ) : (
              <NotFound
                size="sm"
                title="No matches"
                description={
                  <>
                    Update or{' '}
                    <Text as="span" utils={{ color: 'primary' }} onClick={() => setSearch('')} role="button">
                      clear search
                    </Text>
                  </>
                }
                utils={{ border: 0, my: 'auto', pt: 0 }}
              />
            )}
          </FlexCol>
        </CardBody>
      </Card>
    </Modal>
  );
}
