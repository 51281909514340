import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { DealTabs } from '~/@types/deal-tabs';
import { SearchActiveDealsDocument, SearchActiveDealsQuery, SearchActiveDealsQueryVariables, SearchInactiveDealsDocument } from '~/generated/graphql';
import { searchActiveDealsVars, searchInactiveDealsVars } from '~/lib/apolloClient';

const useRemoveDealFromList = () => {
  const client = useApolloClient();

  const removeDealFromList = useCallback(
    (id: string, tab: DealTabs) => {
      const query = tab === DealTabs.Active ? SearchActiveDealsDocument : SearchInactiveDealsDocument;
      const variables = (tab === DealTabs.Active ? searchActiveDealsVars() : searchInactiveDealsVars())!;
      const queryData = client.readQuery<SearchActiveDealsQuery, SearchActiveDealsQueryVariables>({
        query,
        variables,
      });
      if (queryData) {
        client.writeQuery<SearchActiveDealsQuery, SearchActiveDealsQueryVariables>({
          query,
          variables,
          data: {
            ...queryData,
            dealsElasticSearch: {
              ...queryData.dealsElasticSearch,
              deals: queryData.dealsElasticSearch.deals.filter((deal) => deal._id !== id),
              count: queryData.dealsElasticSearch.count - 1,
            },
          },
        });
      }
    },
    [client],
  );

  return removeDealFromList;
};

export default useRemoveDealFromList;
