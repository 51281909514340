import { Base, Block, Col, Row } from '~/components/layout';
import { BaseProps } from '~/components/layout/Base';
import { FC, useState } from 'react';
import { CurrentUserQuery, GetDealsForReportsQuery } from '~/generated/graphql';
import { Select } from '~/components/vendor';
import { Text } from '~/components/type';
// import ReportsDealsChart from './ReportsDealsChart';
import ReportsEarningsChart from './ReportsEarningsChart';
import ReportsFeesChart from './ReportsFeesChart';
import ReportsFinancedChart from './ReportsFinancedChart';
import styled, { css } from 'styled-components';

export const StyledCircle = styled(Base)`
  ${() => css`
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  `}
`;

type DealProps = GetDealsForReportsQuery['getReportDeals']['deals'][number];

type ReportsChartsProps = BaseProps & {
  closedDeals: DealProps[];
  currentClosedDeals: DealProps[];
  currentUser: CurrentUserQuery['currentUser'];
  timeWindow: number;
  isAdmin: boolean;
};

const ReportsCharts: FC<ReportsChartsProps> = ({ closedDeals, currentClosedDeals, currentUser, timeWindow, isAdmin, ...props }) => {
  interface SelectOptionProps {
    label: string;
    value: string;
  }

  const selectOptions: Array<SelectOptionProps> = [
    { label: 'Financed', value: 'Financed' },
    { label: 'Fees', value: 'Fees' },
    { label: `${isAdmin ? 'Your' : 'Our'} Earnings`, value: 'Earnings' },
    // { label: 'Funnel', value: 'Funnel' },
  ];

  const [type, setType] = useState(selectOptions[0]);

  return (
    <Block as="section" {...props}>
      <Row utils={{ alignItems: 'center', mb: 8 }}>
        <Col>
          {type.value === 'Funnel' ? (
            <>
              <StyledCircle utils={{ mr: 3, bgColor: 'primary' }} />
              <Text as="span" utils={{ fontSize: 'sm' }}>
                Deal Count
              </Text>
            </>
          ) : (
            <Row gutter={4} utils={{ alignItems: 'center ' }}>
              <Col span="auto">
                <StyledCircle utils={{ mr: 3, bgColor: 'primary' }} />
                <Text as="span" utils={{ fontSize: 'sm' }}>
                  Closed
                </Text>
              </Col>
              <Col span="auto">
                <StyledCircle utils={{ mr: 3, bgColor: 'purple' }} />
                <Text as="span" utils={{ fontSize: 'sm' }}>
                  Prediction
                </Text>
              </Col>
              {timeWindow > 0 && (
                <Col span="auto">
                  <StyledCircle utils={{ mr: 3, bgColor: 'blue' }} />
                  <Text as="span" utils={{ fontSize: 'sm' }}>
                    {timeWindow - 1}
                  </Text>
                </Col>
              )}
            </Row>
          )}
        </Col>
        <Col span="auto">
          <Select
            size="sm"
            instanceId="reportsChartsSelect"
            // data-testid="reportsChartsSelect"
            options={selectOptions}
            value={type}
            menuAlignment="right"
            menuWidth="auto"
            onChange={(value: SelectOptionProps | null) => value && setType(value)}
            isSearchable={false}
          />
        </Col>
      </Row>
      <Block>
        {type.value === 'Financed' && <ReportsFinancedChart closedDeals={closedDeals} currentClosedDeals={currentClosedDeals} timeWindow={timeWindow} />}
        {type.value === 'Fees' && <ReportsFeesChart closedDeals={closedDeals} currentClosedDeals={currentClosedDeals} timeWindow={timeWindow} />}
        {type.value === 'Earnings' && (
          <ReportsEarningsChart
            closedDeals={closedDeals}
            currentClosedDeals={currentClosedDeals}
            currentUser={currentUser}
            timeWindow={timeWindow}
            isAdmin={isAdmin}
          />
        )}
        {/* {type.value === 'Funnel' && <ReportsDealsChart timeWindow={timeWindow} />} */}
      </Block>
    </Block>
  );
};

export default ReportsCharts;
