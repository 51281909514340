import React from 'react';
import { Block, FlexRow } from '~/components/layout';
import { briefcase, eye, messageCircle, plusCircle } from '~/components/vendor/Icon/icons';
import { DealLenderStatus, LenderResponsivenessFragment, LenderResponsivenessInterestLevel, LenderResponsivenessLevel } from '~/generated/graphql';
import { dealLenderStatusOptions } from '~/data/dealLenderStatusOptions';
import { Icon } from '~/components/vendor';
import { responsivenessInterestLabels, responsivenessInterestVariants, responsivenessLabels, responsivenessVariants } from '~/data/responsiveness';
import { Status } from '~/components/ui';
import { Text } from '~/components/type';
import dynamic from 'next/dynamic';

const Tooltip = dynamic(() => import('~/components/vendor/Tooltip'), { ssr: false });

const interestStatus = [
  DealLenderStatus.Interested,
  DealLenderStatus.Quoted,
  DealLenderStatus.Closed,
  DealLenderStatus.NotCompetitive,
];

const statusOptions = dealLenderStatusOptions.filter(({ value }) => value !== DealLenderStatus.NoAnswer);

export const getResponsivenessInterestLevel = (data: LenderResponsivenessFragment): LenderResponsivenessInterestLevel => {
  let totalResponses = 0;
  const interestResponses = data.responses.reduce((acc, { status, count }) => {
    totalResponses += count;
    if (interestStatus.includes(status)) {
      return acc + count;
    }
    return acc;
  }, 0);

  if (totalResponses <= 3) {
    return LenderResponsivenessInterestLevel.LimitedData;
  }

  const interestRate = interestResponses / totalResponses;
  return interestRate >= 0.5
    ? LenderResponsivenessInterestLevel.OftenInterested
    : interestRate >= 0.25
    ? LenderResponsivenessInterestLevel.SometimesInterested
    : LenderResponsivenessInterestLevel.RarelyInterested;
};

type ResponsivenessTooltipProps = {
  id: string;
  level?: LenderResponsivenessLevel;
  data: LenderResponsivenessFragment;
};

export default function ResponsivenessTooltip({ id, level, data }: ResponsivenessTooltipProps) {
  const interestLevel = getResponsivenessInterestLevel(data);

  return (
    <Tooltip id={id}>
      <Block utils={{ width: '210px', my: '-4', mx: '-5', p: 7, fontSize: 'sm', color: 'white' }}>
        {!data.added && typeof level !== 'undefined' && responsivenessLabels[level] && (
          <FlexRow utils={{ mb: 4 }}>
            <Text utils={{ lineHeight: 1, fontSize: 'xl', color: responsivenessVariants[level], mr: 4 }}>●</Text>
            <Text utils={{ fontWeight: 'bold' }}>{responsivenessLabels[level]}</Text>
          </FlexRow>
        )}
        <Block>
          {Boolean(data.added) && (
            <FlexRow utils={{ alignItems: 'center' }}>
              <Icon icon={plusCircle} utils={{ color: 'white50', mr: 3 }} />
              <Text utils={{ mr: 8 }}>Added</Text>
              <Text utils={{ width: '3ch', textAlign: 'center', ml: 'auto' }}>{data.added}</Text>
            </FlexRow>
          )}
          <FlexRow utils={{ alignItems: 'center', mt: data.added ? 2 : 0 }}>
            <Icon icon={briefcase} utils={{ color: 'white50', mr: 3 }} />
            <Text utils={{ mr: 8 }}>Invited</Text>
            <Text utils={{ width: '3ch', textAlign: 'center', ml: 'auto' }}>{data.invited}</Text>
          </FlexRow>
          <FlexRow utils={{ alignItems: 'center', mt: 2 }}>
            <Icon icon={eye} utils={{ color: 'white50', mr: 4 }} />
            <Text utils={{ mr: 8 }}>Viewed Deal</Text>
            <Text utils={{ width: '3ch', textAlign: 'center', ml: 'auto' }}>{data.viewed}</Text>
          </FlexRow>
          <FlexRow utils={{ alignItems: 'center', mt: 2 }}>
            <Icon icon={messageCircle} utils={{ color: 'white50', mr: 4 }} />
            <Text utils={{ mr: 8 }}>Responded</Text>
            <Text utils={{ width: '3ch', textAlign: 'center', ml: 'auto' }}>{data.responded}</Text>
          </FlexRow>
        </Block>
        {data.responses.length > 0 && (
          <Block utils={{ borderTop: 1, borderTopColor: 'gray900', mt: 5, pt: 5 }}>
            {!data.added && (
              <FlexRow utils={{ mb: 4 }}>
                <Text utils={{ lineHeight: 1, fontSize: 'xl', color: responsivenessInterestVariants[interestLevel], mr: 4 }}>●</Text>
                <Text utils={{ fontWeight: 'bold' }}>{responsivenessInterestLabels[interestLevel]}</Text>
              </FlexRow>
            )}
            {statusOptions.map((option) => {
              const response = data.responses.find(({ status }) => status === option.value);
              if (!response) {
                return null;
              }
              return (
                <FlexRow key={option.value} utils={{ alignItems: 'center', mt: 2 }}>
                  <Status icon={option.icon} variant={option.variant} progress={option.progress} utils={{ mr: 4 }} />
                  <Text>{option.label}</Text>
                  <Text utils={{ width: '3ch', textAlign: 'center', ml: 'auto' }}>{response.count}</Text>
                </FlexRow>
              );
            })}
          </Block>
        )}
      </Block>
    </Tooltip>
  );
}
