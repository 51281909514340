import React, { FC } from 'react';

import { Card, CardBody } from '../components/card';
import { Heading } from '../components/type';
import { Close, Modal } from '../components/ui';
import RenameAttachmentForm from '../containers/RenameAttachmentForm';

type RenameAttachmentModalProps = {
  isOpen: boolean;
  onClose: (name?: string) => void;
  name: string;
};

const RenameAttachmentModal: FC<RenameAttachmentModalProps> = ({ isOpen, onClose, name, ...props }) => (
  <Modal isOpen={isOpen} onClose={onClose} {...props}>
    <Card icon="box" isModalContent>
      <CardBody>
        <Close onClick={() => onClose()} isAbsolute desktop={{ display: 'none' }} />
        <Heading utils={{ fontSize: '2xl', textAlign: 'center', mb: 6 }}>Rename Attachment</Heading>
        <RenameAttachmentForm name={name} onClick={onClose} />
      </CardBody>
    </Card>
  </Modal>
);

export default RenameAttachmentModal;
