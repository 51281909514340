import React, { FC, useEffect, useState } from 'react';
import { ActivityItemText } from '../../components/activity/ActivityItemText';
import { Block } from '~/components/layout';
import { Collapse } from 'react-collapse';
import { Text } from '~/components/type';

type ActivityBodyCollapseProps = {
  title: React.ReactNode;
  toggle: React.ReactNode;
  children: React.ReactNode;
  isOpened?: boolean;
};

export const ActivityItemCollapsible: FC<ActivityBodyCollapseProps> = ({ title, toggle, children, isOpened: isOpenedDefault = false }) => {
  const [isOpened, setIsOpened] = useState(isOpenedDefault);

  useEffect(() => {
    setIsOpened(isOpenedDefault);
  }, [isOpenedDefault]);

  return (
    <>
      <ActivityItemText>
        {title}{' '}
        <Text as="span" onClick={() => setIsOpened((isOpened) => !isOpened)} role="button">
          <Text as="span" utils={{ mr: 3 }}>
            {toggle}
          </Text>
          <Block
            as="svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            utils={{ color: 'gray600', transition: 'base' }}
            style={{ transformOrigin: 'center center', transform: `rotate(${isOpened ? '90deg' : '0deg'})` }}
          >
            <path d="M2 1 L 8 5 L 2 9 Z" fill="currentColor" />
          </Block>
        </Text>
      </ActivityItemText>
      <Collapse isOpened={isOpened}>{children}</Collapse>
    </>
  );
};
