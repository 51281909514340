import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat, NumberFormatValues } from 'react-number-format';
import { assetTypeOptions, capitalTypeOptions, dealTypeOptions } from '~/data';
import { FlexRow, Sticky } from '~/components/layout';
import { Button, Feedback, Form, Input, FormGroup, Label } from '~/components/form';
import { Card, CardBody, CardHeader } from '~/components/card';
import { Modal } from '~/components/ui';
import { Heading } from '~/components/type';
import { Select, SelectAddress } from '~/components/vendor';
import getTransformedDate from '~/helpers/getTransformedDate';
import isValidDate from '~/helpers/isValidDate';
import {
  CoordinatesInput,
  DealCapitalTypeInput,
  DealFieldsFragment,
  DealProjectAssetTypeInput,
  DealProjectLoanTypeInput,
  DealSearchResultFragment,
  useUpdateDealMutation,
  ReportDealFragment,
  DealSearchResultFragmentDoc,
} from '~/generated/graphql';
import BorrowersDropdown, { BorrowersDropdownOption } from '~/containers/BorrowersDropdown';
import { Table, TableCell, TableRow } from '~/components/table';
import ConfirmModal from './ConfirmModal';
import { useApolloClient } from '@apollo/client';

const DATE_PATTERN = /^(\d{2})\/(\d{2})\/(\d{4})$/;

type FormData = {
  name: string;
  address?: string | null;
  coordinates?: CoordinatesInput;
  capitalType?: DealCapitalTypeInput | null;
  loanType?: DealProjectLoanTypeInput | null;
  assetType?: DealProjectAssetTypeInput | null;
  estimatedCloseDate?: string | null;
  netRevenue?: number | null;
  borrower?: BorrowersDropdownOption;
};

type DealSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deal?: DealFieldsFragment | DealSearchResultFragment | ReportDealFragment;
  isBorrowerDropdownFocused?: boolean;
};

const DealSettingsModal: FC<DealSettingsModalProps> = ({ isOpen, deal, onClose, isBorrowerDropdownFocused = false, ...props }) => {
  const client = useApolloClient();

  const [isConfirmCapitalChangeOpen, setConfirmCapitalChangeOpen] = useState(false);

  const settingsForm = useForm<FormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: deal?.name,
      address: deal?.project?.address ?? null,
      capitalType: deal?.financials?.capitalType ?? null,
      loanType: deal?.project?.loanType ?? null,
      assetType: deal?.project?.assetType ?? null,
      estimatedCloseDate: getTransformedDate(deal?.settings?.estimatedCloseDate!),
      netRevenue: deal?.netRevenue ?? null,
    },
  });

  useEffect(() => {
    settingsForm.register('coordinates');
    if (deal?.project?.coordinates) {
      const { __typename, ...coordinates } = deal.project.coordinates;
      settingsForm.setValue('coordinates', coordinates);
      settingsForm.setValue('address', deal?.project?.address);
    }

    settingsForm.setValue('name', deal?.name ?? '');
    settingsForm.setValue('capitalType', deal?.financials?.capitalType);
    settingsForm.setValue('loanType', { value: deal?.project?.loanType?.value, label: deal?.project?.loanType?.label });
    settingsForm.setValue('assetType', { value: deal?.project?.assetType?.value, label: deal?.project?.assetType?.label });
    if (deal?.settings?.estimatedCloseDate) {
      settingsForm.setValue('estimatedCloseDate', getTransformedDate(deal.settings.estimatedCloseDate));
    }
    settingsForm.setValue('netRevenue', deal?.netRevenue ?? null);
  }, [deal, settingsForm]);

  const {
    formState: { errors: formErrors },
  } = settingsForm;

  const [updateDeal, { loading }] = useUpdateDealMutation({ refetchQueries: ['getDealNewFinancing'] });

  const onDealUpdate = async ({ borrower, ...fields }: FormData, force = false) => {
    try {
      if (fields.address?.length! > 0 && !fields.coordinates) {
        settingsForm.setError('address', {});
        return;
      }

      fields.capitalType = { value: fields.capitalType!.value, label: fields.capitalType!.label };
      const { data } = await updateDeal({
        variables: {
          ...fields,
          id: deal!._id,
          estimatedCloseDate: fields.estimatedCloseDate ? new Date(fields.estimatedCloseDate) : null,
          borrowerId: borrower?.value?._id ?? null,
          force,
        },
      });

      const dealSearchResult = client.readFragment<DealSearchResultFragment>({
        id: `DealSearchResult:${deal!._id}`,
        fragmentName: 'DealSearchResult',
        fragment: DealSearchResultFragmentDoc,
      });
      if (dealSearchResult) {
        client.writeFragment<DealSearchResultFragment>({
          id: `DealSearchResult:${deal!._id}`,
          fragmentName: 'DealSearchResult',
          fragment: DealSearchResultFragmentDoc,
          data: {
            ...dealSearchResult,
            borrower: data?.updateDeal.borrower,
          },
        });
      }

      onClose();
    } catch (error) {
      if ((error as Error).message === 'DEAL_FINANCING_CAPITAL_TYPE_ERROR') {
        setConfirmCapitalChangeOpen(true);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Modal layout="horizontal" isOpen={isOpen} onClose={onClose} {...props}>
        <Card id="dealSettingsModalScrollable" isModalContent>
          <Form onSubmit={settingsForm.handleSubmit((fields) => onDealUpdate(fields))}>
            <Sticky rootId="dealSettingsModalScrollable" style={{ top: 0 }}>
              {(isStuck) => (
                <CardHeader as={FlexRow} utils={{ alignItems: 'center', px: 7, py: 6 }} desktop={{ px: 8 }} isStuck={isStuck}>
                  <Button size="sm" variant="white" utils={{ my: -1 }} onClick={onClose} type="button">
                    Cancel
                  </Button>
                  <Heading utils={{ mx: 'auto', fontSize: 'base' }}>Deal Settings</Heading>
                  <Button size="sm" utils={{ my: -1 }} type="submit" isLoading={loading} disabled={loading}>
                    Save
                  </Button>
                </CardHeader>
              )}
            </Sticky>

            <CardBody utils={{ px: 7, py: 8 }} desktop={{ py: 8, px: 10 }}>
              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Deal Basics
              </Heading>

              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow as={FormGroup} isValid={!formErrors.name} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Name</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="name"
                      render={({ field }) => (
                        <Input _size="sm" autoFocus={isBorrowerDropdownFocused ? false : true} type="text" placeholder="Deal name" maxLength={25} {...field} />
                      )}
                      control={settingsForm.control}
                      rules={{ required: true }}
                      defaultValue={deal?.name}
                    />
                    {formErrors.name?.type === 'required' && <Feedback>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>

                <TableRow as={FormGroup} isValid={!formErrors.address} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Address</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="address"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <SelectAddress
                            inputProps={{
                              _size: 'sm',
                              id: 'address',
                              placeholder: 'Enter a location',
                              utils: { boxShadow: 2 },
                            }}
                            value={value!}
                            onChange={onChange}
                            onError={(error) => {
                              if (error === 'ZERO_RESULTS') {
                                settingsForm.setError('address', {});
                              }
                            }}
                            onSelect={({ __typename, ...coordinates }) => {
                              settingsForm.setValue('coordinates', coordinates);
                              settingsForm.clearErrors('address');
                            }}
                          />
                        );
                      }}
                      control={settingsForm.control}
                      rules={{ required: false }}
                      defaultValue={deal?.project?.address ?? null}
                    />
                    {formErrors.address && <Feedback>Address can&apos;t be found.</Feedback>}
                  </TableCell>
                </TableRow>

                <TableRow as={FormGroup} isValid={!formErrors.assetType} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Asset Type</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="assetType"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="assetType" placeholder="Pick asset types" options={assetTypeOptions} value={value} onChange={onChange} />
                      )}
                      control={settingsForm.control}
                      rules={{ required: true }}
                      defaultValue={null}
                    />
                    {formErrors.assetType && <Feedback>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>

                <TableRow as={FormGroup} isValid={!formErrors.loanType} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Deal Type</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="loanType"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="loanType" placeholder="Select deal type" options={dealTypeOptions} value={value} onChange={onChange} />
                      )}
                      control={settingsForm.control}
                      rules={{ required: true }}
                      defaultValue={null}
                    />
                    {formErrors.loanType && <Feedback>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>

                <TableRow as={FormGroup} isValid={!formErrors.capitalType} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Capital Type</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="capitalType"
                      render={({ field: { onChange, value } }) => (
                        <Select size="sm" id="capitalType" placeholder="Select capital type" options={capitalTypeOptions} value={value} onChange={onChange} />
                      )}
                      control={settingsForm.control}
                      rules={{ required: true }}
                      defaultValue={{ value: deal?.financials?.capitalType?.value!, label: deal?.financials?.capitalType?.label! }}
                    />
                    {formErrors.capitalType && <Feedback>This field is required.</Feedback>}
                  </TableCell>
                </TableRow>
              </Table>

              <Heading as="h2" utils={{ fontSize: 'xl', mb: 6 }}>
                Additional Info
              </Heading>

              <Table size="sm" utils={{ mb: 9 }}>
                <TableRow as={FormGroup} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Borrower</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="borrower"
                      render={({ field }) => (
                        <BorrowersDropdown
                          autoFocus={isBorrowerDropdownFocused ? true : false}
                          borrower={deal?.borrower}
                          selectProps={{ ...field, size: 'sm' }}
                        />
                      )}
                      control={settingsForm.control}
                    />
                  </TableCell>
                </TableRow>

                <TableRow as={FormGroup} isValid={!formErrors.estimatedCloseDate} utils={{ mb: 0 }}>
                  <TableCell span="30%">
                    <Label utils={{ fontSize: 'sm', mb: 0 }}>Est. Close Date</Label>
                  </TableCell>
                  <TableCell>
                    <Controller
                      name="estimatedCloseDate"
                      render={({ field: { value, onChange, ...props } }) => (
                        <Input
                          as={PatternFormat}
                          format="##/##/####"
                          placeholder="MM/DD/YYYY"
                          onValueChange={(e: NumberFormatValues) => {
                            if (e.formattedValue && DATE_PATTERN.test(e.formattedValue) && isValidDate(e.formattedValue)) {
                              onChange(new Date(new Date(e.formattedValue).setHours(0, 0, 0, 0)));
                            } else {
                              onChange(e.formattedValue);
                            }
                          }}
                          value={(value as any) instanceof Date ? undefined : value}
                          _size="sm"
                          {...props}
                        />
                      )}
                      control={settingsForm.control}
                      rules={{
                        required: false,
                        pattern: {
                          value: DATE_PATTERN,
                          message: 'Invalid date format. Use MM/DD/YYYY',
                        },
                        validate: (value) => {
                          if (value && !isValidDate(value)) {
                            return 'Invalid date';
                          }
                        },
                      }}
                    />
                    {formErrors.estimatedCloseDate && <Feedback>{formErrors.estimatedCloseDate.message}</Feedback>}
                  </TableCell>
                </TableRow>
              </Table>

              <Button variant="primary" type="submit" isLoading={loading} disabled={loading} isBlock>
                Save changes
              </Button>
            </CardBody>
          </Form>
        </Card>
      </Modal>

      <ConfirmModal
        isOpen={isConfirmCapitalChangeOpen}
        onCancel={() => setConfirmCapitalChangeOpen(false)}
        onClose={() => setConfirmCapitalChangeOpen(false)}
        onConfirm={() => {
          setConfirmCapitalChangeOpen(false);
          onDealUpdate(settingsForm.getValues(), true);
        }}
        question="Financing for this deal will be deleted if you continue."
        text="Financing only supports Senior Debt, so changing the capital type requires deletion of this deal’s Financing."
        okText="Continue"
      />
    </>
  );
};

export default DealSettingsModal;
