import React from 'react';
import { ActivityListItem } from '~/components/activity/ActivityListItem';
import {
  BaseLenderActivityFragment,
  DealLenderStatus,
  ActivitiesFragment,
  LenderStatusChangeActivityFragment,
  LenderResearchStatusChangeActivityFragment,
} from '~/generated/graphql';
import ActivitiesItemBody from '../Activities/ActivitiesItemBody';

type LenderActivitiesItemProps = {
  lenderId: string;
  activity: ActivitiesFragment;
};

const LenderActivitiesItem: React.FC<LenderActivitiesItemProps> = ({ lenderId, activity }) => {
  const createdBy = (activity as BaseLenderActivityFragment).createdBy;

  const isCard = Boolean(
    ((activity as LenderStatusChangeActivityFragment).lenderStatus === DealLenderStatus.Interested &&
      (activity as LenderStatusChangeActivityFragment).interestedStatusReason) ||
      ((activity as LenderStatusChangeActivityFragment).lenderStatus === DealLenderStatus.Maybe &&
        (activity as LenderStatusChangeActivityFragment).maybeStatusReason) ||
      ((activity as LenderStatusChangeActivityFragment).lenderStatus === DealLenderStatus.Passed &&
        (activity as LenderStatusChangeActivityFragment).passedStatusReason) ||
      ((activity as LenderStatusChangeActivityFragment).lenderStatus === DealLenderStatus.NotCompetitive &&
        (activity as LenderStatusChangeActivityFragment).notCompetitiveStatusReason) ||
      (activity as LenderResearchStatusChangeActivityFragment).researchStatusReason,
  );

  return (
    <ActivityListItem createdBy={createdBy} createdAt={(activity as BaseLenderActivityFragment).createdAt} isCard={isCard}>
      <ActivitiesItemBody lenderId={lenderId} activity={activity} key={(activity as BaseLenderActivityFragment)._id} />
    </ActivityListItem>
  );
};

export default LenderActivitiesItem;
