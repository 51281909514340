import React, { FC } from 'react';
import { Col, FlexRow } from '~/components/layout';
import { Text } from '~/components/type';
import { Checkbox } from '~/components/form';

type NoteEditorToolbarAddonProps = {
  id: string;
  name: string;
  type: 'lender' | 'borrower';
  checked: boolean;
  onChange: (checked: boolean) => void;
};

const NoteEditorToolbarAddon: FC<NoteEditorToolbarAddonProps> = ({ id, name, type, checked, onChange }) => {
  return (
    <Col span="auto">
      <FlexRow>
        <Text utils={{ color: 'gray300', mx: 3 }}>|</Text>
        <Checkbox
          inputProps={{ id, checked, onChange: (e) => onChange((e.currentTarget as HTMLInputElement).checked) }}
          data-tooltip-id="GlobalTooltip"
          data-tooltip-content={`Check this if you want this note to be shown on ${name}’s ${
            type === 'lender' ? 'lender' : 'borrower'
          } profile, not just in this deal.`}
        >
          <Text utils={{ fontSize: 'xs', color: 'gray700', ml: '-3' }}>
            <Text as="span" desktop={{ display: 'none' }}>
              Add to {type} profile
            </Text>
            <Text as="span" mobile={{ display: 'none' }}>
              Also add to {type}’s {type === 'lender' ? 'lender' : 'borrower'} profile
            </Text>
          </Text>
        </Checkbox>
      </FlexRow>
    </Col>
  );
};

export default NoteEditorToolbarAddon;
