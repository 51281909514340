import React, { FC, useEffect, useState } from 'react';
import { ListChainedAnchor, ListItem } from '~/components/list';
import { CreateNoteInput, useGetLocalUserQuery } from '~/generated/graphql';
import { useLeavePageConfirm } from '~/hooks/useLeavePageConfirm';
import NoteEditorToolbarAddon from './NoteEditorToolbarAddon';
import UserAvatar from '~/components/ui/Avatar/UserAvatar';
import { Editor } from '~/components/vendor/Editor';

type AddNoteProps = {
  lender?: { id: string; name: string };
  borrower?: { _id: string; name: string };
  dealId?: string;
  onCreate: (input: CreateNoteInput) => Promise<void>;
  setNoteModifyingInProgress?: (isNoteModifyingInProgress: boolean) => void;
  placeholder?: string;
  showAddons?: boolean;
};

const AddNote: FC<AddNoteProps> = ({ lender, borrower, dealId, onCreate, setNoteModifyingInProgress, placeholder, showAddons }) => {
  const [text, setText] = useState('');
  const [textHash, setTextHash] = useState(Math.random());
  const [showOnExplore, setShowOnExplore] = useState(false);

  useLeavePageConfirm(Boolean(text), 'Are you sure want to abandon your note?');
  useEffect(() => {
    setNoteModifyingInProgress?.(Boolean(text));
  }, [Boolean(text)]);

  const { data: currentUserData } = useGetLocalUserQuery({ fetchPolicy: 'cache-only' });
  const currentUser = currentUserData?.currentUser;

  const handleCreate = async () => {
    if (!text) {
      return;
    }

    await onCreate({ lenderId: lender?.id, borrowerId: borrower?._id, dealId, showOnExplore, text });
    setShowOnExplore(false);
    setText('');
    setTextHash(Math.random());
  };

  return (
    <ListItem utils={{ alignItems: 'flex-start' }}>
      <ListChainedAnchor utils={{ mr: 6 }}>
        <UserAvatar size="lg" user={currentUser} />
      </ListChainedAnchor>
      <Editor
        placeholder={placeholder ?? 'Add a note'}
        value={text}
        valueHash={textHash}
        onChange={setText}
        onCreate={handleCreate}
        isCollapsible
        allowFileAttachments
        toolbarAddons={
          showAddons && (
            <NoteEditorToolbarAddon
              id="newNote"
              name={(lender?.name ?? borrower?.name)!}
              type={lender ? 'lender' : 'borrower'}
              checked={showOnExplore}
              onChange={setShowOnExplore}
            />
          )
        }
      />
    </ListItem>
  );
};

export default AddNote;
